import React from "react";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import NavBarPresident from "components/Navbars/PresidenteRightNav";
import Index from "views/President/president";

export default function President() {
  return (
    <div className="h-100">
      <NavBarPresident />
      <div>
        <Index />
      </div>
    </div>
  );
}
