import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import apiPartidos from "../../api/partidos";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert"; // core components
import { Error } from "./styles";
import _ from "lodash";
import PropTypes from "prop-types";

export default function ModalEdit(props) {
  const classes = useStyles();
  const { session } = props;
  const [name, setName] = React.useState(_.get(session, "name", ""));
  const [sigla, setSigla] = React.useState(_.get(session, "sigla", ""));
  const id = _.get(session, "id", "");

  const [alert, setAlert] = useState(false);
  const [validation, setValidation] = useState([]);

  const editPartido = () => {
    apiPartidos
      .post("partidos/update?id=" + id, {
        id: id,
        sigla: sigla,
        name: name,
      })
      .catch(function(error) {
        if (error.response) {
          setValidation(_.get(error, "response.data.errors", []));
        }
      })
      .then(function(response) {
        if (_.get(response, "data", false) == true) {
          setAlert(true);

          setTimeout(
            function() {
              setAlert();
              props.handleClick();
            }.bind(this),
            1000
          );
          return;
        }
      });
  };

  const handleForm = (e) => {
    switch (e.target.name) {
      case "sigla":
        return setSigla(e.target.value);
      case "name":
        return setName(e.target.value);
    }
  };

  return (
    <>
      <GridContainer style={{ display: "flex", justifyContent: "center" }}>
        <GridItem xs={18} sm={18} md={18}>
          <Card>
            <CardHeader color="success" className={classes.cardHeader}>
              <h4 className={classes.cardTitleWhite}>Editar Partido</h4>
              <span
                className="material-icons"
                onClick={() => props.handleClick()}
                style={{ cursor: "pointer" }}
              >
                close
              </span>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl className={classes.form}>
                    <InputLabel className={classes.label}>{"Sigla"}</InputLabel>
                    <Input name="sigla" value={sigla} onChange={handleForm} />
                    <Error>{_.get(validation, "sigla", "")}</Error>
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={8}>
                  <FormControl
                    className={classes.form}
                    style={{ minWidth: 250 }}
                  >
                    <InputLabel className={classes.label}>
                      {"Nome do partido"}
                    </InputLabel>
                    <Input name="name" value={name} onChange={handleForm} />
                    <Error>{_.get(validation, "name", "")}</Error>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter style={{ padding: "10px" }}>
              <Button onClick={editPartido} color="success">
                Salvar
              </Button>
            </CardFooter>
            <Snackbar
              place="tr"
              color="success"
              icon={AddAlert}
              message="Editado com sucesso"
              open={alert}
              closeNotification={() => setAlert(false)}
              close
            />
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
  },
};

ModalEdit.propTypes = {
  session: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
};

const useStyles = makeStyles(styles);
