import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import TablePagination from "@material-ui/core/TablePagination";
import Tooltip from "@material-ui/core/Tooltip";
import { ptBR } from "@material-ui/core/locale";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead
            className={classes[tableHeaderColor + "TableHeader"]}
            style={{ fontSize: "16px" }}
          >
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          { tableData.length > 0 ? (tableData || [])
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((prop, key) => {
              return (
                <TableRow key={key} className={classes.tableBodyRow}>
                  <TableCell
                    className={classes.tableCell}
                    style={{ fontSize: "16px" }}
                  >
                    {prop.sigla}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ fontSize: "16px" }}
                  >
                    {prop.name}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ cursor: "pointer", textAlign: "end" }}
                  >
                    <LightTooltip title="Editar partido">
                      <span
                        className="material-icons"
                        style={{ color: "#43a047" }}
                        onClick={() => props.handleClickOpenEdit(prop)}
                      >
                        create
                      </span>
                    </LightTooltip>

                    <LightTooltip title="Excluir partido">
                      <span
                        className="material-icons"
                        style={{ color: "red", marginLeft: "16px" }}
                        onClick={() => props.handleClickOpenDelete(prop)}
                      >
                        delete_sweep
                      </span>
                    </LightTooltip>
                  </TableCell>
                </TableRow>
              );
            }) :
              <TableRow key={0} className={classes.tableBodyRow}>
                  <TableCell
                      Colspan={3}
                      className={classes.dangerTableHeader}
                      style={{ fontSize: "20px", fontColor: classes.validation,textAlign:'center' }}
                  >
                      {'Não encontrado'}
                  </TableCell>
              </TableRow>
          }
        </TableBody>
      </Table>
      <ThemeProvider theme={theme}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </ThemeProvider>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray"
};

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  ptBR
);

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  }
}))(Tooltip);
CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  handleClickOpenEdit: PropTypes.func,
  handleClickOpenDelete: PropTypes.func
};
