import React from "react";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import moment from "moment";
import { ptBR } from "date-fns/locale";
import "date-fns";
import PropTypes from "prop-types";
import _ from "lodash";

export default function DashboardReport(props) {
  const { filters } = props;
  const sessions = _.get(filters, "sessions", []);
  const projects = _.get(filters, "type_projects", []);
  //const [data, setData] = React.useState(null);

  const classes = useStyles();

  const [session, setSession] = React.useState("");
  const [project, setProject] = React.useState("");
  const [partido, setPartido] = React.useState(0);
  const [state, setState] = React.useState("");
  const [projects_session, setProjectsSession] = React.useState(null);
  const [relatorioUrl, setUrl] = React.useState(null);

  const handleChange = (event) => {
    // const name = event.target.
    //setData(null);
    setState(event.target.value);
  };

  const currentDate = new Date();
  const oldDate = new Date().setMonth(new Date().getMonth() - 1);

  const [selectedDate, setSelectedDate] = React.useState(oldDate);
  const [selectedDateFinal, setSelectedDateFinal] = React.useState(currentDate);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleDateFinal = (date) => {
    setSelectedDateFinal(date);
  };

  const handleSession = async (e) => {
    setSession(e.target.value);
    if (state === "/report/voting") {
      const req = await props.apiReport.get("/session/report/parlamentary", {
        params: {
          id_session: e.target.value,
        },
      });
      setProjectsSession(_.get(req, "data", null));
    }
  };

  const handleProject = (e) => {
    setProject(e.target.value);
  };

  const handlePartido = (e) => {
    setPartido(e.target.value);
  };

  const onClickFind = async () => {

    switch (state) {
      case "/report/parlamentary":
        setUrl(state + "/" + partido);
        break;
      case "/report/voting":
        if (session === null || session === "") {
          alert("Selecione uma sessão");
          return;
        }

        setUrl(state + "/" + session);
        break;
      case "/report/detail":
        if (session === null || session === "") {
          alert("Selecione uma sessão");
          return;
        }
        setUrl(state + "/" + session);
        break;
      case "/report/presence":
        if (session === null || session === "") {
          alert("Selecione uma sessão");
          return;
        }
        setUrl(state + "/" + session);
        break;
      case "/report/tribuna":
        if (session === null || session === "") {
          alert("Selecione uma sessão");
          return;
        }
        setUrl(state + "/" + session);
        break;
      case "/report/projects":
        if (session === null || session === "") {
          alert("Selecione uma sessão");
          return;
        }
        setUrl(state + "/" + session + "/" + project);
        break;
      case "/report/active/plenary":
        setUrl(
          state +
            "/" +
            moment(selectedDate).format("YYYY-MM-DD") +
            "/" +
            moment(selectedDateFinal).format("YYYY-MM-DD")
        );
        break;
      case "/report/sessions":
        setUrl(
          state +
            "/" +
            moment(selectedDate).format("YYYY-MM-DD") +
            "/" +
            moment(selectedDateFinal).format("YYYY-MM-DD")
        );
        break;
    }
  };

  const onClickExport = async () => {
    console.log("Download");
  };

  return (
    <Grid container spacing={3} style={{ margin: "0px", width: "100%" }}>
      <Grid item xs={12} sm={12}>
        <Paper className={classes.paper}>
          Relatórios
          <div syles={{ display: "flex", justifyContent: "center" }}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple">
                Selecione o tipo de relatório
              </InputLabel>
              <Select
                native
                value={state}
                onChange={handleChange}
                label="Selecione o tipo de relatório"
              >
                <option aria-label="None" value="" />
                <option value={"/report/parlamentary"}>Parlamentares</option>
                <option value={"/report/projects"}>Projetos</option>
                <option value={"/report/sessions"}>Sessões</option>
                <option value={"/report/detail"}>Detalhado de Sessão</option>
                <option value={"/report/active/plenary"}>
                  Atividades do Plénario
                </option>
                <option value={"/report/voting"}>Votações</option>
                <option value={"/report/presence"}>Presença</option>
                <option value={"/report/tribuna"}>Tribuna</option>
              </Select>
            </FormControl>
            {state === "/report/presence" ||
            state === "/report/detail" ||
            state === "/report/projects" ||
            state === "/report/voting" ||
            state === "/report/tribuna" ? (
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple">
                  Selecione a Sessão
                </InputLabel>
                <Select
                  native
                  value={session}
                  onChange={handleSession}
                  label="Selecione a Sessão"
                >
                  <option aria-label="None" value="" />

                  {sessions.map((t) => (
                    <option key={t.id} value={t.id}>{t.name}</option>
                  ))}
                </Select>
              </FormControl>
            ) : (
              ""
            )}
            {state === "/report/projects" ? (
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple">
                  Tipo de Projeto
                </InputLabel>
                <Select
                  native
                  value={project}
                  onChange={handleProject}
                  label="Tipo de Projeto"
                  inputProps={{
                    name: "age",
                    id: "outlined-age-native-simple",
                  }}
                >
                  <option aria-label="None" value="" />

                  {projects.map((t) => (
                    <option key={t.id} value={t.id}>{t.name}</option>
                  ))}
                </Select>
              </FormControl>
            ) : (
              ""
            )}
            {state === "/report/voting" && projects_session !== null ? (
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple">
                  Tipo de Projeto
                </InputLabel>
                <Select
                  native
                  value={project}
                  onChange={handleProject}
                  label="Tipo de Projeto"
                  inputProps={{
                    name: "age",
                    id: "outlined-age-native-simple",
                  }}
                >
                  <option aria-label="None" value="" />

                  {projects_session.map((t) => (
                    <option key={t.id} value={t.id}>{t.name}</option>
                  ))}
                </Select>
              </FormControl>
            ) : (
              ""
            )}

            {state === "/report/parlamentary" ? (
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple">
                  Selecione o Partido
                </InputLabel>
                <Select
                  native
                  value={partido}
                  onChange={handlePartido}
                  label="Selecione o Partido"
                  inputProps={{
                    name: "age",
                    id: "outlined-age-native-simple",
                  }}
                >
                  <option value={0}> Todos</option>

                  {JSON.parse(sessionStorage.getItem("PARTIDOS")).map((t) => (
                    <option key={t.id} value={t.id}>{t.name}</option>
                  ))}
                </Select>
              </FormControl>
            ) : (
              ""
            )}
            {state !== "" &&
            state !== "/report/presence" &&
            state !== "/report/voting" &&
            state !== "/report/detail" &&
            state !== "/report/parlamentary" &&
            state !== "/report/projects" &&
            state !== "/report/tribuna" ? (
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Data de Início da Pesquisa"
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Data Final da pesquisa"
                    format="dd/MM/yyyy"
                    value={selectedDateFinal}
                    onChange={handleDateFinal}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            ) : (
              ""
            )}
          </div>
          {state !== "" && state !== null ? (
            <>
              <Button
                onClick={onClickFind}
                style={{ marginTop: "15px" }}
                variant="contained"
                color="primary"
                disableElevation
              >
                Visualizar Relatório
              </Button>
              <Button
                onClick={onClickExport}
                style={{
                  display: "none",
                  marginTop: "15px",
                  marginLeft: "15px",
                }}
                variant="contained"
                color="primary"
                disableElevation
              >
                Exportar Relatório
              </Button>
            </>
          ) : (
            ""
          )}
        </Paper>
      </Grid>

      {relatorioUrl != null ? (
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            <iframe
              height="500"
              width="100%"
              src={process.env.REACT_APP_STAGE + relatorioUrl}
            ></iframe>
          </Paper>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: "center",
    color: theme.palette.text.secondary,
    fontSize: "160%",
    fontWeight: "bold",
    borderRadius: "10px",
    //  width:'100%'
  },
  formControl: {
    margin: theme.spacing(3),
    minWidth: 400,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

DashboardReport.propTypes = {
  classes: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  apiReport: PropTypes.func.isRequired,
};
