import React, { Component } from "react";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "assets/css/style.css";
import "assets/css/videowall.css";
import VideoWall from "components/VideoWall/VideoWall";
import NavBar from "components/Navbars/VideoWallNav";
import { getConfigurations } from "api/videowall.js";
import echo from "api/channels";
import "../assets/sass/components/videowall/index.scss";
import "../assets/sass/styles.scss";

import VideoWallBg from "assets/img/videowall-bg.jpg";

class VideoWallPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      configurations: {},
      settingsVotacoes: {},
      //configurationPlenary: [],
    };
  }

  async componentDidMount() {
    const { videoWall, votacao /*configurationPlenary*/ } = await getConfigurations();
    this.setState({ configurations: videoWall });
    this.setState({ settingsVotacoes: votacao });
    //this.setState({ configurationPlenary: configurationPlenary });

    //Canal de configurações
    echo.channel("videowall").listen("VideoWall", async (e) => {
      this.setState({ configurations: e.configs });
    });
  }

  render() {
    const { configurations, settingsVotacoes /*configurationPlenary*/ } = this.state;
    return (
      <div className="containerVideowall" style={{ backgroundImage: "url(" + VideoWallBg + ")" }}>
        <NavBar configurations={configurations} />
        <VideoWall configurations={configurations} settingsVotacoes={settingsVotacoes} /*configurationPlenary={configurationPlenary}*/ />
      </div>
    );
  }
}

export default VideoWallPage;
