import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { Grid, Typography } from "@material-ui/core";
import logo from "assets/img/logo-cliente.png";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'rgba(0,0,0,0.9)',
    height: "64px",
    padding: "0px 20px",
    display: "flex",
    justifyContent: "flex-end",
    borderBottom: "1px solid #555555",
  },
  env_date: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: "calc(14px + 100%)",
    margin: "0px 0px",
  },
}));

export default function Index(props) {
  const classes = useStyles();
  let data = new Date();
  const optionFormat = { timeZone: 'America/Sao_Paulo', hour: 'numeric', minute: 'numeric', second: 'numeric' };
  const hour              = data.toLocaleTimeString("pt-BR",optionFormat)
  const [str_hora, setHora] = React.useState(hour);
  const str_data = data.toLocaleDateString("pt-BR");
  
  setInterval(() => {
    const now = new Date();
    setHora(now.toLocaleTimeString("pt-BR",optionFormat));
  }, 1000);

  return (
    <Toolbar className={classes.root}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs>
          <img
            src={
              props.configurations?.logoCliente
                ? props.configurations.logoCliente
                : logo
            }
            style={{
              width: "auto",
              maxWidth: "180px",
              maxHeight: "50px",
              display: "block",
            }}
          />
        </Grid>
        <Grid item xs></Grid>
        <Grid item xs className="box-time">
          <Typography align="right" className={classes.env_date}>
            {str_data + " | " + str_hora}
          </Typography>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

Index.propTypes = {
  configurations: PropTypes.object.isRequired,
};