import React from 'react';
import _ from "lodash";
import "../../../assets/sass/components/president/voting.scss";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
function ModalRecovery(props) {
    const { user} = props;
    return (
        <>
            <GridContainer className="generalModal">
                <GridItem xs={12}>
                    <div className="modalContainer">
                        <div className="modalContainer--header">
                            <h4>Redefinir senha</h4>
                        </div>
                        <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center" }}>
                            <span>Foi enviado para seu e-mail <strong>{user.email}</strong> <br></br>as instruções para redefinir sua senha.</span>
                        </div>
                        <div className="modalFooter__vote">
                            <div
                                className="modalFooter__vote--voltar"
                                variant="contained"
                                onClick={props.handleCloseRecovery}
                            >
                                <i className="fa-solid fa-arrow-rotate-left"></i>
                                Voltar
                            </div>
                            <div
                                className="modalFooter__vote--facial componentButton componentButton--green mx-0"
                                variant="contained"
                                onClick={props.handleCloseRecovery}
                            >
                                Comprendido
                            </div>
                        </div>
                    </div>
                </GridItem>
            </GridContainer>
        </>
    );
}

export default ModalRecovery;
