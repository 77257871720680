import { container } from "assets/jss/material-dashboard-react.js";

const appStyle = () => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    backgroundColor: "black",
    color: "white",
  },

  content: {
    // minHeight: "calc(100vh - 123px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  container,
  map: {
    marginTop: "70px",
  },
});

export default appStyle;
