import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import avatar from "assets/img/faces/marc.jpg";
import Brightness1Icon from "@material-ui/icons/Brightness1";

class Presence extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, users } = this.props;
    return (
      <List className={classes.root}>
        {users == null
          ? ""
          : users.map((prop, key) => (
              <ListItem
                key={key}
                style={{ padding: "0px" }}
                alignItems="flex-start"
              >
                <ListItemAvatar>
                  <Avatar alt="tribuna" src={prop.photoUrl || avatar} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    prop.vote_committee[0] == null ? (
                      <p className={classes.cargo}>
                        {" "}
                        Não votou
                        <Brightness1Icon
                          style={{
                            width: "12px",
                            marginLeft: "4px",
                            color: "#ccc",
                          }}
                        />
                      </p>
                    ) : prop.vote_committee[0].vote == null ? (
                      <p className={classes.cargo}>
                        {" "}
                        Absteve
                        <Brightness1Icon
                          style={{
                            width: "12px",
                            marginLeft: "4px",
                            color: "#4AA2A2",
                          }}
                        />
                      </p>
                    ) : prop.vote_committee[0].vote == true ? (
                      <p className={classes.cargo}>
                        SIM
                        <Brightness1Icon
                          style={{
                            width: "12px",
                            marginLeft: "4px",
                            color: "#43BD18",
                          }}
                        />
                      </p>
                    ) : (
                      <p className={classes.cargo}>
                        NÃO
                        <Brightness1Icon
                          style={{
                            width: "12px",
                            marginLeft: "4px",
                            color: "#f00",
                          }}
                        />
                      </p>
                    )
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {prop.public_name}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            ))}
      </List>
    );
  }
}

const styles = {
  root: {
    width: "100%",
    padding: "20px",
    marginTop: "20px",
    display: "grid",
    gridTemplateColumns: "auto auto",
    // backgroundColor: theme.palette.background.paper,
  },
  cargo: {
    fontSize: "12px",
    margin: "0px",
    display: "flex",
    alignItems: "center",
  },
  inline: {
    display: "inline",
    fontSize: "12px",
  },
};

Presence.propTypes = {
  classes: PropTypes.object.isRequired,
  users: PropTypes.array,
};

export default withStyles(styles)(Presence);
