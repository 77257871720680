import React, { useState, useEffect } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import api from "../../api/api_v2";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import _ from "lodash";

export default function ConfAmbiente() {
  const [configurations, setConfiguration] = useState([]);
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await api
        .get("/configuration")
        .then((response) => setConfiguration(_.get(response, "data", [])));
    };
    fetchData();
  }, []);

  const update = async (event, id) => {
    return await api
      .post("/configuration/update", {
        id: id,
        active: event.target.checked,
      })
      .then((response) => {
        setConfiguration(_.get(response, "data", []));
        setAlert(true);
        setTimeout(() => {
          setAlert(false);
        }, 2000);
      });
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card
          style={{
            boxShadow:
              "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
          }}
        >
          {
            configurations.filter(item =>item.parent_id==null).map((itemParent, i) => (
                  <CardBody key={i}>
                      <GridContainer>
                        <GridItem xs={6}>
                          <FormControlLabel
                              label={itemParent.name}
                              control={
                                <Switch
                                    checked={itemParent.active}
                                    onChange={(e) => update(e, itemParent.id)}
                                    color="primary"
                                    value="dynamic-class-name"
                                />
                              }
                          />
                        </GridItem>
                      {
                        configurations.filter(filter => filter.parent_id==itemParent.id).map((item, i) => (
                            <GridItem xs={6}>
                              <ul className="list">
                                <li>
                                  <FormControlLabel
                                      label={item.name}
                                      control={
                                        <Switch
                                            checked={item.active}
                                            onChange={(e) => update(e, item.id)}
                                            color="primary"
                                            value="dynamic-class-name"
                                            disabled={itemParent.active==1 ? false : true}
                                        />
                                      }
                                  />
                                </li>
                              </ul>
                            </GridItem>
                          )
                        )
                      }
                  </GridContainer>
                </CardBody>
              )
            )
          }
          <Snackbar
            place="tr"
            color="success"
            icon={AddAlert}
            message={"Configuração atualizada com sucesso"}
            open={alert}
            closeNotification={() => setAlert(false)}
            close
          />
        </Card>
      </GridItem>
    </GridContainer>
  );
}
