import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import _ from "lodash";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import StickyHeadTable from "components/President/Documents/documents.js";
import Votacao from "components/President/Documents/votacao.js";
import VotacaoComission from "components/President/Documents/votacaoComision";
import ControllerTimer from "components/President/Tribuna/index";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import ModalVoting from "components/Parliamentary/modal/voting";
import ModalVotingBloc from "components/Parliamentary/modal/votingBloco";
import ModalVotingCommittee from "components/Parliamentary/modal/votingCommittee";
//import { apiImplement } from "api/tribuna";
import { GrantComment, RejectComment, ControlTime } from "api/_tribuna";
import {
  apiCloseDiscution,
  apiStartVotacao,
  apiDiscussion,
  apiReadDocument,
  apiSuspendSession,
  apiStartVotingBlock,
  apiOpenPdf,
} from "api/president";
import { apiFinishVoting, getVotingComision } from "api/voting";
import echo from "api/channels";
//import { apiAddTime } from "api/timeapp.js";
import Fade from "@material-ui/core/Fade";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import ListPresent from "components/ControllerTime/ListPresent";
import Pdf from "components/Util/pdf";
import "../../assets/sass/components/president/dashboard.scss";
import "../../assets/sass/styles.scss";
import { apiGetDocumentsSessionPresident } from "../../api/documents";
import { checkStartSession } from "api/_tribuna";
import votingComision from "./List/votingComision";
import StickyHeadTable2 from "components/Parliamentary/DocsCityCouncilor/docsCityCouncilor";
import StickyHeadTable3 from "components/Parliamentary/DocsCityCouncilor/previousDocuments";

var tokenGet =
  sessionStorage.getItem("TOKEN_KEY") &&
  sessionStorage.getItem("TOKEN_KEY").charAt(0) == '"'
    ? sessionStorage.getItem("TOKEN_KEY")
    : JSON.stringify(sessionStorage.getItem("TOKEN_KEY"));
var TOKEN_KEY = JSON.parse(tokenGet);
class DashboardPresident extends React.Component {
  constructor(props) {
    super(props);
    const { documents, roteiro, idsession, timeLeft } = props;
    this.state = {
      //roteiro: _.get(roteiro, 'file', null),
      roteiro: _.get(roteiro, "pdfUrl", null),
      documents: _.get(documents, "leitura", null),
      idsession: idsession,
      value: _.get(roteiro, "file", null) !== null ? 0 : 5,
      visualizar: null,
      file: null,
      pdfFile: null,
      pdfExecutivo: null,
      documents_votacao: _.get(documents, "votacao", null),
      alertSuccess: false,
      aparte: null,
      modal: false,
      modalPdf: false,
      modal_votacao: false,
      modal_votacao_comision: false,
      btn_votacao_comision: false,
      votacao: _.get(documents, "start", null),
      votingComision: _.get(documents, "start_committee", null),
      votacao_checkedall: false,
      votacao_checkedall_disabled: false,
      votacao_bloco: null,
      votacao_comisao: null,
      descriptors: null,
      modalquorun: false,
      openwebcam:
        localStorage.getItem("WEBCAM") === null
          ? 0
          : localStorage.getItem("WEBCAM"),
      votingBlock: [],
      modal_votacao_bloco: false,
      type_voting_bloc: null,
      user_vote: _.get(documents, "start.vote_user", false),
      implement_aparte: [],
      implement_ordem: [],
      enable_discution: [],
      disabled_buttons: false,
      buttonEnableConcluir: false,
      buttonEnableConcluirAparte: false,
      messageError: "",
      alertError: false,
    };
  }
  clearImplementRow() {
    this.setState({ modal: false });
    this.setState({ implement_aparte: [] });
    this.setState({ implement_ordem: [] });
  }

  addTimeImplement(operation, time) {
    //apiAddTime(operation, time);
    ControlTime(operation, time);
    this.setState({ buttonEnableConcluirAparte: false });
  }

  async componentDidMount() {
    const data = await checkStartSession();

    if (data.aparte && data.aparte.length) {
      this.setState({ buttonEnableConcluir: true });
    }

    this.state.documents_votacao.map((row) => {
      if (row.discution) {
        this.setState({ enable_discution: { id: row.id, status: true } });
      }
    });

    echo.channel("tribuna").listen("TalkTimeStop", () => {
      this.setState({ implement_aparte: [] });
      this.setState({ modal: false });
      this.setState({ buttonEnableConcluir: false });
      this.setState({ buttonEnableConcluirAparte: false });
    });

    echo.channel("tribuna").listen("GoOnTalk", (e) => {
      const { aparte, modal, implement_aparte, implement_ordem } = this.state;
      if (modal == true) {
        if (e.type == "ordem") {
          let add = _.find(implement_ordem, function(obj) {
            if (obj.user.id === e.user.id) {
              return true;
            }
          });

          if (
            _.get(add, "id", null) === null &&
            _.get(aparte, "user.id", null) !== _.get(e, "user.id", null)
          ) {
            let column = implement_ordem;
            column.push(e);
            this.setState({ implement_ordem: column });
          }

          return;
        }
        let add = implement_aparte;

        add.push(e);
        this.setState({ implement_aparte: add });
        return;
      }
      this.setState({ aparte: e });
      this.handleModal();
    });

    echo.channel("tribuna").listen("TalkTimeImplementStop", () => {
      const { modal } = this.state;

      if (modal == false) {
        this.setState({ buttonEnableConcluir: false });
        this.setState({ buttonEnableConcluirAparte: false });
      }
    });

    echo.channel("tribuna").listen("TalkTimeImplement", () => {
      this.setState({ buttonEnableConcluir: true });
      this.setState({ buttonEnableConcluirAparte: true });
      this.setState({ modal: false });
    });

    //canal de alerta da tribuna
    echo.channel("tribuna").listen("MessageDenied", () => {
      this.setState({ modal: false });
    });

    echo.channel("subscription").listen("StopSubscription", () => {
      this.clearImplementRow();
    });
    echo.channel("subscription").listen("CloseDiscution", () => {
      this.clearImplementRow();
    });

    echo.channel("voting").listen("StartVoting", (e) => {
      this.setState({ idsession: _.get(e, "id_session", null) });
      this.setState({ votacao: e });
    });

    // Retorna dados da votação da comissão iniciada
    const data_comision = await getVotingComision();
    if (data_comision != null) {
      let user = JSON.parse(sessionStorage.getItem("USER")); // get user

      this.setState({ votacao_comisao: true });
      this.setState({ value: 6 });

      //validar membros da comissão e apresenta modal apenas para os logados
      if (this.state.votingComision.membersCommittee.includes(user.id)) {
        this.setState({ btn_votacao_comision: true });
      }

      this.setState({ disabled_buttons: true }); // desabilitar botões de iniciar votação
    }

    //canal informa inicio da votação das comissões
    echo.channel("votingComision").listen("StartVotingComision", (e) => {
      let user = JSON.parse(sessionStorage.getItem("USER")); // get user
      this.setState({ votacao_comisao: true });
      this.setState({ value: 6 });
      this.setState({ votingComision: e });
      this.setState({ idsession: _.get(e, "id_session", null) });
      this.setState({ btn_votacao_comision: false });

      //validar membros da comissão e apresenta modal apenas para os logados
      if (e.members_committee.includes(user.id)) {
        this.setState({ btn_votacao_comision: true });
      }

      this.setState({ disabled_buttons: true }); // desabilitar botões de iniciar votação
    });

    //canal informa fim da votação das comissões
    echo.channel("voting").listen("StopVoteCommittee", (e) => {
      this.setState({ btn_votacao_comision: false });
      this.setState({ votacao_comisao: false });
      this.setState({ user_vote: false });
      this.setState({ value: 3 });

      this.setState({ disabled_buttons: false }); // desabilitar botões de iniciar votação
    });

    echo.channel("voting").listen("StartVotingBloc", (e) => {
      this.setState({ idsession: _.get(e, "documents.id_session", null) });
      this.setState({ votacao: e.documents });
      this.setState({ votacao_bloco: e });
    });

    echo.channel("voting").listen("StopVoting", () => {
      this.setState({ votacao: null });
      this.setState({ user_vote: false });
      this.setState({ votacao_checkedall: false });
      this.setState({ votacao_checkedall_disabled: false });
      this.setState({ votingBlock: [] });
    });

    echo.channel("session").listen("StopSession", () => {
      this.props.stop();
    });

    echo.channel("session").listen("Suspend", () => {
      this.props.stop();
    });

    echo.channel("session").listen("GetDocuments", (docs) => {
      //this.setState({ roteiro: _.get(docs, 'roteiro.file', null) })
      this.setState({ roteiro: _.get(docs, "roteiro.pdfUrl", null) });
      this.setState({ documents: _.get(docs, "documents.leitura", null) });
      this.setState({
        documents_votacao: _.get(docs, "documents.votacao", null),
      });
      this.setState({ votacao: _.get(docs, "documents.start", null) });
    });

    echo.channel("session").listen("Quorum", (e) => {
      let quorum = _.get(e, "quorum", false);
      if (quorum == false) {
        this.setState({ modalquorun: true });
        return;
      }
      this.setState({ modalquorun: false });
    });
  }

  handleChange = (event) => {
    this.setState({ value: event });
  };

  handleVisualizar = (doc) => {
    this.setState({ file: _.get(doc, "pdfUrl", null) });
  };

  handleOpenExecutivo = (pdfExecutivo) => {
    if (pdfExecutivo.indexOf("localhost") >= 0) {
      pdfExecutivo = pdfExecutivo.replace("/api/", "");
    } else {
      pdfExecutivo = pdfExecutivo.replace("/api2/api/", "/api2/");
    }
    pdfExecutivo = pdfExecutivo.replace("public", "") + "#toolbar=0&view=FitH";
    this.setState({ modalPdf: true, pdfFile: pdfExecutivo });
  };

  handleOpenPdf = (pdf, name, type) => {
    let user = JSON.parse(sessionStorage.getItem("USER"));
    if (pdf.indexOf(".pdf") >= 0 && pdf.indexOf("uploads") >= 0) {
      this.handleOpenExecutivo(pdf);
    } else {
      const urlLog =
        "log.pdf?name=" + name + "&type=" + type + "&userid=" + user.id;
      const pdfUrl =
        pdf +
        "?name=" +
        name +
        "&userid=" +
        user.id +
        "&api_token=" +
        TOKEN_KEY;
      const result = apiOpenPdf(urlLog);
      this.setState({ modalPdf: true });
      this.setState({ pdfFile: pdfUrl, pdfExecutivo: null });
    }
  };

  handleCloseModalPdf = () => {
    this.setState({ modalPdf: false });
    this.setState({ pdfFile: null });
  };

  handleCloseChange = () => {
    this.setState({ file: null });
  };

  handlelido = (id) => {
    apiReadDocument(id);
  };

  handlevotacao = async (id, name) => {
    const { idsession } = this.state;
    const result = await apiStartVotacao(id, name);
    let docs = await apiGetDocumentsSessionPresident(idsession);

    if (_.get(result, "data.error", null) == 401) {
      this.setState({ alertError: true });
      this.setState({
        messageError: _.get(result, "data.message", "Não há Quorún"),
      });

      setTimeout(() => {
        this.setState({ alertError: false });
      }, 3000);
      return;
    }
    this.setState({
      documents_votacao: _.get(docs, "documents.votacao", null),
    });
    this.setState({ alertSuccess: true });
    setTimeout(() => {
      this.setState({ alertSuccess: false });
    }, 2000);
  };

  handletribuna = (id, name) => {
    apiDiscussion(id, name);
    this.setState({ enable_discution: { id: id, status: true } });
  };

  closeDiscution = async (id, name) => {
    let response = await apiCloseDiscution(id, name);
    if (response != null) {
      alert(response);
      return;
    }
    this.setState({ enable_discution: { id: id, status: false } });
  };

  handleModal = () => {
    this.setState({ modal: true });
  };

  handleModalConceder = (time, id, type) => {
    const { implement_aparte, implement_ordem } = this.state;
    //apiImplement(time, id, type, "/tribuna/talk/time/approved");
    GrantComment(time, id, type);

    if (_.get(implement_ordem[0], "user.name", null) != null) {
      this.setState({ aparte: implement_ordem[0] });
      implement_ordem.shift();
      this.setState({ implement_ordem: implement_ordem });
      this.setState({ modal: true });

      return;
    } else if (_.get(implement_aparte[0], "user.name", null) != null) {
      this.setState({ aparte: implement_aparte[0] });
      implement_aparte.shift();
      this.setState({ implement_aparte: implement_aparte });
      this.setState({ modal: true });

      return;
    } else {
      this.setState({ modal: false });
    }
  };

  handleModalNaoConceder = (id_user, type) => {
    const { implement_aparte, implement_ordem } = this.state;

    let message = "Questão de Ordem Negada";

    if (type === "aparte") {
      if (_.get(implement_aparte[0], "user.name", null) != null) {
        message = "Aparte Negado";
        this.setState({ aparte: implement_aparte[0] });
        implement_aparte.shift();
        this.setState({ implement_aparte: implement_aparte });
        this.setState({ modal: true });
        //apiImplement(id_user, id_user, message, "/tribuna/talk/time/TalkDenied");
        return;
      } else {
        this.setState({ modal: false });
      }
    }

    if (_.get(implement_ordem[0], "user.name", null) != null) {
      this.setState({ aparte: implement_ordem[0] });
      implement_ordem.shift();
      this.setState({ implement_ordem: implement_ordem });
      this.setState({ modal: true });
      //apiImplement(id_user, id_user, message, "/tribuna/talk/time/TalkDenied");
      return;
    } else {
      this.setState({ modal: false });
    }

    RejectComment(id_user, message);
  };

  handleClose = () => {
    this.setState({ modal_votacao: false });
    this.setState({ modal_votacao_comision: false });
  };

  handleOpen = () => {
    this.setState({ modal_votacao: true });
  };

  handleOpenComision = () => {
    this.setState({ modal_votacao_comision: true });
  };

  handleFinish = (id, name) => {
    apiFinishVoting(id, name);
    this.setState({ votingBlock: [] });
    this.setState({ votacao_checkedall: false });
    this.setState({ votacao_checkedall_disabled: false });
  };
  handleSessionSuspender = () => {
    this.setState({ modalquorun: false });
    apiSuspendSession();
  };

  handleVotingBloco = (id, type) => {
    const { votingBlock, type_voting_bloc } = this.state;
    if (votingBlock.find((element) => element === id)) {
      votingBlock.splice(votingBlock.indexOf(id), 1);
      if (votingBlock.length === 0 || votingBlock.length === null) {
        this.setState({ type_voting_bloc: null });
      }
      this.setState({ votingBlock: votingBlock });
      return;
    }
    if (type_voting_bloc != null && type != type_voting_bloc) {
      alert("Você só pode selecionar tipos de votação " + type_voting_bloc);
      return;
    }
    if (type_voting_bloc == null) {
      this.setState({ type_voting_bloc: type });
    }

    votingBlock.push(id);
    this.setState({ votingBlock: votingBlock });
  };

  handleAllChecked = () => {
    this.setState({ votacao_checkedall: !this.state.votacao_checkedall });
    let documentos = this.state.documents_votacao;
    const type_voting = documentos[0].type_voting;

    documentos.map((row) => {
      if (row.voted == false && row.type_voting == type_voting) {
        this.handleVotingBloco(row.id, row.type_voting);
      }
    });
  };

  handleSendVotingBlock = async () => {
    const { votingBlock, type_voting_bloc } = this.state;
    const result = await apiStartVotingBlock(votingBlock, type_voting_bloc);

    if (_.get(result, "data.error", null) == 401) {
      this.setState({ alertError: true });
      this.setState({
        messageError: _.get(result, "data.message", "Não há Quorún"),
      });
      setTimeout(() => {
        this.setState({ alertError: false });
      }, 3000);
      return;
    }

    this.setState({ votacao_checkedall_disabled: true });
    this.setState({ alertSuccess: true });
    setTimeout(() => {
      this.setState({ alertSuccess: false });
    }, 2000);
  };

  handleCloseBloc = () => {
    this.setState({ modal_votacao_bloco: false });
  };
  handleButtonEnableConcluir = (operation, time) => {
    //apiAddTime(operation, time);
    ControlTime(operation, time);
    this.setState({ buttonEnableConcluir: false });
  };

  handleOpenBloc = () => {
    this.setState({ modal_votacao_bloco: true });
  };

  handleTrueVoteUser = () => {
    this.setState({ user_vote: true });
  };

  hasDocumentWithPastDate = () => {
    const { docsTribuna } = this.props;
    const currentDate = new Date();

    if (docsTribuna && docsTribuna.length > 0) {
      return docsTribuna.some((doc) => new Date(doc.date_use) < currentDate);
    }

    return false;
  };

  render() {
    const { classes, docsTribuna, timeLeft } = this.props;
    const {
      alertError,
      messageError,
      buttonEnableConcluirAparte,
      buttonEnableConcluir,
      enable_discution,
      user_vote,
      votingBlock,
      idsession,
      openwebcam,
      descriptors,
      votacao,
      aparte,
      alertSuccess,
      value,
      documents,
      documents_votacao,
      votacao_checkedall,
      votacao_checkedall_disabled,
      modalPdf,
      roteiro,
      file,
      pdfFile,
      votacao_comisao,
      votingComision,
    } = this.state;

    let user = JSON.parse(sessionStorage.getItem("USER")); // get user
    return (
      <div className="dashboardTable">
        {(value === 1 || value === 3) && file != null ? (
          <button
            style={{
              width: "40px",
              height: "40px",
              fontSize: "30px",
              position: "relative",
              left: "44%",
              top: "0%",
              color: "white",
              backgroundColor: "#4B5A5A",
              border: "10px",
            }}
            onClick={() => this.handleCloseChange()}
          >
            X
          </button>
        ) : (
          ""
        )}
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              label="Roteiro"
              style={
                roteiro === null ? { display: "none" } : { display: "block" }
              }
              onClick={() => this.handleChange(0)}
              value={0}
            />
            <Tab
              label="Documentos"
              style={
                _.get(documents, "0.id", null) === null
                  ? { display: "none" }
                  : { display: "block" }
              }
              onClick={() => this.handleChange(1)}
              value={1}
            />
            <Tab
              label="Votação"
              style={
                _.get(documents_votacao, "0.id", null) === null
                  ? { display: "none" }
                  : { display: "block" }
              }
              onClick={() => this.handleChange(3)}
              value={3}
            />
            <Tab
              label="Inscrições"
              onClick={() => this.handleChange(5)}
              value={5}
            />
            {votacao_comisao == true ? (
              <Tab
                label="Votação das Comissões"
                onClick={() => this.handleChange(6)}
                value={6} /* Aba votação das comissões */
              />
            ) : (
              ""
            )}
            {user.id_access_level === 5 ? (
              <Tab
                label="Presenças"
                onClick={() => this.handleChange(7)}
                value={7} /* Aba de preseças para logout */
              />
            ) : (
              ""
            )}
            <Tab
              label="DOCUMENTOS DO VEREADOR"
              onClick={() => this.handleChange(8)}
              value={8}
            />
            <Tab
              label="DOCUMENTOS ANTERIORES"
              style={
                this.hasDocumentWithPastDate()
                  ? { display: "block", fontSize: "1.5rem" }
                  : { display: "none" }
              }
              onClick={() => this.handleChange(9)}
              value={9}
            />
          </Tabs>
        </AppBar>
        {/*
                    roteiro !== null ?
                        <iframe src={roteiro + '#toolbar=0'} style={value === 0 && roteiro !== null ? { display: 'block' } : { display: 'none' }} width="100%" height="890"></iframe>
                        : ''*/}
        {roteiro !== null ? (
          <div
            className="pdfRoteiro"
            style={
              value === 0 && roteiro !== null
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <Pdf height={800} file={roteiro} />
          </div>
        ) : (
          ""
        )}
        {value === 1 &&
        documents != null &&
        (file === null || pdfFile != null) ? (
          <StickyHeadTable
            handlelido={this.handlelido.bind()}
            documentos={documents}
            handleVisualizar={this.handleVisualizar.bind()}
            handleOpenPdf={this.handleOpenPdf.bind()}
          />
        ) : (
          ""
        )}
        {(value === 1 || value === 3) && file != null ? (
          <iframe src={file + "#toolbar=0"} width="100%" height="350"></iframe>
        ) : (
          ""
        )}
        {value === 5 ? (
          <ControllerTimer
            timeLeft={timeLeft}
            handleButtonEnableConcluir={this.handleButtonEnableConcluir.bind(
              this
            )}
            buttonEnableConcluir={buttonEnableConcluir}
          />
        ) : (
          ""
        )}
        {/* Validando usuario para apresentação da tab PRESENÇAS do controlador */}
        {value === 7 && user.id_access_level === 5 ? <ListPresent /> : ""}

        {value === 3 && documents_votacao != null && file === null ? (
          <Votacao
            enable_discution={enable_discution}
            disabled_buttons={this.state.disabled_buttons}
            closeDiscution={this.closeDiscution.bind(this)}
            user_vote={user_vote}
            handleOpenBloc={this.handleOpenBloc.bind(this)}
            handleSendVotingBlock={this.handleSendVotingBlock.bind(this)}
            votacao={votacao}
            handleVotingBloco={this.handleVotingBloco.bind(this)}
            votingBlocoSelect={votingBlock}
            documentos={documents_votacao}
            handletribuna={this.handletribuna}
            handlevotacao={this.handlevotacao.bind()}
            handleFinish={this.handleFinish.bind()}
            handleOpen={this.handleOpen.bind()}
            handleVisualizar={this.handleVisualizar.bind()}
            handleOpenPdf={this.handleOpenPdf.bind()}
            votacao_checkedall={votacao_checkedall}
            votacao_checkedall_disabled={votacao_checkedall_disabled}
            handleAllChecked={this.handleAllChecked.bind()}
          />
        ) : (
          ""
        )}

        {value === 6 &&
        documents_votacao != null &&
        file === null /* Votação das comissões*/ ? (
          <VotacaoComission
            enable_btn={this.state.btn_votacao_comision}
            closeDiscution={this.closeDiscution.bind(this)}
            user_vote={user_vote}
            handleOpenBloc={this.handleOpenBloc.bind(this)}
            handleSendVotingBlock={this.handleSendVotingBlock.bind(this)}
            votacao={votacao}
            handleVotingBloco={this.handleVotingBloco.bind(this)}
            votingBlocoSelect={votingBlock}
            documentos={documents_votacao}
            handletribuna={this.handletribuna}
            handlevotacao={this.handlevotacao.bind()}
            handleFinish={this.handleFinish.bind()}
            handleOpen={this.handleOpenComision.bind()}
            handleVisualizar={this.handleVisualizar.bind()}
            handleOpenPdf={this.handleOpenPdf.bind()}
            votacao_checkedall={votacao_checkedall}
            votacao_checkedall_disabled={votacao_checkedall_disabled}
            handleAllChecked={this.handleAllChecked.bind()}
          />
        ) : (
          ""
        )}
        {value == 8 ? (
          <StickyHeadTable2
            docsTribuna={docsTribuna}
            handleVisualizar={this.handleVisualizar.bind()}
            handleOpenPdf={this.handleOpenPdf.bind()}
          />
        ) : (
          ""
        )}
        {value == 9 ? (
          <StickyHeadTable3
            docsTribuna={docsTribuna}
            handleVisualizar={this.handleVisualizar.bind()}
            handleOpenPdf={this.handleOpenPdf.bind()}
          />
        ) : (
          ""
        )}

        <Snackbar
          place="tr"
          color="success"
          icon={AddAlert}
          message="Votação Iniciada"
          open={alertSuccess}
          closeNotification={() => this.setState({ alertSuccess: false })}
          close
        />

        <Snackbar
          place="tr"
          color="danger"
          icon={AddAlert}
          message={messageError}
          open={alertError}
          closeNotification={() => this.setState({ alertError: false })}
          close
        />

        <Modal
          className={classes.modal}
          open={this.state.modal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Paper className="modalAparte">
            <h4>
              Parlamentar {_.get(aparte, "user.name", "")} pediu{" "}
              {_.get(aparte, "type", "")}
            </h4>
            <div>
              <Button
                disabled={buttonEnableConcluirAparte}
                onClick={() =>
                  this.handleModalConceder(
                    _.get(aparte, "time", null),
                    _.get(aparte, "user.id", null),
                    _.get(aparte, "type", null)
                  )
                }
                variant="contained"
                className="componentButton componentButton--green"
              >
                Conceder
              </Button>
              <Button
                onClick={() =>
                  this.handleModalNaoConceder(
                    _.get(aparte, "user.id", null),
                    _.get(aparte, "type", "")
                  )
                }
                variant="contained"
                className="componentButton componentButton--red"
              >
                Rejeitar
              </Button>
            </div>
            {/* <div>
              <Button
                disabled={!buttonEnableConcluirAparte}
                variant="contained"
                onClick={() => this.addTimeImplement("finish", "00:00:00")}
                className="componentButton componentButton--yellow"
              >
                Concluir Aparte/ Questão de ordem
              </Button>
            </div> */}
          </Paper>
        </Modal>
        <Modal
          className="modalVotacao generalModal"
          open={this.state.modal_votacao}
          closeAfterTransition
          BackdropComponent={Backdrop}
          onClose={this.handleClose}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <div className="mw-35">
            <ModalVoting
              user_vote={user_vote}
              handleAlterarVoto={this.handleTrueVoteUser.bind()}
              openwebcam={openwebcam}
              descriptors={descriptors}
              voting={votacao}
              id_session={idsession}
              handleClose={this.handleClose.bind()}
            />
          </div>
        </Modal>
        <Modal
          className="generalModal"
          open={this.state.modal_votacao_bloco}
          closeAfterTransition
          BackdropComponent={Backdrop}
          onClose={this.handleCloseBloc}
          BackdropProps={{
            timeout: 800,
          }}
        >
          <div>
            <ModalVotingBloc
              user_vote={user_vote}
              handleAlterarVoto={this.handleTrueVoteUser.bind()}
              openwebcam={openwebcam}
              descriptors={descriptors}
              voting={votacao}
              id_session={idsession}
              handleClose={this.handleCloseBloc.bind()}
            />
          </div>
        </Modal>
        <Modal /* modal de votação das comissões */
          className="generalModal"
          // open={this.state.modal_votacao_comision}
          open={this.state.modal_votacao_comision}
          closeAfterTransition
          BackdropComponent={Backdrop}
          onClose={this.handleClose}
          BackdropProps={{ timeout: 500 }}
        >
          <div className="mw-35">
            <div>
              <ModalVotingCommittee
                handleAlterarVoto={this.handleTrueVoteUser.bind()}
                user_vote={user_vote}
                openwebcam={openwebcam}
                descriptors={descriptors}
                voting={votingComision}
                // id_session={id_session}
                id_session={idsession}
                handleClose={this.handleClose.bind()}
                // handleClose={this.handleCloseComision.bind()}
              />
            </div>
          </div>
        </Modal>
        <Modal
          className="generalModal"
          open={this.state.modalquorun}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <div className="modalContainer p-3">
            <h4 className="h4">Não há Quórum para continuar a Sessão</h4>
            <div className="d-flex w-100 justify-content-around">
              <Button
                onClick={() => this.handleSessionSuspender()}
                variant="contained"
                className="componentButton componentButton--gray my-3 btn-layoff"
              >
                SUSPENDER
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          className={"modalPreview__pdf"}
          open={modalPdf}
          onClose={() => this.handleCloseModalPdf()}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={modalPdf}>
            <Card className="modalPreview__pdf--container">
              <CardHeader color="success" className="modalPreview__pdf--header">
                <span
                  className="material-icons"
                  onClick={() => this.handleCloseModalPdf()}
                >
                  close
                </span>
              </CardHeader>
              <CardBody className="modalPreview__pdf--body">
                {this.state.pdfExecutivo ? (
                  <object
                    data={this.state.pdfExecutivo}
                    type="application/pdf"
                    width="100%"
                    height="500"
                  />
                ) : (
                  <Pdf height={800} file={pdfFile} />
                )}
              </CardBody>
            </Card>
          </Fade>
        </Modal>
      </div>
    );
  }
}

const styles = {
  root: {
    flexGrow: 1,
    // maxWidth: '70%',
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    textAlign: "center",
    fontSize: "160%",
    fontWeight: "bold",
    borderRadius: "10px",
    width: "800px",
    height: "300px",
  },
};

DashboardPresident.propTypes = {
  classes: PropTypes.object.isRequired,
  documents: PropTypes.array.isRequired,
  idsession: PropTypes.string.isRequired,
  stop: PropTypes.bool.isRequired,
  roteiro: PropTypes.array.isRequired,
};

export default withStyles(styles)(DashboardPresident);
