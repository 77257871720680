import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import api from "../../api/api_v2";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import TableTipoPropositura from "./Tables/TableTipoPropositura";
import { Error } from "../Parties/styles";
import _ from "lodash";
import Modal from "@material-ui/core/Modal";
import ModalStatus from "./Modal/ModalStatus";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Checkbox from "@material-ui/core/Checkbox";
import NativeSelect from "@material-ui/core/NativeSelect";
import { DialogContent, FormControlLabel } from "@material-ui/core";

export default function TypeDocument() {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [prazo, setPrazo] = useState(0);
  const [qtdade, setQtdade] = useState();
  const [tiposProposituras, setTiposProposituras] = useState([]);
  const [alert, setAlert] = useState(false);
  const [validation, setValidation] = useState([]);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState([]);
  const [statusSelect, setStatusSelect] = useState([]);
  const [diasuteis, setDiasUteis] = useState();
  const [multiple, setMultiple] = useState(0);
  const [enabled, setEnabled] = useState(0);
  const [emenda, setEmenda] = useState(0);
  const [subemenda, setSubEmenda] = useState(0);
  const [projetolei, setProjetoLei] = useState(0);
  const [autografo, setAutografo] = useState(0);
  const [administrativo, setAdministrativo] = useState(0);
  const [visiblePesquisaExterna, setVisiblePesquisaExterna] = useState(0);
  const [visiblePesquisaInterna, setVisiblePesquisaInterna] = useState(0);
  const [oficioExecutivo, setOficioExecutivo] = useState(0);
  const [disablePdfEmenta, setDisablePdfEmenta] = useState(0);
  const [dataLinkPublicacion, setDataLinkPublicacion] = useState(0);
  const [type, setType] = useState(null);
  const types_voting = [
    { name: "Maioria Absoluta" },
    { name: "Maioria Qualificada (2/3)" },
    { name: "Maioria Qualificada (3/5)" },
    { name: "Maioria Simples" },
    { name: "Null" },
  ];
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await Promise.all([
      api.get("/type-proposition"),
      api.get("/status"),
    ]).then(results => {
      const typePropositions = _.get(results[0], 'data', [])
      const status = _.get(results[1], 'data', [])
      status.forEach((value) => {
        value.prazo = 0;
        value.diasuteis = 0;
      });
      setTiposProposituras(typePropositions)
      setStatus(status)
    })
  };
  const handleForm = (e) => {
    console.log(e.target.value);
    switch (e.target.name) {
      case "name":
        return setName(e.target.value);
      case "qtdassinaturas":
        let num = e.target.value === "" ? 0 : parseInt(e.target.value); //eslint-disable-line
        return setQtdade(num);
      case "prazo":
        return setPrazo(e.target.value);
      case "diasuteis":
        return setDiasUteis(e.target.value);
      case "enabled":
        return setEnabled(e.target.value);
      case "multiple":
        return setMultiple(e.target.value);
      case "adminstrativo":
        return setAdministrativo(e.target.value);
      case "type":
        return setType(e.target.value);
      case "emenda":
        return setEmenda(e.target.value);
      case "subemenda":
        return setSubEmenda(e.target.value);
      case "projetolei":
        return setProjetoLei(e.target.value);
      case "autografo":
        return setAutografo(e.target.value);
      case "visiblePesquisaExterna":
        return setVisiblePesquisaExterna(e.target.value);
      case "visiblePesquisaInterna":
        return setVisiblePesquisaInterna(e.target.value);
      case "oficioExecutivo":
        return setOficioExecutivo(e.target.value);
      case "dataLinkPublicacion":
        return setDataLinkPublicacion(e.target.value);
      case "disablePdfEmenta":
        return setDisablePdfEmenta(e.target.value);
    }
  };

  const handleAddTypeDocument = async () => {
    if (statusSelect.length === 0) {
      setValidation({
        status: "Status do tipo de propositura devem ser adicionados.",
      });

      return;
    }

    if (edit == true) {
      await api
        .put("/type-proposition/update/" + id, {
          name: name,
          qntdade_assign: qtdade,
          num_row_proposition: 0,
          statuses: statusSelect,
          prazo: prazo,
          diasuteis: diasuteis,
          oficio_multiple: multiple,
          administrativo: administrativo,
          type_voting: type,
          enabled: enabled,
          emenda: emenda,
          subemenda: subemenda,
          projeto_lei: projetolei,
          autografo: autografo,
          visible_pesquisa_externa: visiblePesquisaExterna,
          visible_pesquisa_interna: visiblePesquisaInterna,
          oficio_executivo: oficioExecutivo,
          link_data_publicacion: dataLinkPublicacion,
          disable_pdf_ementa: disablePdfEmenta
        })
        .then(function() {
          setAlertMessage("Tipo de Propositura criada com sucesso");

          setAlert(true);
          fetchData();
          setTimeout(() => {
            setAlert(false);
          }, 3000);
        })
        .catch(function(error) {
          if (error.response) {
            setValidation(_.get(error, "response.data.errors", []));
          }
        });
      setEdit(false);
      setType("");
      setName("");
      setQtdade(0);
      setMultiple(0);
      setEnabled(0);
      setAdministrativo(0);
      setEmenda(0);
      setSubEmenda(0);
      setProjetoLei(0);
      setAutografo(0);
      setStatusSelect([]);
      setVisiblePesquisaExterna(0);
      setVisiblePesquisaInterna(0);
      setOficioExecutivo(0);
      setDataLinkPublicacion(0);
      setDisablePdfEmenta(0);
      return;
    }

    await api
      .post("/type-proposition/store", {
        name: name,
        qntdade_assign: qtdade,
        num_row_proposition: 0,
        statuses: statusSelect,
        oficio_multiple: multiple,
        administrativo: administrativo,
        type_voting: type,
        enabled: enabled,
        emenda: emenda,
        subemenda: subemenda,
        projeto_lei: projetolei,
        autografo: autografo,
        visible_pesquisa_externa: visiblePesquisaExterna,
        visible_pesquisa_interna: visiblePesquisaInterna,
        oficio_executivo: oficioExecutivo,
        link_data_publicacion: dataLinkPublicacion,
        disable_pdf_ementa: disablePdfEmenta
      })
      .then(function() {
        setAlert(true);
        setAlertMessage("Tipo de Propositura criada com sucesso");
        fetchData();

        setTimeout(() => {
          setAlert(false);
        }, 3000);
      })
      .catch(function(error) {
        if (error.response) {
          setValidation(_.get(error, "response.data.errors", []));
        }
      });
    setType("");
    setName("");
    setQtdade(0);
    setStatusSelect([]);
    setMultiple(0);
    setAdministrativo(0);
    setEmenda(0);
    setSubEmenda(0);
    setProjetoLei(0);
    setEnabled(0);
    setAutografo(0);
    setVisiblePesquisaExterna(0);
    setVisiblePesquisaInterna(0);
    setOficioExecutivo(0);
    setDataLinkPublicacion(0);
    setDisablePdfEmenta(0);
  };

  const handleDelete = async (e) => {
    setValidation("");

    await api.get(`/type-proposition/delete/${e.id}`);

    await fetchData();
  };

  const handleEdit = async (e) => {
    setId(e.id);
    setType(_.get(e, "type_voting", null));
    setName(_.get(e, "name", ""));
    setQtdade(_.get(e, "qntdade_assign", 0));
    setMultiple(_.get(e, "oficio_multiple", 0));
    setEnabled(_.get(e, "enabled", 0));
    setAdministrativo(_.get(e, "administrativo", 0));
    setEmenda(_.get(e, "emenda", 0));
    setSubEmenda(_.get(e, "subemenda", 0));
    setProjetoLei(_.get(e, "projeto_lei", 0));
    setAutografo(_.get(e, "autografo", 0));
    setVisiblePesquisaExterna(_.get(e, "visible_pesquisa_externa", 0))
    setVisiblePesquisaInterna(_.get(e, "visible_pesquisa_interna", 0))
    setOficioExecutivo(_.get(e, "oficio_executivo", 0))
    setDataLinkPublicacion(_.get(e, "link_data_publicacion", 0))
    setDisablePdfEmenta(_.get(e, "disable_pdf_ementa", 0))
    const array = _.get(e, "status", []);
    setEdit(true);

    array.forEach((value) => {
      value.prazo = _.get(value, "pivot.prazo", null);
      value.diasuteis = _.get(value, "pivot.diasuteis", null);
      value.order = _.get(value, "pivot.order", null);
    });

    setStatusSelect(_.orderBy(array, ["order"], ["asc"]));
  };

  const handleOpen = async () => {
    // ESTE CODIGO LO HACE EL TABLE MODAL STATUS AL CARGAR
    // setValidation([]);
    // let statusSelect2 =  [...statusSelect];

    // if (edit == true) {
    //   statusSelect2.forEach((value) => {
    //     status.find(function(item) {
    //       if (item.id === value.id) {
    //         value.index = true;
    //       }
    //     });
    //   });
    //   setStatusSelect(statusSelect2);
    // }
    setOpen(true);
  };

  const add = (select) => {
    setStatusSelect(select);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card
            style={{
              boxShadow:
                "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
            }}
          >
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Adicionar Tipo de Documento</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>{"Nome"}</InputLabel>
                  <FormControl className={classes.form} fullWidth>
                    <Input
                      name="name"
                      value={name}
                      onChange={handleForm}
                      fullWidth
                    />
                    <Error>{_.get(validation, "name", "")}</Error>
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    {"Qtd Assinaturas"}
                  </InputLabel>
                  <FormControl className={classes.form} fullWidth>
                    <Input
                      type="number"
                      inputProps={{ min: "0" }}
                      name="qtdassinaturas"
                      value={qtdade}
                      onChange={handleForm}
                      fullWidth
                    />
                    <Error>{_.get(validation, "name", "")}</Error>
                  </FormControl>
                </GridItem>
                <GridItem xs={12}>
                  <InputLabel
                    id="demo-simple-select-label"
                    className={classes.label}
                  >
                    Tipo de votação
                  </InputLabel>

                  <FormControl className={classes.form} fullWidth>
                    <NativeSelect
                      labelId="demo-simple-select-label"
                      id="type"
                      value={type}
                      name="type"
                      onChange={handleForm}
                      fullWidth
                    >
                      <option value={""}></option>
                      {types_voting.map((type, i) => (
                        <option key={i} value={type.name}>
                          {type.name}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={4} className={classes.flexColCenter} >
                  <FormControlLabel className={classes.form}
                    control={<Checkbox
                      checked={enabled}
                      name="enabled"
                      value={enabled}
                      onChange={(e) => setEnabled(e.target.checked)}
                    />}
                    label="Ativado"
                  />
                </GridItem>

                <GridItem xs={12} sm={4} className={classes.flexColCenter}>
                  <FormControlLabel className={classes.form}
                    control={<Checkbox
                      checked={multiple}
                      id="vehicle1"
                      name="multiple"
                      value={multiple}
                      onChange={(e) => setMultiple(e.target.checked)}
                    />}
                    label="Oficio em bloco"
                  />
                </GridItem>

                <GridItem xs={12} sm={4} className={classes.flexColCenter}>
                  <FormControlLabel className={classes.form}
                    control={<Checkbox
                      checked={administrativo}
                      id="administrativo"
                      name="administrativo"
                      value={administrativo}
                      onChange={(e) => setAdministrativo(e.target.checked)}
                    />}
                    label="Administrativo"
                  />
                </GridItem>
                <GridItem xs={12} sm={4} className={classes.flexColCenter}>
                  <FormControlLabel className={classes.form}
                  control={<Checkbox
                      checked={emenda}
                      id="emenda"
                      name="emenda"
                      value={emenda}
                      onChange={(e) => setEmenda(e.target.checked)}
                    />}
                    label="Emenda"
                  />
                </GridItem>
                <GridItem xs={12} sm={4} className={classes.flexColCenter}>
                  <FormControlLabel className={classes.form}
                    control={<Checkbox
                      checked={subemenda}
                      id="subemenda"
                      name="subemenda"
                      value={subemenda}
                      onChange={(e) => setSubEmenda(e.target.checked)}
                    />}
                    label="Subemenda"
                  />
                </GridItem>
                <GridItem xs={12} sm={4} className={classes.flexColCenter}>
                  <FormControlLabel className={classes.form}
                    control={<Checkbox
                      checked={autografo}
                      id="autografo"
                      name="autografp"
                      value={autografo}
                      onChange={(e) => setAutografo(e.target.checked)}
                    />}
                    label="Autógrafo"
                  />
                </GridItem>
                <GridItem xs={12} sm={4} className={classes.flexColCenter}>
                  <FormControlLabel className={classes.form}
                    control={<Checkbox
                      checked={projetolei}
                      id="projetolei"
                      name="projetolei"
                      value={projetolei}
                      onChange={(e) => setProjetoLei(e.target.checked)}
                    />}
                    label="Projeto de lei"
                  />
                </GridItem>
                <GridItem xs={12} sm={4} className={classes.flexColCenter}>
                  <FormControlLabel className={classes.form}
                    control={<Checkbox
                      checked={visiblePesquisaExterna}
                      id="visiblePesquisaExterna"
                      name="visiblePesquisaExterna"
                      value={visiblePesquisaExterna}
                      onChange={(e) => setVisiblePesquisaExterna(e.target.checked)}
                    />}
                    label="Visível na pesquisa externa"
                  />
                </GridItem>
                <GridItem xs={12} sm={4} className={classes.flexColCenter}>
                  <FormControlLabel className={classes.form}
                    control={<Checkbox
                      checked={visiblePesquisaInterna}
                      id="visiblePesquisaInterna"
                      name="visiblePesquisaInterna"
                      value={visiblePesquisaInterna}
                      onChange={(e) => setVisiblePesquisaInterna(e.target.checked)}
                    />}
                    label="Visível na pesquisa interna"
                  />
                </GridItem>
                <GridItem xs={12} sm={4} className={classes.flexColCenter}>
                  <FormControlLabel className={classes.form}
                    control={<Checkbox
                      checked={oficioExecutivo}
                      id="oficioExecutivo"
                      name="oficioExecutivo"
                      value={oficioExecutivo}
                      onChange={(e) => setOficioExecutivo(e.target.checked)}
                    />}
                    label="Ofício Executivo"
                  />
                </GridItem>
                <GridItem xs={12} sm={4} className={classes.flexColCenter}>
                  <FormControlLabel className={classes.form}
                    control={<Checkbox
                      checked={dataLinkPublicacion}
                      id="dataLinkPublicacion"
                      name="dataLinkPublicacion"
                      value={dataLinkPublicacion}
                      onChange={(e) => setDataLinkPublicacion(e.target.checked)}
                    />}
                    label="Data e link de publicação"
                  />
                </GridItem>
                <GridItem xs={12} sm={4} className={classes.flexColCenter}>
                  <FormControlLabel className={classes.form}
                    control={<Checkbox
                      checked={disablePdfEmenta}
                      id="disablePdfEmenta"
                      name="disablePdfEmenta"
                      value={disablePdfEmenta}
                      onChange={(e) => setDisablePdfEmenta(e.target.checked)}
                    />}
                    label="Desabilitar ementa do PDF"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Button onClick={handleOpen} color="default">
                    STATUS
                  </Button>

                  <FormControl className={classes.form}>
                    <Error>{_.get(validation, "status", "")}</Error>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button onClick={handleAddTypeDocument} color="success">
                Salvar
              </Button>
            </CardFooter>
            <Snackbar
              place="tr"
              color="success"
              icon={AddAlert}
              message={alertMessage}
              open={alert}
              closeNotification={() => setAlert(false)}
              close
            />
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {tiposProposituras != "" ? (
            <TableTipoPropositura
              tableHeaderColor="primary"
              tableHead={["Nome", "Assinaturas", "Ativado", "", ""]}
              tableData={tiposProposituras}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          ) : (
            ""
          )}
        </GridItem>
      </GridContainer>
      <Modal
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
        <DialogContent>
          <ModalStatus
            status={status}
            status_select={statusSelect}
            handleClick={() => setOpen(false)}
            add={add}
          />
        </DialogContent> 
        </Fade>
      </Modal>
    </>
  );
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
  },
  flexColCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }
};

const useStyles = makeStyles(styles);
