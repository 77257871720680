import axios from "axios";
import _ from "lodash";

var tokenGet =
  sessionStorage.getItem("TOKEN_KEY") &&
  sessionStorage.getItem("TOKEN_KEY").charAt(0) == '"'
    ? sessionStorage.getItem("TOKEN_KEY")
    : JSON.stringify(sessionStorage.getItem("TOKEN_KEY"));
var token = JSON.parse(tokenGet);

async function apiVote(vote, route) {
  let error;

  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + route,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: vote,
  }).then((response) => {
    error = _.get(response, "data.error", null);
  });

  return error;
}

function apiFinishVoting(id,name) {
  axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/voting/finish",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
      data:{
        id:id,
        nome:name
      }
  });
}

async function getVoting() {
  let data;

  await axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + "/voting",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    data = _.get(response, "data.voting", null);
  });

  return data;
}

async function getVotingManual() {
  let data;

  await axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + "/votingManual",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    data = _.get(response, "data.voting", null);
  });

  return data;
}

//Pega dados ativo da votação da comimssão 
async function getVotingComision() {
  let data;

  await axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + "/votingComision",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    data = _.get(response, "data.voting", null);
  });

  return data;
}

//Pega dados ativo da votação da comimssão 
async function getComision() {
  let data = [];

  await axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + "/votingComision/comision",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((response) => {
    data = _.get(response, "data", null);
  });

  return data;
}

export { apiVote, apiFinishVoting, getVoting, getVotingManual, getVotingComision, getComision };
