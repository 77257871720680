import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import api from "../../api/api_v2";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import TableCompanyBoard from "./Tables/TableCompanyBoard";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import _ from "lodash";

export default function TypeDocument() {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [user, setUser] = useState(null);
  const [data, setStatus] = useState([]);
  const [alert, setAlert] = useState(false);
  const [validation, setValidation] = useState([]);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await all();
    };
    fetchData();
  }, []);

  const handleForm = (e) => {
    switch (e.target.name) {
      case "funcao":
        return setName(e.target.value);
      case "user":
        return setUser(e.target.value);
    }
  };

  const handleAddTypeDocument = async () => {
    const response = edit == true ? await update() : await create();

    if (response != undefined) {
      setAlertMessage("Status Salvo com Sucesso");

      setAlert(true);

      setTimeout(() => {
        setAlert();
      }, 1000);

      setEdit(false);
      setName("");
      setUser(null);
      await all();

      return;
    }
  };

  const update = async () => {
    return await api
      .put(`/company-board/update/${id}`, {
        id: id,
        name: name,
        id_user: user,
        enable: 1,
      })
      .catch(function(error) {
        if (error.response) {
          setValidation(_.get(error, "response.data.errors", []));
        }
      });
  };

  const create = async () => {
    return await api
      .post(`/company-board/store`, {
        name: name,
        id_user: user,
        enable: 1,
      })
      .catch(function(error) {
        if (error.response) {
          setValidation(_.get(error, "response.data.errors", []));
        }
      });
  };

  const all = async () => {
    await api
      .get("/company-board")
      .then(
        (response) => (
          setStatus(_.get(response, "data.companyBoard", [])),
          setUsers(_.get(response, "data.users", []))
        )
      );
  };

  const handleUser = (event) => {
    setUser(event.target.value);
  };

  const handleDelete = async (e) => {
    setValidation("");

    const response = await api.get(`/company-board/delete/${e.id}`);

    await all();

    let message = _.get(response, "data.success", "Não foi possivel excluir");

    setAlertMessage(message);

    setAlert(true);

    setTimeout(() => {
      setAlert();
    }, 1000);
  };

  const handleEdit = async (e) => {
    setId(e.id);
    setName(e.name);
    setUser(e.id_user);
    setEdit(true);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card
            style={{
              boxShadow:
                "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
            }}
          >
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Adicionar Integrantes da Mesa Diretora</h4>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <FormControl className={classes.form}>
                    <InputLabel className={classes.label}>
                      {"Função"}
                    </InputLabel>
                    <Input name="funcao" value={name} onChange={handleForm} />
                  </FormControl>
                  <p className={classes.validation}>
                    {_.get(validation, "public_name", "")}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                    className={classes.form}
                    style={{ minWidth: 140 }}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      className={classes.label}
                    >
                      Usuários
                    </InputLabel>
                    <Select
                      id="users"
                      value={user}
                      name="users"
                      onChange={handleUser}
                    >
                      {users.map((prop, i) => (
                        <MenuItem key={i} value={prop.id}>
                          {prop.user?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button onClick={handleAddTypeDocument} color="success">
                Salvar
              </Button>
            </CardFooter>
            <Snackbar
              place="tr"
              color="success"
              icon={AddAlert}
              message={alertMessage}
              open={alert}
              closeNotification={() => setAlert(false)}
              close
            />
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {data != "" ? (
            <TableCompanyBoard
              tableHeaderColor="primary"
              tableHead={["Função", "Nome", "", ""]}
              tableData={data}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          ) : (
            ""
          )}
        </GridItem>
      </GridContainer>
    </>
  );
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
  },
};

const useStyles = makeStyles(styles);
