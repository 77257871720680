const cardAvatarStyle = {
  cardAvatar: {
    "&$cardAvatarProfile img": {
      width: "100%",
      height: "auto",
    },
  },
  cardAvatarProfile: {
    maxWidth: "130px",
    maxHeight: "130px",
    margin: "-50px auto 0",
    borderRadius: "50%",
    overflow: "hidden",
    padding: "0",
    border: "3px solid green",
    "&$cardAvatarPlain": {
      marginTop: "0",
    },
  },
  cardAvatarPlain: {},
};

export default cardAvatarStyle;
