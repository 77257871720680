import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { ptBR } from "@material-ui/core/locale";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import { Snackbar, TextField } from "@material-ui/core";
import { Editor } from "@tinymce/tinymce-react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AddAlert from "@material-ui/icons/AddAlert";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";

const columns = [
  { id: "title_document", label: "Nome do documento", minWidth: 500 },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "60vh",
  },
  modal: {
    position: "fixed",
    minWidth: "100%",
    maxWidth: "100%",
    height: "100vh",
    backgroundColor: "white",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    padding: "2rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "auto",
  },
  addButton: {
    background: "orange",
    color: "white",
    border: "none",
  },
  saveButton: {
    background: "orange",
    color: "white",
    border: "none",
  },
});

export default function StickyHeadTable2(props) {
  const { docsTribuna } = props;
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedPDFs, setSelectedPDFs] = useState([]);
  const [idUser, setIdUser] = useState(null);

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const [selectedDocument, setSelectedDocument] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const [renderPDF, setRenderPDF] = useState([]);
  const [showButtons, setShowButtons] = useState(true);
  const [text, setText] = useState(null);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [documentoData, setDocumentoData] = React.useState({
    id_user: "",
    title_document: "",
    date_use: "",
    text: "",
    pdf_file: [],
    status: 1,
  });

  let user = JSON.parse(sessionStorage.getItem("USER"));
  let id_user = user?.id;
  let id_access_level = user?.id_access_level;
  let id_user_assessor = user?.id_user_assessor;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Função para carregar os dados do documento selecionado para edição
  const loadDocumentForEdit = (document) => {
    setSelectedDocument(document);
    setDocumentoData({
      id_user: document?.id_user,
      title_document: document?.title_document,
      date_use: moment(document?.date_use).format("YYYY-MM-DD"),
      text: document?.text,
      pdf_file: document?.files_anexos || [],
      status: document?.status,
    });
    setRenderPDF(document?.files_anexos);
  };

  useEffect(() => {
    const idToUse = id_access_level === 8 ? id_user_assessor : id_user;
    setIdUser(idToUse);

    setDocumentoData((prevData) => ({
      ...prevData,
      id_user: idToUse,
    }));
  }, [id_access_level, id_user, id_user_assessor, renderPDF]);

  const handleAddPDF = () => {
    document.getElementById("pdfFileInput").click();
  };

  const handlePDFInputChange = (event) => {
    const files = event.target.files;
    setSelectedPDFs([...selectedPDFs, ...files]);

    setDocumentoData((prevData) => ({
      ...prevData,
      pdf_file: [...prevData.pdf_file, ...files],
    }));
  };

  const handleOpenModal = (document, view) => {
    if (document.id) {
      loadDocumentForEdit(document);
    }
    setOpenModal(true);

    if (view == "view") {
      setShowButtons(false);
    } else {
      setShowButtons(true);
      setButtonDisable(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setRenderPDF([]);
    setSelectedPDFs([]);
    setDocumentoData({
      id_user: "",
      title_document: "",
      date_use: "",
      text: "",
      pdf_file: [],
      status: 1,
    });
  };

  const handleObservationChange = (content, editor) => {
    const text = editor.getContent();
    setText(text);
  };

  const handleDocumentoNomeChange = (event) => {
    setDocumentoData({ ...documentoData, title_document: event.target.value });
  };

  const handleDataUtilizacaoChange = (event) => {
    setDocumentoData({ ...documentoData, date_use: event.target.value });
  };

  const getTodayDate = () => {
    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const year = today.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();

      formData.append("id_user", documentoData.id_user);
      formData.append("title_document", documentoData.title_document);
      formData.append("date_use", documentoData.date_use);
      formData.append("text", text);
      formData.append("status", documentoData.status);

      selectedPDFs.forEach((file) => {
        formData.append("pdf_file[]", file);
      });

      let url;
      let method;

      if (selectedDocument) {
        url = `${process.env.REACT_APP_STAGE}/tribuna-documents/update/${selectedDocument.id}`;
        method = "POST";
      } else {
        url = `${process.env.REACT_APP_STAGE}/tribuna-documents/store`;
        method = "POST";
      }

      const response = await fetch(url, {
        method: method,
        body: formData,
      });

      if (response.ok) {
        setSnackbarMessage("Documento salvo com sucesso!");
        setOpenSnackbar(true);
        handleCloseModal();
        setTimeout(() => {
          window.location.reload();
        }, 900);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const deleteDocument = async (id) => {
    const response = await fetch(
      process.env.REACT_APP_STAGE + `/tribuna-documents/delete/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    return data;
  };

  const deleteDocumentTribuna = async (id) => {
    try {
      await deleteDocument(id);

      setSnackbarMessage("Documento deletado com sucesso!");
      setOpenSnackbar(true);
      setTimeout(() => {
        window.location.reload();
      }, 900);
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleDeletePDF = (index) => {
    setSelectedPDFs((prevPDFs) => prevPDFs.filter((_, i) => i !== index));
  };

  const handleDeletePDFApi = async (id) => {
    const response = await fetch(
      process.env.REACT_APP_STAGE + `/tribuna-documents/delete-file/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    return data;
  };

  const deleteFile = async (id, index) => {
    try {
      setRenderPDF((prevPDFs) => prevPDFs.filter((_, i) => i !== index));
      await handleDeletePDFApi(id);
    } catch (error) {
      console.error("Error", error);
    }
  };

  const currentDate = new Date();
  const targetDate = moment(currentDate).format("YYYY-MM-DD");

  return (
    <Paper className="documentTable">
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>{column.label}</div>
                  <Button
                    variant="outlined"
                    onClick={handleOpenModal}
                    style={{
                      background: "orange",
                      color: "white",
                      border: "none",
                    }}
                  >
                    Adicionar Novo Documento
                  </Button>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="documentTable__body">
            {docsTribuna
              .filter((row) => row.date_use >= targetDate)
              .filter((row) => row.id_user === idUser)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: "30px",
                          }}
                        >
                          <div>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </div>
                          <div className="documentTable__body--row">
                            <Button
                              variant="outlined"
                              onClick={() => {
                                handleOpenModal(row, "edit");
                                setButtonDisable(false);
                              }}
                              className="componentButton componentButton--gray-light"
                            >
                              <i
                                className="animate__animated animate__pulse animate__infinite fa-solid fa-edit"
                                style={{ color: "green" }}
                              ></i>
                            </Button>

                            <Button
                              variant="outlined"
                              onClick={() => deleteDocumentTribuna(row?.id)}
                              className="componentButton componentButton--gray-light"
                            >
                              <i
                                className="animate__animated animate__pulse animate__infinite fa-solid fa-trash"
                                style={{ color: "red" }}
                              ></i>
                            </Button>

                            <Button
                              variant="outlined"
                              onClick={() => handleOpenModal(row, "view")}
                              className="componentButton componentButton--gray-light"
                            >
                              <i className="animate__animated animate__pulse animate__infinite fa-solid fa-eye"></i>
                            </Button>
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <ThemeProvider theme={theme}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={docsTribuna.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            className={classes.modal}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h4 id="simple-modal-title">Adicionar Novo Documento</h4>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleCloseModal}
                aria-label="close"
                style={{ position: "absolute", top: 0, right: "0.5rem" }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <TextField
                id="outlined-basic"
                label="Nome do Documento"
                variant="outlined"
                style={{
                  width: "50%",
                }}
                value={documentoData.title_document}
                onChange={handleDocumentoNomeChange}
              />
              <TextField
                id="outlined-basic"
                type="date"
                label="Data de Utilização"
                variant="outlined"
                style={{
                  width: "50%",
                }}
                value={documentoData.date_use}
                onChange={handleDataUtilizacaoChange}
                inputProps={{ min: getTodayDate() }}
              />
            </div>

            <Editor
              apiKey="1g8cb58fzcs5otmm77r4fz8u9dilkfy0jbvlt82004befurm"
              initialValue={documentoData.text}
              init={{
                height: "100%",
                width: "100%",
                menubar: false,
                directionality: "ltr",
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }",
              }}
              onChange={handleObservationChange}
            />
            {renderPDF.length > 0 && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <ul>
                  {renderPDF.map((pdf, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid black",
                        padding: "5px",
                        marginBottom: "5px",
                        borderRadius: "10px",
                        justifyContent: "space-between",
                        cursor: "pointer",
                      }}
                    >
                      <a
                        onClick={() => {
                          props.handleOpenPdf(
                            `https://dev.envsense.dev.br/api/${pdf.pdf_file}`
                          );
                        }}
                      >
                        {pdf.file_name_origin}
                      </a>

                      <IconButton
                        style={{ padding: 0 }}
                        onClick={() => deleteFile(pdf.id, index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  ))}
                </ul>
              </div>
            )}
            {selectedPDFs.length > 0 && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <ul>
                  {selectedPDFs.map((file, index) => (
                    <li
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid black",
                        padding: "5px",
                        marginBottom: "5px",
                        borderRadius: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      {file.name}
                      <IconButton
                        style={{ padding: 0 }}
                        onClick={() => handleDeletePDF(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <input
              type="file"
              id="pdfFileInput"
              multiple
              accept="application/pdf"
              style={{ display: "none" }}
              onChange={handlePDFInputChange}
            />
            <Button
              variant="contained"
              className={classes.addButton}
              onClick={handleAddPDF}
              style={{ display: showButtons ? "block" : "none" }}
            >
              Adicionar PDF
            </Button>

            <Button
              variant="contained"
              className={classes.saveButton}
              onClick={handleSave}
              style={{ display: showButtons ? "block" : "none" }}
              disabled={
                buttonDisable == true &&
                (!documentoData.title_document ||
                  !documentoData.date_use ||
                  !text)
              }
            >
              Salvar
            </Button>
          </div>
        </Modal>
      </ThemeProvider>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        ContentProps={{
          style: {
            backgroundColor: "#00C800",
          },
        }}
        autoHideDuration={2000}
        place="tr"
        color="success"
        icon={AddAlert}
        message={snackbarMessage}
        open={openSnackbar}
        closeNotification={() => setOpenSnackbar(false)}
        close
      />
    </Paper>
  );
}
const theme = createMuiTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  ptBR
);

StickyHeadTable2.propTypes = {
  docsTribuna: PropTypes.array.isRequired,
  handleVisualizarImagem: PropTypes.func.isRequired,
  handleVisualizar: PropTypes.func.isRequired,
  handleOpenPdf: PropTypes.func.isRequired,
};
