import React, { useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
//import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import _ from "lodash";
import TableModalStatus from "../Tables/TableModalStatus";
import TableModalStatusSelecionado from "../Tables/TableModalStatusSelect";
import { stylesModal } from "assets/jss/material-dashboard-react/components/tableSelect.js";
import { useEffect } from "react";

const useStyles = makeStyles(stylesModal);
export default function ModalStatus(props) {
  const classes = useStyles();
  const { status, status_select } = props;

  const [array1, setArray1] = useState([]);
  const [array2, setArray2] = useState([]);

  useEffect(() => {
    setArray1(status);
    setArray2(status_select)
  }, []);

  const updateColumn = (params) => {
    props.add(params);
    setArray2(params)

    return;
  };

  const handleSelect = (param) => {
    let params =  [...array2];
    let del = 0;

    setArray2([])

    params.forEach((element, i) => {
      if (_.get(element, "id", null) === _.get(param, "id", null)) {
        del = 1;
        params.splice(i, 1);
        return;
      }
    });
    
    if (del === 1) {
      setArray2(params)
      props.add(params);
      return;
    }
    params.push(param);
    setArray2(params)
    props.add(params);
  };

    return (
      <GridContainer
        style={{ display: "flex", justifyContent: "center", height: "600px" }}
      >
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="success" className={classes.cardHeader}>
              <h4 className={classes.cardTitleWhite}>
                Selecione os status do tipo do documento
              </h4>
              <span
                className="material-icons"
                onClick={() => props.handleClick()}
                style={{ cursor: "pointer" }}
              >
                close
              </span>
            </CardHeader>
            <CardBody>
              <Grid
                container
                spacing={2}
                justify="center"
                alignItems="center"
                className={classes.root}
              >
                <Grid xs={12} sm={12} md={6}>
                  <TableModalStatus
                    tableHeaderColor="success"
                    tableHead={["Nome", "Ordem"]}
                    tableData={array1}
                    selected={array2}
                    handleSelect={handleSelect}
                  />
                </Grid>

                <Grid xs={12} sm={12} md={6}>
                  <TableModalStatusSelecionado
                    tableHeaderColor="primary"
                    tableHead={["Nome", "Ordem"]}
                    tableData={array2}
                    updateColumn={updateColumn}
                  />
                </Grid>
              </Grid>
            </CardBody>
            <CardFooter></CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    );
  // }
}

ModalStatus.propTypes = {
  classes: PropTypes.object.isRequired,
  add: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
};
