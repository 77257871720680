import React from "react";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import SignIn from "views/Logon/SignIn.js";
import Footer from "components/Footer/Footer";
import styles from "assets/jss/material-dashboard-react/views/loginStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import pjson from '../../package.json';

export default function LoginPage() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const APP_VERSION = pjson.version;
  return (
    <>
      <SignIn />
        <div style={{position: 'fixed', bottom: 0, padding: '5px', fontSize: '14px'}}>
          <spam>versão: {APP_VERSION}</spam>
        </div>
      <div className={classes.divFooter}>
        <Footer />
      </div>
    </>
  );
}
