import React from "react";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import NavBar from "components/Navbars/Parliamentary";
import ParliamentaryIndex from "views/Parliamentary/index";

export default function President() {
  return (
    <>
      <NavBar />
      <ParliamentaryIndex />
    </>
  );
}
