import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { ptBR } from "@material-ui/core/locale";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import "../../../assets/sass/components/president/document.scss";
import "../../../assets/sass/styles.scss";

const columns = [{ id: "name", label: "Nome do documento", minWidth: 500 }];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 740,
  },
});

export default function StickyHeadTable(props) {
  const { documentos } = props;
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className="documentTable">
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="documentTable__body">
            {documentos
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: "20px",
                          }}
                        >
                          <div>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </div>
                          <div className="documentTable__body--row">
                            {row.document_photo[0] == null ? (
                              ""
                            ) : (
                              <Button
                                variant="outlined"
                                onClick={() =>
                                  props.handleVisualizar(row.document_photo[0])
                                }
                                className="componentButton componentButton--gray-light"
                              >
                                <i className="fa-solid fa-image"></i>
                              </Button>
                            )}

                            <Button
                              variant="outlined"
                              /*onClick={() => props.handleVisualizar(row)}*/ onClick={() =>
                                props.handleOpenPdf(row.pdfUrl,value,row.id_type)
                              }
                              className="componentButton componentButton--gray-light"
                            >
                              <i className="animate__animated animate__pulse animate__infinite fa-solid fa-eye"></i>
                            </Button>
                            {row.lido == null ? (
                              <Button
                                variant="outlined"
                                onClick={() => props.handlelido(row.id)}
                                className="componentButton componentButton--green-light"
                              >
                                Marcar como lido
                              </Button>
                            ) : (
                              <Button
                                variant="outlined"
                                disabled={true}
                                className="componentButton componentButton--green"
                              >
                                ✔ LIDO
                              </Button>
                            )}
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <ThemeProvider theme={theme}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={documentos.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </ThemeProvider>
    </Paper>
  );
}

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  ptBR
);

StickyHeadTable.propTypes = {
  documentos: PropTypes.array.isRequired,
  handleVisualizar: PropTypes.func.isRequired,
  handleOpenPdf: PropTypes.func.isRequired,
  handlelido: PropTypes.func.isRequired,
};
