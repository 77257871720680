import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Logo from "assets/img/logo-login.png";
import { apiLoginRecovery } from "api/api.js";
import PropTypes from "prop-types";
import styles from "assets/jss/material-dashboard-react/views/loginStyle.js";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ModalRecovery from "../Logon/Modal/ModalRecovery.js";
import { mask } from "remask";
import {Modal} from "@material-ui/core";
import { Position } from "@phuocng/react-pdf-viewer";
class Recovery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cpf: "",
      setOpen: false,
      setCPF: "",
      openRecovery:false,
      errorsDeleted:[],
      user:[],
    };
    this.handleChangeUsername = this.handleChangeUsername.bind(this);
    this.handleClickSubmit    = this.handleClickSubmit.bind(this);
    this.BaseUrl = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : '';
  }

  handleChangeUsername(event) {
    let masked;
    if(/^[0-9.-]+$/.test(event.target.value)){
       masked = mask(event.target.value, ["999.999.999-99"]);
    }else{
      masked =event.target.value;
    }
    this.setState({ cpf: masked });
  }

  handleCloseRecovery = () => {
    this.setState({errorsDeleted: []});
    this.setState({ openRecovery: false });
  };
  async handleClickSubmit() {
    let recoveryLogin = await apiLoginRecovery(
      this.state.cpf,
    );
    this.setState({errorsDeleted: []});
    
    if (recoveryLogin?.user) {
      this.setState({ setAlert: true , user: recoveryLogin.user,openRecovery:true});
       setTimeout(
        function() {
          this.setState({
            setAlert: false,
            verified: false,
          });
        }.bind(this),
        2000
      );
    }else{
      this.setState({
        errorsDeleted: recoveryLogin,
      });
    }
  }
  

  render() {
    const { classes } = this.props;
    const { setAlert } = this.state;
    const { cpf,errorsDeleted,user} = this.state;

    return (
      <Grid container component="main" className={classes.root}>
        <Grid
          item
          xs={7}
          sm={8}
          md={5}
          component={Paper}
          square
          style={{
            borderRadius: "10px 20px 80px",
            maxWidth: "600px",
            zoom: "90%",
          }}
        >
          <div className={classes.paper}>
              <img className={classes.filogo} src={Logo} alt="logo envsense" />
              <h4>Redefinindo sua senha</h4>
              {
                <form className={classes.form}>
                  <div style={{textAlign: "left"}}>
                  <br />
                    <small>Forneça seu CPF usado no cadastro.</small><br />
                    <small>Nós lhe enviaremos um e-mail que lhe permetirá redefinir sua senha.</small>
                    <br />
                  </div>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Cpf ou Email"
                    name="cpf"
                    autoComplete="off"
                    autoFocus
                    value={cpf}
                    onChange={this.handleChangeUsername}
                    style={{
                      width: "100%",
                      alignItems: "center"
                    
                    }}
                  />
                  { 
                      Object.values(errorsDeleted).map((items,key)=>{
                        return(
                            <span style={{color:'red'}} key={key}>{items}</span>
                          );
                        }
                    )
                  }
                 {
                    <Grid container>
                        <Grid item className={classes.items}>
                        <Link
                            className={classes.filink}
                            href={`${this.BaseUrl}/login`}
                            color="danger"
                            >
                            {"Retornar ao login."}
                        </Link>
                        </Grid>
                    </Grid>
                  } 
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.fisubmit}
                    onClick={this.handleClickSubmit}
                  >
                    ENVIAR
                  </Button>
                  <div className={classes.footerSpan}></div>
                </form>
              }
          </div>
          <Modal
              className="generalModal"
              open={this.state.openRecovery}
              onClose={() => this.setState({ openRecovery: false })}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
            <Fade in={this.state.openRecovery}>
                <ModalRecovery
                  user={user}
                  handleCloseRecovery={this.handleCloseRecovery}
                />
            </Fade>
          </Modal>
        </Grid>
      </Grid>
      
    );
  }
}
Recovery.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Recovery);
