import axios from 'axios'
import _ from 'lodash'

var tokenGet = sessionStorage.getItem("TOKEN_KEY") && sessionStorage.getItem("TOKEN_KEY").charAt(0) == '"' ? sessionStorage.getItem("TOKEN_KEY") : JSON.stringify(sessionStorage.getItem("TOKEN_KEY"))
var token = JSON.parse(tokenGet)

async function apiGetLogs(searchFilter,offset,page) {
    var data = null
    let route = '/logs'

    if (typeof searchFilter.filterPerfil=="string"
        || typeof searchFilter.filterPerfil=="string"
        || typeof searchFilter.filterUsername=="string"){
        route = '/logs/filter';
    }

    await axios({
        method: 'post',
        url: process.env.REACT_APP_STAGE + route,
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token
        },
        data: {
            offset: offset,
            page: page,
            filterPerfil: searchFilter.filterPerfil,
            filterModule: searchFilter.filterModule,
            filterUsername: searchFilter.filterUsername,
        }
    }).then(response => {
        data = _.get(response, 'data.success', 0);
    }).catch(function (error) {
        Promise.reject(error.response);
    });

    return data
}

async function apiGenerateReports(data,route) {
    let req = null
    await axios({
        method: 'post',
        url: process.env.REACT_APP_STAGE + route,
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token
        },
        data: data
    }).then(response => {
            let path = _.get(response, 'data.path', 0);
            window.location = process.env.REACT_APP_STAGE +path
        }).catch(function (error) {
       return  Promise.reject(error.response);
    });
}

const generateHash = (data)=>{
    return btoa(data);
}
export { apiGetLogs, generateHash,apiGenerateReports };

