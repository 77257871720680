import styled from "styled-components";

export const VideowallSection = styled.div`
  height: calc(100vh - 64px);
  background-position: center;
  background-size: cover;
  position: relative;
`;

export const H1 = styled.h1`
  font-weight: bold;
  margin: 0;
  font-size: calc(18px + 100%);
  color: #fff;
`;
export const H2 = styled.h2`
  font-weight: bold;
  margin: 0;
  font-size: calc(60px + 100%);
  color: #fff;
`;

export const H3 = styled.h3`
  padding: 5px 5px;
  margin: 0px 0px;
  color: #ffffff;
  font-weight: bold;
  font-size: calc(2px + 100%);
  text-align: center;
  line-height: 1.1;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.9);
`;

export const Main = styled.div``;

export const Container = styled.div`
  display: flex;
  padding: 30px;
  justify-content: space-between;
`;

/*export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16%, 1fr));
    gap: 25px;
    width: 90%; 
    @media only screen and (max-width: 1600px) {
        grid-template-columns: repeat(auto-fill, minmax(19%, 1fr));  
    }
`;*/

export const GridParliamentary = styled.div`
  width: 100%;

  .gridItem {
    width: 7.5vw;
    display: table;
    margin: 12px 1.5rem !important;
    float: left;
  }
  .gridItem.disabled {
    opacity: 0.3;
    filter: grayscale(100%);
  }

  figure {
    width: 100%;
    height: 8vw;
    margin: 0px auto;
    padding: 0px 0px;
    display: table;
    overflow: hidden;
    border: 6px solid #fff;
    border-radius: 5px;
    position: relative;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.9);
  }

  figure .icon {
    display: none;
    color: #ffffff;
    position: absolute;
    bottom: -5px;
    left: 5px;
    z-index: 4;
  }

  figure .flag {
    width: 50px;
    height: 60px;
    padding: 35px 0px 0px 9px;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 2;
  }

  figure .flag::before {
    content: "";
    width: 0;
    height: 0;
    border-bottom: 50px solid rgba(202, 115, 38, 0.9);
    border-right: 60px solid transparent;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 0;
  }

  figure .flag span {
    color: #ffffff;
    font-weight: bold;
    z-index: 2;
  }

  .gridItem.active figure .icon-speaking {
    display: inline;
  }
  .gridItem.active figure .icon-check {
    display: none;
  }
  .gridItem.active figure .icon-block {
    display: none;
  }
  .gridItem.active figure .flag::before {
    border-bottom-color: green;
  }
  .gridItem.active .flag span {
    display: none;
  }

  .gridItem.inactive figure .icon-speaking {
    display: none;
  }
  .gridItem.inactive figure .icon-check {
    display: inline;
    color: green;
  }
  .gridItem.inactive figure .icon-block {
    display: none;
  }
  .gridItem.inactive figure .flag::before {
    border-bottom-color: #ffffff;
  }
  .gridItem.inactive figure img {
    filter: blur(5px);
  }
  .gridItem.inactive .flag span {
    display: none;
  }

  .gridItem.canceled figure .icon-speaking {
    display: none;
  }
  .gridItem.canceled figure .icon-check {
    display: none;
  }
  .gridItem.canceled figure .icon-block {
    display: inline;
  }
  .gridItem.canceled figure .flag::before {
    border-bottom-color: #a41d33;
  }
  .gridItem.canceled figure img {
    opacity: 0.6;
    filter: grayScale(100%);
  }
  .gridItem.canceled .flag span {
    display: none;
  }

  img {
    width: 110%;
    height: auto;
    position: absolute;
    top: 0px;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
  }

  h3 {
    padding: 2px 0px;
    margin: 0px 0px;
    font-size: calc(1px + 100%);
    font-weight: bold;
    text-align: center;
    line-height: 1.1;
    color: #ffffff;
  }

  h3 small {
    opacity: 0.7;
  }

  .vote-true > figure {
    border-color: #4caf50;
  }
  .vote-true > h3 {
    color: #4caf50;
  }
  .vote-false > figure {
    border-color: #f44336;
  }
  .vote-false > h3 {
    color: #f44336;
  }
  .vote-null > figure {
    border-color: #2196f3;
  }
  .vote-null > h3 {
    color: #2196f3;
  }
`;

export const LogoBottom = styled.div`
  position: absolute;
  bottom: 5px;
  right: 20px;

  img {
    width: 120px;
  }
`;

export const Card = styled.section`
    border: solid 1px #ccc;
    border-radius: 4px;
    box-shadow: 8px 10px 20px 0 rgba(46,61,73,.15);
    max-height:200px;
    margin-bottom:42px;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    grid-row: span 1;
    grid-column: span 1;

    img {
        width: 100%;
        height:calc(55px + 150px);
    }

    p {
        font-size: 170px,
        word-break: break-all
    }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
  padding-left: 30px;

  p {
    margin: 8px;
    font-weight: bold;
    padding: 20px 0;
    font-size: calc(18px + 100%);
    color: #ffffff;
  }
`;

export const Span = styled.span`
  padding: 24px;
  border-radius: 8px;
  color: #fff;
  font-size: calc(50px + 100%);
  margin-bottom: 18px;
  font-weight: bold;

  &:nth-child(1) {
    background: green;
  }
  &:nth-child(2) {
    background: #f00;
  }
  &:nth-child(3) {
    background: #ccc;
  }

  &:nth-child(4) {
    background: transparent;
    border: 1px solid #fff;
  }
`;

export const ContainerSpan = styled.div`
  display: flex;
`;

export const CardTimer = styled.section`
  border: solid 1px #ccc;
  border-radius: 4px;
  box-shadow: 8px 10px 20px 0 rgba(46, 61, 73, 0.15);
  margin: 35px 0px 35px 0px;
  text-align: center;
  color: #ffffff;
  font-weight: bold;

  img {
    width: 100%;
    height: calc(65px + 150px);
    @media only screen and (max-width: 1600px) {
      max-width: 100px;
      height: calc(25px + 100px);
      max-height: 200px;
    }
  }

  p {
    font-size: 22px;
  }
`;
