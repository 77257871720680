import React from "react";
import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import AttendanceTable from "components/Attendance/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import UserCreate from "components/User/Form/ModalUser";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "components/CustomButtons/Button.js";
import { apiUserOnline } from "api/user.js";
import { apiLogoutUsers } from "api/api.js";
import { apiLogoutRoot } from "api/api.js";
import echo from "api/channels";
import _ from "lodash";

let user = JSON.parse(sessionStorage.getItem("USER"));
let acsss = _.get(user, "access_level.level", 0);

class AttendanceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: "",
      first_page_url: "",
      from: "",
      last_page: 1,
      last_page_url: "",
      next_page_url: null,
      path: "",
      per_page: "",
      prev_page_url: null,
      to: 1,
      total: 1,
      open: false,
      setOpen: false,
      edit: 0,
      filter: "",
      filter2: "",
      userPresent: null,
      openFirst: false,
      msgFirst: null,
      msgSecond: null,
      openSecond: false,
    };
    this.BaseUrl = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : '';
  }

  async componentDidMount() {
    this.setState({ users: await apiUserOnline() });
  }

  handleOpen = (param) => {
    this.setState({ edit: param.id ? param : 0 });
    this.setState({ userPresent: param })
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.setState({ openFirst: false });
    this.setState({ msgFirst: null });
    this.setState({ msgSecond: null });
    this.setState({ openSecond: false });
  };

  handleOpenFirst = () => {
    this.setState({ msgFirst: 'Deseja deslogar todos os usuários?' })
    this.setState({ openFirst: true });
  };

  handleOpenSecond = () => {
    this.setState({ msgFirst: null })
    this.setState({ openSecond: true });
    this.setState({ msgSecond: "Ao deslogar todos os usuários a sessão será suspensa por falta de quórum." });
  };

  render() {
    const { classes } = this.props;
    const { users, userPresent, msgFirst, msgSecond } = this.state;
    const usr = JSON.parse(sessionStorage.getItem("USER"));
    echo
      .channel("presence").listen("UsersPushed", (e) => this.setState({ users: e }));
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success" className={classes.cardHeader}>
              <h4 className={classes.cardTitleWhite}>Presenças </h4>
            </CardHeader>
            <CardBody>
              <div
                style={
                  acsss == 1
                    ? {
                        display: "flex",
                        justifyContent: "space-between",
                        lignItems: "flex-end",
                      }
                    : { display: "flex", justifyContent: "flex-end" }
                }
              >
                {acsss == 1 ? (
                  <div>
                    <Button
                      style={{ display: "none" }}
                      color="success"
                      href={`${this.BaseUrl}/videowall`}
                    >
                      Videowall
                    </Button>
                  </div>
                ) : (
                  ""
                )}

                <div
                  style={{
                    width: "70%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    onC
                    color="danger"
                    onClick={
                      // () => apiLogoutUsers(usr.id)
                      () => this.handleOpenFirst()
                    }
                  >
                    Deslogar todos
                  </Button>
                </div>
              </div>

              {users != "" ? (
                <AttendanceTable
                  tableHeaderColor="primary"
                  tableHead={["Nome", "Entrada", "Ultima Interação", "Logout"]}
                  tableData={users}
                  handleClickOpen={this.handleOpen.bind(this)}
                  id={usr.id}
                />
              ) : (
                ""
              )}
            </CardBody>
          </Card>
        </GridItem>
        <Modal /* modal de check Logout user */
          className="generalModal"
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <div className="modalContainer">
              <div className="modalContainer--header">
                <h4>Logout de usuário! </h4>
              </div>

              <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center", paddingBottom: "0" }}>
                <h4>{'Deseja deslogar o(a) '} {_.get(userPresent, 'name', null)}?</h4>
              </div>

              <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center", paddingTop: "0" }}>
                <button
                  className="componentButton componentButton--red mx-0"
                  onClick={() => { apiLogoutRoot(userPresent.id); this.handleClose(); }}>
                  SIM
                </button>

                <button className="componentButton componentButton--gray ml-2" onClick={() => this.handleClose()}>
                  Voltar
                </button>
              </div>
            </div>
          </Fade>
        </Modal>
        {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        <Modal /* modal de check Logout all-Users firt-check*/
          className="generalModal"
          open={this.state.openFirst}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openFirst}>
            <div className="modalContainer">
              <div className="modalContainer--header">
                <h4>Deslogar todos usuários!</h4>
              </div>

              <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center", paddingBottom: "0" }}>
                <h4>{msgFirst != null ? msgFirst : msgSecond} <br />{msgFirst != null ? "" : "Deseja Continuar com o logout dos usuários?"}</h4>
              </div>

              <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center", paddingTop: "0" }}>

                {this.state.openSecond == true ? // Validando se é o segundo check para habilitar o botão de deslogar todos
                  (<button
                    className="componentButton componentButton--red mx-0"
                    onClick={() => { apiLogoutUsers(usr.id); this.handleClose()}}
                  >
                    Deslogar todos
                  </button>)
                  :
                  (<button
                    className="componentButton componentButton--red mx-0"
                    onClick={() => { this.handleOpenSecond(); }}>
                    SIM
                  </button>)
                }

                <button className="componentButton componentButton--gray ml-2" onClick={() => this.handleClose()}>
                  Voltar
                </button>
              </div>
            </div>
          </Fade>
        </Modal>
      </GridContainer>
    );
  }
}

const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: "0px 0px 25px 5px rgba(0,0,0,0.3)",
    padding: "20px 20px 50px 20px",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

AttendanceList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AttendanceList);
