import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import apiPartidos from "../../api/partidos";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert"; // core components
import PropTypes from "prop-types";

import _ from "lodash";

export default function ModalDelete(props) {
  const classes = useStyles();
  const { id,name} = props;
  const [alert, setAlert] = useState(false);
  const [messageAlert, setMessage] = useState("");
  const [typeAlert, setTypeAlert] = useState("danger");

  const deletePartido = () => {

    apiPartidos
      .post(`partidos/${id}`, {
        id: id,
        nome:name
      })
      .catch(function(error) {
        if (error.response) {
          setTypeAlert("danger");
          console.log(error.response);
          setMessage("Há usuários cadastrados com este partido");
          setAlert(true);

          setTimeout(
            function() {
              setAlert();
              props.handleClick();
            }.bind(this),
            1000
          );
          return;
        }
      })
      .then(function(response) {
        if (_.get(response, "data.success", false) == true) {
          setTypeAlert("success");

          setMessage("Partido deletado com Sucesso");

          setAlert(true);

          setTimeout(
            function() {
              setAlert();
              props.handleClick();
              props.handleReloadPartitie();
            }.bind(this),
            1000
          );
          return;
        }
      });
  };

  return (
    <>
      <GridContainer style={{ display: "flex", justifyContent: "center" }}>
        <GridItem xs={18} sm={18} md={18}>
          <Card>
            <CardHeader color="danger" className={classes.cardHeader}>
              <h4 className={classes.cardTitleWhite}>Excluir Partido</h4>
              <span
                className="material-icons"
                onClick={() => props.handleClick()}
                style={{ cursor: "pointer" }}
              >
                close
              </span>
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardtText}>
                Tem certeza que deseja deletar o partido?
              </h4>
            </CardBody>
            <CardFooter>
              <Button onClick={deletePartido} color="danger">
                Deletar
              </Button>
            </CardFooter>
            <Snackbar
              place="tr"
              color={typeAlert}
              icon={AddAlert}
              message={messageAlert}
              open={alert}
              closeNotification={() => setAlert(false)}
              close
            />
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardtText: {
    minHeight: "auto",
    fontFamily: "sans-serif",
    fontSize: "16px",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
  },
};

ModalDelete.propTypes = {
  id: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleReloadPartitie: PropTypes.func.isRequired,
};

const useStyles = makeStyles(styles);
