import React, { Component } from "react";
import echo from "api/channels";
import _ from "lodash";
import "assets/css/material-dashboard-react.css?v=1.8.0";
import PropTypes from "prop-types";

var cron_implement;
var color_tribuna = "white";
var animation = "";

export default class timerImplement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thh: 0,
      tmm: 0,
      tss: 0,
      formatT: "00:00:00",
    };
  }
  componentDidMount() {
    const { time } = this.props;
    color_tribuna = "white";
    animation = "";

    this.convertTimeTribuna(time);

    echo.channel("tribuna").listen("TimeApp", (e) => {
      if (sessionStorage.getItem("implement") != 1) {
        return;
      }

      if (e.operacao === "finish" || e.operacao === "zerar") {
        this.stopTribuna();
      }

      if (e.operacao === "add") {
        clearInterval(cron_implement);
        let format = _.get(e, "time", "00:00:00");
        format = format.split(":");
        format = parseInt(format[1]);
        let minutos = this.state.tmm;

        minutos = format + minutos;

        let horas = "" + this.state.thh + ":" + minutos + ":" + this.state.tss;
        this.convertTimeTribuna(horas);
        return;
      }

      if (e.operacao === "pause") {
        this.pauseTribuna();
        return;
      }
      if (e.operacao === "retomar") {
        this.convertTimeTribuna(sessionStorage.getItem("timerTribuna"));
        return;
      }
    });
  }

  convertTimeTribuna(time) {
    if (time == null) {
      return;
    }
    let format = time.split(":");
    this.setState({ thh: parseInt(format[0]) });
    this.setState({ tmm: parseInt(format[1]) });
    this.setState({ tss: parseInt(format[2]) });
    return this.startTribuna();
  }

  startTribuna() {
    cron_implement = setInterval(() => {
      this.timerTribuna();
    }, 1000);
  }

  timerTribuna() {
    var ss = this.state.tss;
    var mm = this.state.tmm;
    var hh = this.state.thh;

    if (hh == 0 && mm == 0 && ss <= 0) {
      color_tribuna = "white";
      animation = "";
      this.stopTribuna();
    } else {
      ss--;

      if (ss < 0) {
        ss = 59;
        mm = mm - 1;
      }
      if (mm <= 0) {
        mm = 59;
        hh = hh - 1;
      }
      if (hh < 0) {
        hh = 0;
        mm = 0;
      }
    }

    if (hh == 0 && mm == 0 && (ss <= 30 && ss > 15)) {
      color_tribuna = "yellow";
      animation = "blink-slow-animation";
    }

    if (hh == 0 && mm == 0 && (ss > 0 && ss <= 15)) {
      color_tribuna = "red";
      animation = "blink-fast-animation";
    }

    var format =
      (hh < 10 ? "0" + hh : hh) +
      ":" +
      (mm < 10 ? "0" + mm : mm) +
      ":" +
      (ss < 10 ? "0" + ss : ss);

    this.setState({
      thh: hh,
      tmm: mm,
      tss: ss,
      formatT: format,
    });

    sessionStorage.setItem("timerImplement", format);

    return format;
  }

  pauseTribuna = () => {
    clearInterval(cron_implement);
  };

  stopTribuna = () => {
    color_tribuna = "white";
    animation = "";
    clearInterval(cron_implement);
    this.props.handleImplementOff();
  };

  render() {
    const { formatT } = this.state;
    return (
      <h2
        className={"text-shadow timer " + animation}
        style={{
          color: color_tribuna,
          fontSize: "calc(55px + 100%)",
          textAlign: "center",
        }}
      >
        {formatT.substr(3)}
      </h2>
    );
  }
}

timerImplement.propTypes = {
  time: PropTypes.string,
  handleImplementOff: PropTypes.func,
};
