import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import avatar from "assets/img/faces/marc.jpg";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "70%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
    fontSize: "18px",
  },
  margin: {
    margin: theme.spacing(2),
  },
}));

export default function AlignItemsList(props) {
  const classes = useStyles();

  const { name, photo } = props;

  return (
    <List className={classes.root}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="tribuna" src={!photo ? avatar : photo} />
        </ListItemAvatar>
        <ListItemText
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                {name}
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
    </List>
  );
}

AlignItemsList.propTypes = {
  name: PropTypes.string,
  photo: PropTypes.string,
  id: PropTypes.string,
  cont: PropTypes.number,
};
