import React, { Component } from "react";
import echo from "api/channels";
import _ from "lodash";
import "assets/css/material-dashboard-react.css?v=1.8.0";
import { getTribuna } from "api/videowall.js";
import PropTypes from "prop-types";

var cron_tribuna;
var color_tribuna = "white";
var animation = "";

export default class VideoWallProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thh: 0,
      tmm: 0,
      tss: 0,
      formatT: "00:00:00",
      validar: null,
      pause: 0,
    };
  }

  async componentDidMount() {
    let time;
    const tribunaData = await getTribuna();

    if (tribunaData.aparte != null) {
      let aparteTimeApp = _.get(tribunaData, ["aparte", "time_app", "0"], null);

      if (aparteTimeApp) {
        if (aparteTimeApp.pause_time != null) {
          this.setState({ pause: 1 });
        }

        if (aparteTimeApp.time_left != "00:00:00") {
          time = aparteTimeApp.time_left;
        }
      }
    } else if (tribunaData.emFala != null) {
      let emfalaTimeApp = _.get(tribunaData, ["emFala", "time_app", "0"], null);

      if (emfalaTimeApp.pause_time != null) {
        this.setState({ pause: 1 });
      }

      if (emfalaTimeApp.time_left != "00:00:00") {
        time = emfalaTimeApp.time_left;
      }
    }

    /*const { time } = this.props*/

    this.convertTimeTribuna(time);

    echo.channel("tribuna").listen("TimeApp", (e) => {
      if (sessionStorage.getItem("implement") == 1) {
        return;
      }

      if (e.operacao === "add") {
        clearInterval(cron_tribuna);
        let format = _.get(e, "time", "00:00:00");
        format = format.split(":");
        format = parseInt(format[1]);
        let minutos = this.state.tmm;

        minutos = format + minutos;

        let horas = "" + this.state.thh + ":" + minutos + ":" + this.state.tss;
        this.convertTimeTribuna(horas);
        return;
      }

      if (e.operacao === "pause") {
        this.pauseTribuna();
        return;
      }

      if (e.operacao === "retomar") {
        this.setState({ pause: 0 });
        this.convertTimeTribuna(sessionStorage.getItem("timerTribuna"));
        return;
      }
    });

    echo.channel("tribuna").listen("TalkTimeImplement", (e) => {
      clearInterval(cron_tribuna);

      this.setState({
        thh: 0,
        tmm: 0,
        tss: 0,
        formatT: "00:00:00",
      });

      this.props.handleImplementOpen(
        _.get(e, "tribuna", "00:00:00"),
        _.get(e, "user.public_name", null),
        _.get(e, "user.file", null)
      );
    });
  }

  convertTimeTribuna(time) {
    if (time == null) {
      return;
    }
    let format = time.split(":");
    this.setState({ thh: parseInt(format[0]) });
    this.setState({ tmm: parseInt(format[1]) });
    this.setState({ tss: parseInt(format[2]) });

    return this.startTribuna();
  }

  startTribuna() {
    clearInterval(cron_tribuna);
    cron_tribuna = setInterval(() => {
      this.timerTribuna();
    }, 1000);

    if (this.state.pause) {
      setTimeout(() => {
        this.pauseTribuna();
      }, 1000);
    }
  }

  timerTribuna() {
    let ss = this.state.tss;
    let mm = this.state.tmm;
    let hh = this.state.thh;

    if (hh == 0 && mm == 0 && ss <= 0) {
      color_tribuna = "white";
      animation = "";
      sessionStorage.setItem("timerTribuna", "00:00:00");
      this.stopTribuna();
      return;
    } else {
      ss--;

      if (ss < 0) {
        ss = 59;
        mm = mm - 1;
      }
      if (mm <= 0) {
        mm = 59;
        hh = hh - 1;
      }
      if (hh < 0) {
        hh = 0;
        mm = 0;
      }
    }

    if (hh == 0 && mm == 0 && (ss <= 30 && ss > 15)) {
      color_tribuna = "yellow";
      animation = "blink-slow-animation";
    } else if (hh == 0 && mm == 0 && (ss > 0 && ss <= 15)) {
      color_tribuna = "red";
      animation = "blink-fast-animation";
    } else {
      color_tribuna = "white";
      animation = "";
    }

    let format =
      (hh < 10 ? "0" + hh : hh) +
      ":" +
      (mm < 10 ? "0" + mm : mm) +
      ":" +
      (ss < 10 ? "0" + ss : ss);

    this.setState({
      thh: hh,
      tmm: mm,
      tss: ss,
      formatT: format,
    });

    sessionStorage.setItem("timerTribuna", format);
    return format;
  }

  pauseTribuna = () => {
    clearInterval(cron_tribuna);
  };

  stopTribuna = () => {
    color_tribuna = "white";
    animation = "";
    clearInterval(cron_tribuna);
    sessionStorage.removeItem("timerTribuna");
    this.setState({
      thh: 0,
      tmm: 0,
      tss: 0,
      formatT: "00:00:00",
    });

    this.props.handleCloseTribuna();
  };

  render() {
    const { formatT } = this.state;
    return (
      <h2
        className={"text-shadow timer " + animation}
        style={{
          color: color_tribuna,
          fontSize: "calc(55px + 100%)",
          textAlign: "center",
        }}
      >
        {formatT.substr(3)}
      </h2>
    );
  }
}

VideoWallProfile.propTypes = {
  handleImplementOpen: PropTypes.func.isRequired,
  handleCloseTribuna: PropTypes.func.isRequired,
};
