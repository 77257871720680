import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import _ from "lodash";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import StickyHeadTable from "components/Parliamentary/Documents/documents.js";
import Subscription from "components/Parliamentary/tribuna/subscription";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { apiOpenPdf, apiStartVotacao } from "api/president";
import echo from "api/channels";
import Pdf from "./Documents/pdf";

import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import StickyHeadTable2 from "components/Parliamentary/DocsCityCouncilor/docsCityCouncilor.js";
import StickyHeadTable3 from "./DocsCityCouncilor/previousDocuments";

var tokenGet =
  sessionStorage.getItem("TOKEN_KEY") &&
  sessionStorage.getItem("TOKEN_KEY").charAt(0) == '"'
    ? sessionStorage.getItem("TOKEN_KEY")
    : JSON.stringify(sessionStorage.getItem("TOKEN_KEY"));
var TOKEN_KEY = JSON.parse(tokenGet);
class DashboardParlamentary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roteiro: null,
      documents: null,
      file: null,
      value: 1,
      image: null,
      modal: false,
      pdfFile: null,
      pdfExecutivo: null,
    };
  }

  componentDidMount() {
    echo.channel("subscription").listen("StopSubscription", () => {
      const { value } = this.state;
      if (value == 5) {
        this.setState({ value: 1 });
      }
    });

    echo.channel("subscription").listen("CloseDiscution", () => {
      const { value } = this.state;
      if (value == 5) {
        this.setState({ value: 1 });
      }
    });

    const { configRead } = this.props;
    if (configRead) {
      this.setState({ value: 6 });
    }
  }

  handleChange = (event) => {
    this.setState({ value: event });
  };

  handleVisualizar = (doc) => {
    this.setState({ file: _.get(doc, "pdfUrl", null) });
  };

  handleVisualizarImagem = (doc) => {
    this.setState({ image: _.get(doc, "file", null) });
  };

  handleCloseChange = () => {
    this.setState({ file: null });
    this.setState({ image: null });
  };

  handlelido = (id) => {
    console.log(id);
  };

  handlevotacao = (id) => {
    apiStartVotacao(id);
    this.setState({ alertSuccess: true });
    setTimeout(() => {
      this.setState({ alertSuccess: false });
    }, 5000);
  };

  handletribuna = (id) => {
    console.log(id);
  };

  handleOpenExecutivo = (pdfExecutivo) => {
    if (pdfExecutivo.indexOf("localhost") >= 0) {
      pdfExecutivo = pdfExecutivo.replace("/api/", "");
    } else {
      pdfExecutivo = pdfExecutivo.replace("/api2/api/", "/api2/");
    }
    pdfExecutivo = pdfExecutivo.replace("public", "") + "#toolbar=0&view=FitH";
    if (pdfExecutivo.indexOf("uploads") <= 7) {
      pdfExecutivo =
        process.env.REACT_APP_STAGE_V2 + pdfExecutivo + "#toolbar=0&view=FitH";
      pdfExecutivo = pdfExecutivo.replace("api/", "");
    }
    this.setState({ modal: true, pdfFile: pdfExecutivo });
  };

  handleOpenPdf = (pdf, name, type) => {
    let user = JSON.parse(sessionStorage.getItem("USER"));
    if (pdf.indexOf(".pdf") >= 0 && pdf.indexOf("uploads") >= 0) {
      this.handleOpenExecutivo(pdf);
    } else {
      const urlLog =
        "log.pdf?name=" + name + "&type=" + type + "&userid=" + user.id;
      const pdfUrl =
        pdf +
        "?name=" +
        name +
        "&userid=" +
        user.id +
        "&api_token=" +
        TOKEN_KEY;
      const result = apiOpenPdf(urlLog);
      this.setState({ pdfFile: pdfUrl, pdfExecutivo: null });
      this.setState({ modal: true });
    }
  };

  handleCloseModal = () => {
    this.setState({ modal: false });
    this.setState({ pdfFile: null });
  };

  hasDocumentWithPastDate = () => {
    const { docsTribuna } = this.props;
    const currentDate = new Date();

    if (docsTribuna && docsTribuna.length > 0) {
      return docsTribuna.some((doc) => new Date(doc.date_use) < currentDate);
    }

    return false;
  };

  render() {
    const {
      emFala,
      timeLeft,
      inscrition_finish,
      cancelado,
      message,
      implement,
      concluido,
      inscrito,
      name_order_session,
      classes,
      documents,
      roteiro,
      discution,
      subscription,
      user,
      usersTribuna,
      docsTribuna,
      configRead,
    } = this.props;
    const { image, alertSuccess, value, file, modal, pdfFile } = this.state;

    return (
      <div className={classes.root}>
        {(value == 1 || value == 3) && file != null ? (
          <button
            style={{
              width: "40px",
              height: "40px",
              fontSize: "30px",
              position: "relative",
              left: "44%",
              top: "0%",
              color: "white",
              backgroundColor: "#4B5A5A",
              border: "10px",
              borderRadius: "10px",
            }}
            onClick={() => this.handleCloseChange()}
          >
            X
          </button>
        ) : (
          ""
        )}
        {(value == 1 || value == 3) && image != null ? (
          <button
            style={{
              width: "40px",
              height: "40px",
              fontSize: "30px",
              position: "relative",
              left: "44%",
              top: "0%",
              color: "white",
              backgroundColor: "#4B5A5A",
              border: "10px",
              borderRadius: "10px",
            }}
            onClick={() => this.handleCloseChange()}
          >
            X
          </button>
        ) : (
          ""
        )}
        <AppBar
          style={{ borderRadius: "10px" }}
          position="static"
          color="default"
        >
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              label="Roteiro"
              style={
                roteiro == null
                  ? { display: "none" }
                  : { display: "none", fontSize: "1.5rem" }
              }
              onClick={() => this.handleChange(0)}
              value={0}
            />
            <Tab
              label={name_order_session}
              style={
                documents == null
                  ? { display: "none" }
                  : { display: "block", fontSize: "1.5rem" }
              }
              onClick={() => this.handleChange(1)}
              value={1}
            />
            <Tab
              label="Inscrições"
              style={
                subscription == null && discution == null
                  ? { display: "none" }
                  : { display: "block", fontSize: "1.5rem" }
              }
              onClick={() => this.handleChange(5)}
              value={5}
            />
            <Tab
              label="DOCUMENTOS DO VEREADOR"
              style={
                docsTribuna == null
                  ? { display: "none" }
                  : { display: "block", fontsize: "18px" }
              }
              onClick={() => this.handleChange(6)}
              value={6}
            />
            <Tab
              label="DOCUMENTOS ANTERIORES"
              style={
                this.hasDocumentWithPastDate()
                  ? { display: "block", fontsize: "18px" }
                  : { display: "none" }
              }
              onClick={() => this.handleChange(7)}
              value={7}
            />
          </Tabs>
        </AppBar>
        {value == 1 && image != null ? (
          <iframe src={image} width="100%" height={800}></iframe>
        ) : (
          ""
        )}
        {value == 1 && documents != null && file == null ? (
          <StickyHeadTable
            handlelido={this.handlelido.bind()}
            documentos={documents}
            handleVisualizarImagem={this.handleVisualizarImagem.bind()}
            handleVisualizar={this.handleVisualizar.bind()}
            handleOpenPdf={this.handleOpenPdf.bind()}
          />
        ) : (
          ""
        )}
        {value == 1 && file != null ? <Pdf height={800} file={pdfFile} /> : ""}

        {/*
          value == 1 && file != null ? (
          <iframe src={file + "#toolbar=0"} width="100%" height="850"></iframe>
        ) : (
          ""
        )}{" "}
        {/*<Pdf  height={800}  file={file} />*/}
        {value == 5 ? (
          <Subscription
            emFala={emFala}
            timeLeft={timeLeft}
            inscrition_finish={inscrition_finish}
            cancelado={cancelado}
            ordemClick={this.props.ordemClick.bind(this)}
            implementDisable={this.props.implementDisable.bind(this)}
            message={message}
            implement={implement}
            concluido={concluido}
            inscrito={inscrito}
            discution={discution}
            usersTribuna={usersTribuna}
            subscription={subscription}
            user={user}
          />
        ) : (
          ""
        )}
        {value == 6 ? (
          <StickyHeadTable2
            docsTribuna={docsTribuna}
            handleVisualizar={this.handleVisualizar.bind()}
            handleOpenPdf={this.handleOpenPdf.bind()}
          />
        ) : (
          ""
        )}
        {value == 7 ? (
          <StickyHeadTable3
            docsTribuna={docsTribuna}
            handleVisualizar={this.handleVisualizar.bind()}
            handleOpenPdf={this.handleOpenPdf.bind()}
          />
        ) : (
          ""
        )}

        <Snackbar
          place="tr"
          color="success"
          icon={AddAlert}
          message="Votação Iniciada"
          open={alertSuccess}
          closeNotification={() => this.setState({ alertSuccess: false })}
          close
        />
        <div>
          <Modal
            className="modalPreview__pdf"
            open={modal}
            onClose={() => this.handleCloseModal()}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={modal}>
              <Card className="modalPreview__pdf--container">
                <CardHeader className="modalPreview__pdf--header">
                  <span
                    className="material-icons"
                    onClick={() => this.handleCloseModal()}
                  >
                    close
                  </span>
                </CardHeader>
                <CardBody className="modalPreview__pdf--body">
                  {this.state.pdfExecutivo ? (
                    <object
                      data={this.state.pdfExecutivo}
                      type="application/pdf"
                      width="100%"
                      height="500"
                    />
                  ) : (
                    <Pdf height={800} file={pdfFile} />
                  )}
                </CardBody>
              </Card>
            </Fade>
          </Modal>
        </div>
      </div>
    );
  }
}

const styles = {
  root: {
    flexGrow: 1,
  },
};

DashboardParlamentary.propTypes = {
  classes: PropTypes.object.isRequired,
  emFala: PropTypes.bool.isRequired,
  inscrition_finish: PropTypes.bool.isRequired,
  cancelado: PropTypes.bool.isRequired,
  ordemClick: PropTypes.func.isRequired,
  implementDisable: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  implement: PropTypes.bool.isRequired,
  concluido: PropTypes.bool.isRequired,
  inscrito: PropTypes.bool.isRequired,
  discution: PropTypes.bool.isRequired,
  usersTribuna: PropTypes.array.isRequired,
  subscription: PropTypes.array.isRequired,
  user: PropTypes.array.isRequired,
  name_order_session: PropTypes.string.isRequired,
  documents: PropTypes.array.isRequired,
  roteiro: PropTypes.array.isRequired,
};

export default withStyles(styles)(DashboardParlamentary);
