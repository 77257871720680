import axios from "axios";
import _ from "lodash";

var tokenGet =
  sessionStorage.getItem("TOKEN_KEY") &&
  sessionStorage.getItem("TOKEN_KEY").charAt(0) == '"'
    ? sessionStorage.getItem("TOKEN_KEY")
    : JSON.stringify(sessionStorage.getItem("TOKEN_KEY"));
var token = JSON.parse(tokenGet);

async function apiGet() {
  let data;
  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/session",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      data = response.data.success;
    })
    .catch(function(error) {
      Promise.reject(error.response);
    });

  return data;
}

async function apiGetDocsProjects(id) {
  let data;
  let req = (typeof id==='object') ?  id : { id_session: id};
  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/session/getDocsProject",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: req,
  })
      .then((response) => {
        data = response.data.success;
      })
      .catch(function(error) {
        Promise.reject(error.response);
      });

  return data;
}

function apiStartSession(id) {
  axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/session/start",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { id: id },
  }).catch(function(error) {
    Promise.reject(error.response);
  });
}

function apiStopSession(id, status) {
  axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/session/stop",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { id: id, status: status },
  }).catch(function(error) {
    Promise.reject(error.response);
  });
}

function apiOrderSession(order) {
  let user = JSON.parse(sessionStorage.getItem("USER"));
  axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/session/order",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { order: order,userId:user.id},
  }).catch(function(error) {
    Promise.reject(error.response);
  });
}

async function apiGetSession() {
  let data;

  await axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + "/session/get/session",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      data = _.get(response, "data.success", null);
    })
    .catch(function(error) {
      Promise.reject(error.response);
    });

  return data;
}

function apiAutorizedSession(id) {
  let user = JSON.parse(sessionStorage.getItem("USER"));

  axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/session/autorized",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { id: id, user: user.id },
  }).catch(function(error) {
    Promise.reject(error.response);
  });
}

function apiGetCanalName() {
  axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + "/session/name",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).catch(function(error) {
    Promise.reject(error.response);
  });
}

function apiRegisterTime(time_session, time_order_session) {
  axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + "/session/register/time",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    params: {
      time_session: time_session,
      time_order_session: time_order_session,
    },
  }).catch(function(error) {
    Promise.reject(error.response);
  });
}

async function apiDisallow(id) {
  await axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + "/session/disallow",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    params: {
      id: id,
    },
  }).catch(function(error) {
    Promise.reject(error.response);
  });
}

async function apiFilter(status, type) {
  let data;
  await axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + "/session/filter",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    params: {
      situation: status,
      id_type: type,
    },
  })
    .then((response) => {
      data = _.get(response, "data[0]", null);
    })
    .catch(function(error) {
      Promise.reject(error.response);
    });

  return data;
}

async function apiIntegration(file, url, replace) {
  let message;
  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/integration/session/import",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      file: file,
      url: url,
      replace: replace,
    },
  })
    .then((response) => {
      message = _.get(response, "data", "");
    })
    .catch(function(error) {
      if (error.response) {
        console.log(error.response);
        message = _.get(error, "response.data", "houve algo de errado");
      }
    });

  return message;
}

async function exportSession(id_session) {
  let message;
  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/integration/session/export",
    headers: {
      Accept: "application/xml",
      Authorization: "Bearer " + token,
    },
    data: {
      id_session: id_session,
    },
  })
    .then((response) => {
      message = _.get(response, "data", "");
    })
    .catch(function(error) {
      if (error.response) {
        console.log(error.response);
        message = _.get(error, "response.data", "houve algo de errado");
      }
    });

  return message;
}

export {
  apiIntegration,
  exportSession,
  apiFilter,
  apiDisallow,
  apiRegisterTime,
  apiGetCanalName,
  apiGet,
  apiGetDocsProjects,
  apiStartSession,
  apiStopSession,
  apiOrderSession,
  apiGetSession,
  apiAutorizedSession,
};
