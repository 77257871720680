import React from "react";
import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Session/TableDetails/Table.js";
import TableDocs from "components/Session/TableDetails/TableDocs.js";
import Card from "components/Card/Card.js";
import { apiStartSession } from "api/sessions.js";
import { apiGetDocumentsSessionManual } from "../../api/documents";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import PropTypes from "prop-types";
import { apiGet } from "api/sessions.js";
import { apiReadDocument, apiStartVotingBlock, apiStartVotacao } from "api/president";
import { getVotingManual, apiVote } from "api/voting";
import echo from "api/channels";
import moment from "moment";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import _ from "lodash";
import api from "../../api/typedocument";

class ManualSession extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sessions: [],
      types: [],
      tab: 0, // dashboard
      session: null,
      votacao_checkedall: false,
      votacao_checkedall_disabled: false,
      selectAll: [],
      check_true: false,
      check_false: false,
      votingBlock: [],
      type_voting_bloc: null,
      start_session_name: null,
      start_session_time: null,
      session_start: null,
      type_sessions: "",
      docs_session: [],
      docs_session_leitura: [],
      parlamentares: [],
      voteUsers: null,
      votingManual: null,
      message: "", 
      param: "success",
      alertSuccess: false,
      docs_session_voting: [],
      docs_session_leitura: [],
      docs_session_start: null,
    };
  }

  componentDidMount() {
    this.reload();

    echo.channel("session").listen("StartSession", (e) => {
      this.setState({ start_session_name: _.get(e, "name", null) });
      this.setState({ start_session_time: _.get(e, "updated_at", null) });
      this.setState({ session_start: e })
      this.reload();
    });

    echo.channel("session").listen("SessionAutorized", (e) => {
      this.reload();
    });

    echo.channel("session").listen("GetDocumentsManual", (e) => {
      this.setState({ docs_session_leitura: _.get(e, 'documents.leitura', []) });
    });

    echo.channel("voting").listen("StartVoting", (e) => {
      this.setState({ votingManual: e })
      this.reload();
    });

    echo.channel("voting").listen("UsersVoteManual", (e) => {
      this.setState({ voteUsers: e })
    });

    echo.channel("voting").listen("StartVotingBloc", (e) => {
      this.setState({ votingManual: e })
      this.reload();
    });

    echo.channel("session").listen("StopSession", () => {
      this.setState({ session_start: null })
      this.setState({ tab: 0 });
      this.setState({
        message: 'Sessão Finalizada',
        param: "success",
        alertSuccess: true,
      });

      this.reload();

      setTimeout(() => {
        this.setState({ alertSuccess: false });
      }, 2000);
    });

    echo.channel("voting").listen("StopVoting", async () => {
      this.setState({ votingManual: null })
      this.setState({ votacao_checkedall: false })
      this.setState({ votacao_checkedall_disabled: false })
      this.setState({ check_true: false });
      this.setState({ check_false: false });
      this.setState({ votingBlock: [] })
      this.setState({ selectAll: [] })
      this.setState({ voteUsers: null })

      this.setState({
        message: 'Votação Finalizada',
        param: "success",
        alertSuccess: true,
      });

      this.reload();

      setTimeout(() => {
        this.setState({ alertSuccess: false });
      }, 2000);
    });
  }

  async reload() {
    let docs = null;
    let sessions = await apiGet();
    let id_session = _.get(sessions, 'session_start.id', null);
    let votingManual = await getVotingManual();

    if(id_session){
      docs = await apiGetDocumentsSessionManual(id_session);
    }

    this.setState({ session_start: _.get(sessions, "session_start", null) })
    this.setState({ sessions: _.get(sessions, "sessions", []) });
    this.setState({ types: _.get(sessions, "typeSessions", []) });

    this.setState({ docs_session_voting: _.get(docs, 'documents.votacao', []) });
    this.setState({ docs_session_leitura: _.get(docs, 'documents.leitura', []) });
    this.setState({ docs_session_start: _.get(docs, 'documents.start', []) });

    this.setState({ parlamentares: _.get(votingManual, 'presentes', [])})
    this.setState({ votingManual: votingManual})
  }
  //******************************************************************************************/
  //Envio do Array para abertura de votação
  handleSendVotingBlock = async () => {
    const { votingBlock, type_voting_bloc } = this.state;
    const result = await apiStartVotingBlock(votingBlock, type_voting_bloc);

    if (_.get(result, "data.error", null) == 401) {
      
      this.setState({ alertError: true });
      this.setState({
        messageError: _.get(result, "data.message", "Não há Quorún"),
      });
      setTimeout(() => {
        this.setState({ alertError: false });
      }, 3000);
      return;
    }

    this.setState({
      message: 'Votação Em Bloco Iniciada',
      param: "success",
      alertSuccess: true,
    });

    this.setState({ votacao_checkedall_disabled: true });
    setTimeout(() => {
      this.setState({ alertSuccess: false });
    }, 2000);
  };

  //Check all docs para votaçao
  handleVotingBloco = (id, type) => {
    const { votingBlock, type_voting_bloc } = this.state;

    if (votingBlock.find((element) => element === id)) {
      votingBlock.splice(votingBlock.indexOf(id), 1);
      if (votingBlock.length === 0 || votingBlock.length === null) {
        this.setState({ type_voting_bloc: null });
      }
      this.setState({ votingBlock: votingBlock });
      return;
    }
    if (type_voting_bloc != null && type != type_voting_bloc) {
      alert("Você só pode selecionar tipos de votação " + type_voting_bloc);
      return;
    }
    if (type_voting_bloc == null) {
      this.setState({ type_voting_bloc: type });
    }

    votingBlock.push(id);
    this.setState({ votingBlock: votingBlock });
  };

  //Check All documentos para votação
  handleAllChecked = () => {
    this.setState({ votacao_checkedall: !this.state.votacao_checkedall });
    let documentos = this.state.docs_session_voting;
    const type_voting = documentos[0].type_voting;
    
    documentos.map((row) => {
      if (row.voted == false && row.type_voting == type_voting) {
        this.handleVotingBloco(row.id, row.type_voting);
      }
    });
  };
  
  //Send vote User
  handleSendVote = async (doc, user, vote) => {
    let data = {
      vote: vote,
      id_document: doc.id,
      id_session: doc.id_session,
      id_user: user.id,
      vote_manual: true,
      public_name: user.public_name,
    };

    //Se for votação em bloco enviar votos para essa rota
    if (_.get(this.state.votingManual, 'bloc', null) == true){
      await apiVote(data, "/voting/bloc/vote");
    }else{
      await apiVote(data, "/voting/vote");
    }

  }

  handleSelectAll = (id, vote, user) => {
    const { selectAll } = this.state;
    selectAll[id] = vote;

    this.handleSendVote(this.state.docs_session_start, user, vote)
    this.setState({ selectAll: selectAll });
  }

  //Select All True
  handleCheckAllTrue = () => {
    let parlamentares = this.state.parlamentares;
    this.setState({ check_true: !this.state.check_true });

    if (this.state.check_false == true ){
      this.setState({ check_false: !this.state.check_false });
    }
    

    parlamentares.map((user) => {
      this.handleSendVote(this.state.docs_session_start, user, true)
      this.handleSelectAll(user.id, true, user)
    })

  }

  //Select All False
  handleCheckAllFalse = () => {
    let parlamentares = this.state.parlamentares;
    this.setState({ check_false: !this.state.check_false });

    if (this.state.check_true == true) {
      this.setState({ check_true: !this.state.check_true });
    }

    parlamentares.map((user) => {
      this.handleSendVote(this.state.docs_session_start, user, false)
      this.handleSelectAll(user.id, false, user)
    })
  }
  //******************************************************************************************/
  openDocs = (session) => {
    this.setState({ tab: 1}); // tab de documentos da sessão
  }

  //startVoting
  handleStart = async (id, name) => {
    this.setState({
      message: 'Votação Iniciada',
      param: "success",
      alertSuccess: true,
    });

    await apiStartVotacao(id, name, true);

    setTimeout(() => {
      this.setState({ alertSuccess: false });
    }, 2000);
  }

  //Autorize e inicia a sessão
  startSession = async (id) => {
    let user = JSON.parse(sessionStorage.getItem("USER"));
    
    await api
      .post("session/autorized", { id: id, user: user.id })
      .catch(function (error) {
        if (_.get(error, "response.data", null) !== null) {
          alert(
            "Não é possivel autorizar está sessão, pois há outra sessão autorizada"
          );
          return;
        }
      });

    this.setState({
      message: 'Sessão Iniciada',
      param: "success",
      alertSuccess: true,
    });

    await apiStartSession(id);

    setTimeout(() => {
      this.setState({ alertSuccess: false });
    }, 2000);
  };

  handleLeitura = async (id) => {
    await apiReadDocument(id);
  }

  render() {
    const { classes } = this.props;
    const {
      alertSuccess,
      message,
      param,
      parlamentares,
      votingManual,
      sessions,
      votacao_checkedall_disabled,
      votacao_checkedall,
      selectAll,
      voteUsers,
      check_true,
      check_false,
      docs_session_leitura,
      votingBlock,
      start_session_name,
      start_session_time,
      session_start,
      docs_session_start,
      docs_session_voting,
      tab,
    } = this.state;
    return (
      <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success" className={classes.cardHeader}>
                <h4> {tab > 0 && start_session_name != null ? start_session_name : "Votação Manual"} </h4>
                
                {/* Nome do projeto em votação */}
                <h4 style={{marginRight: "10rem"}}>
                  { votingManual != null && tab > 0 && _.get(votingManual, 'bloc', null) == true ? 
                      "Votação em bloco"
                    : votingManual != null && tab > 0 ? 
                      "Em votação: " + _.get(votingManual, 'document.name', " ") 
                    : " " }
                  </h4>

                  {tab > 0 ? 
                    <span className="material-icons" onClick={() => this.setState({ tab: 0 })} >
                      reply_all 
                    </span>
                  :""}
              </CardHeader>

                
              <CardBody>
                {tab == 0 ? 
                  <>
                    { start_session_name == null ? ( "" ) : (
                      <div>
                        {start_session_name} Iniciada ás{" "}
                        {moment(start_session_time).format("YYYY-MM-DD hh:mm ")}
                      </div>
                    )}

                    {sessions != [] ? (
                      <Table
                        tableHeaderColor="primary"
                        tableHead={[
                          "Nome da Sessão",
                          "Tipo",
                          "Ultima atualização",
                          "Status",
                          "Operação",
                        ]}
                        sessionStart={session_start }
                        tableData={sessions} // Listar sessões
                        openDocs={this.openDocs.bind(this)} // abrir documentos
                        startSession={this.startSession.bind(this)}
                      />
                    ) : (null)} 
                  </>
                : null}
            
                {tab == 1 ?
                    <TableDocs
                      tableHeaderColor="primary"
                      tableHead={[
                        "Nome do documento",
                        "Momento da sessão",
                        "Visualizar",
                        "Operação",
                      ]}
                      documentoStartVoting={docs_session_start}
                      documentosVotacao={docs_session_voting}
                      documentosLeitura={docs_session_leitura}
                      votingBlocoSelect={votingBlock}
                      parlamentares={parlamentares}
                      votingManual={votingManual}
                      voteUsers={voteUsers}

                      handleCheckAllTrue={this.handleCheckAllTrue.bind(this)}
                      handleCheckAllFalse={this.handleCheckAllFalse.bind(this)}
                      handleSelectAll={this.handleSelectAll.bind(this)}
                      selectAll={selectAll}
                      check_true={check_true}
                      check_false={check_false}

                      handleSendVotingBlock={this.handleSendVotingBlock.bind(this)}
                      handleVotingBloco={this.handleVotingBloco.bind(this)}
                      votacao_checkedall_disabled={votacao_checkedall_disabled}
                      votacao_checkedall={votacao_checkedall}
                      handleAllChecked={this.handleAllChecked.bind()}
                      changeLeitura={this.handleLeitura.bind(this)}
                      start={this.handleStart.bind(this)}
                    />
                : ""} 
              </CardBody>
            </Card>
          </GridItem>

        {/* Card alert mensage */}
        <Snackbar
          place="tr"
          color={param}
          icon={AddAlert}
          message={message}
          open={alertSuccess}
          closeNotification={() => this.setState({ alertSuccess: false })}
          close
        />
      </GridContainer>
    );
  }
}

const styles = {
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  }
};

ManualSession.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ManualSession);
