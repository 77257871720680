import axios from "axios";
import _ from "lodash";

var tokenGet =
  sessionStorage.getItem("TOKEN_KEY") &&
  sessionStorage.getItem("TOKEN_KEY").charAt(0) == '"'
    ? sessionStorage.getItem("TOKEN_KEY")
    : JSON.stringify(sessionStorage.getItem("TOKEN_KEY"));
var token = JSON.parse(tokenGet);

async function _request(method, path) {
    await axios({
        method: method,
        url: process.env.REACT_APP_STAGE + path,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
    .then((response) => {
        return Promise.resolve(_.get(response, "data"));
    })
    .catch(function(error) {
        return Promise.reject(error.response);
    });
}

async function ringBell() {
    await _request('get', '/automation/bell');
}

async function micStatus(status, key) {
    await _request('get', '/automation/mic/' + status + '/' + key);
}

async function autoStartMics() {
    await _request('get', '/automation/autoStartMics');
}

async function autoStopMics() {
    await _request('get', '/automation/autoStopMics');
}

export {
    ringBell,
    micStatus,
    autoStartMics,
    autoStopMics
};