import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";


export default function CustomTable(props) {
  const { tableData, tableDataAll } = props;

  return (
    <>
      <section className="section">
        <div className="content">
          <h5 className="h5">
            Projetos Selecionados
          </h5>
        </div>

        {_.get(tableData, "length", 0) > 0 && (
          <div className="scroll">
            {_.get(tableData, "length", 0) > 0 &&
              tableData.map(function (column, i) {
                return (
                  <tr className="tr" key={i}>
                    <td className="td">
                      {column.title}
                    </td>
                  </tr>
                );
              })}
          </div>
        )}

        {_.get(tableDataAll, "length", 0) > 0 && (
          <div className="scroll">
            {_.get(tableDataAll, "length", 0) > 0 &&
              tableDataAll.map(function (column, i) {
                return (
                  <tr className="tr" key={i}>
                    <td
                      className="td"
                    >
                      {column.title}
                    </td>
                  </tr>
                );
              })}
          </div>
        )}
      </section>
    </>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};
CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  updateColumn: PropTypes.func,
};