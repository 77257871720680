import axios from "axios";
import _ from "lodash";

async function apiPresence() {
  let data;
  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/videowall/attendance",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => {
      data = _.get(response, "data");
    })
    .catch(function(error) {
      Promise.reject(error.response);
    });

  return data;
}

async function apiDocument() {
  let data = [];

  await axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + "/document",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => {
      data = _.get(response, "data.document", null);
    })
    .catch(function(error) {
      Promise.reject(error.response);
    });

  return data;
}

async function apiSession() {
  let data;
  await axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + "/videowall/session",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => {
      data = _.get(response, "data", null);
    })
    .catch(function(error) {
      Promise.reject(error.response);
    });

  return data;
}

async function getTribuna() {
  let data = null;

  await axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + "/tribuna/checkSession",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => {
      data = _.get(response, "data.success", null);
    })
    .catch(function(error) {
      Promise.reject(error.response);
    });

  return data;
}

async function getConfigurations() {
  let data = null;

  await axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + "/videowall/configurations",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => {
      data = _.get(response, "data", null);
    })
    .catch(function(error) {
      Promise.reject(error.response);
    });

  return data;
}

export { apiPresence, apiDocument, apiSession, getTribuna, getConfigurations };
