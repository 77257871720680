import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { TimeappEcho, GetInfos, TalkStop, TalkStopComment } from "api/_timeapp";
import { Grid, Box } from "@material-ui/core";
import echo from "api/channels";
import Timer from "../Util/timer";
import Talk from "./Talk";
import _, { padStart } from "lodash";
import "animate.css";
import "assets/css/material-dashboard-react.css?v=1.8.0";
import moment from "moment";
import "moment/locale/br";

class TimeApp extends React.Component {
  constructor(props) {
    super(props);
    this.childTimer = React.createRef();
    this.childModalTimer = React.createRef();
    this.state = {
      user_photo: null,
      modal_user_photo: null,
      part_session: null,
      part_session_time: null,
      time_session: null,
      timer: null,
      timer_paused: false,
      modal_timer: null,
      use_storage: false,
      em_fala: [],
      aparte: [],
    };
  }

  async componentDidMount() {
    const infos = await GetInfos();

    this.updateState(infos);

    if (infos.timer != null && infos.timer.length <= 0) {
      if (sessionStorage.getItem("timer")) {
        const timerTime = JSON.parse(sessionStorage.getItem("timer"));

        this.setState({ use_storage: true });
        this.setState({ timer: timerTime.time });
        this.setState({ timer_paused: timerTime.paused });
      }
    } else {
      this.updateTimer(infos.timer);
    }

    /** TimeApp ************************/

    //Update Timer
    TimeappEcho.channel("timeapp").listen("UpdateTimer", (data) => {
      sessionStorage.removeItem("timer");
      this.updateTimer(data);
    });

    TimeappEcho.channel("timeapp").listen("SeparateTimer", (data) => {
      const action = _.get(data, "action", null);
      const timerTime = JSON.parse(sessionStorage.getItem("timer") || null);

      let time;

      this.setState({ use_storage: true });

      switch (action) {
        case "add":
          time = _.get(data, "time", null);

          if (time) {
            this.setState({ timer: null });

            if (timerTime) {
              var milliseconds = moment.duration(padStart(timerTime.time, 8, "00:")).add(time, "seconds").as("milliseconds");
              var newTime = moment.utc(milliseconds).format("HH:mm:ss");
            } else {
              newTime = time;
            }

            this.setTimerStorage(newTime);
            this.setState({ timer: newTime });
          }
          break;
        case "pause":
          if (timerTime) {
            this.setState({ timer: null });
            this.changeSeparateTimer(timerTime.time, true);
          }
          break;
        case "retomar":
          if (timerTime) {
            this.setState({ timer: null });
            this.changeSeparateTimer(timerTime.time, false);
          }
          break;
        case "finish":
          this.removeTimerStorage();
          this.cleanTimer();
          this.setState({ use_storage: false });
          this.setState({ timer_paused: false });
          this.setState({ timer: null });
          break;
      }
    });

    //Session start
    TimeappEcho.channel("timeapp").listen("SessionStart", (data) => {
      this.updateState(data);
    });

    //Session stop
    TimeappEcho.channel("timeapp").listen("SessionStop", (data) => {
      this.updateState(data);
      this.cleanTimer();
    });
    //**********************************/

    //Session
    echo.channel("session").listen("Suspend", () => {
      this.setState({ part_session_time: null });
      this.updateState([]);
      this.setState({ timer: null });
    });

    //Tribuna
    echo.channel("tribuna").listen("GetSessionTime", (data) => {
      this.setState({ part_session_time: null });
      this.setState({ part_session: _.get(data, ["order_session", "name"], null) });
      this.setState({ part_session_time: _.get(data, ["order_session", "time"], null) });
      this.cleanTimer();
    });
  }

  updateState = (data) => {
    this.setState({ part_session: _.get(data, "part_session", null) });
    this.setState({ part_session_time: _.get(data, "part_session_time", null) });
    this.setState({ time_session: _.get(data, "time_session", null) });
  };

  updateTimer = (data) => {
    if (data) {
      //Em fala
      if (data.emFala) {
        this.setState({ timer: null });
        this.setState({ user_photo: _.get(data, ["emFala", 0, "photoUrl"], null) });
        this.setState({ timer: _.get(data, ["emFala", 0, "time_app", 0, "time_left"], null) });
        this.setState({ em_fala: _.get(data, ["emFala", 0, "time_app", 0], null) });

        if (data.emFala[0] && data.emFala[0].time_app[0].pause_time != null) {
          this.setState({ timer_paused: true });
        } else {
          this.setState({ timer_paused: false });
        }
      }

      //Aparte
      if (data.aparte) {
        this.setState({ modal_user_photo: _.get(data, ["aparte", 0, "photoUrl"], null) });
        this.setState({ modal_timer: _.get(data, ["aparte", 0, "time_app", 0, "time_left"], null) });
        this.setState({ aparte: _.get(data, ["aparte", 0, "time_app", 0], null) });
      }
    }
  };

  cleanTimer = () => {
    this.setState({ timer: null });
    this.setState({ user_photo: null });
    this.setState({ em_fala: [] });
    this.setState({ modal_user_photo: null });
    this.setState({ modal_timer: null });
    this.setState({ aparte: [] });
  };

  changeSeparateTimer(time, paused) {
    this.setState({ timer_paused: paused });
    this.setState({ timer: time });
    this.setTimerStorage(time, paused);
  }

  setTimerStorage = (time, paused) => {
    if (this.state.use_storage) {
      sessionStorage.setItem("timer", JSON.stringify({ time: time, paused: paused || false }));
    }
  };

  removeTimerStorage = () => {
    if (this.state.use_storage) {
      sessionStorage.removeItem("timer");
    }
  };

  changeTime = (time) => {
    this.setTimerStorage(time);
  };

  startTalkTimer = () => {
    //
  };

  stopTalkTimer = async () => {
    await TalkStop();
    this.removeTimerStorage();
  };

  startModalTalkTimer = () => {
    //this.childTimer.current.child.current.pause();
  };

  stopModalTalkTimer = async () => {
    await TalkStopComment();
    //this.childTimer.current.child.current.resume();
  };

  render() {
    const { classes } = this.props;
    const { user_photo, modal_user_photo, part_session, part_session_time, time_session, timer, timer_paused, modal_timer } = this.state;

    return (
      <div className={classes.container_timeapp}>
        <Grid container id="topBar" display="flex" alignItems="center">
          <Grid item xs={4} xl={3} className="textLabel">
            {part_session}
          </Grid>
          <Grid item xs={2} xl={2} className={["time", classes.gradient].join(" ")}>
            {part_session_time ? (
              <Timer format="hh:mm:ss" timerStart={part_session_time} timerType="regressive" changeColor={true} changeColorStage1Time="00:05:05" />
            ) : (
              <div>00:00:00</div>
            )}
          </Grid>
          <Grid item xs={2} xl={2}></Grid>
          <Grid item xs={4} xl={3} className="textLabel">
            Tempo de Sessão
          </Grid>
          <Grid item xs={2} xl={2} className={["time", classes.gradient].join(" ")}>
            {time_session ? <Timer format="hh:mm:ss" timerStart={time_session} timerType="timer" /> : <div>00:00</div>}
          </Grid>
        </Grid>
        <Grid container id="content" display="flex" justifyContent="center">
          <Grid item xs={6} xl={6}>
            <Box id="contentBody">
              <Grid container spacing={0}>
                <Talk
                  id="modal_timer"
                  ref={this.childModalTimer}
                  className={["timer", "transition", modal_timer ? "modalTalkShow" : "modalTalkHidden"].join(" ")}
                  photo={modal_user_photo}
                  time={modal_timer}
                  timerChangeColorStage1Time="00:30"
                  timerChangeColorStage2Time="00:15"
                  timerStartCallback={this.startModalTalkTimer.bind(this)}
                  timerStopCallback={this.stopModalTalkTimer.bind(this)}
                />
                <Talk
                  id="timer"
                  ref={this.childTimer}
                  className={["timer", "transition", modal_timer ? "zoomOut" : ""].join(" ")}
                  photo={user_photo}
                  time={timer}
                  paused={timer_paused}
                  timerChangeColorStage1Time="00:30"
                  timerChangeColorStage2Time="00:15"
                  timerStartCallback={this.startTalkTimer.bind(this)}
                  timerStopCallback={this.stopTalkTimer.bind(this)}
                  timerOnChangeTime={this.changeTime.bind(this)}
                />
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = {
  container_timeapp: {
    width: "100%",
    minHeight: "100vh",
    "& #topBar": {
      height: "100px",
      borderBottom: "1px solid #363636",
      "& div": {
        minHeight: "100%",
        padding: "5px 25px 0px 35px",
        textTransform: "uppercase",
        display: "flex",
        alignItems: "center",
      },
      "& .textLabel": {
        justifyContent: "end",
        fontSize: "3rem",
      },
      "& .time": {
        fontSize: "3.4rem",
      },
    },
    "& #content": {
      width: "100%",
      height: "calc(100vh - 100px)",
      backgroundColor: "#1a1a1a",
      position: "relative",
      "& #contentBody": {
        width: "100%",
        maxWidth: "960px",
        height: "100%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        "& .timer": {
          padding: "15px 15px",
          /*position: "absolute",
          top: "0px",
          left: "0px",*/
          borderRadius: "15px 15px",
        },
        "& .zoomOut": {
          //top: "95%!important",
          marginTop: "-60px",
          transform: "scale(0.5)",
          opacity: "0.3",
          boxShadow: "0px 0px 25px rgba(255,255,255,0.8)",
        },
        "& .modalTalkHidden": {
          marginTop: "-100%",
          opacity: "0",
          transform: "scale(0)",
        },
        "& .modalTalkShow": {
          marginTop: "0",
          transform: "scale(1)",
          opacity: "1",
          boxShadow: "0px 0px 30px rgba(0,0,0,0.9)",
        },
      },
      "& div": {
        minHeight: "300px",
        padding: "0px 0px",
        display: "flex",
        alignItems: "center",
        position: "relative",
      },
      "& .boxAvatar": {
        position: "absolute",
        top: "0px",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: "2",
        opacity: "0",
        "& figure": {
          width: "300px",
          height: "300px",
          maxHeight: "300px",
          overflow: "hidden",
          position: "relative",
          borderRadius: "10px 10px",
          boxShadow: "0px 0px 5px rgba(0,0,0,0.8)",
          "& img": {
            minWidth: "100%",
            minHeight: "110%",
            position: "absolute",
            top: "0px",
            left: "50%",
            transform: "translateX(-50%)",
          },
        },
      },
      "& .boxAvatar.left": {
        left: "0px",
        transform: "none",
        opacity: "1",
      },
      "& .boxTimer": {
        padding: "0px 15px",
        display: "table",
        position: "absolute",
        top: "0px",
        border: "1px solid #363636",
        borderRadius: "10px 10px",
        boxShadow: "0px 0px 5px rgba(0,0,0,0.8)",
        background: "linear-gradient(180deg, rgba(26,26,26,1) 82%, rgba(0,0,0,1) 99%)",
        zIndex: "1",
      },
      "& .boxTimer.center": {
        left: "50%",
        transform: "translateX(-50%)",
      },
      "& .boxTimer.right": {
        right: "0px",
      },
    },
    "& .time": {
      textAlign: "center",
      fontSize: "13rem",
      fontWeight: "bold",
      textShadow: "3px 3px 2px rgba(255,255,255,0.2)",
    },
  },
  gradient: {
    backgroundColor: "rgb(0,0,0)",
    background: "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(47,43,43,1) 100%)",
  },
};

TimeApp.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TimeApp);
