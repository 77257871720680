import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import api from "../../api/api_v2";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import TableStatus from "./Tables/TableStatus";
import { Error } from "../Parties/styles";
import _ from "lodash";

export default function StatusProtocol() {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [order, setOrder] = useState();
  const [status, setStatus] = useState([]);
  const [alert, setAlert] = useState(false);
  const [validation, setValidation] = useState([]);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      await all();
    };
    fetchData();
  }, []);

  const handleForm = (e) => {
    switch (e.target.name) {
      case "name":
        return setName(e.target.value);
      case "order":
        return setOrder(e.target.value);
    }
  };

  const handleAddTypeDocument = async () => {
    const response = await update();

    if (_.get(response, "status", null) == 200) {
      setAlertMessage("Status Salvo com Sucesso");

      setAlert(true);

      setTimeout(() => {
        setAlert();
      }, 1000);

      setEdit(false);
      setName("");
      setOrder("");
      await all();

      return;
    }
  };

  const update = async () => {
    return await api
      .put(`/status-protocol/update/${id}`, {
        id: id,
        name: name,
        order: order,
      })
      .catch(function(error) {
        if (error.response) {
          setValidation(_.get(error, "response.data.errors", []));
        }
      });
  };
  const all = async () => {
    await api
      .get("/status-protocol")
      .then((response) => setStatus(_.get(response, "data", [])));
  };

  const handleEdit = async (e) => {
    setId(e.id);
    setName(e.name);
    setOrder(e.order);
    setEdit(true);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card
            style={{
              boxShadow:
                "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
            }}
          >
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Adicionar Status</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <InputLabel className={classes.label}>{"Nome"}</InputLabel>
                  <FormControl className={classes.form}>
                    <Input name="name" value={name} onChange={handleForm} />
                    <Error>{_.get(validation, "name", "")}</Error>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <InputLabel className={classes.label}>{"Ordem"}</InputLabel>
                  <FormControl className={classes.form}>
                    <Input
                      name="order"
                      type="number"
                      value={order}
                      onChange={handleForm}
                    />
                    <Error>{_.get(validation, "order", "")}</Error>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button
                disabled={!edit}
                onClick={handleAddTypeDocument}
                color="success"
              >
                Salvar
              </Button>
            </CardFooter>
            <Snackbar
              place="tr"
              color="success"
              icon={AddAlert}
              message={alertMessage}
              open={alert}
              closeNotification={() => setAlert(false)}
              close
            />
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {status != "" ? (
            <TableStatus
              tableHeaderColor="primary"
              tableHead={["Nome", "Ordem Sequencial do processo", "", ""]}
              tableData={status}
              handleEdit={handleEdit}
            />
          ) : (
            ""
          )}
        </GridItem>
      </GridContainer>
    </>
  );
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
  },
};

const useStyles = makeStyles(styles);
