import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import styles from "assets/jss/material-dashboard-react/layouts/president";
import Button from "@material-ui/core/Button";
import logo from "assets/img/logo-login.png";
import echo from "api/channels";
import Fade from "@material-ui/core/Fade";
import { apiLogout } from "api/api.js";
import { apiStopSession, apiOrderSession } from "api/sessions.js";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import Backdrop from "@material-ui/core/Backdrop";
import { apiSuspendSession, apigetOrder } from "api/president";
import { apiGetCanalName } from "api/sessions";
import _ from "lodash";
import "../../assets/sass/styles.scss";

const useStyles = makeStyles(styles);

export default function NavBarPresident() {
  const classes = useStyles();
  const [isVisible, setIsVisible] = React.useState(true);

  const handleExitProfile = () => {
    let user = JSON.parse(sessionStorage.getItem("USER"));

    apiLogout(user.id);
  };

  const handleOrderSession = () => {
      setModalValidate(true);
      setChangeSession(true);
      setTitleValidate("Iniciar " + order);
      setMsgValidate("Tem certeza que deseja iniciar " + order + "?");
    // if (window.confirm("Tem certeza que deseja iniciar " + order + "?")) {
    //   setModal(false);
    //   // apiOrderSession(order);
    // }
  };

  const handleCloseModal = () => {
    setModalValidate(false);
    setMsgValidate(null);
    setChangeSession(false); // zera o stado de mudar sessão
    setSuspend(false); // zera o stado de suspender 
    setFinish(false); // zera o stado de finalizar
    setTitleValidate(null);
  }

  const hadleStopSession = () => {
    setModalValidate(true);
    setFinish(true);
    setTitleValidate("Finalizar sessão");
    setMsgValidate("Tem certeza que deseja finalizar a Sessão?");
    // if (window.confirm("Tem certeza que deseja finalizar a Sessão?")) {
    //   setModal(false);
    //   // apiStopSession();
    // }
  };
 function envchat(){
    setIsVisible(!isVisible);
    if(isVisible){
      (function () {
          var po = document.createElement('script'); 
          po.type = 'text/javascript'; po.setAttribute('crossorigin', 'anonymous'); po.async = true;
          var date = new Date(); po.src = '//chat.envsic.app.br/design/defaulttheme/js/widgetv2/index.js?' + ("" + date.getFullYear() + date.getMonth() + date.getDate());
          var s = document.getElementsByTagName('script')[0]; 
          s.parentNode.insertBefore(po, s);
      })();
    }else{
      const script = document.getElementsByTagName('script')[0];
      if (script) {
        script.parentNode.removeChild(script);
      }
    }
  
  }
  let data = new Date();

  const [str_hora, setHora] = React.useState(data.toLocaleTimeString());

  const [order, setOrder] = React.useState(null);

  const [session, setSession] = React.useState(false);
  
  const [changeSession, setChangeSession] = React.useState(false);

  const [suspend, setSuspend] = React.useState(false);

  const [finish, setFinish] = React.useState(false);
  
  const [message, setMessage] = React.useState("");

  const [modal, setModal] = React.useState(false);

  const [msgValidate, setMsgValidate] = React.useState(null);

  const [titleValidate, setTitleValidate] = React.useState(null);

  const [modalValidate, setModalValidate] = React.useState(false);

  const [nameSession, setNameSession] = React.useState("");

  const user = JSON.parse(sessionStorage.getItem("USER"));

  echo.channel("tribuna").listen("MessageFinishTribuna", (e) => {
    setModal(true);
    setMessage(e);
  });

  useEffect(() => {
    apiGetCanalName();
    const fetchData = async () => {
      const result = await apigetOrder();
      retrite(result);
    };
    fetchData();

    echo.channel("session").listen("StartSession", (e) => {
      setNameSession(_.get(e, "name", ""));
    });

    echo.channel("session").listen("GetSessionName", (e) => {
      setNameSession(_.get(e, "name", ""));
    });

    echo.channel("session").listen("UpdateOrderSession", (event) => {
      retrite(event[0]);
    });

    echo.channel("session").listen("GetOrderSession", (event) => {
      retrite(event[0]);
    });

    echo.channel("session").listen("GetNotSession", () => {
      setSession(false);
      setMessage("");
    });

    echo.channel("session").listen("Suspend", () => {
      setSession(false);
      setOrder(null);
      setMessage("");
    });
  }, []);

  const str_data = data.toLocaleDateString();

  const interval = setInterval(() => {

    const now = new Date();

    setHora(now.toLocaleTimeString())

}, 1000);


  const retrite = (session) => {
    setMessage("");

    if (session == null) {
      setOrder(null);
      setSession(true);

      return;
    }
    setOrder(session.name);
    setSession(false);
  };

  const handleSuspend = () => {
    setMsgValidate("Tem certeza que deseja suspender a Sessão?");
    setTitleValidate("Suspender sessão");
    setModalValidate(true);
    setSuspend(true);
    // if (window.confirm("Tem certeza que deseja suspender a Sessão?")) {
    //   // apiSuspendSession();
    // }
  };

  return (
    <div className="componentNavbar">
      <AppBar position="static">
        <Toolbar variant="dense" className={classes.sidebar}>
          <div className="componentNavbar__logo">
            <IconButton edge="start" aria-label="menu">
              <img className="componentNavbar__imgLogo" src={logo} alt="logo" />
            </IconButton>
            <a className="componentButton componentButton--green-outline btn  ml-3 w-80" onClick={envchat}>Suporte</a>
  
          </div>

          <div className="boxButtons__session">
            <div className="boxButtons__session--text">
              <p className="boxButtons__session--name">{nameSession} </p>
              <h4 className="boxButtons__session--message">{message}</h4>
            </div>
            {/* Validação faz com que os botoes de mudar ordem da sessão apresente somente para o presidente e para o controlador */}
            {order != null && (user.id_user_office == 1 || user.id_access_level == 5) ? (
              <>
                <Button
                  variant="contained"
                  onClick={handleOrderSession}
                  className="componentButton componentButton--green"
                >
                  INICIAR {order}
                </Button>
                {
                  <Button
                    variant="contained"
                    onClick={handleSuspend}
                    className="componentButton componentButton--gray"
                  >
                    SUSPENDER
                  </Button>
                }
              </>
            ) : (
              ""
            )}
            {/* Validação faz com que de finalizar sessão apresente somente para o presidente e para o controlador */}
            {session == true && order == null && (user.id_user_office == 1 || user.id_access_level == 5) ? (
              <>
                {" "}
                <Button
                  variant="contained"
                  onClick={hadleStopSession}
                  className="componentButton componentButton--yellow"
                >
                  Finalizar Sessão
                </Button>
                {
                  <Button
                    variant="contained"
                    onClick={handleSuspend}
                    className="componentButton componentButton--gray"
                  >
                    SUSPENDER
                  </Button>
                }
              </>
            ) : (
              ""
            )}
          </div>

          <div className="componentNavbar__date">
            <p>{"Hoje é " + str_data + " às " + str_hora}</p>
            <button
              className="componentButton componentButton--orange"
              variant="contained"
              onClick={handleExitProfile}
            >
              SAIR
            </button>
          </div>
        </Toolbar>
      </AppBar>
      <Modal
        className="generalModal"
        open={modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="modalContainer">
          <div className="modalContainer--body">
            <h2 className="text-center">
              {message}
            </h2>

            {order != null ? (
              <Button variant="contained" onClick={handleOrderSession} className="componentButton componentButton--green">
                INICIAR {order}
              </Button>
            ) : (
              <Button variant="contained" onClick={hadleStopSession} className="componentButton componentButton--gray">
                ENCERRAR SESSÃO
              </Button>
            )}
          </div>
        </div>
      </Modal>


      <Modal /* modal de check de troca de momento da sessão */
        className="generalModal"
        open={modalValidate}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalValidate}>
          <div className="modalContainer">
            <div className="modalContainer--header">
              <h4>{titleValidate}</h4>
            </div>

            <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center", paddingBottom: "0" }}>
              {/* <h5>Tem certeza que deseja iniciar {order}? </h5> */}
              <h5>{msgValidate}</h5>
            </div>

            <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center", paddingTop: "0" }}>

              {changeSession == true ? // validação se for altear momento da sessão apresentar botão
                <button className="componentButton componentButton--green mx-0" onClick={() => { apiOrderSession(order); handleCloseModal(); }}>
                    Iniciar
                  </button>
                : suspend == true ? // validação se for suspender sessão apresentar botão
                  <button className="componentButton componentButton--green mx-0" onClick={() => { apiSuspendSession(); handleCloseModal(); }}>
                    Suspender
                  </button>
                : finish == true ?// validação se for finalizar sessão apresentar botão
                    <button className="componentButton componentButton--yellow mx-0" onClick={() => { apiStopSession(); handleCloseModal(); }}>
                    Finalizar
                  </button>
              :""}

              <button className="componentButton componentButton--gray ml-2" onClick={handleCloseModal}>
                Voltar
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
