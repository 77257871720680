import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      interval: null,
      timerColor: "#ffffff",
      timerClass: "",
      current_time: null,
    };
  }

  async componentDidMount() {
    let timerStart = await this.formatStartTime(this.props.timerStart);

    this.setState({ use_storage: _.get(this.props, "useStorage", false) });
    this.setState({ current_time: timerStart });

    if (this.props.timerStart && !this.props.paused) {
      this.start();
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  async formatStartTime(time) {
    if (time) {
      time = time.padStart(8, "00:");
      const splitTime = time.split(":");
      return this.props.format == "mm:ss" ? splitTime[1] + ":" + splitTime[2] : time;
    }

    if (this.props.format == "mm:ss") {
      return "00:00";
    } else {
      return "00:00:00";
    }
  }

  async formatTime(value) {
    let time;

    if (value) {
      const splitTime = value.split(":");

      if (splitTime.length == 2) {
        time = {
          hh: "00",
          mm: _.get(splitTime, "0", "00"),
          ss: _.get(splitTime, "1", "00"),
        };
      } else {
        time = {
          hh: _.get(splitTime, "0", "00"),
          mm: _.get(splitTime, "1", "00"),
          ss: _.get(splitTime, "2", "00"),
        };
      }
    }

    return time;
  }

  async timer() {
    let current_time;

    if (sessionStorage.getItem(this.state.id + "_time") !== null) {
      current_time = sessionStorage.getItem(this.state.id + "_time");
    } else {
      current_time = this.state.current_time;
    }

    const splitTime = await this.formatTime(current_time);

    if (splitTime) {
      var ss = splitTime.ss;
      var mm = splitTime.mm;
      var hh = splitTime.hh;

      ss++;

      if (ss == 60) {
        ss = 0;
        mm++;

        if (mm == 60) {
          mm = 0;
          hh++;
        }
      }

      var time = this.props.format == "hh:mm:ss" ? hh.toString().padStart(2, 0) + ":" : "";
      time += mm.toString().padStart(2, 0) + ":" + ss.toString().padStart(2, 0);

      this.changeTime(time);
    }
  }

  async timerRegressive() {
    const splitTime = await this.formatTime(this.state.current_time);

    if (splitTime) {
      var ss = splitTime.ss;
      var mm = splitTime.mm;
      var hh = splitTime.hh;

      if (hh == 0 && mm == 0 && ss <= 0) {
        this.setState({ timerColor: "#ffffff" });
        this.setState({ timerClass: "" });
        this.stop();
        return;
      } else {
        ss--;

        if (ss < 0) {
          ss = 59;
          mm = mm - 1;
        }

        if (mm <= 0) {
          mm = 59;
          hh = hh - 1;
        }

        if (hh < 0) {
          hh = 0;
          mm = 0;
        }
      }

      var hhmmss = parseInt(hh.toString().padStart(2, 0) + mm.toString().padStart(2, 0) + ss.toString().padStart(2, 0));

      if (this.props.changeColorStage1Time) {
        let splitStage1 = this.props.changeColorStage1Time.padStart(8, "00:").split(":");

        var stage1 = parseInt(splitStage1[0].toString() + splitStage1[1].toString() + splitStage1[2].toString());

        //Stage 1
        if (this.state.timerColor != "#FFD700") {
          if (hhmmss <= stage1) {
            this.setState({ timerColor: "#FFD700" });

            if (this.props.changeColorEfect) {
              this.setState({ timerClass: "blink-slow-animation" });
            }
          }
        }
      }

      if (this.props.changeColorStage2Time) {
        let splitStage2 = this.props.changeColorStage2Time.padStart(8, "00:").split(":");

        var stage2 = parseInt(splitStage2[0].toString() + splitStage2[1].toString() + splitStage2[2].toString());

        //Stage 2
        if (this.state.timerColor != "#FF0000") {
          if (hhmmss <= stage2) {
            this.setState({ timerColor: "#FF0000" });

            if (this.props.changeColorEfect) {
              this.setState({ timerClass: "blink-fast-animation" });
            }
          }
        }
      }

      var time = this.props.format == "hh:mm:ss" ? hh.toString().padStart(2, 0) + ":" : "";
      time += mm.toString().padStart(2, 0) + ":" + ss.toString().padStart(2, 0);

      this.changeTime(time);
    }
  }

  async start(runCallback) {
    runCallback = runCallback || true;

    this.state.interval = setInterval(() => {
      if (this.props.timerType == "timer") {
        this.timer();
      } else {
        this.timerRegressive();
      }
    }, 1000);

    if (runCallback && this.props.startCallback) {
      this.props.startCallback();
    }
  }

  changeTime(time) {
    this.setState({ current_time: time });

    if (this.props.onChangeTime) {
      this.props.onChangeTime(time);
    }
  }

  pause() {
    clearInterval(this.state.interval);

    if (this.props.pauseCallback) {
      this.props.pauseCallback();
    }
  }

  resume() {
    this.start(false);

    if (this.props.resumeCallback) {
      this.props.resumeCallback();
    }
  }

  stop() {
    clearInterval(this.state.interval);

    if (sessionStorage.getItem(this.state.id + "_time") !== null) {
      sessionStorage.removeItem(this.state.id + "_time");
    }

    if (sessionStorage.getItem(this.state.id + "_time_regressive") !== null) {
      sessionStorage.removeItem(this.state.id + "_time_regressive");
    }

    if (this.props.stopCallback) {
      this.props.stopCallback();
    }
  }

  render() {
    const { timerColor, timerClass, current_time } = this.state;
    return (
      <div className={timerClass} style={{ color: timerColor }}>
        {current_time}
      </div>
    );
  }
}

Timer.propTypes = {
  format: PropTypes.string,
  paused: PropTypes.bool,
  timerStart: PropTypes.string,
  timerType: PropTypes.string,
  changeColor: PropTypes.bool,
  changeColorStage1Time: PropTypes.string,
  changeColorStage2Time: PropTypes.string,
  changeColorEfect: PropTypes.bool,
  startCallback: PropTypes.func,
  stopCallback: PropTypes.func,
  pauseCallback: PropTypes.func,
  resumeCallback: PropTypes.func,
  useStorage: PropTypes.bool,
  onChangeTime: PropTypes.func,
};

export default Timer;
