import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { apiSalve } from "api/configuration.js";
import _ from "lodash";
import PropTypes from "prop-types";

export default function Email(props) {
  const { email } = props;
  const classes = useStyles();
  const [id] = React.useState(_.get(email, "id", ""));
  const [name, setName] = React.useState(_.get(email, "name_sender", ""));
  const [email_sender, setEmail] = React.useState(
    _.get(email, "email_sender", "")
  );
  const [host, setHost] = React.useState(_.get(email, "host_smpt", ""));
  const [port, setPort] = React.useState(_.get(email, "port_smpt", ""));
  const [user, setUser] = React.useState(_.get(email, "user_smpt", ""));
  const [password, setPassword] = React.useState(
    _.get(email, "password_smpt", "")
  );
  const [encryption, setEncryption] = React.useState(
    _.get(email, "encryption_smpt", "")
  );

  const send = () => {
    let data = {
      id: id,
      name_sender: name,
      email_sender: email_sender,
      host_smpt: host,
      port_smpt: port,
      user_smpt: user,
      password_smpt: password,
      encryption_smpt: encryption,
    };

    apiSalve(data, "/configuration/email/save");
  };

  const hadleForm = (event) => {
    switch (event.target.name) {
      case "name":
        return setName(event.target.value);
      case "email":
        return setEmail(event.target.value);
      case "host":
        return setHost(event.target.value);
      case "port":
        return setPort(event.target.value);
      case "user":
        return setUser(event.target.value);
      case "password":
        return setPassword(event.target.value);
      case "encryption":
        return setEncryption(event.target.value);
      default:
        return "";
    }
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card
            style={{
              boxShadow:
                "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
            }}
          >
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Configurações de SMTP</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl className={classes.form}>
                    <InputLabel className={classes.label}>
                      {"Nome Remetente"}
                    </InputLabel>
                    <Input name="name" value={name} onChange={hadleForm} />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl className={classes.form}>
                    <InputLabel className={classes.label}>
                      {"Email do Remetente"}
                    </InputLabel>
                    <Input
                      name="email"
                      value={email_sender}
                      onChange={hadleForm}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl className={classes.form}>
                    <InputLabel className={classes.label}>
                      {"HOST SMPT"}
                    </InputLabel>
                    <Input name="host" value={host} onChange={hadleForm} />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl className={classes.form}>
                    <InputLabel className={classes.label}>
                      {"PORTA SMPT"}
                    </InputLabel>
                    <Input name="port" value={port} onChange={hadleForm} />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl className={classes.form}>
                    <InputLabel className={classes.label}>
                      {"Usuário SMPT"}
                    </InputLabel>
                    <Input name="user" value={user} onChange={hadleForm} />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl className={classes.form}>
                    <InputLabel className={classes.label}>
                      {"Senha SMPT"}
                    </InputLabel>
                    <Input
                      name="password"
                      value={password}
                      onChange={hadleForm}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl
                    className={classes.form}
                    style={{ minWidth: 200 }}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      className={classes.label}
                    >
                      Encriptação SMPT
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="encription"
                      onChange={hadleForm}
                      value={encryption}
                      name="encryption"
                    >
                      <MenuItem value="ssl">SSL</MenuItem>
                      <MenuItem value="tls">TLS</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <span
                className={"material-icons"}
                style={{ color: "#4caf50" }}
                onClick={send}
              >
                check_circle
              </span>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
  },
};

const useStyles = makeStyles(styles);

Email.propTypes = {
  email: PropTypes.string.isRequired,
  setId: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  email_sender: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  host: PropTypes.string.isRequired,
  port: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  encryption: PropTypes.string.isRequired,
};
