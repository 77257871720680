import React, {useEffect} from "react"
import { makeStyles } from "@material-ui/core/styles"
import InputLabel from "@material-ui/core/InputLabel"
import GridItem from "components/Grid/GridItem.js"
import GridContainer from "components/Grid/GridContainer.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardBody from "components/Card/CardBody.js"
import Input from "@material-ui/core/Input"
import FormControl from "@material-ui/core/FormControl"

export default function ModalLogs(props) {
    const classes = useStyles()
    const { logInfo } = props
    let logsDetailed = logInfo.action_detailed ?  JSON.parse(logInfo.action_detailed) : [];

    const ITEM_HEIGHT = 48
    const ITEM_PADDING_TOP = 8
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    }
    const strCleanCharacter = (strCharacter) => {
        return   strCharacter ? strCharacter : '';
    }

    return (

        <div>
            <GridContainer style={{ display: 'flex', justifyContent: 'center' }}>
                <GridItem xs={12} sm={12} md={8}>
                    <Card>
                        <CardHeader color="success" className={classes.cardHeader}>
                            <h4 className={classes.cardTitleWhite}>Detalhe Logs</h4>
                            <span
                                className="material-icons"
                                onClick={() => props.handleClick()}
                                style={{ cursor: 'pointer' }}
                                        >
                            close
                          </span>
                        </CardHeader>
                        <CardBody  style={{ overflow: 'auto', height: '60vh' }}>
                            <GridContainer style={{ padding: '10px' }}>
                                <GridItem xs={12} sm={12} md={4}>
                                    <FormControl className={classes.form}>
                                        <InputLabel className={classes.label}>
                                            {'ID'}
                                        </InputLabel>
                                        <Input name='username' value={logInfo.id} disabled/>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <FormControl className={classes.form}>
                                        <InputLabel className={classes.label}>
                                            {'USERNAME'}
                                        </InputLabel>
                                        <Input name='username' value={logInfo.username} disabled/>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <FormControl className={classes.form}>
                                        <InputLabel className={classes.label}>
                                            {'PERFIL'}
                                        </InputLabel>
                                        <Input name='profile' value={logInfo.profile} disabled/>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <FormControl className={classes.form}>
                                        <InputLabel className={classes.label}>
                                            {'MÓDULO'}
                                        </InputLabel>
                                        <Input name='module' value={logInfo.module} disabled/>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <FormControl className={classes.form}>
                                        <InputLabel className={classes.label}>
                                            {'AÇÃO'}
                                        </InputLabel>
                                        <Input name='profile' value={logInfo.action} disabled/>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <FormControl className={classes.form}>
                                        <InputLabel className={classes.label}>
                                            {'IP'}
                                        </InputLabel>
                                        <Input name='profile' value={logInfo.ip} disabled/>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <FormControl className={classes.form}>
                                        <InputLabel className={classes.label}>
                                            {'APP'}
                                        </InputLabel>
                                        <Input name='profile' value={logInfo.environment} disabled/>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <FormControl className={classes.form}>
                                        <InputLabel className={classes.label}>
                                            {'AMBIENTE'}
                                        </InputLabel>
                                        <Input name='profile' value={logInfo.app} disabled/>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <FormControl className={classes.form}>
                                        <InputLabel className={classes.label}>
                                            {'DATA DE CRIAÇÃO'}
                                        </InputLabel>
                                        <Input name='profile' value={logInfo.created_at} disabled/>
                                    </FormControl>
                                </GridItem>
                                {
                                    Object.keys(logsDetailed).map((objectKey,index)=>(
                                            (typeof logsDetailed[objectKey]=="string")
                                            || typeof logsDetailed[objectKey]=="object"
                                            || typeof logsDetailed[objectKey]=="boolean"
                                            || typeof logsDetailed[objectKey]=="Date"
                                            || typeof logsDetailed[objectKey]=="number")
                                                ?
                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <FormControl className={classes.form}>
                                                            <InputLabel className={classes.label}>
                                                                {objectKey.toUpperCase()}
                                                            </InputLabel>
                                                            <Input name={objectKey} value={ typeof logsDetailed[objectKey]=="object"
                                                            ?  JSON.stringify(logsDetailed[objectKey]): strCleanCharacter(logsDetailed[objectKey])} />
                                                        </FormControl>

                                                    </GridItem>

                                            : ''
                                    )
                                }
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}


const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    cardHeader: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    label: {
        fontSize: '16px',
    },
    form: {
        marginBottom: '10px',
        marginTop: '10px'
    },
    validation: {
        color: 'red',
        fontSize: '12px',
        marginTop: '0px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

}

const useStyles = makeStyles(styles)