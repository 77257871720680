import React from "react";
import Report from "views/Maps/dashboard";
import apiReport from "api/report";
import _ from "lodash";

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: "",
    };
  }

  async componentDidMount() {
    const filters = await apiReport.get("filters");
    this.setState({ filters: _.get(filters, "data.filters", null) });
  }

  render() {
    const { filters } = this.state;
    return (
      <div>
        <Report filters={filters} apiReport={apiReport} />
      </div>
    );
  }
}
