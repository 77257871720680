import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TableModal from "./TableModal";
import _ from "lodash";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TableModalConfirmation from "./ModalConfirm";

export default function CustomTable(props) {
  const { tableData, selected, selectAll } = props;

  const handleOpen = (id) => {
    let route =
      process.env.REACT_APP_STAGE_V2 + "/document/" + id + "/signed-view/I";

    window.open(route);
  };

  useEffect(() => {
    tableData.forEach((element) => {
      selected.find(function(item) {
        if (item.id === element.id) {
          element.index = 1;
        }
      });
    });
  }, []);

  const deleteIndex = (column) => {
    tableData.find(function(item) {
      if (item.id === column.id) {
        item.index = null;
      }
    });

    props.handleSelect(column);
  };

  /* State do modal */
  const [modal, setModal] = useState(false);
  /* Dados que são enviados para o modal*/
  const [dataModalOpen, setDataModalOpen] = useState([]);
  /* Função para abrir modal e passar os dados para o modal */
  const openModal = (data) => {
    setModal(true);
    setDataModalOpen(data);
  };
  /* Função para fechar o modal */
  const closedModal = () => {
    setModal(false);
  };

  /* 
    State responsável por ativar ou desativar o btn de openModal quando 
    a função handleSelectAllDocs é disparada
  */
  const [isSelectAllOpen, setIsSelectAllOpen] = useState(null);

  /**
   * State para os modals selecionados em massa
   */
  const [selectAllModals, setSelectAllModals] = useState(null);
  const [textModal, setTextModal] = useState("");

  const [isTableModalOpen, setIsTableModalOpen] = useState(false);
  const [checkboxActive, setCheckboxActive] = useState(false);

  /* Seleciona todos os checkboxs  */
  const handleSelectAllDocs = (event) => {
    let checked = event.target.checked;
    const inputs = document.querySelectorAll(".sessaoInputsList");

    if (checked && !checkboxActive) {
      setIsTableModalOpen(true);
      setSelectAllModals({ selected: [] });
    }

    if (checkboxActive) {
      setSelectAllModals({ selected: [] });
      setIsSelectAllOpen(checked);
      setTextModal("Ação para todos os documentos selecionados");
      props.handleSelectAll();
    } else {
      setSelectAllModals(tableData);
      setIsSelectAllOpen(checked);
      setTextModal("");
    }

    inputs.forEach((item) => {
      item.checked = checked;
    });
  };

  useEffect(() => {
    if (checkboxActive) {
      setCheckboxActive(true);
      props.handleSelectAll();
    }
  }, [checkboxActive]);

  const checkSelected = selected.length == tableData.length;

  return (
    <>
      {modal && (
        <TableModal
          closedModal={() => closedModal()}
          data={dataModalOpen}
          selectAllModals={selectAllModals}
          props={props}
          propositions={props.propositions}
        />
      )}
      {isTableModalOpen && (
        <TableModalConfirmation
          onConfirm={() => {
            setIsTableModalOpen(false);
            setCheckboxActive(true);
          }}
          onClose={() => {
            setIsTableModalOpen(false);
            setCheckboxActive(false);
          }}
          onOpen={isTableModalOpen}
        />
      )}
      <div className="containerTabela">
        <header className="header">
          <section className="titleSearch">
            <p>Titulo da Pesquisa</p>
          </section>
          <section className="targetAll">
            <label className="selectAll" htmlFor="title">
              Selecionar todos
            </label>
            <label className="chk" htmlFor="title">
              <input
                type="checkbox"
                id="title"
                checked={checkSelected}
                onChange={handleSelectAllDocs}
              />
              <div className="checkmark"></div>
            </label>
            {!!isSelectAllOpen && checkboxActive && (
              <LightTooltip title="Adicionar" placement="top">
                <button
                  className="material-icons"
                  onClick={() => openModal(selectAllModals)}
                  style={{
                    background: "transparent",
                    border: "none",
                    color: "#4caf50",
                  }}
                >
                  add_circle
                </button>
              </LightTooltip>
            )}
          </section>
        </header>

        <section className="tableScroll">
          <table>
            <tbody>
              {_.get(tableData, "length", 0) > 0 ? (
                tableData.map(function(column, i) {
                  return (
                    <tr key={i}>
                      {/** Title and PDF **/}
                      <td>
                        <div className="titleAndPdf">
                          <p>{column.title}</p>

                          <LightTooltip title="Visualizar PDF">
                            <VisibilityIcon
                              className="icon"
                              onClick={() => handleOpen(column.id)}
                            />
                          </LightTooltip>
                        </div>
                      </td>

                      {/** Texto Emenda **/}
                      <td>
                        <p>
                          <strong>Ementa:</strong>
                        </p>
                        <span className="limitText">{column.ementa}</span>
                      </td>

                      {/** Abre modal com as opções "Leitura, Momento" **/}
                      <td>
                        <LightTooltip title="Adicionar" placement="top">
                          <button
                            key={i}
                            className="material-icons"
                            onClick={() => openModal(column)}
                            disabled={isSelectAllOpen && selected.length !== 0}
                          >
                            add_circle
                          </button>
                        </LightTooltip>
                      </td>

                      {/** Input responsavel por add a sessão **/}
                      <td>
                        <input
                          type="checkbox"
                          id={column.id}
                          className="sessaoInputsList"
                          disabled={
                            _.get(column, "add_second_voting", null) == true ||
                            _.get(column, "urgent", null) == true ||
                            _.get(column, "add_first_voting", null) == true ||
                            _.get(column, "add_leitura", null) == true || 
                            selectAll === true
                              ? false
                              : true
                          }
                          checked={selected.includes(column)}
                          onClick={() => props.handleSelect(column)}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <span>Não Encontrado...</span>
                </tr>
              )}
            </tbody>
          </table>
        </section>
      </div>
    </>
  );
}

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);
CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  selected: PropTypes.arrayOf(PropTypes.string),
  handleSelect: PropTypes.func,
  handleSelectLeitura: PropTypes.func,
  handleSelectFirstVoting: PropTypes.func,
  handleSelectSecundVoting: PropTypes.func,
  handleSelectUrgent: PropTypes.func,
};
