import React from "react";
import axios from "axios";
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import propTypes from "prop-types";
class Pdf extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUrl: null,
    };
  }

  componentDidMount = async () => {
    if (this.props.file.indexOf(".pdf") == -1) {
      await axios({
        method: "get",
        url: this.props.file,
      }).then((response) => {
        this.setState({ fileUrl: response.data });
      });
    } else {
      this.setState({ fileUrl: this.props.file });
    }
  };

  render() {
    const { fileUrl } = this.state;
    const BaseUrlAPI = process.env.REACT_APP_API ? process.env.REACT_APP_API : '/pdf.worker.min.js'

    return (
      <>
        {fileUrl ? (
          <Worker workerUrl={`${BaseUrlAPI}`}>
            <div>
              <Viewer fileUrl={fileUrl} />
            </div>
          </Worker>
        ) : (
          ""
        )}
      </>
    );
  }
}

Pdf.propTypes = {
  file: propTypes.string,
};

export default Pdf;
