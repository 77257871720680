import {
  drawerWidth,
  transition,
  container
} from "assets/jss/material-dashboard-react.js";
import Image from "assets/img/background.jpg";

const appStyle = (theme) => ({
  wrapper: {
    top: "0",
    height: "100vh",
    minHeight: "100%",
    backgroundImage: "url(" + Image + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    marginTop: "70px",
    padding: "30px 15px",
    minHeight: "calc(100vh - 123px)",
    maxHeight: "900px"
  },
  container,
  map: {
    marginTop: "70px"
  },
  divFooter: {
    position: "absolute",
    bottom: "0",
    right: "0",
    background: "white",
    borderRadius: "15px 0 0 0",
    boxShadow:
      "0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(76 175 80 / 40%)",
    padding: "0 0 0 20px"
  },
});

export default appStyle;
