import React, { useState, useEffect } from "react";
import { api } from "../../api/api";
import _ from "lodash";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { FormControlLabel, FormGroup, Switch } from "@material-ui/core";

export default function TipoComisionConfig() {
  const [validation, setValidation] = useState([]);
  const [option1, setOption1] = useState(false);
  const [option2, setOption2] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await all();
    };
    fetchData();
  }, []);

  const all = async () => {
    await api.get("/tipoComisionConfig").then((response) => {
      _.get(response, "data", []).map((data) => {
        if (data.name === "votacion_session_type_comision") {
          setOption1(data.status);
        }
        if (data.name === "parecer_individual_dos_miembros") {
          setOption2(data.status);
        }
      });
    });
  };

  const toggleChecked = (e) => {
    if (e.target.name === "votacion_session_type_comision")
      setOption1(e.target.checked);
    if (e.target.name === "parecer_individual_dos_miembros")
      setOption2(e.target.checked);
    api
      .post(`/tipoComisionConfig`, {
        name: e.target.name,
        title: e.target.name,
        status: e.target.checked,
      })
      .catch(function(error) {
        if (error.response) {
          setValidation(_.get(error, "response.data.erro", []));
        }
      });
  };
  return (
    <SnackbarContent
      style={{ marginBottom: "1px" }}
      message={
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={option1}
                onChange={toggleChecked}
                name="votacion_session_type_comision"
                color="primary"
              />
            }
            label="Votaçâo em sessâo"
          />
          <FormControlLabel
            control={
              <Switch
                checked={option2}
                onChange={toggleChecked}
                name="parecer_individual_dos_miembros"
                color="primary"
              />
            }
            label="Parecer individual dos membros"
          />
          {validation}
        </FormGroup>
      }
    />
  );
}
