import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "assets/jss/material-dashboard-react/components/tableSelect.js";
import _ from "lodash";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
const useStyles = makeStyles(styles);
export default function TableModalStatusSelect(props) {
  const classesTab = useStyles();
  const { tableData } = props;

  const [data, setData] = useState(tableData);

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const handlePrazo = (event) => {
    data.forEach((item) => {
      if (item.id === parseInt(event.target.id)) {
        item.prazo = parseInt(event.target.value);
      }
    });
    props.updateColumn(data);
  };

  const handleOrder = (event) => {
    data.forEach((item) => {
      if (item.id === parseInt(event.target.id)) {
        let num = event.target.value === "" ? 0 : parseInt(event.target.value);
        item.order = num;
      }
    });

    props.updateColumn(data);
  };

  const onClickDiasUteis = (id) => {
    data.forEach((item) => {
      if (item.id === id) {
        if (_.get(item, "diasuteis", null) != 1) {
          item.diasuteis = 1;
        } else {
          item.diasuteis = 0;
        }
      }
    });
    props.updateColumn(data);
  };

  return (
    <>
      <div className={classesTab.title}>Status Selecionados</div>
      <div
        style={{
          overflow: "auto",
          height: "200px",
        }}
      >
        <table className={classesTab.table}>
          <tr>
            <td className={classesTab.td}>Nome</td>
            <td className={classesTab.td}>Ordem</td>
            <td className={classesTab.td}>Prazo</td>
            <td className={classesTab.td}>Dias úteis</td>
          </tr>

          {data.map(function(column, i) {
            return (
              <tr className={classesTab.tr} key={i}>
                <td className={classesTab.td}>{column.name}</td>
                <td className={classesTab.td}>
                  <Input
                    id={column.id}
                    type="number"
                    inputProps={{ min: "0", defaultValue: "0" }}
                    value={column.order}
                    style={{ width: "50px" }}
                    onChange={handleOrder}
                  />
                </td>
                <td className={classesTab.td}>
                  <FormControl>
                    <Input
                      id={column.id}
                      type="number"
                      value={column.prazo}
                      style={{ width: "50px" }}
                      inputProps={{ min: "0" }}
                      onChange={handlePrazo}
                    />
                  </FormControl>
                </td>
                <td className={classesTab.td}>
                  <FormControl>
                    <Checkbox
                      checked={_.get(column, "diasuteis", 0)}
                      id="vehicle1"
                      name="vehicle1"
                      onClick={() => onClickDiasUteis(column.id)}
                    />
                  </FormControl>
                </td>
              </tr>
            );
          })}
        </table>
      </div>
    </>
  );
}

TableModalStatusSelect.defaultProps = {
  tableHeaderColor: "gray",
};

TableModalStatusSelect.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  updateColumn: PropTypes.func
};
