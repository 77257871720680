import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import styles from "assets/jss/material-dashboard-react/layouts/timeAppStyle";
import TimeApp from "components/TimeApp/TimeApp.js";

const useStyles = makeStyles(styles);

export default function TimeAppPage() {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <TimeApp />
      </div>
    </div>
  );
}
