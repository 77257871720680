import React from "react";
import Logo from "assets/img/logo-login.png";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import styles from "assets/jss/material-dashboard-react/views/loginStyle.js";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from "@material-ui/core/Button";
import { apiLoginResetPassword } from "api/api.js";
import {Modal} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ModalChangePassword from "../Logon/Modal/ModalChangePassword.js";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        let decodedStringBase64 = atob(props.token).split(',')    ;
        this.state = {
            token:decodedStringBase64[1] ,
            email:decodedStringBase64[0] ,
            password: '',
            password_confirmation: '',
            openReset:false,
            user:[],
            errorsRecovery:[],
            showPassword: false,
            showPasswordConfirmation : false,
        };
        this.handleClickSubmit                   = this.handleClickSubmit.bind(this);
        this.handleChangePassword                = this.handleChangePassword.bind(this);
        this.handleChangePasswordConfirmation    = this.handleChangePasswordConfirmation.bind(this);
        this.handleClickShowPassword             = this.handleClickShowPassword.bind(this);
        this.handleClickShowPasswordConfirmation = this.handleClickShowPasswordConfirmation.bind(this);
    }
    handleChangePassword(e){
        this.setState({password:e.target.value})
    }
    handleChangePasswordConfirmation(e){
        this.setState({password_confirmation:e.target.value})
    }
    handleCloseRecovery = () => {
        this.setState({errorsRecovery: []});
        this.setState({ openReset: false });
        window.location='/login';
    };
    async handleClickSubmit() {

        let {token,password,password_confirmation,email} = this.state;
        let data = {
            email:email,
            token:token,
            password: password,
            password_confirmation:password_confirmation,
            showPassword:false,
        }
        let resetPassword = await apiLoginResetPassword(data);
        this.setState({errorsDeleted: []});
        if (resetPassword?.user) {
            this.setState({ setAlert: true , user: resetPassword.user,openReset:true});
            setTimeout(
            function() {
                this.setState({
                setAlert: false,
                verified: false,
                });
            }.bind(this),
            2000
            );
        }else{
            this.setState({
                errorsRecovery: resetPassword,
            });
        }
    }
    handleCloseReset = () => {
        this.setState({errorsRecovery: []});
        this.setState({ openReset: false });
    };
    
    handleClickShowPassword = () => {
        const {showPassword} = this.state;
        this.setState({ showPassword: !showPassword });
    };
    handleClickShowPasswordConfirmation = () => {
        const {showPasswordConfirmation} = this.state;
        this.setState({ showPasswordConfirmation: !showPasswordConfirmation });
    };
    
    handleMouseDownPassword = (event) => {
        event.preventDefault();
     };
    

    render(){
        const { classes } = this.props;
        const { user,errorsRecovery,showPassword,password,password_confirmation,showPasswordConfirmation} = this.state;

        return(
                <Grid container component="main" className={classes.root}>
                    <Grid
                    item
                    xs={7}
                    sm={8}
                    md={5}
                    component={Paper}
                    square
                    style={{
                        borderRadius: "10px 20px 80px",
                        maxWidth: "530px",
                        zoom: "90%",
                    }}
                    >
                    <div className={classes.paper}>
                        <img className={classes.filogo} src={Logo} alt="logo envsense" />
                            <form className={classes.form}>
                                <div style={{textAlign: "left"}}>
                                    <br />
                                    <small>A nova senha deve ter mínimo 6 caracteres.</small>
                                    <br />
                                    <br />
                                </div>
                                <FormControl className={clsx(classes.textField,'MuiInputBase-fullWidth')} variant="outlined">
                                    <InputLabel htmlFor="password_current">Nova senha</InputLabel>
                                    <OutlinedInput
                                        id="password_current"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        with= {'100%'}
                                        onChange={this.handleChangePassword}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="Ver senha"
                                                onClick={this.handleClickShowPassword}
                                                onMouseDown={this.handleMouseDownPassword}
                                                edge="end"
                                                >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        } 
                                        labelWidth={100}
                                    />
                                </FormControl>
                                <br />
                                <br />
                                <FormControl className={clsx(classes.textField,'MuiInputBase-fullWidth')} variant="outlined">
                                    <InputLabel htmlFor="password_new">Repeta a senha</InputLabel>
                                    <OutlinedInput
                                        id="password_new"
                                        type={showPasswordConfirmation ? 'text' : 'password'}
                                        value={password_confirmation}
                                        fullWidth
                                        onChange={this.handleChangePasswordConfirmation}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="Ver senha"
                                                onClick={this.handleClickShowPasswordConfirmation}
                                                onMouseDown={this.handleMouseDownPassword}
                                                edge="end"
                                                >
                                                {showPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        } 
                                        labelWidth={120}
                                    />
                                </FormControl>
                                <br />
                                { 
                                    Object.values(errorsRecovery).map((items,key)=>{
                                            if(typeof items=='string' || items.length==1){
                                                    return(
                                                        <>
                                                            <span style={{color:'red'}} key={key}>{items}</span>
                                                            <br></br>
                                                        </>
                                                    );
                                            }else
                                            {
                                                let outToString = [];
                                                Object.values(items).map((item,key2)=>{
                                                    outToString.push(
                                                            <>  
                                                                <span style={{color:'red'}} key={key2}>{item}</span>
                                                                <br></br>
                                                            </>
                                                        );
                                                    }
                                                );
                                                return (
                                                    <div>
                                                        {outToString}
                                                    </div>
                                                );
                                            }
                                        }
                                    )
                                }
                                <Button
                                variant="contained"
                                color="primary"
                                className={classes.fisubmit}
                                onClick={this.handleClickSubmit}
                                >
                                Enviar
                                </Button>    
                            <div className={classes.footerSpan}></div>
                        </form>
                    </div>
                    <Modal
                        className="generalModal"
                        open={this.state.openReset}
                        onClose={() => this.setState({ openReset: false })}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                        >
                        <Fade in={this.state.openReset}>
                            <ModalChangePassword
                            user={user}
                            handleCloseRecovery={this.handleCloseRecovery}
                            />
                        </Fade>
                    </Modal>
                </Grid>
            </Grid>
        );
    }
}


export default withStyles(styles)(ChangePassword);