
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import DashboardPage from "views/Dashboard/Dashboard.js";
import Configuration from "views/Configuration/Configuration.js";
import Legislatura from "views/Legislativo/Configuration.js";
import Legislativo from "components/Legislativo/ModuloLegislativo";
import TableList from "views/Attendance/TableList.js";
import Session from "views/Session/Session.js";
import ManualSession from "views/ManualSession/ManualSession.js";
import ListAltIcon from '@material-ui/icons/ListAlt';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import Icons from "views/Icons/parties.js";
import Maps from "views/Maps/Index";
import SettingsIcon from '@material-ui/icons/Settings';
import _ from 'lodash'
import Logs from "views/Logs/logs.js";
import Dashboard from "@material-ui/icons/Dashboard";

let user = JSON.parse(sessionStorage.getItem("USER"));
let acsss = _.get(user, "id_access_level", 0);
let legislativo = JSON.parse(sessionStorage.getItem("LEGISLATIVO"));
let manual_voting = JSON.parse(sessionStorage.getItem("MANUALVOTING"));

let dashboardRoutes = acsss == 1 ? [
  {
    path: "/dashboard",
    name: "Usuários",
    icon: Person,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/session",
    name: "Sessão",
    icon: LibraryBooks,
    component: Session,
    layout: "/admin"
  },
  {
    path: "/partidos",
    name: "Partidos Politicos",
    icon: AccountBalanceIcon,
    component: Icons,
    layout: "/admin"
  },

  {
    path: "/configuration",
    name: "Configuração",
    icon: SettingsIcon,
    component: Configuration,
    layout: "/admin"
  },
  {
    path: "/table",
    name: "Presenças",
    icon: "content_paste",
    component: TableList,
    layout: "/admin"
  },
  {
    path: "/maps",
    name: "Relatórios",
    icon: ListAltIcon,
    component: Maps,
    layout: "/admin"
  },
 {
    path: "/logs",
    name: "Logs",
    icon: ViewHeadlineIcon,
    component: Logs,
    layout: "/admin"
 }
] : [
  {
    path: "/dashboard",
    name: "Sessão",
    icon: LibraryBooks,
    component: Session,
    layout: "/admin"
  },
  {
    path: "/table",
    name: "Presenças",
    icon: "content_paste",
    component: TableList,
    layout: "/admin"
  },
  {
    path: "/maps",
    name: "Relatórios",
    icon: ListAltIcon,
    component: Maps,
    layout: "/admin"
  }

];

if (acsss == 10) {
  //RESET/CLEAR ARRAY
  //dashboardRoutes.length = 0;
  dashboardRoutes = [
    {
      path: "/session",
      name: "Sessão",
      icon: LibraryBooks,
      component: Session,
      layout: "/admin",
    },
    {
      path: "/executivo",
      name: "Modulo Executivo",
      icon: Dashboard,
      component: null,
      layout: "/admin",
    },
  ];
}

if (manual_voting === 1) {
  dashboardRoutes.push({
    path: "/manual",
    name: "Votação Manual",
    icon: "analytics",
    component: ManualSession,
    layout: "/admin"
  });
}

if (legislativo === 1 && acsss == 1) {
  dashboardRoutes.push({
    path: "/legislativo",
    name: "Configuração Legislativo",
    icon: SettingsIcon,
    component: Legislatura,
    layout: "/admin",
  });
}

if (legislativo === 1 && acsss == 2 ) {
  dashboardRoutes.push({
    path: "/legislativo",
    name: "Modulo Legislativo",
    icon: Dashboard,
    component: Legislativo,
    layout: "/admin",
  });
}

export default dashboardRoutes;
