import Echo from "laravel-echo";
import socketio from "socket.io-client";

const port1 = process.env.REACT_APP_STAGE_PORT ? process.env.REACT_APP_STAGE_PORT : '6001'

const echo = new Echo({
  broadcaster: "socket.io",
  host: process.env.REACT_APP_URL_SERVER ? process.env.REACT_APP_URL_SERVER + ':'+port1 : window.location.hostname + ':'+port1,
  client: socketio,
  auth: {
    headers: {
      Accept: "application/json",
    },
  },
});

export default echo;
