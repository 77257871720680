import axios from "axios";
import _ from "lodash";

var tokenGet =
  sessionStorage.getItem("TOKEN_KEY") && sessionStorage.getItem("TOKEN_KEY").charAt(0) == '"'
    ? sessionStorage.getItem("TOKEN_KEY")
    : JSON.stringify(sessionStorage.getItem("TOKEN_KEY"));
var token = JSON.parse(tokenGet);

async function checkStartSession() {
  let data = null;

  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/tribuna/checkSession",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      data = _.get(response, "data.success", null);
    })
    .catch(function (error) {
      Promise.reject(error.response);
    });

  return data;
}

async function Subscribe(id_user, id_tribuna) {
  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/tribuna/subscribe",
    data: { id_user: id_user, id_tribuna: id_tribuna },
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

function Unsubscribe(id) {
  axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/tribuna/unsubscribe",
    data: { id: id },
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

function GrantSpeech(id) {
  axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/tribuna/grantSpeech",
    data: { id: id },
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

async function RejectSpeech(id) {
  axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/tribuna/rejectSpeech",
    data: { id: id },
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

async function RequestComment(id, type) {
  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/tribuna/requestComment",
    data: { id: id, type: type },
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

function GrantComment(time, id, type) {
  axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/tribuna/grantComment",
    data: { time: time, id: id, type: type },
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

function RejectComment(id, messsage) {
  axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/tribuna/rejectComment",
    data: { id: id, message: messsage },
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

function ControlTime(action, time) {
  axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/tribuna/controlTime",
    data: { action: action, time: time },
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

function FinishInscription(id) {
  axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/tribuna/finishInscription",
    data: { id: id },
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

function OpenTribuna(id) {
  axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/tribuna/openTribuna",
    data: { id: id },
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

function CloseTribuna() {
  axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/tribuna/closeTribuna",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export {
  checkStartSession,
  Subscribe,
  Unsubscribe,
  GrantSpeech,
  RejectSpeech,
  RequestComment,
  GrantComment,
  RejectComment,
  ControlTime,
  FinishInscription,
  OpenTribuna,
  CloseTribuna,
};
