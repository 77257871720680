import React, { useEffect, useRef } from "react";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Presence from "components/President/TabLeft";
import Voting from "components/President/TabRight";
import VotingCommittee from "components/President/TabRightCommittee";
import SessionPresident from "components/President/session.js";
import DashboardPresident from "components/President/dashboard";
import Microphone from "components/President/List/microphone";
import { FinishInscription } from "api/_tribuna.js";
import DashboardParliamentary from "components/Parliamentary/parliamentary";
import { getAutomationSettings, getSettingsByName } from "api/settings.js";
import echo from "api/channels";
import {
  apiGetDocumentsSessionPresident,
  apiGetDocumentsTribuna,
} from "api/documents.js";
import NotificationsActiveRoundedIcon from "@material-ui/icons/NotificationsActiveRounded";
import NotificationsOffRoundedIcon from "@material-ui/icons/NotificationsOffRounded";
import { getVoting } from "api/voting.js";
import { getVotingComision } from "api/voting.js";
import { Img } from "components/President/List/styles";
import _ from "lodash";
import "animate.css";
import "../../assets/sass/components/president/president.scss";
import { ringBell } from "api/automation.js";
import { apiSession } from "api/president.js";

export default function Index() {
  const classes = useStyles();

  const [session, setSession] = React.useState(false);
  const [documents, setDocuments] = React.useState(null);
  const [roteiro, setRoteiro] = React.useState(null);
  //const [campainha, setCampainha] = React.useState(true);
  const [voting, setVoting] = React.useState(null);
  const [number, setNumber] = React.useState(9);
  const [idsession, setIdSession] = React.useState(null);
  const [automacao, setAutomacao] = React.useState(null);
  const [settingsVotacao, setSettingsVotacao] = React.useState(null);
  const [buttons_disabled, setButtonStatus] = React.useState(false);
  const [votingComision, setVotingComision] = React.useState(null);
  const [tribuna, setTribuna] = React.useState(null);
  const [timeLeft, setTimeLeft] = React.useState();
  const [docsTribuna, setDocsTribuna] = React.useState(null);
  const [sessionOpen, setSessionOpen] = React.useState([]);
  const [configRead, setConfigRead] = React.useState(false);
  const timeLeftRef = useRef(timeLeft);

  const start = async (id) => {
    let docs = await apiGetDocumentsSessionPresident(id);

    setIdSession(id);
    setDocuments(_.get(docs, "documents", null));
    setRoteiro(_.get(docs, "roteiro", null));
    setSession(true);
  };

  const getDocTribuna = async () => {
    let docsTribuna = await apiGetDocumentsTribuna();
    setDocsTribuna(docsTribuna);
  };

  const hadleButtonStatus = async (status) => {
    setButtonStatus(status);
  };

  const bell = async () => {
    hadleButtonStatus(true);

    await ringBell().catch((error) => {
      if (error.data.erro) {
        alert(error.data.erro);
      } else {
        alert("Ocorreu um erro!");
      }
    });

    hadleButtonStatus(false);
  };

  useEffect(() => {
    timeLeftRef.current = timeLeft;
  }, [timeLeft]);

  useEffect(() => {
    const habilitarDocsVereador = JSON.parse(
      sessionStorage.getItem("CONFIGURATIONPLENARY")
    );
    setConfigRead(habilitarDocsVereador[1]?.active ? true : false);

    apiSession().then((sessionResult) => {
      // Atualizar o estado sessionOpen com o resultado completo da apiSession
      setSessionOpen(sessionResult.success);
    });
  }, [configRead]);

  useEffect(() => {
    echo.channel("voting").listen("StartVoting", (e) => {
      setVoting(e);
      setNumber(6);
      start(e.idsession);
    });
    //Start Votaço das comissões
    echo.channel("votingComision").listen("StartVotingComision", (e) => {
      setVotingComision(e);
      setNumber(6);
    });
    echo.channel("voting").listen("StartVotingBloc", (e) => {
      setVoting(e);
      setNumber(6);
    });
    echo.channel("voting").listen("StopVoting", () => {
      setVoting(null);
      setNumber(9);
    });
    //Finish votaçao das comissões
    echo.channel("voting").listen("StopVoteCommittee", () => {
      setVotingComision(null);
      setNumber(9);
    });
    //Start TribunaOPen
    echo.channel("subscription").listen("OpenSubscription", (e) => {
      
      if (e.time_start_conceder == false){
        if (e.time_inscription == null) {
          setTimeLeft();
        } else {
          setTimeLeft(e.time_inscription * 60);
        }

        setTribuna(e);
      }

    });
    //Start Conceder fala
    echo.channel("timer-conceder").listen("TimerConceder", (e) => {

      if (e.time_start_conceder == true && isNaN(timeLeftRef.current)){ // vaidação conceder tempo
          if (e.time_inscription == null) {
            setTimeLeft();
          } else {
            setTimeLeft(e.time_inscription * 60);
          }

          setTribuna(e);
      }
      
    });
    //Stop Inscription
    echo.channel("tribuna").listen("closeSubscriptions", (e) => {
      setTimeLeft();
    });

    //retorna dados da votação ativa
    const fetchData = async () => {
      const data = await getVoting();
      const dataComision = await getVotingComision(); //pega dados da votação da vomissão ativa

      setVoting(data);
      setVotingComision(dataComision); // atribuia ao stado 'votingComision' dados da comissão

      setNumber(dataComision || data != null ? 6 : 9); // numero para mudar tamanho do modal
    };
    fetchData();

    getAutomationSettings("automacao").then((result) => {
      setAutomacao(result);
    });

    getSettingsByName("gerais").then((result) => {
      setSettingsVotacao(result.values.votacao);
    });
    getDocTribuna();
  }, []);

  useEffect(() => {
    // Encerra o timer se o tempo for zero
    if (timeLeft === 0) {
      handleTimerEnd();
      return;
    }

    // Diminui o tempo restante a cada segundo
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 980);

    // Limpa o intervalo na desmontagem do componente
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  // Função a ser executada quando o timer acabar
  const handleTimerEnd = () => {
    FinishInscription(_.get(tribuna, "id", null)); // finish Iscription
  };

  const stop = () => {
    setSession(false);
  };

  const user = JSON.parse(sessionStorage.getItem("USER"));
  //let configurationPlenary = JSON.parse(sessionStorage.getItem("CONFIGURATIONPLENARY"));
  //let habilitar_boton_abster = configurationPlenary ? configurationPlenary.find(config => config.alias =='habilitar_boton_abster') : {active : 0};

  return (
    <Grid container spacing={3} className="m-0 w-100 h-100">
      <Grid item xs={12} sm={3} className="userProfile">
        <div className="userProfile__data">
          <div className="userProfile__data--circle">
            {user.photoUrl.includes(".") ? (
              <Img src={user.photoUrl} />
            ) : (
              <i className="fa-solid fa-user"></i>
            )}
          </div>
          <div className="userProfile__data--text">
            <span className="userProfile__data--name">{user.name}</span>
            <span className="userProfile__data--job">{user.public_name}</span>
          </div>
        </div>

        <div className="userProfile__data--connected">
          <Presence echo={echo} />
        </div>
        {automacao && automacao.values.enabled && (
          <div className="userProfile__data--bell">
            <Button disabled={buttons_disabled} onClick={bell}>
              {automacao.values.token_campainha &&
                (automacao.values.token_campainha ? (
                  <NotificationsActiveRoundedIcon className=" animate__animated animate__tada animate__slow animate__delay-2s" />
                ) : (
                  <NotificationsOffRoundedIcon />
                ))}
              <span>Campainha</span>
            </Button>
            <Microphone
              automacao={automacao}
              buttons_disabled={buttons_disabled}
              hadleButtonStatus={hadleButtonStatus}
              classes={classes}
            />
          </div>
        )}
      </Grid>

      {session == true ? (
        <>
          <Grid item xs={12} sm={number}>
            <Paper className="h-100">
              <DashboardPresident
                timeLeft={timeLeft}
                echo={echo}
                stop={stop}
                session={session}
                roteiro={roteiro}
                idsession={idsession}
                documents={documents}
                docsTribuna={docsTribuna}
              />
            </Paper>
          </Grid>

          {votingComision != null ? (
            /* Validando se tem votação das comissões para apresentar menu lateral direito */
            <Grid item xs={12} sm={3}>
              <Paper>
                <VotingCommittee
                  voting={votingComision}
                  settingsVotacao={settingsVotacao}
                />
              </Paper>
            </Grid>
          ) : (
            ""
          )}

          {voting != null ? (
            <Grid item xs={12} sm={3}>
              <Paper>
                <Voting
                  voting={voting}
                  settingsVotacao={
                    settingsVotacao
                  } /*habilitar_boton_abster={habilitar_boton_abster}*/
                />
              </Paper>
            </Grid>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}

      {sessionOpen.length == 0 && (
        <Grid item xs={12} sm={number}>
          <Paper className="h-100">
            <DashboardParliamentary
              session={session}
              docsTribuna={docsTribuna}
              configRead={configRead}
            />
          </Paper>
        </Grid>
      )}

      {session == false && sessionOpen.length != 0 ? (
        <Grid item xs={12} sm={9} className="gridSession">
          <Paper>
            <SessionPresident
              echo={echo}
              start={start}
              configRead={configRead}
            />
          </Paper>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    fontSize: "160%",
    fontWeight: "bold",
    borderRadius: "10px",
  },

  wrapper: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    fontSize: "160%",
    fontWeight: "bold",
    borderRadius: "10px",
    padding: "22px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
}));
