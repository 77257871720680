import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tribuna from "components/Configuration/Tribuna.js";
import Email from "components/Configuration/Email.js";
import Gerais from "components/Configuration/Gerais";
import TypeDocument from "components/Configuration/TypeDocument";
import TypeSession from "components/Configuration/TypeSession";
import Legislatura from "components/Configuration/legislaturas";
import CustomCargo from "components/Configuration/PersonalizarCargo";
//import Microfone from "components/Configuration/Microfone.js";
import { apiGetConfiguration, getSettings, /*getSettingByName,*/ apiSalve } from "api/configuration.js";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import {
  //FaMicrophone,
  FaMailBulk,
  FaCog,
  FaHistory,
  FaRegFileAlt,
  FaTv,
  FaRobot,
} from "react-icons/fa";
import { Snack } from "./styles";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Tooltip from "@material-ui/core/Tooltip";
import AlarmOffIcon from "@material-ui/icons/AlarmOff";
import AlarmOnIcon from "@material-ui/icons/AlarmOn";
import { Button } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import UploadImagePreview from "components/Util/UploadImagePreview";
import TipoComision from "components/Configuration/TipoComision";
import GroupIcon from "@material-ui/icons/Group";
import Automacao from "components/Configuration/Automacao";

class Configuration extends React.Component {
  constructor(props) {
    super(props);
    const { session } = this.props;
    this.state = {
      expediente: [],
      micros: [],
      ordemdia: [],
      peqExpedientes: [],
      tribuna: 0,
      formemail: 0,
      gerais: 0,
      automacao: 0,
      microfones: 0,
      typedocument: 0,
      peqExpediente: 0,
      customizeoffice: 0,
      discution: 0,
      automation: "",
      email: "",
      ordem_dia: 1,
      sessionexpediente: [],
      sessionordemdia: [],
      time_ordemdia: "",
      configPlenary: [],
      time_expediente: "",
      time_peqExpediente: "",
      sessionId: _.get(session, "id", ""),
      sessionPeqexpediente: [],
      message: "",
      open_message: false,
      color_message: "success",
      aparte: "",
      ordem: "",
      aparte_ordemdia: "",
      ordem_ordemdia: "",
      aparte_expediente: "",
      ordem_expediente: "",
      limit_expediente: 0,
      limit_ordemdia: 0,
      limit_peqexpediente: 0,
      enablePeqExpediente: null,
      aparte_discution: "",
      ordem_discution: "",
      time_discution: "",
      legislatura: 0,
      legislaturas: [],
      sistema_legislativo: false,
      legislativo: 0,
      type_sessions: [],
      typesession: 0,
      videowall: 0,
      tipo_comision: 0,
      enable_ementa: 0,
      enable_slide: 0,
      time_ementa: "",
      logoCliente: "",
      slide_images: [],
      settingsValues: [],
    };

    this.addTribuna = this.addTribuna.bind(this);
    this.addMicro = this.addMicro.bind(this);
    this.addTribunaOrdemDia = this.addTribunaOrdemDia.bind(this);
    this.clickExpediente = this.clickExpediente.bind(this);
    this.clickOrdemDia = this.clickOrdemDia.bind(this);
    this.clickEmail = this.clickEmail.bind(this);
    this.clickGeral = this.clickGeral.bind(this);
    this.clickAutomacao = this.clickAutomacao.bind(this);
    this.clickMicros = this.clickMicros.bind(this);
    this.clickTypeDocument = this.clickTypeDocument.bind(this);
    this.clickCustomizeOffice = this.clickCustomizeOffice.bind(this);
    this.clickDiscution = this.clickDiscution.bind(this);
    this.formTime = this.formTime.bind(this);
    this.formTimeEx = this.formTimeEx.bind(this);
    this.formTimePe = this.formTimePe.bind(this);
    this.formTimeDiscution = this.formTimeDiscution.bind(this);
    this.clickPeqExpediente = this.clickPeqExpediente.bind(this);
    this.clickLegislatura = this.clickLegislatura.bind(this);
    this.clickLegislativo = this.clickLegislativo.bind(this);
    this.clickTypeSession = this.clickTypeSession.bind(this);
    this.clickTypeVideoWall = this.clickTypeVideoWall.bind(this);
    this.videowallConfig = this.videowallConfig.bind(this);
    this.setSlideImage = this.setSlideImage.bind(this);
    this.clickTipoComision = this.clickTipoComision.bind(this);
  }

  handleOpen = (param) => {
    this.setState({ edit: param.id ? param : 0 });
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  async handleDocs() {
    this.setState({ documents_select: "A" });
    this.setState({ modal_documents: true });
  }

  sendMessage = async (retorno, color) => {
    if (color) {
      this.setState({ color_message: color });
    }
    
    this.setState({ message: retorno });
    this.setState({ open_message: true });
    await this.reloadConfiguration();

    setTimeout(() => {
      this.setState({ color_message: 'success' });
      this.setState({ open_message: false });
      this.setState({ message: '' });
    }, 1000);

    return;
  };

  componentDidMount() {
    this.reloadConfiguration();
  }

  async reloadConfiguration() {
    let settingsValues = {};

    let data = await apiGetConfiguration();

    await getSettings().then((response) => {
      response.map((item) => {
        settingsValues[item.name] = item.values;
      });
    });
    
    const tribuna = {
      inscricao: "",
      time: "",
      aparte: "",
    };
    let mc = {
      id: "",
      name: "",
      OF: "",
      ON: "",
    };

    this.setState({
      sistema_legislativo: _.get(data, "success.legislativo.status", false),
    });
    this.setState({ time_ordemdia: _.get(data, "success.ordemdia.time", "") });
    this.setState({ configPlenary: _.get(data, "success.configPlenary", []) });
    this.setState({
      limit_peqexpediente: _.get(data, "success.peqexpediente.limit", 0),
    });
    this.setState({
      limit_ordemdia: _.get(data, "success.ordemdia.limit", ""),
    });
    this.setState({
      limit_expediente: _.get(data, "success.expediente.limit", 0),
    });
    this.setState({
      time_expediente: _.get(data, "success.expediente.time", ""),
    });
    this.setState({
      enablePeqExpediente: _.get(data, "success.peqexpediente.enable", ""),
    });
    this.setState({
      time_peqExpediente: _.get(data, "success.peqexpediente.time", ""),
    });
    this.setState({
      aparte: _.get(data, "success.peqexpediente.tribunas[0].aparte", ""),
    });
    this.setState({
      ordem: _.get(data, "success.peqexpediente.tribunas[0].ordem", ""),
    });
    this.setState({
      aparte_expediente: _.get(
        data,
        "success.expediente.tribunas[0].aparte",
        ""
      ),
    });
    this.setState({
      ordem_expediente: _.get(data, "success.expediente.tribunas[0].ordem", ""),
    });
    this.setState({
      aparte_ordemdia: _.get(data, "success.ordemdia.tribunas[0].aparte", ""),
    });
    this.setState({
      ordem_ordemdia: _.get(data, "success.ordemdia.tribunas[0].ordem", ""),
    });
    this.setState({ sessionordemdia: _.get(data, "success.ordemdia", "") });
    this.setState({
      ordemdia: _.get(data, "success.ordemdia.tribunas", tribuna),
    });
    this.setState({ sessionexpediente: _.get(data, "success.expediente", "") });
    this.setState({
      sessionPeqexpediente: _.get(data, "success.peqexpediente", ""),
    });
    this.setState({
      peqExpedientes: _.get(data, "success.peqexpediente.tribunas", tribuna),
    });
    this.setState({
      expediente: _.get(data, "success.expediente.tribunas", tribuna),
    });
    this.setState({ automation: _.get(data, "success.automation", "") });
    this.setState({ legislaturas: _.get(data, "success.legilaturas", "") });
    this.setState({ email: _.get(data, "success.email", "") });
    this.setState({ micros: _.get(data, "success.microphones", mc) });
    this.setState({
      time_discution: _.get(data, "success.discution.time", ""),
    });
    this.setState({
      aparte_discution: _.get(data, "success.discution.aparte", ""),
    });
    this.setState({
      ordem_discution: _.get(data, "success.discution.ordem", ""),
    });
    this.setState({ type_sessions: _.get(data, "success.typesSession", "") });
    this.setState({
      enable_ementa: _.get(data, "success.videowall.enable_ementa", 0),
    });
    this.setState({
      enable_slide: _.get(data, "success.videowall.enable_slide", 0),
    });
    this.setState({
      time_ementa: _.get(data, "success.videowall.time_ementa", "00:00:05"),
    });
    this.setState({ logoCliente: _.get(data, "success.logoCliente", "") });
    this.setState({
      slide_images: _.get(data, "success.videowall.slide_images", []),
    });
    this.setState({ settingsValues: settingsValues });
  }

  addTribuna() {
    const tribuna = {
      inscricao: "",
      time: "",
      aparte: "",
    };

    if (this.state.peqExpediente == 1) {
      const data = this.state.peqExpedientes;
      data.push(tribuna);
      this.setState({ peqExpedientes: data });
      return;
    }

    if (this.state.ordem_dia == 1) {
      const data = this.state.ordemdia;
      data.push(tribuna);
      this.setState({ ordemdia: data });
      return;
    }

    const data = this.state.expediente;
    data.push(tribuna);
    this.setState({ expediente: data });
    return;
  }

  addTribunaOrdemDia() {
    const data = this.state.ordemdia;
    const tribuna = {
      inscricao: "",
      time: "",
      aparte: "",
    };
    data.push(tribuna);
    this.setState({ ordemdia: data });
  }

  addMicro() {
    const data = this.state.micros;
    const micros = {
      micro: "",
      url: "",
    };
    data.push(micros);
    this.setState({ micros: data });
  }

  clickTypeSession() {
    this.setState({ peqExpediente: 0 });
    this.setState({ tribuna: 0 });
    this.setState({ formemail: 0 });
    this.setState({ gerais: 0 });
    this.setState({ automacao: 0 });
    this.setState({ microfones: 0 });
    this.setState({ ordem_dia: 0 });
    this.setState({ typedocument: 0 });
    this.setState({ customizeoffice: 0 });
    this.setState({ discution: 0 });
    this.setState({ legislatura: 0 });
    this.setState({ legislativo: 0 });
    this.setState({ typesession: 1 });
    this.setState({ videowall: 0 });
    this.setState({ tipo_comision: 0 });
  }

  clickPeqExpediente() {
    this.setState({ peqExpediente: 1 });
    this.setState({ tribuna: 0 });
    this.setState({ formemail: 0 });
    this.setState({ gerais: 0 });
    this.setState({ automacao: 0 });
    this.setState({ microfones: 0 });
    this.setState({ ordem_dia: 0 });
    this.setState({ typedocument: 0 });
    this.setState({ customizeoffice: 0 });
    this.setState({ discution: 0 });
    this.setState({ legislatura: 0 });
    this.setState({ legislativo: 0 });
    this.setState({ typesession: 0 });
    this.setState({ videowall: 0 });
    this.setState({ tipo_comision: 0 });
  }

  clickExpediente() {
    this.setState({ tribuna: 1 });
    this.setState({ formemail: 0 });
    this.setState({ gerais: 0 });
    this.setState({ automacao: 0 });
    this.setState({ microfones: 0 });
    this.setState({ ordem_dia: 0 });
    this.setState({ typedocument: 0 });
    this.setState({ peqExpediente: 0 });
    this.setState({ customizeoffice: 0 });
    this.setState({ discution: 0 });
    this.setState({ legislatura: 0 });
    this.setState({ legislativo: 0 });
    this.setState({ typesession: 0 });
    this.setState({ videowall: 0 });
    this.setState({ tipo_comision: 0 });
  }

  clickOrdemDia() {
    this.setState({ ordem_dia: 1 });
    this.setState({ formemail: 0 });
    this.setState({ gerais: 0 });
    this.setState({ automacao: 0 });
    this.setState({ microfones: 0 });
    this.setState({ tribuna: 0 });
    this.setState({ typedocument: 0 });
    this.setState({ peqExpediente: 0 });
    this.setState({ customizeoffice: 0 });
    this.setState({ discution: 0 });
    this.setState({ legislatura: 0 });
    this.setState({ legislativo: 0 });
    this.setState({ typesession: 0 });
    this.setState({ videowall: 0 });
    this.setState({ tipo_comision: 0 });
  }

  clickEmail() {
    this.setState({ tribuna: 0 });
    this.setState({ formemail: 1 });
    this.setState({ gerais: 0 });
    this.setState({ automacao: 0 });
    this.setState({ microfones: 0 });
    this.setState({ ordem_dia: 0 });
    this.setState({ typedocument: 0 });
    this.setState({ peqExpediente: 0 });
    this.setState({ customizeoffice: 0 });
    this.setState({ discution: 0 });
    this.setState({ legislatura: 0 });
    this.setState({ legislativo: 0 });
    this.setState({ typesession: 0 });
    this.setState({ videowall: 0 });
    this.setState({ tipo_comision: 0 });
  }

  clickGeral() {
    this.setState({ tribuna: 0 });
    this.setState({ formemail: 0 });
    this.setState({ gerais: 1 });
    this.setState({ automacao: 0 });
    this.setState({ microfones: 0 });
    this.setState({ ordem_dia: 0 });
    this.setState({ typedocument: 0 });
    this.setState({ peqExpediente: 0 });
    this.setState({ customizeoffice: 0 });
    this.setState({ discution: 0 });
    this.setState({ legislatura: 0 });
    this.setState({ legislativo: 0 });
    this.setState({ typesession: 0 });
    this.setState({ videowall: 0 });
    this.setState({ tipo_comision: 0 });
  }

  clickAutomacao() {
    this.setState({ tribuna: 0 });
    this.setState({ formemail: 0 });
    this.setState({ gerais: 0 });
    this.setState({ automacao: 1 });
    this.setState({ microfones: 0 });
    this.setState({ ordem_dia: 0 });
    this.setState({ typedocument: 0 });
    this.setState({ peqExpediente: 0 });
    this.setState({ customizeoffice: 0 });
    this.setState({ discution: 0 });
    this.setState({ legislatura: 0 });
    this.setState({ legislativo: 0 });
    this.setState({ typesession: 0 });
    this.setState({ videowall: 0 });
    this.setState({ tipo_comision: 0 });
  }

  clickMicros() {
    this.setState({ tribuna: 0 });
    this.setState({ formemail: 0 });
    this.setState({ gerais: 0 });
    this.setState({ automacao: 0 });
    this.setState({ microfones: 1 });
    this.setState({ ordem_dia: 0 });
    this.setState({ typedocument: 0 });
    this.setState({ peqExpediente: 0 });
    this.setState({ customizeoffice: 0 });
    this.setState({ discution: 0 });
    this.setState({ legislatura: 0 });
    this.setState({ legislativo: 0 });
    this.setState({ typesession: 0 });
    this.setState({ videowall: 0 });
    this.setState({ tipo_comision: 0 });
  }

  clickTypeDocument() {
    this.setState({ typedocument: 1 });
    this.setState({ customizeoffice: 0 });
    this.setState({ tribuna: 0 });
    this.setState({ formemail: 0 });
    this.setState({ gerais: 0 });
    this.setState({ automacao: 0 });
    this.setState({ microfones: 0 });
    this.setState({ ordem_dia: 0 });
    this.setState({ peqExpediente: 0 });
    this.setState({ discution: 0 });
    this.setState({ legislatura: 0 });
    this.setState({ legislativo: 0 });
    this.setState({ typesession: 0 });
    this.setState({ videowall: 0 });
    this.setState({ tipo_comision: 0 });
  }

  clickCustomizeOffice() {
    this.setState({ customizeoffice: 1 });
    this.setState({ typedocument: 0 });
    this.setState({ tribuna: 0 });
    this.setState({ formemail: 0 });
    this.setState({ gerais: 0 });
    this.setState({ automacao: 0 });
    this.setState({ microfones: 0 });
    this.setState({ ordem_dia: 0 });
    this.setState({ peqExpediente: 0 });
    this.setState({ discution: 0 });
    this.setState({ legislatura: 0 });
    this.setState({ legislativo: 0 });
    this.setState({ typesession: 0 });
    this.setState({ videowall: 0 });
    this.setState({ tipo_comision: 0 });
  }

  clickDiscution() {
    this.setState({ customizeoffice: 0 });
    this.setState({ typedocument: 0 });
    this.setState({ tribuna: 0 });
    this.setState({ formemail: 0 });
    this.setState({ gerais: 0 });
    this.setState({ automacao: 0 });
    this.setState({ microfones: 0 });
    this.setState({ ordem_dia: 0 });
    this.setState({ peqExpediente: 0 });
    this.setState({ discution: 1 });
    this.setState({ legislatura: 0 });
    this.setState({ legislativo: 0 });
    this.setState({ typesession: 0 });
    this.setState({ videowall: 0 });
    this.setState({ tipo_comision: 0 });
  }

  clickLegislatura() {
    this.setState({ customizeoffice: 0 });
    this.setState({ typedocument: 0 });
    this.setState({ tribuna: 0 });
    this.setState({ formemail: 0 });
    this.setState({ gerais: 0 });
    this.setState({ automacao: 0 });
    this.setState({ microfones: 0 });
    this.setState({ ordem_dia: 0 });
    this.setState({ peqExpediente: 0 });
    this.setState({ discution: 0 });
    this.setState({ legislatura: 1 });
    this.setState({ legislativo: 0 });
    this.setState({ typesession: 0 });
    this.setState({ videowall: 0 });
    this.setState({ tipo_comision: 0 });
  }

  clickLegislativo() {
    this.setState({ customizeoffice: 0 });
    this.setState({ typedocument: 0 });
    this.setState({ tribuna: 0 });
    this.setState({ formemail: 0 });
    this.setState({ gerais: 0 });
    this.setState({ automacao: 0 });
    this.setState({ microfones: 0 });
    this.setState({ ordem_dia: 0 });
    this.setState({ peqExpediente: 0 });
    this.setState({ discution: 0 });
    this.setState({ legislatura: 0 });
    this.setState({ legislativo: 1 });
    this.setState({ typesession: 0 });
    this.setState({ videowall: 0 });
    this.setState({ tipo_comision: 0 });
  }

  clickTypeVideoWall() {
    this.setState({ peqExpediente: 0 });
    this.setState({ tribuna: 0 });
    this.setState({ formemail: 0 });
    this.setState({ gerais: 0 });
    this.setState({ automacao: 0 });
    this.setState({ microfones: 0 });
    this.setState({ ordem_dia: 0 });
    this.setState({ typedocument: 0 });
    this.setState({ customizeoffice: 0 });
    this.setState({ discution: 0 });
    this.setState({ legislatura: 0 });
    this.setState({ legislativo: 0 });
    this.setState({ typesession: 0 });
    this.setState({ videowall: 1 });
    this.setState({ tipo_comision: 0 });
  }

  clickTipoComision() {
    this.setState({ peqExpediente: 0 });
    this.setState({ tribuna: 0 });
    this.setState({ formemail: 0 });
    this.setState({ gerais: 0 });
    this.setState({ automacao: 0 });
    this.setState({ microfones: 0 });
    this.setState({ ordem_dia: 0 });
    this.setState({ typedocument: 0 });
    this.setState({ customizeoffice: 0 });
    this.setState({ discution: 0 });
    this.setState({ legislatura: 0 });
    this.setState({ legislativo: 0 });
    this.setState({ typesession: 0 });
    this.setState({ videowall: 0 });
    this.setState({ tipo_comision: 1 });
  }

  async formTimeDiscution() {
    const { time_discution, aparte_discution, ordem_discution } = this.state;
    if (
      time_discution == "" ||
      aparte_discution == "" ||
      ordem_discution == ""
    ) {
      alert("Campo em Branco");
      return;
    }

    let resp = await apiSalve(
      {
        time: time_discution,
        aparte: aparte_discution,
        ordem: ordem_discution,
      },
      "/configuration/discution/save"
    );

    this.sendMessage(resp);
  }

  async formTime() {
    const {
      limit_ordemdia,
      sessionordemdia,
      time_ordemdia,
      aparte_ordemdia,
      ordem_ordemdia,
    } = this.state;
    if (time_ordemdia == "" || aparte_ordemdia == "" || ordem_ordemdia == "") {
      alert("Campo em Branco");
      return;
    }

    let resp = await apiSalve(
      {
        time: time_ordemdia,
        aparte: aparte_ordemdia,
        ordem: ordem_ordemdia,
        id: sessionordemdia.id,
        limit: limit_ordemdia,
      },
      "/configuration/partsession/save"
    );

    this.sendMessage(resp);
  }

  async formTimeEx() {
    const {
      limit_expediente,
      sessionexpediente,
      time_expediente,
      aparte_expediente,
      ordem_expediente,
    } = this.state;

    if (
      time_expediente == "" ||
      aparte_expediente == "" ||
      ordem_expediente == ""
    ) {
      alert("Campo em Branco");
      return;
    }

    let resp = await apiSalve(
      {
        time: time_expediente,
        id: sessionexpediente.id,
        aparte: aparte_expediente,
        ordem: ordem_expediente,
        limit: limit_expediente,
      },
      "/configuration/partsession/save"
    );

    this.sendMessage(resp);
  }

  async formTimePe() {
    const {
      enablePeqExpediente,
      limit_peqexpediente,
      sessionPeqexpediente,
      time_peqExpediente,
      aparte,
      ordem,
    } = this.state;

    if (time_peqExpediente == "" || aparte == "" || ordem == "") {
      alert("Campo em Branco");
      return;
    }

    let resp = await apiSalve(
      {
        id: sessionPeqexpediente.id,
        aparte: aparte,
        ordem: ordem,
        time: time_peqExpediente,
        limit: limit_peqexpediente,
        enable: enablePeqExpediente,
      },
      "/configuration/partsession/save"
    );

    this.sendMessage(resp);
  }

  setSlideImage(slide_images) {
    let images = [];

    slide_images.map((image, index) => {
      images[index] = { data_url: image.data_url };
    });

    this.setState({ slide_images: images });
  }

  async videowallConfig() {
    const { time_ementa, slide_images } = this.state;
    let resp = await apiSalve(
      {
        time_ementa: time_ementa,
        slide_images: slide_images,
      },
      "/configuration/videowall/save"
    );

    this.sendMessage(resp);
  }

  hadleForm = (event) => {
    switch (event.target.name) {
      case "time_peqExpediente":
        return this.setState({ time_peqExpediente: event.target.value });
      case "time_expediente":
        return this.setState({ time_expediente: event.target.value });
      case "time_ordemdia":
        return this.setState({ time_ordemdia: event.target.value });
      case "aparte":
        return this.setState({ aparte: event.target.value });
      case "aparte_expediente":
        return this.setState({ aparte_expediente: event.target.value });
      case "aparte_ordemdia":
        return this.setState({ aparte_ordemdia: event.target.value });
      case "ordem":
        return this.setState({ ordem: event.target.value });
      case "ordem_expediente":
        return this.setState({ ordem_expediente: event.target.value });
      case "ordem_ordemdia":
        return this.setState({ ordem_ordemdia: event.target.value });
      case "limit_peqexpediente":
        return this.setState({ limit_peqexpediente: event.target.value });
      case "limit_expediente":
        return this.setState({ limit_expediente: event.target.value });
      case "limit_ordemdia":
        return this.setState({ limit_ordemdia: event.target.value });
      case "time_discution":
        return this.setState({ time_discution: event.target.value });
      case "aparte_discution":
        return this.setState({ aparte_discution: event.target.value });
      case "ordem_discution":
        return this.setState({ ordem_discution: event.target.value });
      case "time_ementa":
        return this.setState({ time_ementa: event.target.value });
      default:
        return "";
    }
  };

  onClickEnaBlePeqExpediente = (param) => {
    this.setState({ enablePeqExpediente: param });
  };

  handleChangeToggle = async (event) => {
    let input = {};
    let target = "/configuration/videowall/save";
    let fieldName = event.target.name;

    switch (fieldName) {
      case "sistema_legislativo":
        this.setState({ sistema_legislativo: event.target.checked });
        input = { status: event.target.checked };
        target = "/legislativo/update";
        break;
      case "enable_ementa":
        this.setState({ enable_ementa: event.target.checked });
        input = { enable_ementa: event.target.checked };
        break;
      case "enable_slide":
        this.setState({ enable_slide: event.target.checked });
        input = { enable_slide: event.target.checked };
        break;
    }

    await apiSalve(input, target).then((response) => {
      if (response == "houve algo de errado") {
        this.setState({ [fieldName]: 0 });
      }
      //this.sendMessage('Configurações do legislativo atualizadas com sucesso.')
    });
  };

  handleSettings = async (name, values) => {
    let resp = await apiSalve({name: name, values: values}, "/settings/update");
    
    if (typeof resp == "object" && resp.erro) {
      this.sendMessage(resp.erro, 'danger');
    } else {
      this.sendMessage('Configuração salva com sucesso!', 'success');
    }
  }

  render() {
    const { classes } = this.props;
    const {
      ordem_ordemdia,
      ordem_expediente,
      aparte_ordemdia,
      aparte_expediente,
      ordem,
      aparte,
      color_message,
      open_message,
      message,
      time_peqExpediente,
      sessionPeqexpediente,
      peqExpedientes,
      peqExpediente,
      typedocument,
      formemail,
      automation,
      tribuna,
      email,
      gerais,
      expediente,
      automacao,
      //microfones,
      //micros,
      ordemdia,
      ordem_dia,
      sessionexpediente,
      sessionordemdia,
      time_ordemdia,
      configPlenary,
      time_expediente,
      customizeoffice,
      enablePeqExpediente,
      limit_expediente,
      limit_ordemdia,
      limit_peqexpediente,
      discution,
      time_discution,
      aparte_discution,
      ordem_discution,
      legislatura,
      legislaturas,
      legislativo,
      sistema_legislativo,
      typesession,
      type_sessions,
      videowall,
      enable_ementa,
      enable_slide,
      time_ementa,
      slide_images,
      logoCliente,
      tipo_comision,
      settingsValues,
    } = this.state;

    return (
      <Card>
        <CardHeader color="success">
          <h4 className={classes.cardTitleWhite}>Configurações do sistema </h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Snack
                onClick={this.clickPeqExpediente}
                style={
                  peqExpediente === 1 ? { backgroundColor: "#4caf50" } : {}
                }
              >
                <FaHistory
                  style={peqExpediente === 1 ? { color: "#fff" } : {}}
                />
                <span style={peqExpediente === 1 ? { color: "#fff" } : {}}>
                  Peq Expediente
                </span>
              </Snack>
              <Snack
                onClick={this.clickExpediente}
                style={tribuna === 1 ? { backgroundColor: "#4caf50" } : {}}
              >
                <FaHistory style={tribuna === 1 ? { color: "#fff" } : {}} />
                <span style={tribuna === 1 ? { color: "#fff" } : {}}>
                  Expediente
                </span>
              </Snack>
              <Snack
                onClick={this.clickOrdemDia}
                style={ordem_dia === 1 ? { backgroundColor: "#4caf50" } : {}}
              >
                <FaHistory
                  style={ordem_dia === 1 ? { color: "#fff" } : {}}
                ></FaHistory>
                <span style={ordem_dia === 1 ? { color: "#fff" } : {}}>
                  Ordem do dia
                </span>
              </Snack>
              <Snack
                onClick={this.clickEmail}
                style={formemail === 1 ? { backgroundColor: "#4caf50" } : {}}
              >
                <FaMailBulk style={formemail === 1 ? { color: "#fff" } : {}} />
                <span style={formemail === 1 ? { color: "#fff" } : {}}>
                  Email
                </span>
              </Snack>
              <Snack
                onClick={this.clickGeral}
                style={gerais === 1 ? { backgroundColor: "#4caf50" } : {}}
              >
                <FaCog style={gerais === 1 ? { color: "#fff" } : {}} />
                <span style={gerais === 1 ? { color: "#fff" } : {}}>
                  Configurações Gerais
                </span>
              </Snack>
              <Snack onClick={this.clickAutomacao} style={automacao === 1 ? { backgroundColor: "#4caf50" } : {}}>
                <FaRobot style={automacao === 1 ? { color: "#fff" } : {}} />
                <span style={automacao === 1 ? { color: "#fff" } : {}}>
                  Automação
                </span>
              </Snack>
              {/*<Snack
                onClick={this.clickMicros}
                style={microfones === 1 ? { backgroundColor: "#4caf50" } : {}}
              >
                <FaMicrophone
                  style={microfones === 1 ? { color: "#fff" } : {}}
                />
                <span style={microfones === 1 ? { color: "#fff" } : {}}>
                  Microfones
                </span>
              </Snack>*/}
              <Snack
                onClick={this.clickTypeDocument}
                style={typedocument === 1 ? { backgroundColor: "#4caf50" } : {}}
              >
                <FaRegFileAlt
                  style={typedocument === 1 ? { color: "#fff" } : {}}
                />
                <span style={typedocument === 1 ? { color: "#fff" } : {}}>
                  Adicionar tipo de documento
                </span>
              </Snack>
              <Snack
                onClick={this.clickCustomizeOffice}
                style={
                  customizeoffice === 1 ? { backgroundColor: "#4caf50" } : {}
                }
              >
                <FaRegFileAlt
                  style={customizeoffice === 1 ? { color: "#fff" } : {}}
                />
                <span style={customizeoffice === 1 ? { color: "#fff" } : {}}>
                  Personalizar Cargo
                </span>
              </Snack>
              <Snack
                onClick={this.clickDiscution}
                style={discution === 1 ? { backgroundColor: "#4caf50" } : {}}
              >
                <FaHistory style={discution === 1 ? { color: "#fff" } : {}} />
                <span style={discution === 1 ? { color: "#fff" } : {}}>
                  Discussão
                </span>
              </Snack>
              <Snack
                onClick={this.clickLegislatura}
                style={legislatura === 1 ? { backgroundColor: "#4caf50" } : {}}
              >
                <FaHistory style={legislatura === 1 ? { color: "#fff" } : {}} />
                <span style={legislatura === 1 ? { color: "#fff" } : {}}>
                  Legislaturas
                </span>
              </Snack>
              <Snack
                onClick={this.clickLegislativo}
                style={legislativo === 1 ? { backgroundColor: "#4caf50" } : {}}
              >
                <FaHistory style={legislativo === 1 ? { color: "#fff" } : {}} />
                <span style={legislativo === 1 ? { color: "#fff" } : {}}>
                  Sistema Legislativo
                </span>
              </Snack>
              <Snack
                onClick={this.clickTypeSession}
                style={typesession === 1 ? { backgroundColor: "#4caf50" } : {}}
              >
                <FaHistory style={typesession === 1 ? { color: "#fff" } : {}} />
                <span style={typesession === 1 ? { color: "#fff" } : {}}>
                  Gerenciar tipos de Sessão
                </span>
              </Snack>
              <Snack
                onClick={this.clickTypeVideoWall}
                style={videowall === 1 ? { backgroundColor: "#4caf50" } : {}}
              >
                <FaTv style={videowall === 1 ? { color: "#fff" } : {}} />
                <span style={videowall === 1 ? { color: "#fff" } : {}}>
                  Video Wall
                </span>
              </Snack>
              <Snack onClick={this.clickTipoComision}>
                <GroupIcon />
                <span>Tipos Comissâo</span>
              </Snack>
            </GridItem>
            {typesession === 1 ? (
              <GridItem xs={12} sm={12} md={6}>
                <SnackbarContent
                  style={{ marginBottom: "1px" }}
                  message={"Tipos de Documento"}
                />
                <TypeSession typesSession={type_sessions} />
              </GridItem>
            ) : (
              ""
            )}
            {ordem_dia === 1 ? (
              <GridItem xs={12} sm={12} md={6}>
                <SnackbarContent
                  style={{ marginBottom: "1px" }}
                  message={"Configuração de Tempo"}
                />
                <div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Card
                        style={{
                          boxShadow:
                            "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
                        }}
                      >
                        <CardBody>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                              <InputLabel style={{ fontSize: "12px" }}>
                                {"Tempo Total da Ordem do Dia "}
                              </InputLabel>
                              <FormControl className={classes.form}>
                                <Input
                                  name="time_ordemdia"
                                  type="time"
                                  value={time_ordemdia}
                                  onChange={this.hadleForm}
                                />
                              </FormControl>
                              <LightTooltip title="Numero de inscritos que podem falar na tribuna">
                                <TextField
                                  style={{ marginTop: "12px" }}
                                  id="standard-number"
                                  label="Inscrições tribuna"
                                  type="number"
                                  name="limit_ordemdia"
                                  value={limit_ordemdia}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={this.hadleForm}
                                />
                              </LightTooltip>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <InputLabel style={{ fontSize: "12px" }}>
                                {"Aparte"}
                              </InputLabel>
                              <FormControl className={classes.form}>
                                <Input
                                  name="aparte_ordemdia"
                                  type="time"
                                  value={aparte_ordemdia}
                                  onChange={this.hadleForm}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <InputLabel style={{ fontSize: "12px" }}>
                                {"Questão de Ordem"}
                              </InputLabel>
                              <FormControl className={classes.form}>
                                <Input
                                  name="ordem_ordemdia"
                                  type="time"
                                  value={ordem_ordemdia}
                                  onChange={this.hadleForm}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <Button
                                style={{
                                  backgroundColor: "#4caf50",
                                  color: "white",
                                  marginTop: "12px",
                                }}
                                onClick={this.formTime}
                                color="primary"
                              >
                                Salvar
                              </Button>
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                      {ordemdia.map((prop, key) => {
                        return (
                          <Tribuna
                            sendMessage={this.sendMessage.bind(this)}
                            key={key}
                            tribuna={prop}
                            part={sessionordemdia}
                          />
                        );
                      })}
                    </GridItem>
                  </GridContainer>
                </div>
                <span
                  className="material-icons"
                  style={{ color: "#4caf50", marginTop: "5px" }}
                  onClick={this.addTribuna}
                >
                  library_add
                </span>
              </GridItem>
            ) : (
              ""
            )}
            {peqExpediente === 1 ? (
              <GridItem xs={12} sm={12} md={6}>
                <SnackbarContent
                  style={{ marginBottom: "1px" }}
                  message={"Configuração de Tempo"}
                />

                <div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Card
                        style={{
                          boxShadow:
                            "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
                        }}
                      >
                        <CardBody>
                          <GridContainer>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: "15px",
                              }}
                            >
                              <FormControl>
                                {enablePeqExpediente == false ? (
                                  <Button
                                    onClick={() =>
                                      this.onClickEnaBlePeqExpediente(true)
                                    }
                                    color="default"
                                  >
                                    Habilitar{" "}
                                    <AlarmOnIcon style={{ fontSize: "15px" }} />
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() =>
                                      this.onClickEnaBlePeqExpediente(false)
                                    }
                                    color="default"
                                  >
                                    Desabilitar{" "}
                                    <AlarmOffIcon
                                      style={{ fontSize: "15px" }}
                                    />
                                  </Button>
                                )}
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                              <InputLabel style={{ fontSize: "12px" }}>
                                {"Tempo do Peq Expediente "}
                              </InputLabel>
                              <FormControl className={classes.form}>
                                <Input
                                  name="time_peqExpediente"
                                  type="time"
                                  value={time_peqExpediente}
                                  onChange={this.hadleForm}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <InputLabel style={{ fontSize: "12px" }}>
                                {"Aparte"}
                              </InputLabel>
                              <FormControl className={classes.form}>
                                <Input
                                  name="aparte"
                                  type="time"
                                  value={aparte}
                                  onChange={this.hadleForm}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <InputLabel style={{ fontSize: "12px" }}>
                                {"Questão de Ordem"}
                              </InputLabel>
                              <FormControl className={classes.form}>
                                <Input
                                  name="ordem"
                                  type="time"
                                  value={ordem}
                                  onChange={this.hadleForm}
                                />
                              </FormControl>
                            </GridItem>

                            <GridItem xs={12} sm={12} md={4}>
                              <FormControl>
                                <LightTooltip title="Numero de inscritos que podem falar na tribuna">
                                  <TextField
                                    style={{ marginTop: "12px" }}
                                    id="standard-number"
                                    label="Inscrições tribuna"
                                    type="number"
                                    name="limit_peqexpediente"
                                    value={limit_peqexpediente}
                                    onChange={this.hadleForm}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </LightTooltip>
                              </FormControl>
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12}>
                              <Button
                                style={{
                                  backgroundColor: "#4caf50",
                                  color: "white",
                                  marginTop: "12px",
                                }}
                                onClick={this.formTimePe}
                                color="default"
                              >
                                Salvar
                              </Button>
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                      {peqExpedientes.map((prop, key) => {
                        return (
                          <Tribuna
                            sendMessage={this.sendMessage.bind(this)}
                            key={key}
                            tribuna={prop}
                            part={sessionPeqexpediente}
                          />
                        );
                      })}
                    </GridItem>
                  </GridContainer>
                </div>
                <span
                  className="material-icons"
                  style={{ color: "#4caf50" }}
                  onClick={this.addTribuna}
                >
                  library_add
                </span>
              </GridItem>
            ) : (
              ""
            )}
            {tribuna === 1 ? (
              <GridItem xs={12} sm={12} md={6}>
                <SnackbarContent
                  style={{ marginBottom: "1px" }}
                  message={"Configuração de Tempo"}
                />
                <div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Card
                        style={{
                          boxShadow:
                            "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
                        }}
                      >
                        <CardBody>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                              <InputLabel style={{ fontSize: "12px" }}>
                                {"Total do Expediente "}
                              </InputLabel>
                              <FormControl className={classes.form}>
                                <Input
                                  type="time"
                                  name="time_expediente"
                                  value={time_expediente}
                                  onChange={this.hadleForm}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <InputLabel style={{ fontSize: "12px" }}>
                                {"Aparte"}
                              </InputLabel>
                              <FormControl className={classes.form}>
                                <Input
                                  name="aparte_expediente"
                                  type="time"
                                  value={aparte_expediente}
                                  onChange={this.hadleForm}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <InputLabel style={{ fontSize: "12px" }}>
                                {"Questão de Ordem"}
                              </InputLabel>
                              <FormControl className={classes.form}>
                                <Input
                                  name="ordem_expediente"
                                  type="time"
                                  value={ordem_expediente}
                                  onChange={this.hadleForm}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <FormControl>
                                <LightTooltip title="Numero de inscritos que podem falar na tribuna">
                                  <TextField
                                    style={{ marginTop: "12px" }}
                                    id="standard-number"
                                    label="Inscrições tribuna"
                                    type="number"
                                    name="limit_expediente"
                                    value={limit_expediente}
                                    onChange={this.hadleForm}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </LightTooltip>
                              </FormControl>
                              <Button
                                style={{
                                  backgroundColor: "#4caf50",
                                  color: "white",
                                  marginTop: "12px",
                                }}
                                onClick={this.formTimeEx}
                                color="default"
                              >
                                Salvar
                              </Button>
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                      {expediente.map((prop, key) => {
                        return (
                          <Tribuna
                            key={key}
                            tribuna={prop}
                            sendMessage={this.sendMessage.bind(this)}
                            part={sessionexpediente}
                          />
                        );
                      })}
                    </GridItem>
                  </GridContainer>
                </div>
                <span
                  className="material-icons"
                  style={{ color: "#4caf50" }}
                  onClick={this.addTribuna}
                >
                  library_add
                </span>
              </GridItem>
            ) : (
              ""
            )}
            {formemail === 1 ? (
              <GridItem xs={12} sm={12} md={6}>
                <SnackbarContent
                  style={{ marginBottom: "8px", color: "green" }}
                  message={"Configuração Email"}
                />

                <Email email={email} />
              </GridItem>
            ) : (
              ""
            )}
            {legislativo === 1 ? (
              <GridItem xs={12} sm={12} md={6}>
                <SnackbarContent
                  style={{ marginBottom: "1px" }}
                  message={
                    <div>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={sistema_legislativo}
                            onChange={this.handleChangeToggle}
                            name="sistema_legislativo"
                            color="primary"
                            value="dynamic-class-name"
                          />
                        }
                        label="Habilitar sistema legislativo"
                      />
                    </div>
                  }
                />
              </GridItem>
            ) : (
              ""
            )}
            {gerais === 1 ? (
              <GridItem xs={12} sm={12} md={6}>
                <SnackbarContent
                  style={{ marginBottom: "1px" }}
                  message={"Configuração Gerais"}
                />
                <Gerais
                  settingsValues={settingsValues}
                  automation={automation}
                  logoCliente={logoCliente}
                  color_message={color_message}
                  sendMessage={this.sendMessage.bind(this)}
                  handleSettings={this.handleSettings.bind(this)}
                  configPlenary={configPlenary}
                />
              </GridItem>
            ) : (
              ""
            )}
            {automacao === 1 ? (
              <GridItem xs={12} sm={12} md={6}>
                <SnackbarContent
                  style={{ marginBottom: "1px" }}
                  message={"Automação"}
                />
                <div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Automacao
                        settingsValues={settingsValues}
                        handleSettings={this.handleSettings.bind(this)}
                        color_message={color_message}
                        sendMessage={this.sendMessage.bind(this)}
                      />
                    </GridItem>
                  </GridContainer>
                </div>
              </GridItem>
            ) : (
              ""
            )}
            {/*microfones === 1 ? (
              <GridItem xs={12} sm={12} md={6}>
                <SnackbarContent
                  style={{ marginBottom: "1px" }}
                  message={"Microfones"}
                />
                <div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      {micros.map((prop) => {
                        return (
                          <Microfone
                            key={prop.id}
                            sendMessage={this.sendMessage.bind(this)}
                            micro={prop}
                          />
                        );
                      })}
                    </GridItem>
                  </GridContainer>
                </div>
                <span
                  className="material-icons"
                  style={{ color: "#4caf50" }}
                  onClick={this.addMicro}
                >
                  library_add
                </span>
              </GridItem>
            ) : (
              ""
            )*/}
            {typedocument === 1 ? (
              <GridItem xs={12} sm={12} md={6}>
                <SnackbarContent
                  style={{ marginBottom: "1px" }}
                  message={"Tipos de Documento"}
                />
                <TypeDocument automation={automation} />
              </GridItem>
            ) : (
              ""
            )}

            {customizeoffice === 1 ? (
              <GridItem xs={12} sm={12} md={6}>
                <SnackbarContent
                  style={{ marginBottom: "1px" }}
                  message={"Adicionar e customizar cargo"}
                />
                <CustomCargo automation={automation} />
              </GridItem>
            ) : (
              ""
            )}
            {legislatura === 1 ? (
              <GridItem xs={12} sm={12} md={6}>
                <SnackbarContent
                  style={{ marginBottom: "1px" }}
                  message={"Adicionar e customizar Legislatura"}
                />
                <Legislatura legislaturas={legislaturas} />
              </GridItem>
            ) : (
              ""
            )}
            {discution === 1 ? (
              <GridItem xs={12} sm={12} md={6}>
                <SnackbarContent
                  style={{ marginBottom: "1px" }}
                  message={"Configuração de Tempo"}
                />
                <div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Card
                        style={{
                          boxShadow:
                            "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
                        }}
                      >
                        <CardBody>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                              <InputLabel style={{ fontSize: "12px" }}>
                                {"Tempo de fala de Discussão "}
                              </InputLabel>
                              <FormControl className={classes.form}>
                                <Input
                                  name="time_discution"
                                  type="time"
                                  value={time_discution}
                                  onChange={this.hadleForm}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <InputLabel style={{ fontSize: "12px" }}>
                                {"Aparte"}
                              </InputLabel>
                              <FormControl className={classes.form}>
                                <Input
                                  name="aparte_discution"
                                  type="time"
                                  value={aparte_discution}
                                  onChange={this.hadleForm}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <InputLabel style={{ fontSize: "12px" }}>
                                {"Questão de Ordem"}
                              </InputLabel>
                              <FormControl className={classes.form}>
                                <Input
                                  name="ordem_discution"
                                  type="time"
                                  value={ordem_discution}
                                  onChange={this.hadleForm}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                              <span
                                className={"material-icons"}
                                style={{ color: "#4caf50", marginTop: "12px" }}
                                onClick={this.formTimeDiscution}
                              >
                                check_circle
                              </span>
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </div>
              </GridItem>
            ) : (
              ""
            )}
            {videowall === 1 ? (
              <GridItem xs={12} sm={12} md={6}>
                <SnackbarContent
                  style={{ marginBottom: "1px" }}
                  message={"Video Wall"}
                />
                <Card
                  style={{
                    boxShadow:
                      "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
                  }}
                >
                  <CardBody>
                    <GridContainer display="flex" alignItems="center">
                      <GridItem sm={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={enable_ementa ? true : false}
                              onChange={this.handleChangeToggle}
                              name="enable_ementa"
                              color="primary"
                              value="dynamic-class-name"
                            />
                          }
                          label="Habilitar Ementa da votação."
                        />
                      </GridItem>
                      <GridItem sm={6}>
                        <InputLabel style={{ fontSize: "12px" }}>
                          {"Tempo de Ementa"}
                        </InputLabel>
                      </GridItem>
                      <GridItem sm={6}>
                        <FormControl className={classes.form}>
                          <Input
                            name="time_ementa"
                            type="time"
                            value={time_ementa}
                            onChange={this.hadleForm}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
                <Card
                  style={{
                    boxShadow:
                      "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
                  }}
                >
                  <CardBody>
                    <GridContainer display="flex" alignItems="center">
                      <GridItem sm={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={enable_slide ? true : false}
                              onChange={this.handleChangeToggle}
                              name="enable_slide"
                              color="primary"
                              value="dynamic-class-name"
                            />
                          }
                          label="Habilitar Slide na tela de espera."
                        />
                      </GridItem>
                      <GridItem sm={12}>
                        <FormControl className={classes.form}>
                          <UploadImagePreview
                            maxNumber="5"
                            resolutionType="more"
                            resolutionWidth="1024"
                            resolutionHeight="768"
                            value={slide_images}
                            callback={this.setSlideImage}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
                <Button
                  style={{
                    backgroundColor: "#4caf50",
                    color: "white",
                    marginTop: "12px",
                  }}
                  onClick={this.videowallConfig}
                  color="default"
                >
                  Salvar
                </Button>
              </GridItem>
            ) : (
              ""
            )}
            {tipo_comision === 1 ? (
              <GridItem xs={12} sm={12} md={6}>
                <TipoComision />
              </GridItem>
            ) : (
              ""
            )}
          </GridContainer>
        </CardBody>

        <Snackbar
          place="tr"
          color={color_message}
          icon={AddAlert}
          message={message}
          open={open_message}
          closeNotification={() => this.setState({ open_message: false })}
          close
        />
      </Card>
    );
  }
}

const styles = {
  root: {
    background: "red",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

Configuration.propTypes = {
  classes: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
};

export default withStyles(styles)(Configuration);
