import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import api from "../../api/api_v2";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Table from "./Tables/TableComite";
import _ from "lodash";
import Modal from "@material-ui/core/Modal";
import ModalEditor from "./Modal/ModalEditor";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

export default function ModeloDocumento() {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [order, setOrder] = useState();
  const [models, setModels] = useState([]);
  const [alert, setAlert] = useState(false);
  const [setValidation] = useState([]);
  const [edit, setEdit] = useState(null);
  const [id] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [open, setOpen] = useState(false);
  const [types_document, setypesDocumentos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await all();
    };
    fetchData();
  }, []);

  const handleAddTypeDocument = async () => {
    const response = edit == true ? await update() : await create();

    if (_.get(response, "status", null) == 200) {
      setAlertMessage("Status Salvo com Sucesso");

      setAlert(true);

      setTimeout(() => {
        setAlert();
      }, 1000);

      setEdit(false);
      setName("");
      setOrder("");
      await all();

      return;
    }
  };

  const update = async () => {
    return await api
      .put(`/status/update/${id}`, {
        id: id,
        name: name,
        order: order,
      })
      .catch(function(error) {
        if (error.response) {
          setValidation(_.get(error, "response.data.errors", []));
        }
      });
  };

  const create = async () => {
    return await api
      .post(`/status/store`, {
        name: name,
        order: order,
      })
      .catch(function(error) {
        if (error.response) {
          setValidation(_.get(error, "response.data.errors", []));
        }
      });
  };

  const all = async () => {
    await api
      .get("/model-documents")
      .then((response) => setModels(_.get(response, "data", [])));
  };

  const handleDelete = async (e) => {
   // setValidation("");

    const response = await api.post(`/model-documents/delete/${e.id}`,
      {
        id:e.id,
        name: e.name,
      }
    );

    await all();

    let message = _.get(
      response,
      "data.success",
      "Não foi possivel excluir status"
    );

    setAlertMessage(message);

    setAlert(true);

    setTimeout(() => {
      setAlert();
    }, 1000);
  };

  const handleEdit = async (e) => {
    setEdit(e);
    await api
      .get("/type-proposition")
      .then((response) => setypesDocumentos(_.get(response, "data", [])));
    setOpen(true);
  };

  const handleOpen = async () => {
    await api
      .get("/type-proposition")
      .then((response) => setypesDocumentos(_.get(response, "data", [])));
    setOpen(true);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card
            style={{
              boxShadow:
                "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
            }}
          >
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Adicionar Modelo</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <Button onClick={handleOpen} color="default">
                    NOVO
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button onClick={handleAddTypeDocument} color="success">
                Salvar
              </Button>
            </CardFooter>
            <Snackbar
              place="tr"
              color="success"
              icon={AddAlert}
              message={alertMessage}
              open={alert}
              closeNotification={() => setAlert(false)}
              close
            />
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {models != "" ? (
            <Table
              tableHeaderColor="primary"
              tableHead={["Nome", "Ordem Sequencial do processo", "", ""]}
              tableData={models}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          ) : (
            ""
          )}
        </GridItem>
      </GridContainer>
      <Modal
        className={classes.modal}
        open={open}
        onClose={() => (setOpen(false), setEdit(null))}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <ModalEditor
            edit={edit}
            types_document={types_document}
            handleClick={async () => (
              setOpen(false), setEdit(null), await all()
            )}
          />
        </Fade>
      </Modal>
    </>
  );
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
  },
};

const useStyles = makeStyles(styles);
