import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { RequestComment } from "api/_tribuna.js";
import List from "./List.js";
import Button from "@material-ui/core/Button";
//import { apiSendTribuna, apiStopTribuna, apiFinishInscription } from "api/tribuna.js";
import { checkStartSession } from "api/_tribuna.js";
//import { apiAddTime } from "api/timeapp.js";
import { ControlTime, FinishInscription, OpenTribuna, CloseTribuna } from "api/_tribuna.js";
import echo from "api/channels";
import _ from "lodash";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import SettingsVoiceIcon from "@material-ui/icons/SettingsVoice";
import TextField from "@material-ui/core/TextField";
//import api from "../../../api/typedocument";
import "../../../assets/sass/components/president/tribuna-index.scss";
import "../../../assets/sass/styles.scss";
import { Subscribe, Unsubscribe } from "api/_tribuna.js";

class ControllerTimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      select_inscricao: null,
      selected_tribuna: "",
      usersTribuna: null,
      tribuna_open: null,
      user: JSON.parse(sessionStorage.USER),
      pause: false,
      disable_conceder: false,
      inscription: false,
      discution_open: null,
      discution: "",
      implement: null,
      cont: 0,
      buttonEnableConcluir: false,
      disabled: true,
      textcont: "00:00:00",
    };
    this.hadleTribuna = this.hadleTribuna.bind(this);
    this.sendTribuna = this.sendTribuna.bind(this);
    this.hadleDesabledInscription = this.hadleDesabledInscription.bind(this);
  }

  autostop() {
    //api.get("automation/first/off");
  }

  autostart() {
    //api.get("automation/first/on");
  }

  async componentDidMount() {
    this.realoadUsers();
    echo.channel("tribuna").listen("StopTribuna", () => {
      this.setState({ disable_conceder: false });
      localStorage.setItem("disable_conceder", false);
      this.realoadUsers();
    });

    echo.channel("tribuna").listen("TalkTimeStop", () => {
      this.setState({ disable_conceder: false });
      localStorage.setItem("disable_conceder", false);
      this.realoadUsers();
      this.setState({ implement: null });
    });

    echo.channel("tribuna").listen("TalkTimeImplementStop", (data) => {
      this.isPaused(data);
    });

    //canal que avisa o proximo tempo de fala
    echo.channel("tribuna").listen("TalkTime", (e) => {
      const { user } = this.state;
      if (e.id_user === user.id) {
        this.setState({ implement: null });
        return;
      }
      this.setState({ implement: false });
    });

    echo.channel("tribuna").listen("TimeApp", (data) => {
      this.isPaused(data);
    });

    echo.channel("session").listen("StartSessionTribuna", (e) => {
      if (e.id == null || e.id == undefined) {
        this.setState({ select_inscricao: null });
      }
      this.setState({ select_inscricao: e });
    });

    echo.channel("tribuna").listen("GetTribunas", (e) => {
      this.setState({ select_inscricao: e });
    });

    echo.channel("getUsersTimeApp").listen("AddSubscription", (e) => {
      this.setState({ usersTribuna: _.get(e, "emEspera", null) });
    });

    echo.channel("tribuna").listen("getUsersSubscription", (e) => {
      this.setState({ usersTribuna: e.emEspera });
    });

    echo.channel("subscription").listen("StopSubscription", () => {
      this.setState({ tribuna_open: null });
      this.setState({ implement: null });
    });

    echo.channel("subscription").listen("CloseDiscution", () => {
      this.setState({ tribuna_open: null });
      this.setState({ implement: null });
    });

    echo.channel("subscription").listen("OpenSubscription", (e) => {
      this.setState({ inscription: true });
      this.setState({ tribuna_open: e });
    });

    echo.channel("tribuna").listen("closeSubscriptions", (e) => {
      this.setState({ inscription: false });
    });
  }

  async realoadUsers() {
    const data = await checkStartSession();

    this.isPaused(data);

    if (data.tribuna_open) {
      this.setState({ selected_tribuna: data.tribuna_open.id });
    }

    if (data.emFala && data.emFala.length) {
      this.setState({ disable_conceder: true });
    }

    this.setState({
      select_inscricao: _.get(data, "tribunas", null),
      usersTribuna: _.get(data, "emEspera", null),
      tribuna_open: _.get(data, "tribuna_open", null),
      inscription: _.get(data, "tribuna_open.inscription", false),
      discution_open: _.get(data, "discution", null),
      discution: _.get(data, "discution.name", ""),
    });
  }

  isPaused(data) {
    const pause_time = _.get(data, ["emFala", 0, "time_app", 0, "pause_time"], null);

    if (pause_time) {
      this.setState({ pause: true });
    }
  }

  hadleTribuna(event) {
    this.setState({ selected_tribuna: event.target.value });
  }

  sendTribuna() {
    //apiSendTribuna(this.state.selected_tribuna);
    OpenTribuna(this.state.selected_tribuna);
    this.setState({ disable_conceder: false });
    localStorage.setItem("disable_conceder", false);
  }
  closeTribuna() {
    //apiStopTribuna();
    CloseTribuna();
  }

  hadleDesabled() {
    this.setState({ disable_conceder: true });
    localStorage.setItem("disable_conceder", true);
  }

  hadleDesabledInscription() {
    const { tribuna_open } = this.state;
    this.setState({ inscription: false });
    //apiFinishInscription(tribuna_open.id);
    FinishInscription(tribuna_open.id);
  }

  addTime(operation, time) {
    if (operation === "add") {
      //this.autostart();
      this.setState({ cont: 0, textcont: "00:00:00" });
    }
    if (operation === "pause") {
      this.setState({ pause: true });
    }
    if (operation === "retomar" || operation === "finish") {
      this.setState({ pause: false });
    }

    if (operation === "finish") {
      this.autostop();
      this.setState({ disable_conceder: false });
      localStorage.setItem("disable_conceder", false);
    }

    if (operation === "zerar") {
      this.setState({ cont: 0, textcont: "00:00:00" });
      return;
    }
    //apiAddTime(operation, time);

    ControlTime(operation, time);
  }

  implementDisable = (id) => {
    RequestComment(id, "aparte");
    this.setState({ implement: null });
  };

  handleCont(value) {
    const { cont } = this.state;
    let mm = cont + value;
    this.setState({ cont: mm });
    this.setState({ textcont: "00:" + mm + ":00" });
  }

  subscribe = (id) => {
    this.setState({ disabled: false })
    const { selected_tribuna } = this.state;
    Subscribe(id, selected_tribuna);
  };

  unsubscribe = (id) => {
    Unsubscribe(id);
    this.setState({ disabled: true })
  };

  render() {
    const { classes, buttonEnableConcluir, timeLeft } = this.props;
    const { textcont, implement, discution_open, discution, inscription, disable_conceder, select_inscricao, selected_tribuna, usersTribuna, tribuna_open, disabled } = this.state;
    const user = JSON.parse(sessionStorage.getItem("USER"));

    return (
      <div className="controllerTime">
        <Grid container style={{ width: "100%", margin: "0px" }}>
          <Grid item xs={12} sm={12}>
            {select_inscricao == null ? (
              <Paper className={classes.paper}>Aguarde a sessão ser iniciada </Paper>
            ) : _.get(discution_open, "id", null) === null ? (
              <Paper className="controllerTime__textSelect">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <span>Inscrição de tribuna</span>
                  {/* validação para apresentar a contagem da inscrição */}
                  { tribuna_open == null || !timeLeft ? "" : inscription == true ?
                      <span>Tempo de inscrição Restante: {Math.floor(timeLeft / 60)}:{String(timeLeft % 60).padStart(2, '0')}</span>
                      : ""
                  }
                </div>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="outlined-age-native-simple">Tribuna</InputLabel>
                      <Select
                        native
                        value={selected_tribuna}
                        onChange={this.hadleTribuna}
                        label="Age"
                        inputProps={{
                          name: "age",
                          id: "outlined-age-native-simple",
                        }}
                      >
                        <option value=""> </option>
                        {select_inscricao.map((prop, key) => {
                          return (
                            <option key={key} value={prop.id}>
                              {prop.subscription}
                            </option>
                          );
                        })}
                    </Select>
                  </FormControl>

                  {selected_tribuna && tribuna_open == null && (
                    <div className={classes.div_button}>
                      <Button onClick={this.sendTribuna} variant="contained" className="componentButton componentButton--green">
                        Abrir Inscrição
                      </Button>
                    </div>
                  )}
                  
                  <div className={classes.div_button} style={{ display:"block" }}>
                  
                    {tribuna_open ? (
                      <>
                        {!!disabled && 
                        (<Button style={{ marginRight: "5px" }} onClick={() => this.subscribe(user.id)} variant="contained" className="componentButton componentButton--green">
                          INSCREVA-SE
                        </Button>)}

                        {!disabled && 
                        (<Button style={{ marginRight: "5px" }} onClick={() => this.unsubscribe(user.id)} variant="contained" className="componentButton componentButton--gray">
                          CANCELAR
                        </Button>)}
                      </>
                    ) : ("")}

                    { implement == false &&
                      <Button onClick={() => this.implementDisable(user.id)} variant="contained" className="componentButton componentButton--orange">
                        Aparte
                      </Button>
                    }

                    <div style={{ float:"right" }}>
                      {tribuna_open == null ? ("") : inscription == true ? 
                      (
                        <Button onClick={this.hadleDesabledInscription} variant="contained" className="componentButton componentButton--gray">
                          Encerrar Inscrição
                        </Button>
                      ) : (
                        <Button onClick={this.closeTribuna} variant="contained" className="componentButton componentButton--orange">
                          Finalizar {tribuna_open.subscription}
                        </Button>
                      )}
                    </div>
                  </div>
              </Paper>
            ) : (
              <Paper className="controllerTime__Voice">
                <div className="controllerTime__Voice--div">
                  <SettingsVoiceIcon /> Discussão aberta Referente a o Projeto {discution}
                </div>
              </Paper>
            )}
          </Grid>

          <Grid item xs={12}>
            <Paper className="controllerTime__buttonTime">
              <span>Controle de Tempo</span>
              {buttonEnableConcluir === false ? (
                <div className="flexButtons">
                  <Grid item xs={12} lg={7}>
                    <Button variant="contained" onClick={() => this.handleCont(1)} className="componentButton componentButton--green-light">
                      + 1{" "}
                    </Button>
                    <Button variant="contained" onClick={() => this.handleCont(5)} className="componentButton componentButton--green-light">
                      + 5
                    </Button>
                    <Button variant="contained" onClick={() => this.addTime("zerar", "00:00:00")} className="componentButton componentButton--green-light">
                      zerar
                    </Button>
                    <Button variant="contained" onClick={() => this.addTime("finish", "00:00:00")} className="componentButton componentButton--orange">
                      Concluir
                    </Button>
                  </Grid>
                  <Grid item xs={12} lg={5} className="d-flex">
                    {this.state.pause == false ? (
                      <Button variant="contained" onClick={() => this.addTime("pause", "00:00:00")} className="componentButton componentButton--green">
                        Pausar
                      </Button>
                    ) : (
                      <Button variant="contained" onClick={() => this.addTime("retomar", "00:00:00")} className="componentButton componentButton--green">
                        Retomar
                      </Button>
                    )}
                    <div>
                      <TextField
                        id="filled-read-only-input"
                        value={this.state.textcont.substr(3)}
                        variant="outlined"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      <Button variant="contained" onClick={() => this.addTime("add", textcont)} className="componentButton componentButton--green">
                        Enviar
                      </Button>
                    </div>
                  </Grid>{" "}
                </div>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => this.props.handleButtonEnableConcluir("finish", "00:00:00")}
                  className="componentButton componentButton--orange"
                >
                  Concluir Aparte/ Questão de ordem
                </Button>
              )}
            </Paper>
          </Grid>
          {usersTribuna == null || usersTribuna.length == 0 ? (
            ""
          ) : (
            <Grid item xs={12} sm={12}>
              <Paper className="controllerTime__vereadores">
                <span className="controllerTime__vereadores--title">Vereadores Inscritos:</span>
                <GridList cellHeight={160} className="controllerTime__vereadores--list" cols={3}>
                  {usersTribuna.map((prop, key) => (
                    <GridListTile key={key} cols={2 || 1}>
                      <List hadleDesabled={this.hadleDesabled.bind(this)} disabled={disable_conceder} cont={key} name={prop.public_name} id={prop.id} />
                    </GridListTile>
                  ))}
                </GridList>
              </Paper>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

const styles = {
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: "left",
    color: "black",
    display: "flex",
    fontSize: "80%",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "left",
    fontWeight: "bold",
    padding: "66px",
    boxShadow: "none",
  },
  formControl: {
    //margin: '4%',
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: "3%",
  },
  margin: {
    margin: "1%",
  },
  div_button: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "10px",
  },
  gridList: {
    height: 350,
  },
};

ControllerTimer.propTypes = {
  classes: PropTypes.object.isRequired,
  buttonEnableConcluir: PropTypes.bool,
  handleButtonEnableConcluir: PropTypes.func,
};

export default withStyles(styles)(ControllerTimer);
