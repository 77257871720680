import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CardAvatar from "components/Card/CardAvatar.js";
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import avatar from "assets/img/faces/marc.jpg";
import TablePagination from "@material-ui/core/TablePagination";
import Tooltip from "@material-ui/core/Tooltip";
import { ptBR } from "@material-ui/core/locale";
import {
  createMuiTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import HowToRegOutlinedIcon from "@material-ui/icons/HowToRegOutlined";
import PersonAddDisabledOutlinedIcon from "@material-ui/icons/PersonAddDisabledOutlined";
import _ from "lodash";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(6);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {(tableData || [])
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((prop, key) => {
              return (
                <TableRow key={key} className={classes.tableBodyRow}>
                  <TableCell className={classes.tableCell}>
                    <CardAvatar
                      style={{ width: "50px", margin: "0px", height: "50px" }}
                      profile
                    >
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          style={{ width: "50px", height: "50px" }}
                          src={prop.photoUrl || avatar}
                          alt={prop.name}
                        />
                      </a>
                    </CardAvatar>
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ fontSize: "16px" }}
                  >
                    {prop.cpf}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ fontSize: "16px" }}
                  >
                    {prop.name}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ fontSize: "16px" }}
                  >
                    {prop.user_office.name}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ fontSize: "16px" }}
                  >
                    {prop.access_level.name}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <LightTooltip title="Editar usuário">
                      <span
                        className="material-icons"
                        style={{ color: "#43a047" }}
                        onClick={() => props.handleClickOpen(prop)}
                      >
                        create
                      </span>
                    </LightTooltip>
                  </TableCell>
                  <TableCell>
                    {prop.status ? (
                      <LightTooltip title="Usuário ativo">
                        <HowToRegOutlinedIcon style={{ color: "#43a047" }} />
                      </LightTooltip>
                    ) : (
                      <LightTooltip title="Usuário inativo">
                        <PersonAddDisabledOutlinedIcon
                          style={{ color: "red" }}
                        />
                      </LightTooltip>
                    )}

                    {/* {
                    _.get(prop, 'face_recognition[0].id', null) ? <AttachFileIcon style={{ color: '#43a047', marginRight: '3px' }} /> : ''
                  } */}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <LightTooltip title="Eliminar usuário">
                      <span
                          className="material-icons"
                          style={{ color: "red" }}
                          onClick={() => props.handleClickOpenDelete(prop)}
                      >
                        delete_sweep
                      </span>
                    </LightTooltip>
                  </TableCell>
                  <TableCell>
                    {_.get(prop, "face_recognition[0].id", null) ? (
                      <CardAvatar
                        style={{ width: "48px", margin: "0px", height: "48px" }}
                        profile
                      >
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            style={{ width: "48px", height: "48px" }}
                            src={prop.photoUrl || avatar}
                            alt={prop.name}
                          />
                        </a>
                      </CardAvatar>
                    ) : (
                      ""
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <div className={classes.root}>
        <ThemeProvider theme={theme}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={tableData ? tableData.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </ThemeProvider>
      </div>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};
const theme = createMuiTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  ptBR
);
CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  handleClickOpen: PropTypes.func,
};
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);
