import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import api from "../../api/api_v2";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import TableTipoProtocol from "./Tables/TableTipoProtocol";
import { Error } from "../Parties/styles";
import _ from "lodash";
import Modal from "@material-ui/core/Modal";
import ModalStatus from "./Modal/ModalStatus";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

export default function TypeDocument() {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [setQtdade] = useState();
  const [tiposProposituras, setTiposProposituras] = useState([]);
  const [alert, setAlert] = useState(false);
  const [validation, setValidation] = useState([]);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [open, setOpen] = useState(false);
  const [status] = useState([]);
  const [statusSelect, setStatusSelect] = useState([]);
  const [setMultiple] = useState(0);
  const [setType] = useState(null);
  const [color, setColor] = useState("success");
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await api
      .get("/departament")
      .then((response) => setTiposProposituras(_.get(response, "data", [])));
  };
  const handleForm = (e) => {
    setName(e.target.value);
  };

  const handleAddTypeDocument = async () => {
    if (edit == true) {
      await api
        .put("/departaments/update/" + id, {
          name: name,
        })
        .then(function() {
          setAlertMessage("Departamento atualizado com sucesso");
          setColor("success");
          setAlert(true);
          fetchData();
          setTimeout(() => {
            setAlert(false);
          }, 3000);
        })
        .catch(function(error) {
          if (error.response) {
            setValidation(_.get(error, "response.data.errors", []));
          }
        });
      setName("");
      setQtdade(0);
      setMultiple("");
      setType("");
      return;
    }

    await api
      .post("/departaments/store", {
        name: name,
      })
      .then(function() {
        setColor("success");

        setAlert(true);
        setAlertMessage("Departamento criado com sucesso");
        fetchData();

        setTimeout(() => {
          setAlert(false);
        }, 3000);
      })
      .catch(function(error) {
        if (error.response) {
          setValidation(_.get(error, "response.data.errors", []));
        }
      });

    setName("");
  };

  const handleDelete = async (e) => {
    setValidation("");

    await api
      .get(`/departaments/delete/${e.id}`)
      .then(function() {
        setColor("success");

        setAlert(true);
        setAlertMessage("Departamento excluído com sucesso");
        fetchData();

        setTimeout(() => {
          setAlert(false);
        }, 3000);
      })
      .catch(function() {
        setColor("danger");

        setAlertMessage(
          "Não é possivel excluir departamento, pois há usuários adicionados a ele"
        );
        fetchData();
        setAlert(true);

        setTimeout(() => {
          setAlert(false);
        }, 3000);
      });

    await fetchData();
  };

  const handleEdit = async (e) => {
    setId(e.id);
    setName(_.get(e, "name", ""));
    setEdit(true);
  };

  const add = (select) => {
    setStatusSelect(select);
  };

  return (
    <>
      <GridContainer>
        {/*
        <!---

            <GridItem xs={12} sm={12} md={12}>
        <Card
          style={{
            boxShadow:
              "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
          }}
        >
          <CardBody>
           <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <h4>Gerenciar Departamentos</h4>
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <InputLabel className={classes.label}>{"Nome"}</InputLabel>
                <FormControl className={classes.form}>
                  <Input name="name" value={name} onChange={handleForm} />
                  <Error>{_.get(validation, "name", "")}</Error>
                </FormControl>
              </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <Button onClick={handleAddTypeDocument} color="success">
                    Salvar
                  </Button>
                </CardFooter>
                <Snackbar
                  place="tr"
                  color={color}
                  icon={AddAlert}
                  message={alertMessage}
                  open={alert}
                  closeNotification={() => setAlert(false)}
                  close
                />
              </Card>
            </GridItem>! -->
          */}
        <GridItem xs={12} sm={12} md={12}>
          {tiposProposituras != "" ? (
            <TableTipoProtocol
              tableHeaderColor="primary"
              tableHead={["Nome", ""]}
              tableData={tiposProposituras}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          ) : (
            ""
          )}
        </GridItem>
      </GridContainer>
      <Modal
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <ModalStatus
            status={status}
            status_select={statusSelect}
            handleClick={() => setOpen(false)}
            add={add}
          />
        </Fade>
      </Modal>
    </>
  );
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
  },
};

const useStyles = makeStyles(styles);
