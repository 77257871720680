import React, {useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
//import Radio from "@material-ui/core/Radio";
//import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { Button } from "@material-ui/core";
//import _ from "lodash";
import PropTypes from "prop-types";

export default function Automacao(props) {
    const classes = useStyles();
    const { settingsValues } = props;

    const [automacao, setValues] = React.useState({
        enabled: 0,
        endpoint: 'http://host:port',
        token_campainha: '?s=',
        campainha_autostart: 0,
        microfones: [
            {
                name: 'Mic 1',
                token_on: '?s=8OUAL1',
                token_off: '?s=8OUAD1',
                init_turn_on: 0,
                end_turn_off: 0
            }
        ]
    });

    useEffect(() => {
        let _automacao = { ...automacao };
        
        if (settingsValues.automacao) {
            for (const [key, value] of Object.entries(settingsValues.automacao)) {
                _automacao[key] = value;
                setValues(_automacao);
            }
        }
    }, []);

    const microfone = {
        name: 'Mic',
        token_on: '?s=',
        token_off: '?s=',
        init_turn_on: 0,
        end_turn_off: 0
    }

    const handleValues = async (e) => {
        let key = e.target.dataset.key || null;
        let index = e.target.dataset.index || null;
        let values = { ...automacao };
        
        switch (key) {
            case "microfones":
                values[key][index][e.target.name] = e.target.value;
                break;
            default:
                values[e.target.name] = e.target.value;
        }
        
        setValues(values);
    }

    const handleEnable = async (e) => {
        let key = e.target.dataset.key || null;
        let index = e.target.dataset.index || null;
        let values = { ...automacao };
        
        switch (key) {
            case "microfones":
                values[key][index][e.target.name] = !values[key][index][e.target.name];
                break;
            default:
                values[e.target.name] = !values[e.target.name];
        }
        
        setValues(values);
    }

    const addMicrofone = async () => {
        const values = { ...automacao };
        values.microfones.push(microfone);
        setValues(values);
    }

    const deleteMicrofone = async (key) => {
        const values = { ...automacao };
        values.microfones.splice(key, 1);
        setValues(values);
    }

    const updateSettings = async () => {
        props.handleSettings('automacao', automacao);
    } 

    return (
        <div>
            <Card style={{boxShadow:"0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",}}>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <FormControlLabel
                                label="Habilitar Automação"
                                control={
                                <Switch
                                    checked={automacao.enabled === true}
                                    name="enabled"
                                    onChange={handleEnable}
                                    color="primary"
                                    value={automacao.enabled}
                                />
                                }
                            />
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
            <Card style={{boxShadow:"0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",}}>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6} style={{ marginBottom: '30px' }}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}><h4>Ponto de Acesso</h4></GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <FormControl className={classes.form} fullWidth={true}>
                                        <InputLabel className={classes.label}>{"URL"}</InputLabel>
                                        <Input 
                                            fullWidth={true}
                                            name="endpoint"
                                            value={automacao.endpoint} 
                                            onChange={handleValues}
                                        />
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}><h4>Campainha</h4></GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <FormControl className={classes.form} fullWidth={true}>
                                        <InputLabel className={classes.label}>{"TOKEN"}</InputLabel>
                                        <Input 
                                            fullWidth={true}
                                            name="token_campainha"
                                            value={automacao.token_campainha} 
                                            onChange={handleValues}
                                        />
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <FormControlLabel
                                label="Tocar campainha automaticamente após término do tempo"
                                control={
                                <Switch
                                    checked={automacao.campainha_autostart === true}
                                    name="campainha_autostart"
                                    onChange={handleEnable}
                                    color="secondary"
                                    value={automacao.campainha_autostart}
                                />
                                }
                            />
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
            {automacao.microfones.map((prop, key) => {
                return (
                    <Card key={key} style={{ boxShadow: "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)", }}>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}><h4>Microfone { key + 1}</h4></GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <FormControl className={classes.form} fullWidth={true}>
                                                <InputLabel className={classes.label}>{"Nome"}</InputLabel>
                                                <Input
                                                    fullWidth={true}
                                                    name="name"
                                                    value={prop.name}
                                                    onChange={handleValues}
                                                    inputProps={{'data-key': 'microfones', 'data-index': key}}
                                                />
                                            </FormControl>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <FormControl className={classes.form} fullWidth={true}>
                                                <InputLabel className={classes.label}>{"ON TOKEN"}</InputLabel>
                                                <Input
                                                    fullWidth={true}
                                                    name="token_on"
                                                    value={prop.token_on}
                                                    onChange={handleValues}
                                                    inputProps={{'data-key': 'microfones', 'data-index': key}}
                                                />
                                            </FormControl>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <FormControl className={classes.form} fullWidth={true}>
                                                <InputLabel className={classes.label}>{"OFF TOKEN"}</InputLabel>
                                                <Input
                                                    fullWidth={true}
                                                    name="token_off"
                                                    value={prop.token_off}
                                                    onChange={handleValues}
                                                    inputProps={{'data-key': 'microfones', 'data-index': key}}
                                                />
                                            </FormControl>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
                                            <FormControlLabel
                                                label="Ligar microfone ao iniciar tempo de fala"
                                                control={
                                                <Switch
                                                    checked={prop.init_turn_on === true}
                                                    name="init_turn_on"
                                                    onChange={handleEnable}
                                                    color="secondary"
                                                    value={prop.init_turn_on}
                                                    inputProps={{'data-key': 'microfones', 'data-index': key}}
                                                />
                                                }
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
                                            <FormControlLabel
                                                label="Cortar microfone após término do tempo"
                                                control={
                                                <Switch
                                                    checked={prop.end_turn_off === true}
                                                    name="end_turn_off"
                                                    onChange={handleEnable}
                                                    color="secondary"
                                                    value={prop.end_turn_off}
                                                    inputProps={{'data-key': 'microfones', 'data-index': key}}
                                                />
                                                }
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <span></span>
                            <span
                                className="material-icons"
                                style={{ color: "red" }}
                                onClick={() => deleteMicrofone(key)}
                            >
                                delete_sweep
                            </span>
                        </CardFooter>
                    </Card>
                );
            })}
            <span
                className="material-icons"
                style={{ color: "#4caf50" }}
                onClick={addMicrofone}
            >
                library_add
            </span>
            <GridContainer>
                <GridItem sm={12}>
                    <Button
                        style={{
                        backgroundColor: "#4caf50",
                        color: "white",
                        marginTop: "12px",
                        }}
                        color="default"
                        onClick={updateSettings}
                    >
                        Salvar
                  </Button>
                </GridItem>
            </GridContainer>
        </div>
    )
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
  },
};

Automacao.propTypes = {
  settingsValues: PropTypes.object.isRequired,
  sendMessage: PropTypes.func,
  handleSettings: PropTypes.func.isRequired,
};

const useStyles = makeStyles(styles);