import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import avatar from "assets/img/faces/marc.jpg";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import CardAvatar from "components/Card/CardAvatar.js";

class Presence extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, users, color, classPresense } = this.props;
    return (
      <List className={[classes.root, classPresense]}>
        {users == null
          ? ""
          : users.map((prop, key) => (
              <ListItem
                key={key}
                style={{ padding: "0px", marginTop: "12px" }}
                alignItems="flex-start"
              >
                <CardAvatar
                  style={{ width: "50px", margin: "0px", height: "50px" }}
                  profile
                >
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      style={{ width: "50px", height: "50px" }}
                      src={prop.photoUrl || avatar}
                      alt={prop.name}
                    />
                  </a>
                </CardAvatar>
                <ListItemText
                  style={{ marginLeft: "16px" }}
                  primary={
                    <p className={classes.cargo}>
                      {prop.user_office.name}
                      <Brightness1Icon
                        style={{
                          width: "12px",
                          marginLeft: "4px",
                          color: color,
                        }}
                      />
                    </p>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {prop.public_name}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            ))}
      </List>
    );
  }
}

var resizing = window.innerWidth;

const styles = {
  root: {
    width: "100%",
    padding: "20px",
    marginTop: "20px",
    display: "grid",
    gridTemplateColumns: resizing > 1600 ? "auto auto" : "auto",
    // backgroundColor: theme.palette.background.paper,
  },
  cargo: {
    fontSize: "12px",
    margin: "0px",
    display: "flex",
    alignItems: "center",
  },
  inline: {
    display: "inline",
    fontSize: "12px",
  },
};

Presence.propTypes = {
  classes: PropTypes.object.isRequired,
  users: PropTypes.array,
  color: PropTypes.string,
  classPresense: PropTypes
};

export default withStyles(styles)(Presence);
