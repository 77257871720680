import React from "react";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import Table from "@material-ui/core/Table";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Fade from "@material-ui/core/Fade";
import TablePagination from "@material-ui/core/TablePagination";
import Modal from "@material-ui/core/Modal";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Backdrop from "@material-ui/core/Backdrop";
import TableCell from "@material-ui/core/TableCell";
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import Button from "@material-ui/core/Button";
import _ from "lodash";
import Tooltip from "@material-ui/core/Tooltip";
import { apiFinishVoting, apiVote } from "api/voting";
import { apiStopSession } from "api/sessions";
import Pdf from "components/Util/pdf";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles();
  const { 
    tableHead, 
    tableHeaderColor, 
    documentoStartVoting, 
    documentosLeitura, 
    documentosVotacao, 
    parlamentares,
    votingManual,
    selectAll,
    voteUsers,
    check_true,
    check_false,
    votingBlocoSelect } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [modalStartVoting, setModalStartVoting] = React.useState(false);
  const [modalFinishVoting, setModalFinishVoting] = React.useState(false);
  const [modalFinishSession, setModalFinishSession] = React.useState(false);
  const [documentVoting, setDocumentVoting] = React.useState(null);
  const [value, setValue] = React.useState(0);
  const [modal, setModal] = React.useState(false);
  const [pdf, setPdf] = React.useState(null);
  const [pdfExecutivo, setPdfExecutivo] = React.useState(null);
  const [img, setImg] = React.useState(false);

  //Open Modal view Pdf api2
  const handleOpenExecutivo = (pdfExecutivo) => {
    if (pdfExecutivo.indexOf("localhost") >= 0) {
      pdfExecutivo = pdfExecutivo.replace("/api/", "");
    } else {
      pdfExecutivo = pdfExecutivo.replace("/api2/api/", "/api2/");
    }
    pdfExecutivo = pdfExecutivo.replace("public", "") + "#toolbar=0&view=FitH";
    setPdfExecutivo(pdfExecutivo);
    setModal(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleStartVoting = (document) => {
    setDocumentVoting(document);
    setModalStartVoting(true);
  }

  //Open Modal view Pdf
  const handleOpen = (pdf, img_click) => {
    if (pdf.indexOf(".pdf") >= 0) {
      handleOpenExecutivo(pdf);
    } else {
      setPdfExecutivo(null);
      if (img_click) {
        setPdf(pdf);
        setImg(true);
        setModal(true);
      } else {
        setPdf(pdf);
        setImg(false);
        setModal(true);
      }
    }
  };

  //Change tabs
  const handleChange = (event) => {
    setValue(event);
  };

  //Finalizar Votação
  const finishVoting = async (startVoting) => {
    await apiFinishVoting(startVoting.id, startVoting.name)
  }

  //Finalizar Sessão
  const finishSession = async () => {
    await apiStopSession()
  }
  
  return (
    <div className={classes.tableResponsive}>
      
      <div>
        <Tabs indicatorColor="primary" value={value} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
          <Tab label="Leitura" style={{ display: "block" }} onClick={() => handleChange(0)} value={0} />
          <Tab label="Votação" style={{ display: "block" }} onClick={() => handleChange(1)} value={1} />

              {   _.get(voteUsers, 'result', null) == 0 && value == 1?
                    <p className="resultClass__reprovado" style={{ padding: 0 }}>REPROVADO</p>
                : _.get(voteUsers, 'result', null) == 1 && value == 1 ?
                    <p className="resultClass__aprovado" style={{ padding: 0 }}>APROVADO</p>
                : ""  
              }

              { votingManual != null ? "" :
                <div style={{ display: "flex", justifyContent: "end", width:"100%", }}>
                    <Button variant="outlined" className="componentButton componentButton--orange" onClick={() => setModalFinishSession(true)}>
                        Finalizar Sessão 
                    </Button>
                </div>
              }
        </Tabs>
      </div>
    
      
      <Table className={classes.table}>
        {tableHead !== undefined && votingManual == null ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell style={{padding:0}} className={classes.tableCell + " " + classes.tableHeadCell} key={key}>
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}

        {value == 1 ? // **************** ABA VOTAÇÃO ***************************
          votingManual == null ? // Valida se tem Votação iniciada
          <TableBody>
            <TableRow style={{ backgroundColor: "#f3f3f3"}}>
                <TableCell style={{ padding: 0 }} className={classes.tableCell}>
                    <Checkbox onClick={() => props.handleAllChecked()} disabled={props.votacao_checkedall_disabled} checked={props.votacao_checkedall} style={{ color: "#40ad67" }} />
                    Selecionar todos
                </TableCell>

                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
            </TableRow>

            {(documentosVotacao || []).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((prop, key) => {
                return (
                  <TableRow key={key} className={classes.tableBodyRow} name={prop.id}>

                    <TableCell style={{ padding: 0 }} className={classes.tableCell}>
                      {prop.voted !== true ? (
                          votingBlocoSelect.find((element) => element == prop.id) ? (
                                <Checkbox checked={true} style={{ color: "#40ad67" }} onClick={() => props.handleVotingBloco(prop.id, prop.id_type)} />
                              ) : (
                                <Checkbox checked={false} style={{ color: "#40ad67" }} onClick={() => props.handleVotingBloco(prop.id, prop.type_voting)} />
                              )
                          ) :
                        <Checkbox disabled={true} checked={true}/>
                      }
                      {prop.name} 
                    </TableCell>

                    <TableCell  style={{ padding: 0 }}  className={classes.tableCell}>
                      {prop.id_part == 1 ? "Peq Expediente" : prop.id_part == 2 ? "Expediente" : "Ordem do Dia"}
                    </TableCell>
                    
                    { _.get(prop, "pdf", null) ? (
                      <TableCell style={{ padding: 0 }}>
                        <LightTooltip title="Visualizar">
                          <span className="material-icons" onClick={() => handleOpen(prop.pdfUrl, false)}>
                            visibility
                          </span>
                        </LightTooltip>
                      </TableCell>
                    ):(
                      <TableCell></TableCell>
                    )}

                    <TableCell style={{ padding: 0 }}>
                      {  
                        (_.get(prop, 'document_session_register[0].approved', null) == 1 && _.get(prop, 'id_type', null) == 5) ?
                          <Button variant="outlined" disabled={true} className={'componentButton componentButton--green-light'}>
                            Veto Acatado
                          </Button>

                        : (_.get(prop, 'document_session_register[0].approved', null) == 0 && _.get(prop, 'id_type', null) == 5) ?
                          <Button variant="outlined" disabled={true} className={'componentButton componentButton--red-light'}>
                            Veto Rejeitado
                          </Button>

                        : _.get(prop, 'document_session_register[0].approved', null) == 1 ?
                            <Button variant="outlined" disabled={true} className={'componentButton componentButton--green-light'}>
                              Aprovado
                            </Button>

                        : _.get(prop, 'document_session_register[0].approved', null) == 0 ?
                            <Button variant="outlined" disabled={true} className={'componentButton componentButton--red-light'}>
                              Reprovado
                            </Button>

                        : votingBlocoSelect.length > 1 ? 
                            <Button disabled={true} variant="outlined" className="componentButton componentButton--grey">
                              Iniciar Votação
                            </Button>
                        : 
                          <Button variant="outlined" className="componentButton componentButton--green" onClick={() => handleStartVoting(prop)}>
                            Iniciar Votação
                          </Button>    
                      }
                    </TableCell>
                  </TableRow>
                );
            })}

              { votingBlocoSelect.length > 1 ? (
                <TableRow   className={classes.tableBodyRow}>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell style={{ padding: 0 }}>
                    <Button variant="contained" onClick={() => setModalStartVoting(true)} className="componentButton componentButton--green">
                      INICIAR VOTAÇÃO EM BLOCO
                    </Button>
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
                <TableCell colSpan={12} style={{ padding: 0 }}>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      backIconButtonText={"Anterior"}
                      nextIconButtonText={"Proxima"}
                      labelDisplayedRows={({ from, to, count }) => `Itens por página ${from}-${to} de ${count} páginas`}
                      component="div"
                      count={documentosVotacao.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableCell>
          </TableBody>
            //*********************************************************************************************************************************************/
            //Se Existe Votação iniciada Apresenta Tela de votação 
            : <TableBody>
                <TableRow style={{ backgroundColor: "#f3f3f3" }}> 

                  {/*************CHECKS SELECT ALL VOTES  **********/}
                    <TableCell className={classes.tableCell} style={{ padding: "0px 20px" }}>
                            Selecionar todos como : SIM
                        <Checkbox checked={check_true} style={{ color: "#40ad67" }}  onClick={() => props.handleCheckAllTrue()} /> 
                            NÃO
                        <Checkbox checked={check_false} style={{ color: "red" }} onClick={() => props.handleCheckAllFalse()} />
                    </TableCell>

                  {/*************CONTAGEM E FINALIZAR VOTAÇÃO **********/}
                    <TableCell style={{ display: "flex", justifyContent: "center", padding: "7px" }} className={classes.tableCell}>
                        <span style={{ padding: "5px 25px 2px 25px", border: "2px solid green", borderRadius: "5px", marginRight: "15px"}}> 
                          {_.get(voteUsers, 'votes_true', 0)}
                        </span>

                            <span style={{ padding: "5px 25px 2px 25px", border: "2px solid red", borderRadius: "5px", marginRight: "15px" }}> 
                              {_.get(voteUsers, 'votes_false', 0)}  
                            </span>

                        <span style={{ padding: "5px 25px 2px 25px", border: "2px solid blue", borderRadius: "5px", marginRight: "15px" }}> 
                          {_.get(voteUsers, 'votes_null', 0)}  
                        </span>
                                
                          <Button style={{ marginRight: "15px" }} variant="outlined" className="componentButton componentButton--orange" onClick={() => setModalFinishVoting(true)}>
                              Finalizar Votação
                          </Button>
                    </TableCell>
                </TableRow>

               {(parlamentares || []).map((prop, key) => {
                  return (
                    <TableRow key={key} className={classes.tableBodyRow} name={prop.id}>
                        <TableCell style={{ paddingLeft: "20px" }} className={classes.tableCell}>
                          {prop.name}
                        </TableCell>

                          {/* Left Botoes de votação */}
                        <TableCell style={{ display: "flex", justifyContent: "center", padding: "6px" }}>

                          <Button 
                              variant="outlined" 
                              className={`componentButton ${selectAll[prop.id] === true ? "componentButton--green" : "componentButton--green-outline"}`} 
                              onClick={() => props.handleSelectAll(prop.id, true, prop)}
                              style={{ padding: "5px 40px 4px 40px", marginRight: "15px" }}>
                            SIM
                          </Button>


                          <Button
                              variant="outlined" 
                              className={`componentButton ${selectAll[prop.id] === false ? "componentButton--red" : "componentButton--red-outline"}`} 
                              // className={"componentButton" + _.get(prop, 'vote[0].vote', undefined) === 0 ? "componentButton--red" : "componentButton--red-outline"} 
                              onClick={() => props.handleSelectAll(prop.id, false, prop)}
                              style={{ padding: "5px 40px 4px 40px", marginRight: "15px" }}>
                            NÃO
                          </Button>


                          <Button 
                              variant="outlined"
                              className={`componentButton ${selectAll[prop.id] === null ? "componentButton--blue" : "componentButton--blue-outline"}`}  
                              // className={"componentButton" + _.get(prop, 'vote[0].vote', undefined) === null ? "componentButton--blue" : "componentButton--blue-outline"}
                              onClick={() => props.handleSelectAll(prop.id, null, prop)}
                              style={{ padding: "5px 40px 4px 40px", marginRight: "15px" }}>
                            ABSTER
                          </Button>

                        </TableCell>
                    </TableRow>
                  );
              })}
            </TableBody>
        : ""}

        {value == 0 ? // **************** ABA LEITURA ***************************
          <TableBody>
            {(documentosLeitura || []).map((prop, key) => {
              return (
                <TableRow
                  key={key}
                  className={classes.tableBodyRow}
                  name={prop.id}
                >
                  <TableCell style={{ padding: 0 }} className={classes.tableCell}>
                    {prop.name}
                  </TableCell>

                  <TableCell style={{ padding: 0 }} className={classes.tableCell}>
                    {prop.id_part == 1 ? "Peq Expediente" : prop.id_part == 2 ? "Expediente" : "Ordem do Dia"}
                  </TableCell>

                  {_.get(prop, "pdf", null) ? ( // Validação de o doc existe PDF
                    <TableCell style={{ padding: 0 }}>
                      <LightTooltip title="Visualizar">
                        <span className="material-icons" onClick={() => handleOpen(prop.pdfUrl, false)} >
                          visibility
                        </span>
                      </LightTooltip>
                    </TableCell>
                  ) : (
                    <TableCell style={{ padding: 0 }}></TableCell>
                  )}

                  <TableCell style={{ padding: 0 }}>
                      {_.get(prop, 'lido', null) == null ? // Validação se o doc foi lido 
                        <Button variant="outlined"className="componentButton componentButton--green"
                          onClick={() => props.changeLeitura(prop.id)}>
                            Marcar como lido
                        </Button>
                      : <Button variant="outlined" style={{ color: "#C0941D" }} disabled={true}>
                            Lido
                        </Button>
                      }
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        : ""}
      </Table>

      <Modal
        // className={classes.modal}
        className="generalModal"
        open={modal}
        onClose={() => setModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <Card>
            <CardHeader color="success" className={classes.cardHeader}>
              <span className="material-icons" onClick={() => setModal(false)}>
                close
              </span>
            </CardHeader>

            <CardBody>
              {pdfExecutivo ? (
                <object
                  data={pdfExecutivo}
                  type="application/pdf"
                  width="100%"
                  height="300"
                />
              ) : img ? (
                <img src={pdf}></img>
              ) : (
                <Pdf height={800} file={pdf} />
              )}
            </CardBody>
          </Card>
        </Fade>
      </Modal>
      
      <Modal //Modal start Votação
        className="generalModal"
        open={modalStartVoting}
        onClose={() => setModalStartVoting(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{timeout: 500,}}
      >
        <Fade in={modalStartVoting}>
          <div className="modalContainer">
            <div className="modalContainer--header">
              <h4>Iniciar votação!</h4>
            </div>

            <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center", paddingBottom: "0" }}>
              <h4>Deseja iniciar a votação?</h4>
            </div>

            <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center", paddingTop: "0" }}>

              {votingBlocoSelect.length > 1 ?  // valida se esta selecionado para votação em bloco
                <button className="componentButton componentButton--green mx-0" 
                  onClick={() => { props.handleSendVotingBlock(); setModalStartVoting(false) }}>
                  Iniciar votação em bloco
                </button>
                :
                <button className="componentButton componentButton--green mx-0"
                  onClick={() => { props.start(documentVoting.id, documentVoting.name); setModalStartVoting(false) }}>
                  Iniciar votação
                </button>
              }

              <button className="componentButton componentButton--gray ml-2" onClick={() => setModalStartVoting(false)} >
                Voltar
              </button>
            </div>
          </div>
        </Fade>
      </Modal>

      <Modal //Modal start Finish Votação
        className="generalModal"
        open={modalFinishVoting}
        onClose={() => setModalFinishVoting(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500, }}
      >
        <Fade in={modalFinishVoting}>
          <div className="modalContainer">
            <div className="modalContainer--header">
              <h4>Finalizar votação!</h4>
            </div>

            <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center", paddingBottom: "0" }}>
              <h4>Deseja finalizar a votação?</h4>
            </div>

            <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center", paddingTop: "0" }}>
              <button className="componentButton componentButton--orange mx-0"
                onClick={() => { finishVoting(documentoStartVoting); setModalFinishVoting(false) }}>
                Finalizar votação
              </button>

              <button className="componentButton componentButton--gray ml-2" onClick={() => setModalFinishVoting(false)} >
                Voltar
              </button>
            </div>
          </div>
        </Fade>
      </Modal>

      <Modal //Modal finish Session
        className="generalModal"
        open={modalFinishSession}
        onClose={() => setModalFinishSession(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500, }}
      >
        <Fade in={modalFinishSession}>
          <div className="modalContainer">
            <div className="modalContainer--header">
              <h4>Finalizar sessão!</h4>
            </div>

            <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center", paddingBottom: "0" }}>
              <h4>Deseja finalizar a sessão?</h4>
            </div>

            <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center", paddingTop: "0" }}>
              <button className="componentButton componentButton--orange mx-0"
                onClick={() => { finishSession(); setModalFinishSession(false) }}>
                Finalizar sessão
              </button>

              <button className="componentButton componentButton--gray ml-2" onClick={() => setModalFinishSession(false)} >
                Voltar
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  DataLeitura: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  desabilitar: PropTypes.func,
  openDocs: PropTypes.func,
  startSession: PropTypes.func,
  dasautorizar: PropTypes.func,
  stopSession: PropTypes.func,
  updated_at: PropTypes.func,
  handleExport: PropTypes.func,
  handleClickOpen: PropTypes.func,
  handleClickDelete: PropTypes.func,
};
