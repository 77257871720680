import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import apiPartidos from "../../api/partidos";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { Error } from "./styles";
import PropTypes from "prop-types";

import _ from "lodash";

export default function ModalPartidos(props) {
  const classes = useStyles();
  const [name, setName] = useState();
  const [sigla, setSigla] = useState();
  const [alert, setAlert] = useState(false);
  const [validation, setValidation] = useState([]);

  const handleForm = e => {
    switch (e.target.name) {
      case "sigla":
        return setSigla(e.target.value);
      case "name":
        return setName(e.target.value);
    }
  };

  const handleAddPartido = async (e) => {
    e.preventDefault();

    let data = await apiPartidos
      .post("partidos", {
        sigla: sigla,
        name: name,
      })
      .catch(function(error) {
        if (error.response) {
          setValidation(_.get(error, "response.data.errors", []));
        }
      });

    if (data == undefined || data == null) {
      return;
    }

    setAlert(true);

    setTimeout(
      function() {
        setAlert();
        props.handleClick();
      }.bind(this),
      1000
    );
  };

  return (
    <>
      <GridContainer style={{ display: "flex", justifyContent: "center" }}>
        <GridItem xs={18} sm={18} md={18}>
          <Card>
            <CardHeader color="success" className={classes.cardHeader}>
              <h4 className={classes.cardTitleWhite}>Cadastro de Partidos</h4>
              <span
                className="material-icons"
                onClick={() => props.handleClick()}
                style={{ cursor: "pointer" }}
              >
                close
              </span>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl className={classes.form}>
                    <InputLabel className={classes.label}>{"Sigla"}</InputLabel>
                    <Input name="sigla" value={sigla} onChange={handleForm} />
                    <Error>{_.get(validation, "sigla", "")}</Error>
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={8}>
                  <FormControl className={classes.form}>
                    <InputLabel className={classes.label}>
                      {"Nome do partido"}
                    </InputLabel>
                    <Input name="name" value={name} onChange={handleForm} />
                    <Error>{_.get(validation, "name", "")}</Error>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter style={{ padding: "10px" }}>
              <Button onClick={handleAddPartido} color="success">
                Cadastrar
              </Button>
            </CardFooter>
            <Snackbar
              place="tr"
              color="success"
              icon={AddAlert}
              message="Cadastrado com sucesso"
              open={alert}
              closeNotification={() => setAlert(false)}
              close
            />
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

const styles = (theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    },
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between"
  },
  label: {
    fontSize: "12px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px"
  },
});

ModalPartidos.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

const useStyles = makeStyles(styles);
