import React from "react";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import Presence from "components/President/List/votingComision";
import echo from "api/channels";
import { apiFinishVotingComision } from "api/comisiones";
import Button from "@material-ui/core/Button";
import "../../assets/sass/components/president/tabright.scss";
import "../../assets/sass/styles.scss";
import {Modal} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

class TabRightCommittee extends React.Component {
  constructor(props) {
    super(props);
    const voting = props.voting;
    this.state = {
      value: 0,
      presentes: _.get(voting, "presentes", null),
      votes_true: _.get(voting, "votes_true", 0),
      votes_false: _.get(voting, "votes_false", 0),
      votes_total: _.get(voting, "total_votos", 0),
      votes_null: _.get(voting, "votes_null", 0),
      result: null,
      modal_finish: false
    };
  }

  async componentDidMount() {
    echo.channel("voting").listen("UserVoteCommitte", (e) => {
      this.setState({ presentes: _.get(e, "presentes", null) });
      this.setState({ votes_true: _.get(e, "votes_true", 0) });
      this.setState({ votes_false: _.get(e, "votes_false", 0) });
      this.setState({ votes_null: _.get(e, "votes_null", 0) });
      this.setState({ votes_total: _.get(e, "total_votos", 0) });

      this.handleResult(e.result, e.abstencao);
      //this.setState({ result: e.result != null ? e.result == true ? 'APROVADO' : 'REPROVADO' : '' })
    });

    echo.channel("votingComision").listen("StartVotingComision", (e) => {
      // this.setState({ presentes: null });
      this.setState({ presentes: _.get(e, "presentes", null) });
      this.setState({ votes_true: 0 });
      this.setState({ votes_false: 0 });
      this.setState({ votes_null: 0 });
      this.setState({ votes_total: 0 });

      this.setState({ result: null });
    });
    this.handleResult(this.props.voting.approved, this.props.voting.abstencao);
  }

  handleResult = (approved, abstain) => {
    if (abstain == true) {
      this.setState({ result: "NÃO HÁ QUÓRUM" });
    } else {
      if (approved != null) {
        this.setState({
          result:approved == true ? (
              <p className="resultClass__aprovado">APROVADO</p>
            ) : (
              <p className="resultClass__reprovado">REPROVADO</p>
            ),
        });
      }
    }
  };

  handleChange = (event) => {
    this.setState({ value: event });
  };

  handleFinish = () => {
    apiFinishVotingComision();
  };
  openModalFinsih= (valueId,valueName) =>{
    this.setState({ modal_finish: true });
  }
  render() {
    const { classes, settingsVotacao /*habilitar_boton_abster*/} = this.props;
    const {
      result,
      votes_total,
      value,
      presentes,
      votes_null,
      votes_false,
      votes_true,
      modal_finish
    } = this.state;

    return (
      <div className={classes.root}>
        {result == null ? "" : <div className={classes.result}>{result}</div>}
        <div className="countVote" position="static" color="default">
          <span className="titleVote">Votação das Comissões</span>
          <span className="countVote--total">
            Total <div className={classes.span}> {votes_total}</div>
          </span>
          <span className="countVote--yes">
            Sim <div className={classes.span}> {votes_true}</div>
          </span>
          <span className="countVote--no">
            Não <div className={classes.span}> {votes_false}</div>
          </span>
          {settingsVotacao && settingsVotacao.enableAbs && (
            <span className="countVote--absteve">
              Absteve <div className={classes.span}> {votes_null}</div>
            </span>
          )}
          {/*
            habilitar_boton_abster.active===1 ?
            <span className="countVote--absteve">
              Absteve <div className={classes.span}> {votes_null}</div>
            </span> : ''
          */}

        </div>
        {value == 0 ? <Presence users={presentes} /> : ""}
        <div className="finishButton">
          <Button
            variant="contained"
            onClick={() => this.openModalFinsih()}
            className="componentButton componentButton--yellow"
          >
            Finalizar Votação das Comissões
          </Button>
        </div>
        <Modal
            className="generalModal"
            open={modal_finish}
            onClose={() =>  this.setState({ modal_finish: false })}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
        >
          <Fade in={modal_finish}>
            <div className="modalContainer">
              <div className="modalContainer--header">
                <h4>Deseja Finalizar Votação? </h4>
              </div>
              <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center" }}>
                <button className="componentButton componentButton--green mx-0" onClick={() => {this.handleFinish(); this.setState({ modal_finish: false })}}>SIM</button>
                <button className="componentButton componentButton--gray ml-2" onClick={() =>  this.setState({ modal_finish: false })}>Voltar</button>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  }
}

const styles = {
  root: {
    flexGrow: 1,
    width: "100%",
  },
  result: {
    display: "flex",
    justifyContent: "center",
    padding: "20px",
  },
  span: {
    padding: "0px",
    borderRadius: "8px",
    color: "#fff",
    fontSize: "25px",
    fontWeight: "bold",
    background: "green",
    width: "35px",
    height: "40px",
    margin: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  item: {
    boxShadow: "1px 5px 14px 0 rgba(46,61,73,.15)",
    borderRadius: "10px",
    display: "flex",
    flexWrap: "wrap",
  },
  container: {
    display: "flex",
    alignItems: "center",
    width: "50%",
  },
};

TabRightCommittee.propTypes = {
  classes: PropTypes.object.isRequired,
  voting: PropTypes.object.isRequired,
  abster: PropTypes.object.isRequired,
  settingsVotacao: PropTypes.object.isRequired,
};

export default withStyles(styles)(TabRightCommittee);
