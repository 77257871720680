import { container } from "assets/jss/material-dashboard-react.js";

const appStyle = () => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    backgroundColor: "#E0E4E4",
    color: "white",
  },
  content: {
    // minHeight: "calc(100vh - 123px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  container,
  map: {
    marginTop: "70px",
  },
  img: {
    width: "135px",
    verticalAlign: "middle",
  },
  sidebar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "whitesmoke",
    height: "70px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default appStyle;
