import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import Button from "@material-ui/core/Button";
import _ from "lodash";
import moment from "moment";
import { Wrapper } from "./styles";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor, sessionStart } = props;

  const [modalStartSession, setModalStartSession] = React.useState(false);
  const [idSession, setIdSession] = React.useState(null);


  const handleStartSession = (id) => {
    setIdSession(id);
    setModalStartSession(true);
  }

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {(tableData || []).map((prop, key) => {
            return (
              <TableRow key={key} className={classes.tableBodyRow} name={prop.id} >
                  <TableCell className={classes.tableCell} style={{padding: "6px"}}>
                    {prop.name}
                  </TableCell>

                  <TableCell className={classes.tableCell} style={{padding: "6px"}}>
                    {prop.type.name}
                  </TableCell>

                  <TableCell className={classes.tableCell} style={{padding: "6px"}}>
                    {moment(prop.updated_at).format("DD/MM/YYYY - HH:mm")} 
                  </TableCell>

                  <TableCell style={{padding: "6px"}}>
                    <Wrapper>
                      {prop.situation ? prop.situation : "Aguardando"}
                    </Wrapper>
                  </TableCell>

                  <TableCell style={{padding: "6px"}}>
                    { _.get(sessionStart, 'id', null) == prop.id ? 
                      <Button variant="outlined"className="componentButton componentButton--green" onClick={() => props.openDocs()}>
                        Documentos da sessão
                      </Button>
                     : _.get(sessionStart, 'id', null) != null ? 
                      <Button disabled={true} variant="outlined"className="componentButton">
                        Iniciar Sessão
                      </Button>
                      :
                      <Button variant="outlined" className="componentButton componentButton--green" onClick={() => handleStartSession(prop.id)}>
                        Iniciar Sessão
                      </Button>
                    }
                  </TableCell>    
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <Modal //Modal Iniciaar Sessão
        className="generalModal"
        open={modalStartSession}
        onClose={() => setModalStartSession(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500, }}
      >
        <Fade in={modalStartSession}>
          <div className="modalContainer">
            <div className="modalContainer--header">
              <h4>Iniciar sessão!</h4>
            </div>

            <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center", paddingBottom: "0" }}>
              <h4>Deseja iniciar a sessão?</h4>
            </div>

            <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center", paddingTop: "0" }}>
              <button className="componentButton componentButton--green mx-0"
                onClick={() => { props.startSession(idSession); setModalStartSession(false) }}>
                Iniciar sessão
              </button>

              <button className="componentButton componentButton--gray ml-2" onClick={() => setModalStartSession(false)} >
                Voltar
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  openDocs: PropTypes.func,
  startSession: PropTypes.func,
};
