import axios from "axios";
var tokenGet =
  sessionStorage.getItem("TOKEN_KEY") &&
  sessionStorage.getItem("TOKEN_KEY").charAt(0) == '"'
    ? sessionStorage.getItem("TOKEN_KEY")
    : JSON.stringify(sessionStorage.getItem("TOKEN_KEY"));
var token = JSON.parse(tokenGet);

function apiOpenTime(id) {
  const user = JSON.parse(sessionStorage.getItem("USER"));
  axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/timeapp/talk",
    data: { id: id ,userId:   user},
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).catch(function(error) {
    Promise.reject(error.response);
  });
}

function apiCloseTime(id) {
  axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/timeapp/close",
    data: { id: id },
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

function apiRejectTime(id) {
    const user = JSON.parse(sessionStorage.getItem("USER"));
  axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/timeapp/reject",
    data: { id: id,userId:user},
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

function apiAddTime(operation, time) {
  const user = JSON.parse(sessionStorage.getItem("USER"));
  axios({
    method: "get",
    data: { userId:user},
    url:
      process.env.REACT_APP_STAGE +
      "/timeapp/controller/" +
      operation +
      "/" +
      time +"/"+user.id,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export { apiOpenTime, apiCloseTime, apiAddTime, apiRejectTime };
