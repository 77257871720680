/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { ptBR } from "@material-ui/core/locale";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import _ from "lodash";
import echo from "api/channels";
import CheckBoxOutlineBlankRoundedIcon from "@material-ui/icons/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import { DialogContent, Modal } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Checkbox from "@material-ui/core/Checkbox";
import "../../../assets/sass/components/president/votacao.scss";
import "../../../assets/sass/styles.scss";
import VotacaoComision from "./votacaoComision";
import { apiStartVotacaoComision, getStatusConfig, getComisionesUser, getCurrentComision } from "api/comisiones";
import { getVotingComision } from "api/voting";
import Fade from "@material-ui/core/Fade";

const columns = [{ id: "name", label: "Selecionar todos os documentos", minWidth: 500 }];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 740,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    textAlign: "center",
    fontSize: "160%",
    fontWeight: "bold",
    borderRadius: "10px",
    width: "800px",
    height: "300px",
  },
  btnVotar: {
    fontSize: "30px",
  },
});

export default function Votacao(props) {
  const { disabled_buttons, enable_discution, user_vote, documentos, votacao, votingBlocoSelect } = props;

  let calls = 0;

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openVotacionComisiones, setOpenVotacionComisiones] = React.useState(false);
  const [doc, setDoc] = React.useState({});
  const [option1, setOption1] = useState(false);
  const [comisionesUser, setComisionesUser] = useState([]);
  const [comision, setComision] = useState({});
  const [modal_discusion_fechar, setOpenModalFecharDiscusion] = useState(false);
  const [modal_discusion, setOpenModalDiscusion] = useState(false);
  const [modal_finish, setOpenModalFinish] = useState(false);
  const [modal_start, setOpenModalStart] = useState(false);
  const [rowById, setRowById] = useState(false);
  const [rowByValue, setRowByValue] = useState(false);
  
  useEffect(() => {
    const fetchData = async () => {
      //GET CONFIG COMISIONES
      setOption1(await getStatusConfig("votacion_session_type_comision"));
      setComisionesUser(await getComisionesUser());
    };
    fetchData();
  }, []);

  useEffect(() => {
    echo.channel("voting").listen("StartVoting", (e) => {
      onStarVotacion(e.id, e.name);
    });
  }, []);

  useEffect(() => {
    echo.channel("voting").listen("StopVoting", () => {
      calls = 0;
    });
  }); 

  useEffect(() => {
    if (comisionesUser) {
      const c = getCurrentComision(comisionesUser);
      setComision(c);
    }
  }, [comisionesUser]);

  const handleVotacionComisiones = (doc) => {
    apiStartVotacaoComision(doc.id, doc.title);
    setDoc(doc);
    // setOpenVotacionComisiones(true); // abre modal de votação automaticamente
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const openModalAbrirDiscusion = (valueId, valueName) => {
    setRowById("");
    setRowByValue("");
    setOpenModalDiscusion(true);
    setRowById(valueId);
    setRowByValue(valueName);
  };
  const openModalFecharDiscusion = (valueId, valueName) => {
    setRowById("");
    setRowByValue("");
    setOpenModalFecharDiscusion(true);
    setRowById(valueId);
    setRowByValue(valueName);
  };
  const openModalFinsih = (valueId, valueName) => {
    calls = 0;
    setRowById("");
    setRowByValue("");
    setOpenModalFinish(true);
    setRowById(valueId);
    setRowByValue(valueName);
  };

  const openModalStart = (valueId, valueName) => {
    setRowById("");
    setRowByValue("");
    setOpenModalStart(true);
    setRowById(valueId);
    setRowByValue(valueName);
  };
  const onStarVotacion = (rowById, rowByValue) => {
    if (calls == 0) {
      props.handlevotacao(rowById, rowByValue);
      setOpenModalStart(false);
      setPage(0);
      calls++;
    }
  };
  return (
    <Paper className="voteTable">
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                  <Checkbox disabled={props.votacao_checkedall_disabled} checked={props.votacao_checkedall} onClick={() => props.handleAllChecked()} />
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="voteTable__body">
            {documentos
              .sort((a, b) => b?.priorityOrder - a?.priorityOrder)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} className="rowTable">
                          <div>
                            {votacao == null && row.voted !== true ? (
                              votingBlocoSelect.find((element) => element == row.id) ? (
                                <CheckBoxRoundedIcon style={{ marginRight: "5px" }} onClick={() => props.handleVotingBloco(row.id, row.id_type)} />
                              ) : (
                                <CheckBoxOutlineBlankRoundedIcon
                                  style={{ marginRight: "5px" }}
                                  onClick={() => props.handleVotingBloco(row.id, row.type_voting)}
                                />
                              )
                            ) : (
                              ""
                            )}

                            {column.format && typeof value == "number" ? column.format(value) : value}
                          </div>
                          <div className="voteTable__body--row">
                            {!row.document_photo[0] ? (
                              ""
                            ) : (
                              <Button
                                variant="outlined"
                                onClick={() => props.handleVisualizar(row.document_photo[0])}
                                className="componentButton componentButton--gray-light"
                              >
                                <i className="fa-solid fa-image"></i>
                              </Button>
                            )}
                            {row.pdfUrl ? (
                              <Button
                                variant="outlined"
                                /*onClick={() => props.handleVisualizar(row)}*/
                                onClick={() => props.handleOpenPdf(row.pdfUrl, value, row.id_type)}
                                className="componentButton componentButton--gray-light"
                              >
                                <i className="animate__animated animate__pulse animate__infinite fa-solid fa-eye"></i>
                              </Button>
                            ) : (
                              ""
                            )}
                            {votacao == null && disabled_buttons == false ? (
                              <>
                                {row.discution || (_.get(enable_discution, "id", "") == row.id && _.get(enable_discution, "status", null) == true) ? (
                                  <Button
                                    variant="outlined"
                                    onClick={() => openModalFecharDiscusion(row.id, value)}
                                    className="componentButton componentButton--orange"
                                  >
                                    Fechar Discussão{" "}
                                  </Button>
                                ) : row.discution == 0 ||
                                  (_.get(enable_discution, "id", "") == row.id && _.get(enable_discution, "status", null) == false) ||
                                  (_.get(enable_discution, "id", null) !== row.id && _.get(enable_discution, "status", null) == true) ||
                                  _.get(row, ["document_session_register", 0, "approved"], null) != null ? (
                                  ""
                                ) : (
                                  <Button
                                    variant="outlined"
                                    onClick={() => openModalAbrirDiscusion(row.id, value)}
                                    className="componentButton componentButton--green-outline"
                                  >
                                    Abrir Discussão
                                  </Button>
                                )}
                                {
                                  (option1 && row.committee && row.finish_comision == false) ? ( // valida se documento tem comissão para apresentar botão de votação
                                    <Button variant="outlined" style={{ paddingInlineStart: "1.32rem" }} className="componentButton componentButton--green" onClick={() => handleVotacionComisiones(row)}>
                                      Votação das Comissões 
                                    </Button>
                                  ) : row.finish_comision == true && option1 ? (
                                    <Button disabled={true} variant="outlined" className="componentButton componentButton--green-light" onClick={() => handleVotacionComisiones(row)}>
                                      Votado pelas Comissões
                                    </Button>
                                  ) : ("")
                                }
                                {row.voted == true && _.get(row, "document_session_register[0].approved", null) != null ? (
                                  <Button
                                    variant="outlined"
                                    disabled={true}
                                    className={`componentButton ${
                                      row.document_session_register[0]?.approved == 0 ? "componentButton--red-light" : "componentButton--green-light"
                                    }`}
                                  >
                                    {row.id_type == 5 ? (
                                      _.get(row, "document_session_register[0].approved", null) == 1 ? (
                                        <> Veto Acatado</>
                                      ) : (
                                        <>Veto Rejeitado</>
                                      )
                                    ) : _.get(row, "document_session_register[0].approved", null) == 0 ? (
                                      <>Reprovado</>
                                    ) : (
                                      <>Aprovado</>
                                    )}
                                  </Button>
                                ) : (
                                  <>
                                    <Button variant="outlined" onClick={() => openModalStart(row.id, value)} className="componentButton componentButton--green">
                                      Iniciar Votação
                                    </Button>{" "}
                                  </>
                                )}
                              </>
                            ) : _.get(votacao, 'id', null) == row.id && _.get(votacao, "document_session_register[0].voting_bloc", 0) == 0 ? (
                              <>
                                <Button variant="contained" onClick={() => openModalFinsih(row.id, value)} className="componentButton componentButton--yellow">
                                  Finalizar Votação
                                </Button>

                                {JSON.parse(sessionStorage.getItem("USER")).id_access_level !== 5 ?  /*Valida se não é controlador */
                                  <Button variant="contained" onClick={() => props.handleOpen()} className="componentButton componentButton--green-outline">
                                    {user_vote == true ? "Mudar Voto" : "Votar"}
                                  </Button>
                                :""}
                              </>
                              ) : (
                                ""
                                )}
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        className="generalModal"
        open={modal_start}
        onClose={() => setOpenModalStart(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal_start}>
          <div className="modalContainer">
            <div className="modalContainer--header">
              <h4>Deseja Iniciar Votação? </h4>
            </div>
            <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="componentButton componentButton--green mx-0"
                onClick={() => {
                  onStarVotacion(rowById, rowByValue);
                }}
              >
                SIM
              </button>
              <button className="componentButton componentButton--gray ml-2" onClick={() => setOpenModalStart(false)}>
                Voltar
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        className="generalModal"
        open={modal_finish}
        onClose={() => setOpenModalFinish(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal_finish}>
          <div className="modalContainer">
            <div className="modalContainer--header">
              <h4>Deseja Finalizar Votação? </h4>
            </div>
            <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="componentButton componentButton--green mx-0"
                onClick={() => {
                  props.handleFinish(rowById, rowByValue);
                  setOpenModalFinish(false);
                }}
              >
                SIM
              </button>
              <button className="componentButton componentButton--gray ml-2" onClick={() => setOpenModalFinish(false)}>
                Voltar
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        className="generalModal"
        open={modal_discusion}
        onClose={() => setOpenModalDiscusion(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal_discusion}>
          <div className="modalContainer">
            <div className="modalContainer--header">
              <h4>Deseja Abrir Discussão? </h4>
            </div>
            <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="componentButton componentButton--green mx-0"
                onClick={() => {
                  props.handletribuna(rowById, rowByValue);
                  setOpenModalDiscusion(false);
                }}
              >
                SIM
              </button>
              <button className="componentButton componentButton--gray ml-2" onClick={() => setOpenModalDiscusion(false)}>
                Voltar
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        className="generalModal"
        open={modal_discusion_fechar}
        onClose={() => setOpenModalFecharDiscusion(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal_discusion_fechar}>
          <div className="modalContainer">
            <div className="modalContainer--header">
              <h4>Deseja Fechar Discussão? </h4>
            </div>
            <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="componentButton componentButton--green mx-0"
                onClick={() => {
                  props.closeDiscution(rowById, rowByValue);
                  setOpenModalFecharDiscusion(false);
                }}
              >
                SIM
              </button>
              <button className="componentButton componentButton--gray ml-2" onClick={() => setOpenModalFecharDiscusion(false)}>
                Voltar
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
      {/* <Modal 
        className="generalModal"
        open={openVotacionComisiones}
        onClose={() => setOpenVotacionComisiones(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <DialogContent>
          <VotacaoComision comision={comision} comisionesUser={comisionesUser} document={doc} onClose={() => setOpenVotacionComisiones(false)} />
        </DialogContent>
      </Modal> */}

      <ThemeProvider theme={theme}>
        <div
          style={{
            display: "flex",
            widht: "100%",
            alignItems: "flex-start",
            justifyContent: "flex-end",
            marginLeft: "5px",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          {votacao == null && votingBlocoSelect.length > 1 ? (
            <Button variant="contained" onClick={() => props.handleSendVotingBlock()} className="componentButton componentButton--green">
              INICIAR VOTAÇÃO EM BLOCO
            </Button>
          ) : (
            ""
          )}

          {_.get(votacao, "document_session_register[0].voting_bloc", 0) == 1 ? (
            <> {JSON.parse(sessionStorage.getItem("USER")).id_access_level !== 5 ? /*Valida se não é controlador */
                  <Button variant="contained" onClick={() => props.handleOpenBloc()} className="componentButton componentButton--green">
                    {user_vote == true ? "MUDAR VOTO" : "VOTAR"}
                  </Button>
                : ""  
              }
              <Button variant="contained" onClick={() => openModalFinsih()} className="componentButton componentButton--yellow">
                FINALIZAR VOTAÇÃO EM BLOCO
              </Button>
            </>
          ) : (
            ""
          )}
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          backIconButtonText={"Anterior"}
          nextIconButtonText={"Proxima"}
          labelDisplayedRows={({ from, to, count }) => `Itens por página ${from}-${to} de ${count} páginas`}
          component="div"
          count={documentos.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </ThemeProvider>
    </Paper>
  );
}
const theme = createMuiTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  ptBR
);
