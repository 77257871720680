import {
  defaultFont
} from "assets/jss/material-dashboard-react.js";

const footerStyle = {
  block: {
    color: "inherit",
    padding: "15px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
    ...defaultFont,
    fontWeight: "500",
    fontSize: "12px",
  },
  left: {
    float: "left!important",
    display: "block",
  },
  right: {
    padding: "5px 0",
    margin: "0",
    fontSize: "14px",
    fontWeight: "400",
    float: "right!important",
  },
  footer: {
    bottom: "0",
    // borderTop: "1px solid " + grayColor[11],
    padding: "0 0",
    ...defaultFont,
  },
  container: {
    padding: "0 25px 0 0",
  },
  a: {
    color: "#2b2b2b",
    textDecoration: "none",
    fontWeight: "500",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#76852b",
    },
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
};
export default footerStyle;
