import axios from "axios";
import _ from "lodash";

var tokenGet =
  sessionStorage.getItem("TOKEN_KEY") &&
  sessionStorage.getItem("TOKEN_KEY").charAt(0) == '"'
    ? sessionStorage.getItem("TOKEN_KEY")
    : JSON.stringify(sessionStorage.getItem("TOKEN_KEY"));
var token = JSON.parse(tokenGet);

async function apiRefreshAtaSession(id) {//atualizar Ata_session
  let data;
  await axios({
    method: "put",
    url: process.env.REACT_APP_STAGE + "/ata/document/" + id,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    }}).then((response) => {
        data = response.data.success;
      })
      .catch(function(error) {
        Promise.reject(error.response);
      });

  return data;
}

function apiCreateAtaSession(id) { // criar Ata_session
  axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/ata/document",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { id_session: id },
  }).catch(function(error) {
    Promise.reject(error.response);
  });
}

function apiDisableAta(id) { // disable Ata_session
  axios({
    method: "put",
    url: process.env.REACT_APP_STAGE + "/ata/document/disable/" + id,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    }
  }).catch(function(error) {
    Promise.reject(error.response);
  });
}

export {
  apiCreateAtaSession,
  apiRefreshAtaSession,
  apiDisableAta,
};
