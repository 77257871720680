import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import api from "../../api/typedocument";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import TableDocument from "./TableDocument";
import { Error } from "../Parties/styles";
import _ from "lodash";

export default function TypeSession(props) {
  const classes = useStyles();

  const [name, setName] = useState();
  const [alert, setAlert] = useState(false);
  const [validation, setValidation] = useState([]);
  const [documents, setDocuments] = useState(_.get(props, "typesSession", []));
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null);

  const handleForm = (e) => {
    switch (e.target.name) {
      case "name":
        return setName(e.target.value);
    }
  };

  const handleAddTypeDocument = async (e) => {
    e.preventDefault();

    if (edit == true) {
      await api
        .put(`typeSession/update/${id}`, {
          name: name,
        })
        .then(function(response) {
          if (response === undefined) {
            return;
          }

          setDocuments(response.data);
          setName("");
          setValidation("");

          setAlert(true);

          setTimeout(
            function() {
              setAlert();
            }.bind(this),
            1000
          );
          setEdit(false);
        })
        .catch(function(error) {
          if (_.get(error, "response.status", null) === 422) {
            setValidation(_.get(error, "response.data.errors", []));
          }
        });

      return;
    }

    await api
      .post("typeSession/store", {
        name: name,
      })
      .catch(function(error) {
        console.log(error.response);
        if (_.get(error, "response.status", null) === 422) {
          setValidation(_.get(error, "response.data.errors", []));
        }
        return;
      })
      .then(function(response) {
        if (response === undefined) {
          return;
        }
        setDocuments(_.get(response, "data", []));
        setName("");
        setValidation("");

        setAlert(true);

        setTimeout(
          function() {
            setAlert();
          }.bind(this),
          1000
        );
      });
  };

  const handleDelete = async (e) => {
    await api
      .get(`typeSession/delete/${e.id}`, {
        id: e.id,
      })
      .then(function(response) {
        setDocuments(_.get(response, "data", []));

        setAlert(true);

        setTimeout(() => {
          setAlert();
        }, 1000);
      });
  };

  const handleEdit = async (e) => {
    setId(e.id);
    setName(e.name);
    setEdit(true);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card
            style={{
              boxShadow:
                "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
            }}
          >
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Adicionar tipo de Sessão</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <InputLabel className={classes.label}>
                    {"Nome da sessão"}
                  </InputLabel>
                  <FormControl className={classes.form}>
                    <Input name="name" value={name} onChange={handleForm} />
                    <Error>{_.get(validation, "name", "")}</Error>
                  </FormControl>
                </GridItem>
                <CardFooter>
                  <Button onClick={handleAddTypeDocument} color="success">
                    Salvar
                  </Button>
                </CardFooter>
              </GridContainer>
            </CardBody>
            <Snackbar
              place="tr"
              color="success"
              icon={AddAlert}
              message="Sucesso"
              open={alert}
              closeNotification={() => setAlert(false)}
              close
            />
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {documents && setDocuments != "" ? (
            <TableDocument
              tableHeaderColor="primary"
              tableHead={["Tipo de Documento", "", ""]}
              tableData={documents}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          ) : (
            ""
          )}
        </GridItem>
      </GridContainer>
    </>
  );
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
  },
};

const useStyles = makeStyles(styles);
