import React from "react"
import PropTypes from "prop-types"
import {withStyles, makeStyles, ThemeProvider, createMuiTheme} from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js"
import Button from '@material-ui/core/Button'
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip'
import TablePagination from "@material-ui/core/TablePagination";
import {ptBR} from "@material-ui/core/locale";

const useStyles = makeStyles(styles)

export default function CustomTable(props) {
    const classes = useStyles();
    const { tableHead,Logs, tableHeaderColor } = props;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.handleChangePage(rowsPerPage,newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        props.handleChangePage(event.target.value,page);
    };
    return (
        <div className={classes.tableResponsive}>
            <Table className={classes.table}>
                {tableHead !== undefined ? (
                    <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                        <TableRow className={classes.tableHeadRow}>
                            {tableHead.map((prop, key) => {
                                return (
                                    <TableCell
                                        className={classes.tableCell + " " + classes.tableHeadCell}
                                        key={key}
                                    >
                                        {prop}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                ) : null}
                <TableBody>
                    {(Logs.logs || []).map((prop, key) => {
                        return (
                            <TableRow key={key} className={classes.tableBodyRow} name={prop.id}>
                                <TableCell className={classes.tableCell}>
                                    {prop.id}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {prop.username}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {prop.profile}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {prop.module}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {prop.action}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {prop.ip}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {prop.app}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {prop.created_at}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <LightTooltip title="Ver Logs">
                                        <span className="material-icons" style={{ color: '#43a047' }} onClick={() => props.handleClickOpen(prop)}>
                                            visibility
                                        </span>
                                    </LightTooltip>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
            <div className={classes.root}>
                <ThemeProvider theme={theme}>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15,20,25]}
                        component="div"
                        count={Logs.recordsTotal ? Logs.recordsTotal  : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </ThemeProvider>
            </div>
        </div>
    )
}

CustomTable.defaultProps = {
    tableHeaderColor: "gray"
};
const theme = createMuiTheme({
    palette: {
        primary: { main: '#1976d2' },
    },
}, ptBR);
CustomTable.propTypes = {
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray"
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.string)
};
const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);
