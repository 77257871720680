import axios from "axios";
import _ from "lodash";

var tokenGet =
  sessionStorage.getItem("TOKEN_KEY") &&
  sessionStorage.getItem("TOKEN_KEY").charAt(0) == '"'
    ? sessionStorage.getItem("TOKEN_KEY")
    : JSON.stringify(sessionStorage.getItem("TOKEN_KEY"));
var token = JSON.parse(tokenGet);

async function apiGetDocumentsSessionPresident(id) {
  let data = [];

  let user = JSON.parse(sessionStorage.getItem("USER"));

  await axios({
    method: "get",
    url:
      process.env.REACT_APP_STAGE +
      "/documents/session/president?id=" +
      id +
      "&user_id=" +
      _.get(user, "id", 0),
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      data["documents"] = _.get(response, "data.documents", null);
      data["roteiro"] = _.get(response, "data.roteiro", null);
    })
    .catch(function(error) {
      Promise.reject(error.response);
    });

  return data;
}

async function apiGetDocumentsSessionManual(id) {
  let data = [];

  let user = JSON.parse(sessionStorage.getItem("USER"));

  await axios({
    method: "get",
    url:
      process.env.REACT_APP_STAGE +
      "/documents/session/manual?id=" +
      id +
      "&user_id=" +
      _.get(user, "id", 0),
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      data["documents"] = _.get(response, "data.documents", null);
    })
    .catch(function(error) {
      Promise.reject(error.response);
    });

  return data;
}

async function apiGetDocuments(id) {
  let data = [];

  await axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + "/documents?id=" + id,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      data = _.get(response, "data.documents", null);
    })
    .catch(function(error) {
      Promise.reject(error.response);
    });

  return data;
}

async function apiUpdate(data, route) {
  let data_documents;

  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + route,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: data,
  }).then((response) => {
    data_documents = _.get(response, "data.documentos", null);
  });

  return data_documents;
}

async function deleteDocument(id, idsession) {
  let data_documents;
  await axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + "/documents/delete",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { id: id, id_session: idsession },
  }).then((response) => {
    data_documents = _.get(response, "data.documents", null);
  });

  return data_documents;
}

async function apiGetDocumentsParliamentary(id) {
  let data = [];

  await axios({
    method: "get",
    url:
      process.env.REACT_APP_STAGE + "/documents/session/parliamentary?id=" + id,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      data = _.get(response, "data", null);
    })
    .catch(function(error) {
      Promise.reject(error.response);
    });

  return data;
}

async function apiDeleteDocument(id, idSession) {
  return await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/documents/disable",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      id: id,
      idsession: idSession,
    },
  });
}

async function apiUpdateOrder(data) {
  let result;
  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/session/orderdoc",
    headers: {
      Accept: "application/json",
      Authorization:
        "Bearer " + JSON.parse(sessionStorage.getItem("TOKEN_KEY")),
    },
    data: data,
  })
    .then(function(response) {
      result = response;
    })
    .catch(function(error) {
      console.log(error);
    });
  return result;
}
//ORDENAR DOCUMENTO A LA POSICION ANTERIOR
function getOrderUp(array_doc, row, ord) {
  let doc_ant;
  let data;
  //get first doc
  array_doc.forEach((doc) => {
    if (row.id_part == doc.id_part) {
      doc_ant = doc;
    }
  });
  array_doc.forEach((doc) => {
    if (row.id == doc.id && row.id_part == doc.id_part) {
      data = {
        id: doc.id_session,
        action: ord,
        doc_id: doc.id,
        doc_order: doc_ant.order,
        doc_parent_id: doc_ant.id,
        doc_parent_order: doc.order,
      };
    }
    //anterior
    if (doc_ant.id !== doc.id && doc_ant.id_part == doc.id_part) {
      doc_ant = doc;
    }
  });
  return data;
}
//ORDENAR DOCUMENTO A LA POSICION SIGUIENTE
function getOrderDown(array_doc, row, ord) {
  let data;
  let next = false;
  array_doc.forEach((doc) => {
    if (row.id == doc.id && row.id_part == doc.id_part) {
      next = true;
      return;
    }
    if (next) {
      next = false;
      data = {
        id: doc.id_session,
        action: ord,
        doc_id: doc.id,
        doc_order: row.order,
        doc_parent_id: row.id,
        doc_parent_order: doc.order,
      };
    }
  });
  return data;
}

async function apiGetDocumentsTribuna() {
  let data = [];

  await axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + "/tribuna-documents/index",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      data = _.get(response, "data", null);
    })
    .catch(function(error) {
      Promise.reject(error.response);
    });

  return data.success;
}

export {
  apiDeleteDocument,
  apiGetDocumentsSessionPresident,
  apiGetDocumentsSessionManual,
  apiGetDocuments,
  apiUpdate,
  deleteDocument,
  apiGetDocumentsParliamentary,
  apiUpdateOrder,
  getOrderUp,
  getOrderDown,
  apiGetDocumentsTribuna,
};
