import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
//import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import SettingsVoiceIcon from '@material-ui/icons/SettingsVoice';
import { micStatus } from "api/automation.js";

class Microphone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      microfones: []
    };
  }

  async componentDidMount() {
    let mics = [];
    await this.props.automacao.values.microfones.map((prop, key) => {
      mics[key] = {
        name: prop.name,
        status: false,
      };
    });

    this.setState({ microfones: mics });
  }

  handleMic = async (status, key) => {
    this.props.hadleButtonStatus(true);

    await micStatus(status, key)
      .then(() => {
        let microfones = this.state.microfones;
        microfones[key]['status'] = status;
        this.setState({ microfones: microfones });
      })
      .catch((error) => {
        if (error.data.erro) {
          alert(error.data.erro);
        } else {
          alert('Ocorreu um erro!');
        }
      });

    this.props.hadleButtonStatus(false);
  }

  render() {
    const { classes, buttons_disabled } = this.props;
    const { microfones } = this.state;
    
    return (
      <List className={classes.root}>
        {microfones && microfones.map((prop, key) => (
          <ListItem
            key={key}
            style={{ padding: "10px" }}
            alignItems="flex-end"
          >
            <Button
              onClick={() => this.handleMic(!prop.status, key)}
              disabled={buttons_disabled}
              style={{minHeight: '80px'}}
            >
              <ListItemAvatar>
                {
                  !prop.status ? (
                    <MicOffIcon fontSize="medium" />
                  ) : (
                    <SettingsVoiceIcon fontSize="medium" />
                  )
                }
              </ListItemAvatar>
              <ListItemText
                primary={"Microfone"}
                style={{ marginTop: "0px" }}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      {prop.name}
                    </Typography>
                  </React.Fragment>
                }
              />
            </Button>
          </ListItem>
        ))}
      </List>
    );
  }
}

const styles = {
  root: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "auto auto",
    padding: "20px",
  },
  cargo: {
    fontSize: "12px",
    margin: "0px",
    display: "flex",
    alignItems: "center",
  },
  inline: {
    display: "inline",
    fontSize: "16px",
  },
};

Microphone.propTypes = {
  classes: PropTypes.object.isRequired,
  automacao: PropTypes.object,
  buttons_disabled: PropTypes.bool.isRequired,
  hadleButtonStatus: PropTypes.func.isRequired,
};

export default withStyles(styles)(Microphone);
