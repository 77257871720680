import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { apiPresence, apiSession, getTribuna } from "api/videowall.js";
import {} from "api/president.js";
import _ from "lodash";
import echo from "api/channels";
import avatar from "assets/img/faces/marc.jpg";
import TimerTribuna from "./Tribuna/timer";
import TimerImplement from "./Tribuna/timer_implement";
import { getVoting } from "api/voting.js";
import { getVotingComision } from "api/voting.js";
import logo from "assets/img/logo-cliente.png";
import logoEnvsenseLight from "assets/img/logo-envsense-light.png";
import { Grid, Box, Typography } from "@material-ui/core";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import BlockIcon from "@material-ui/icons/Block";

import { Fade } from "react-slideshow-image";
import { Fade as FadeEffect } from "@material-ui/core";
import "../../assets/sass/components/videowall/index.scss";
import "../../assets/sass/styles.scss";

import {
  //VideowallSection,
  GridParliamentary,
  LogoBottom,
  H1,
  H2,
} from "./styles";

class VideowallPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoCliente: "",
      presentes: null,
      ausentes: null,
      title_voting: "",
      title_discussao: "",
      voting_comision: false,
      committee_name: "",
      votes_true: "0",
      votes_false: "0",
      votes_null: "0",
      votes_total: 0,
      name: "",
      title: "",
      quorum: "",
      start: false,
      voting: false,
      count_presentes: 0,
      count_ausentes: 0,
      result: null,
      color_result: "white",
      message: "",
      tribuna_open: null,
      tribuna_inscritos: null,
      tribuna_emFala: null,
      tribuna_concluidos: null,
      tribuna_cancelados: null,
      tribuna: 0,
      name_tribuna: "",
      time_tribuna: "00:00:00",
      name_user_tribuna: "",
      file_user_tribuna: null,
      implement_tribuna: 0,
      secret_voting: null,
      order: 0,
      name_implement: "",
      time_implement: "00:00:00",
      name_user_implement: "",
      file_user_implement: null,
      abstencao: false,
      ementa: "",
    };
  }

  async componentDidMount() { 
    // buscando sessão
    const session = await apiSession();

    this.setState({ quorum: _.get(session, "quorum", false) });
    this.setState({ start: _.get(session, "start", null) });
    this.setState({ description: _.get(session, ["success", "name"], "") });

    const users = await apiPresence();

    this.setState({ presentes: _.get(users, "presentes", null) });
    this.setState({ ausentes: _.get(users, "ausentes", null) });
    this.setState({ count_presentes: _.get(users, "count_presentes", null) });
    this.setState({ count_ausentes: _.get(users, "count_ausentes", null) });

    const data_comision = await getVotingComision(); // retorno de dados da votação das comisões
    const data = await getVoting();

    if (data !== null) {
      this.setState({ voting: true });
      if (_.get(data, "bloc", null) === 0 || _.get(data, "bloc", null) === null) {
        this.setState({ title_voting: _.get(data, "document.name", "") });
        this.setState({ secret_voting: _.get(data, "document.secret", "") });
      } else {
        this.setState({ title_voting: "VOTAÇÃO EM BLOCO" });
        this.setState({ secret_voting: _.get(data, "secret", "") });
      }

      this.setState({ presentes: _.get(data, "presentes", null) });
      this.setState({ votes_true: _.get(data, "votes_true", 0) });
      this.setState({ votes_false: _.get(data, "votes_false", 0) });
      this.setState({ votes_null: _.get(data, "votes_null", 0) });
      this.setState({ votes_total: _.get(data, "total_votos", 0) });

      this.handleResult(data.approved, data.abstencao);
    }
    // Valida se existe dados de votação da comissão
    if (data_comision !== null) {
      this.setState({ voting: true });
      if (_.get(data_comision, "bloc", null) === 0 || _.get(data_comision, "bloc", null) === null) {
        this.setState({ title_voting: _.get(data_comision, "document.name", "") });
      } else {
        this.setState({ title_voting: "VOTAÇÃO EM BLOCO" });
      }
      
      this.setState({ presentes: _.get(data_comision, "presentes", null) });
      this.setState({ ausentes: null });
      this.setState({ voting_comision: true });
      this.setState({ votes_true: _.get(data_comision, "votes_true", 0) });
      this.setState({ votes_false: _.get(data_comision, "votes_false", 0) });
      this.setState({ votes_null: _.get(data_comision, "votes_null", 0) });
      this.setState({ votes_total: _.get(data_comision, "total_votos", 0) });
      this.setState({ committee_name: _.get(data_comision.committee, "name", "") });

      this.handleResult(data_comision.result, data_comision.abstencao);
    }

    echo.channel("session").listen("SessionAutorized", (e) => {
      this.setState({ description: _.get(e, "name", "") });
    });

    echo.channel("session").listen("DisallowSession", () => {
      this.setState({ start: false });
      this.setState({ description: "" });
    });

    echo.channel("session").listen("Suspend", () => {
      this.setState({ start: false });
      this.setState({ description: "" });
    });

    // canal de  retorno se há quorum
    echo.channel("session").listen("Quorum", (e) => {
      this.setState({ quorum: _.get(e, "quorum", false) });
    });

    // canal de retorno que inicia sessão
    echo.channel("session").listen("StartSession", (e) => {
      this.setState({ session: true });
      this.setState({ start: true });
      this.setState({ description: _.get(e, "name", "") });
      //this.start(e)
    });

    // canal de retorno de sessão terminada
    echo.channel("session").listen("StopSession", () => {
      this.setState({ session: false });
      this.setState({ start: false });
      this.setState({ description: "" });
      this.setState({ quorum: null });
    });

    //* Tribuna ********************/
    const tribunaData = await getTribuna();

    if (tribunaData && tribunaData.tribuna_open !== "") {
      this.setState({ tribuna_open: _.get(tribunaData, "tribuna_open", "") });

      if (tribunaData.tribuna_open) {
        this.setState({
          title_discussao: _.get(tribunaData, "tribuna_open.subscription", ""),
        });
        this.setState({ tribuna_open: true });
      } else if (tribunaData.discution) {
        this.setState({
          title_discussao: _.get(tribunaData, "discution.name", ""),
        });
        this.setState({ tribuna_open: true });
      }

      this.setUsersTribuna(tribunaData);
      this.filterTimeTribuna(tribunaData);
    } else {
      this.setState({ tribuna_open: null });
      this.setState({ title_discussao: "" });
      this.setUsersTribuna(tribunaData);
    }

    echo.channel("subscription").listen("OpenSubscription", (e) => {
      this.setState({ tribuna_open: e });
      this.setState({ title_discussao: _.get(e, "subscription", "") });
      this.setUsersTribuna(e);
    });

    echo.channel("getUsersTimeApp").listen("AddSubscription", (e) => {
      this.setUsersTribuna(e);
    });

    echo.channel("tribuna").listen("getUsersSubscription", (e) => {
      this.setUsersTribuna(e);
    });

    echo.channel("subscription").listen("StopSubscription", () => {
      this.setState({ tribuna_open: null });
      this.setState({ title_discussao: "" });
      this.setUsersTribuna([]);
      this.handleCloseTribuna();
    });

    echo.channel("tribuna").listen("TalkTimeStop", (e) => {
      this.setUsersTribuna(e.UsersSubscription);
    });

    //canal retorna tribuna
    echo.channel("tribuna").listen("TalkTime", (e) => {
      sessionStorage.clear();
      this.setState({ name_tribuna: _.get(e, "inscription", "") });
      this.setState({ time_tribuna: _.get(e, "time", "00:00:00") });
      this.setState({ file_user_tribuna: _.get(e, "file", avatar) });
      this.setState({ name_user_tribuna: _.get(e, "name", null) });
      this.setState({ tribuna: 1 });
      this.setState({ order: 0 });

      //this.setState({ id_user_tribuna: _.get(e, 'id_user', null) })
    });

    echo.channel("tribuna").listen("TimeApp", (e) => {
      if (sessionStorage.getItem("implement") === 1) {
        return;
      }

      if (e.operacao === "retomar") {
        this.setState({ time_tribuna: sessionStorage.getItem("timerTribuna") });
        return;
      }

      if (e.operacao === "add") {
        return;
      }

      if (e.operacao != "pause" && this.state.order === 0) {
        sessionStorage.removeItem("timerTribuna");
        this.setState({ tribuna: 0 });
        this.setState({ order: null });
        sessionStorage.clear();
        return;
      }
    });

    echo.channel("tribuna").listen("TalkTimeImplementStop", (e) => {
      this.filterTimeTribuna(e);
    });

    // canal que retorno de tempo rigimental acabou
    echo.channel("tribuna").listen("MessageFinishTribuna", (e) => {
      this.setState({ message: e });
    });

    //canal que reporta início de tempo regimental
    echo.channel("tribuna").listen("GetSessionTime", (e) => {
      let typeSession = "ABERTO " + _.get(e, "order_session.name", "").toUpperCase();
      this.setState({ message: typeSession });

      setTimeout(() => {
        this.setState({ message: "" });
      }, 3000);
    });

    //canal que retorna se iniciou uma discução
    echo.channel("subscription").listen("OpenDiscution", (e) => {
      this.setState({ tribuna_open: e });
      this.setState({ title_discussao: _.get(e, "name", "") });
    });

    //canal que retorna se terminou uma discução
    echo.channel("subscription").listen("CloseDiscution", () => {
      this.setState({ tribuna_open: null });
      this.setState({ title_discussao: "" });
      this.setUsersTribuna([]);
    });
    //******************************/

    //canal que retorna quais usuários estão presentes ou não (parlamentares)
    echo.channel("session").listen("ParliamentaryOnline", (e) => {
      if (this.state.voting == true) {
        return;
      }

      this.setState({ presentes: _.get(e, "presentes", null) });
      this.setState({ ausentes: _.get(e, "ausentes", null) });
      this.setState({ count_presentes: _.get(e, "count_presentes", null) });
      this.setState({ count_ausentes: _.get(e, "count_ausentes", null) });
    });

    // this.setState({ description: _.get(docs, 'description', null) })

    //canal que retorna se iniciou uma votação
    echo.channel("voting").listen("StartVoting", (e) => {
      if (this.props.configurations.enable_ementa) {
        this.setState({ ementa: _.get(e, "description", "") });
        setTimeout(() => {
          this.setState({ ementa: false });
        }, this.props.configurations.timeEmentaInSeconds * 1000);
      }

      this.setState({ votes_true: 0 });
      this.setState({ votes_false: 0 });
      this.setState({ votes_null: 0 });
      this.setState({ votes_total: 0 });
      this.setState({ voting: true });
      this.setState({ title_voting: _.get(e, "name", "") });
      this.setState({ secret_voting: _.get(e, "secret", "") });
      this.setState({ name: _.get(e, "name", "") });
    });

    //canal que retorna se iniciou uma votação das COMISSÕES
    echo.channel("votingComision").listen("StartVotingComision", (e) => {
      this.setState({ votes_true: 0 });
      this.setState({ votes_false: 0 });
      this.setState({ votes_null: 0 });
      this.setState({ votes_total: 0 });
      this.setState({ voting: true });
      this.setState({ title_voting: _.get(e, "name", "") }); // alterar para colocar a comisão da votação
      this.setState({ secret_voting: _.get(e, "secret", "") });
      this.setState({ name: _.get(e, "name", "") });
      this.setState({ presentes: _.get(e, "presentes", null) });
      this.setState({ ausentes: null });
      this.setState({ voting_comision: true});
      this.setState({ committee_name: _.get(e.committee, "name", "")});

      this.handleResult(null, false);// inicia o resultado da  votação como null
    });

    echo.channel("voting").listen("StartVotingBloc", (e) => {
      this.setState({ votes_true: 0 });
      this.setState({ votes_false: 0 });
      this.setState({ votes_null: 0 });
      this.setState({ votes_total: 0 });
      this.setState({ voting: true });
      this.setState({ secret_voting: _.get(e, "secret", "") });
      this.setState({ title_voting: "VOTAÇÃO EM BLOCO" });
      this.setState({ name: "VOTAÇÃO EM BLOCO" });
    });

    //canal retorna voto dos usuários
    echo.channel("voting").listen("UsersVote", (e) => {
      /*if (_.get(e, 'result', null) != null) {
        this.setState({ color_result: e.result == true ? "green" : "red" })
        this.setState({ result: e.result == true ? 'APROVADO' : 'REPROVADO' })
      }*/

      this.setState({ presentes: _.get(e, "presentes", null) });
      this.setState({ ausentes: _.get(e, "ausentes", null) });
      this.setState({ votes_true: _.get(e, "votes_true", "") });
      this.setState({ votes_false: _.get(e, "votes_false", "") });
      this.setState({ votes_null: _.get(e, "votes_null", "") });
      this.setState({ votes_total: _.get(e, "total_votos", "") });
      this.setState({ abstencao: _.get(e, "abstencao", false) });

      this.handleResult(e.result, e.abstencao);
    });

    //canal retorna voto dos usuários das COMISSÕES 
    echo.channel("voting").listen("UserVoteCommitte", (e) => {
      this.setState({ presentes: _.get(e, "presentes", null) });
      this.setState({ ausentes: null });
      this.setState({ votes_true: _.get(e, "votes_true", "") });
      this.setState({ votes_false: _.get(e, "votes_false", "") });
      this.setState({ votes_null: _.get(e, "votes_null", "") });
      this.setState({ votes_total: _.get(e, "total_votos", "") });
      this.setState({ abstencao: _.get(e, "abstencao", false) });

      this.handleResult(e.result, e.abstencao);
    });

    echo.channel("voting").listen("StopVoting", async () => {
      this.setState({ voting: false });
      this.setState({ title_voting: "" });
      this.setState({ secret_voting: null});
      this.setState({ result: null });
      this.setState({ abstencao: false });

      const users = await apiPresence();

      this.setState({ presentes: _.get(users, "presentes", null) });
      this.setState({ ausentes: _.get(users, "ausentes", null) });
      this.setState({ count_presentes: _.get(users, "count_presentes", null) });
      this.setState({ count_ausentes: _.get(users, "count_ausentes", null) });
    });

    //Stop votaço dos comissões
    echo.channel("voting").listen("StopVoteCommittee", async () => {
      this.setState({ voting: false });
      this.setState({ voting_comision: false });
      this.setState({ title_voting: "" });
      this.setState({ secret_voting: null});
      this.setState({ committee_name: "" });
      this.setState({ result: null });
      this.setState({ abstencao: false });

      const users = await apiPresence();

      this.setState({ presentes: _.get(users, "presentes", null) });
      this.setState({ ausentes: _.get(users, "ausentes", null) });
      this.setState({ count_presentes: _.get(users, "count_presentes", null) });
      this.setState({ count_ausentes: _.get(users, "count_ausentes", null) });
    });
  }

  handleResult = (approved, abstain) => {
    if (abstain == true) {
      this.setState({ color_result: "#2196f3" });
      this.setState({ result: "NÃO HÁ QUÓRUM PARA OBTENÇÃO DE RESULTADO" });
    } else {
      if (approved != null) {
        if (approved == true) {
          this.setState({ color_result: "green" });
          this.setState({ result: "APROVADO" });
        } else {
          this.setState({ color_result: "red" });
          this.setState({ result: "REPROVADO" });
        }
      }else{
        this.setState({ color_result: "white" });
        this.setState({ result: null });
      }
    }
  };

  /** Begin functions Tribuna */
  filterTimeTribuna(tribunaData) {
    if (tribunaData.aparte != null) {
      let aparteTimeApp = _.get(tribunaData, ["aparte", "time_app", "0"], null);
      if (aparteTimeApp && aparteTimeApp.time_left != "00:00:00") {
        this.setUserTimeTribuna(aparteTimeApp.time_left, tribunaData.aparte, 1);
      }
    } else if (tribunaData.emFala != null) {
      let emfalaTimeApp = _.get(tribunaData, ["emFala", "time_app", "0"], null);
      if (emfalaTimeApp.time_left != "00:00:00") {
        this.setUserTimeTribuna(emfalaTimeApp.time_left, tribunaData.emFala, 0);
      }
    }
  }

  setUsersTribuna(data) {
    this.setState({
      tribuna_inscritos: _.get(data, "inscritos", null),
      tribuna_emFala: _.get(data, "emFala", null),
      tribuna_concluidos: _.get(data, "concluidos", null),
      tribuna_cancelados: _.get(data, "cancelados", null),
    });
  }

  setUserTimeTribuna(time, data, order) {
    this.setState({ time_tribuna: time });
    this.setState({ file_user_tribuna: _.get(data, "photoUrl", avatar) });
    this.setState({ name_user_tribuna: _.get(data, "public_name", null) });
    this.setState({ tribuna: 1 });
    this.setState({ order: order || 0 });
  }
  /** End functions Tribuna */

  handleImplementOpen = (time, name, file) => {
    if (this.state.order === 1) {
      this.handleImplementOff();
    }

    sessionStorage.setItem("timerImplement", time);
    sessionStorage.setItem("implement", 1);
    this.setState({ time_implement: time });
    this.setState({ name_user_implement: name });
    this.setState({ file_user_implement: file });
    this.setState({ order: 1 });
  };

  handleImplementOff = () => {
    this.setState({ time_tribuna: sessionStorage.getItem("timerTribuna") });
    sessionStorage.removeItem("timerImplement");
    this.setState({ order: 0 });
    sessionStorage.removeItem("implement");
  };

  handleCloseTribuna = () => {
    this.setState({ tribuna: 0 });
    sessionStorage.removeItem("timerTribuna");
  };

  render() {
    const { classes, settingsVotacoes } = this.props;
    const {
      title_discussao,
      presentes,
      ausentes,
      title_voting,
      secret_voting,
      votes_true,
      votes_false,
      votes_null,
      description,
      quorum,
      start,
      voting,
      count_presentes,
      count_ausentes,
      result,
      color_result,
      message,
      tribuna_open,
      tribuna_inscritos,
      tribuna_emFala,
      tribuna_concluidos,
      tribuna_cancelados,
      tribuna,
      time_tribuna,
      name_user_tribuna,
      file_user_tribuna,
      committee_name,
      order,
      time_implement,
      name_user_implement,
      file_user_implement,
      votes_total,
      ementa,
      voting_comision,
    } = this.state;

    //this.configurationPlenary = this.props.configurationPlenary;
    //let habilitar_boton_abster = this.configurationPlenary ? this.configurationPlenary.find(config => config.alias =='habilitar_boton_abster') : {active : 0};

    const zoomInProperties = {
      indicators: false,
      arrows: false,
      autoplay: true,
      pauseOnHover: false,
      scale: 1.4,
      transitionDuration: 2000,
    };

    const waiting = (
      <div className="boxImage__slide">
        <div style={{ width: "100%", textAlign: "center", display: "table" }}>
          <H1
            className="text-shadow"
            style={{
              margin: "0px 0px 10px 0px",
              textTransform: "uppercase",
              color: "#b0d34c",
              opacity: "1",
            }}
          >
            {quorum ? "HÁ QUÓRUM" : "AGUARDANDO QUÓRUM"}
          </H1>
          <H1
            className="text-shadow"
            style={{
              textAlign: "center",
              fontSize: "3rem",
              marginBottom: "20px",
            }}
          >
            {description}
          </H1>
          <img
            src={this.props.configurations?.logoCliente ? this.props.configurations.logoCliente : logo}
            alt="logo"
            style={{ maxWidth: "50vw", maxHeight: "27vh" }}
          />
          <H2 className="text-shadow" style={{ padding: "30px 0px", fontSize: "4rem" }}>
            AGUARDANDO O INÍCIO DA SESSÃO
          </H2>
        </div>
      </div>
    );

    const colsParliamentary = (
      <>
        {presentes === null
          ? ""
          : presentes.map((presente) => {
            var presente_vote       = _.get(presente, "vote_committee[0]", null); // validando o voto do usuario presente
            var vote_committee_user = _.get(presente, "vote_committee[0].vote", null); //validando detalhes do voto 
            var vote_committee = presente_vote === null ? "" : vote_committee_user === null ? "vote-null" : vote_committee_user === 0 ? "vote-false" : "vote-true"; // realizando condição para alterar class do voto
            var vote_user_proct = // criado variavel para add classe dos projetos comum
                 _.get(presente, "vote[0]", null) === null
                  ? ""
                  : _.get(presente, "vote[0].vote", null) === null
                  ? "vote-null"
                  : _.get(presente, "vote[0].vote", null) === 0
                  ? "vote-false"
                  : "vote-true";

            var itemClass = voting_comision ? vote_committee : vote_user_proct; // validando se existe votação das comissões usar o vote_commitee para add class 

              return (
                <div className={`gridItem ${secret_voting == true ? ' ' : itemClass}`} key={presente.id}>
                  <figure>
                    <img src={_.get(presente, "photoUrl", avatar)} alt={_.get(presente, "public_name")} />
                  </figure>
                  <h3>
                    {_.get(presente, "public_name")}
                    <br />
                    <small>({_.get(presente, "political_party.sigla", "")})</small>
                  </h3>
                </div>
              );
            })}
        {ausentes == null
          ? ""
          : ausentes.map((ausente) => {
              return (
                <div className="gridItem disabled" key={ausente.id}>
                  <figure>
                    <img src={_.get(ausente, "photoUrl", avatar)} alt={_.get(ausente, "public_name")} />
                  </figure>
                  <h3>
                    {_.get(ausente, "public_name")}
                    <br />
                    <small>({_.get(ausente, "political_party.sigla", "")})</small>
                  </h3>
                </div>
              );
            })}
      </>
    );

    return (
      <div className="containerVideowall__content">
        {start == false ? (
          <>
            <div>
              {this.props.configurations.enable_slide ? (
                <Fade {...zoomInProperties} className={classes.slide}>
                  <div
                    style={{
                      width: "100%",
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      zIndex: "1",
                    }}
                  >
                    {waiting}
                  </div>
                  {this.props.configurations.slide_images.map((each, index) => (
                    <div className="slide-item" key={index} style={{ width: "100%", backgroundColor: "#000000" }}>
                      <img style={{ objectFit: "cover", width: "100%" }} src={each.data_url} />
                    </div>
                  ))}
                  <div
                    style={{
                      width: "100%",
                      padding: "15px 15px",
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      zIndex: "2",
                    }}
                  >
                    <Box display="flex" alignItems="center" style={{ height: "calc(100vh - 64px)" }}>
                      <GridParliamentary>
                        <Box display="flex" justifyContent="flex-start" flexDirection="row" flexWrap="wrap" m={0} px={3}>
                          {colsParliamentary}
                        </Box>
                      </GridParliamentary>
                    </Box>
                  </div>
                </Fade>
              ) : (
                waiting
              )}
            </div>
          </>
        ) : (
          <>
            <div className="containerVideowall__content--row">
              {/** LEFT */}
              <Grid item xs={9} xl={10} className="containerVideowall__content--column-1">
                <div className="containerVideowall__content--column--title">
                  {/** TOP INFOS */}
                  <Grid item xs={12}>
                    <H1 style={{ color: color_result, textAlign: "center" }}>{result}</H1>
                    <H1
                      className="text-shadow"
                      style={{
                        margin: "0px 0px 0px 0px",
                        textAlign: "center",
                        textTransform: "uppercase",
                        color: "#b0d34c",
                        opacity: "1",
                      }}
                    >
                      {!quorum ? "AGUARDANDO QUÓRUM" : ""}
                    </H1>
                    <H1 className="text-shadow" style={{ textAlign: "center" }}>
                      {description}
                    </H1>
                    <H1 style={{ letterSpacing: "5px", textAlign: "center" }}>{message}</H1>
                    {/*abstencao == true ? <H1>Não há quórum para obtenção de resultado</H1> : ''*/}
                    <H1 className="text-shadow" style={{ textAlign: "center" }}>
                      {
                      voting_comision == true ? 
                      
                      title_voting ? "EM VOTAÇÃO DA COMISSÃO DE " + committee_name + ": " + title_voting : "" :

                      title_voting ? "EM VOTAÇÃO: " + title_voting : title_discussao ? "EM DISCUSSÃO: " + title_discussao : ""
                      
                      }
                    </H1>
                  </Grid>
                </div>
                {/** GRID PARLIAMENTARY */}
                <GridParliamentary>
                  <div className="boxInscritos">
                    {tribuna_open != null ? (
                      <>
                        <Typography
                          variant="h2"
                          align="center"
                          style={{
                            width: "100%",
                            fontWeight: "bold",
                            color: "#ffffff",
                          }}
                        >
                          INSCRITOS
                        </Typography>
                        {tribuna_inscritos === null
                          ? ""
                          : tribuna_inscritos.map((user, index) => {
                              var rejeitado = tribuna_cancelados.filter((item) => item.id === user.id);
                              var emFala = tribuna_emFala != null ? (tribuna_emFala.id == user.id ? true : false) : false;
                              var concluido = tribuna_concluidos.filter((item) => item.id == user.id);
                              var tribuna_itemClass = rejeitado != "" ? "canceled" : emFala == true ? "active" : concluido != "" ? "inactive" : "";
                              return (
                                <div className={"gridItem boxImg " + tribuna_itemClass} key={user.id}>
                                  <figure>
                                    <div className="icon icon-speaking">
                                      <RecordVoiceOverIcon />
                                    </div>
                                    <div className="icon icon-check">
                                      <CheckCircleIcon />
                                    </div>
                                    <div className="icon icon-block">
                                      <BlockIcon />
                                    </div>
                                    <div className="flag">
                                      <span>{index + 1}º</span>
                                    </div>
                                    <img src={user.photoUrl != "" ? user.photoUrl : avatar} />
                                  </figure>
                                </div>
                              );
                            })}
                      </>
                    ) : (
                      <>
                        {ementa ? (
                          <Box
                            display="flex"
                            alignItems="center"
                            p={5}
                            style={{
                              fontSize: "2rem",
                              lineHeight: "1.4",
                              color: "#ffffff",
                            }}
                          >
                            <FadeEffect in={true}>
                              <div
                                style={{
                                  padding: "35px 35px",
                                  boxShadow: "-1px 3px 8px rgba(0,0,0,0.2)",
                                }}
                              >
                                <p
                                  style={{
                                    textAlign: "left",
                                    fontSize: "2.6rem",
                                  }}
                                >
                                  <b>Ementa:</b>
                                </p>
                                <p>{ementa}</p>
                              </div>
                            </FadeEffect>
                          </Box>
                        ) : (
                          colsParliamentary
                        )}
                      </>
                    )}
                  </div>
                </GridParliamentary>
                {/** END GRID PARLIAMENTARY */}
              </Grid>
              {/** END LEFT */}
              {/** RIGHT */}
              <Grid item xs={3} xl={2} className="containerVideowall__content--column-2">
                {voting == true ? (
                  <div className="boxVoting--content">
                    <div className="boxVoting boxVoting__yes">
                      <p>SIM</p>
                      <div className="circleCount">{votes_true}</div>
                    </div>
                    <div className="boxVoting boxVoting__no">
                      <p>NÃO</p>
                      <div className="circleCount">{votes_false}</div>
                    </div>
                    {settingsVotacoes && settingsVotacoes.enableAbs && (
                      <div className="boxVoting boxVoting__absteve">
                          <p>{voting_comision ? 'EM PARTE' : 'ABSTEVE'}</p>
                        <div className="circleCount">{votes_null}</div>
                      </div>
                    )}
                    {/*
                      habilitar_boton_abster?.active === 1 ?
                          <div className="boxVoting boxVoting__absteve">
                            <p>
                              ABSTEVE
                            </p>
                            <div className="circleCount">
                              {votes_null}
                            </div>
                          </div> : ''
                    */}
                    <div className="boxVoting boxVoting__total">
                      <p>VOTOS</p>
                      <div className="circleCount">{votes_total}</div>
                    </div>
                  </div>
                ) : (
                  <div className="boxVoting--content">
                    <div className="boxVoting boxVoting__present">
                      <p>PRESENTES</p>
                      <div className="circleCount">{count_presentes}</div>
                    </div>
                    <div className="boxVoting boxVoting__missing">
                      <p>AUSENTES</p>
                      <div className="circleCount">{count_ausentes}</div>
                    </div>

                    <div>
                      {/** Time Track */}
                      <Box width="100%" height="29%" display="flex" alignItems="flex-end" justifyContent="center">
                        {tribuna == 1 ? (
                          order == 0 ? (
                            <Box
                              className="boxTimeTribuna"
                              width="100%"
                              display="flex"
                              justifyContent="center"
                              flexWrap="wrap"
                              borderRadius={5}
                              p={3}
                              style={{
                                backgroundColor: "rgba(76,175,255,0.2)",
                                display: "none",
                              }}
                            >
                              <GridParliamentary style={{ width: "14vw" }}>
                                <figure style={{ height: "15vw" }}>
                                  <img src={file_user_tribuna || avatar} />
                                </figure>
                                <Typography
                                  variant="h4"
                                  align="center"
                                  className="text-shadow"
                                  style={{
                                    color: "#ffffff",
                                    padding: "8px 0px",
                                    fontSize: "1.6rem",
                                  }}
                                >
                                  {name_user_tribuna}
                                </Typography>
                                <TimerTribuna
                                  style={{ width: "100%" }}
                                  time={time_tribuna}
                                  handleImplementOpen={this.handleImplementOpen.bind(this)}
                                  handleImplementOff={this.handleImplementOff.bind(this)}
                                  handleCloseTribuna={this.handleCloseTribuna.bind(this)}
                                />
                              </GridParliamentary>
                            </Box>
                          ) : (
                            <Box
                              className="boxTimeTribuna"
                              width="100%"
                              display="flex"
                              justifyContent="center"
                              borderRadius={5}
                              p={3}
                              style={{
                                backgroundColor: "rgba(76,175,255,0.2)",
                                display: "none",
                              }}
                            >
                              <GridParliamentary style={{ width: "14vw" }}>
                                <figure style={{ height: "15vw" }}>
                                  <img src={file_user_implement || avatar} />
                                </figure>
                                <Typography
                                  variant="h4"
                                  align="center"
                                  className="text-shadow"
                                  style={{
                                    color: "#ffffff",
                                    padding: "8px 0px",
                                    fontSize: "1.6rem",
                                  }}
                                >
                                  {name_user_implement}
                                </Typography>
                                <TimerImplement
                                  style={{ width: "100%" }}
                                  time={time_implement}
                                  handleImplementOpen={this.handleImplementOpen.bind(this)}
                                  handleImplementOff={this.handleImplementOff.bind(this)}
                                  handleCloseTribuna={this.handleCloseTribuna.bind(this)}
                                />
                              </GridParliamentary>
                            </Box>
                          )
                        ) : (
                          ""
                        )}
                      </Box>
                    </div>
                  </div>
                )}
              </Grid>
              {/** END RIGHT */}
            </div>
          </>
        )}
        <LogoBottom>
          <img src={logoEnvsenseLight} />
        </LogoBottom>
      </div>
    );
  }
}

const styles = {
  slide: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    position: "absolute",
    top: "0px",
    left: "0px",
    zIndex: 998,
    "& .slide-item": {
      width: "100%",
      height: "100%",
      //backgroundColor: '#000000',
      position: "absolute",
      top: "0px !important",
      left: "0px !important",
      zIndex: 999,
    },
    "& .slide-item img": {
      width: "100% !important",
      maxHeight: "100% !important",
      position: "absolute !important",
    },
  },
};

VideowallPage.propTypes = {
  classes: PropTypes.object.isRequired,
  configurations: PropTypes.object.isRequired,
  settingsVotacoes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VideowallPage);
