import axios from "axios";
var tokenGet =
  sessionStorage.getItem("TOKEN_KEY") &&
  sessionStorage.getItem("TOKEN_KEY").charAt(0) == '"'
    ? sessionStorage.getItem("TOKEN_KEY")
    : JSON.stringify(sessionStorage.getItem("TOKEN_KEY"));
var token = JSON.parse(tokenGet);

const apiReport = axios.create({
  baseURL: process.env.REACT_APP_STAGE + "/report",
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + token,
  },
});

export default apiReport;
