const styles = {
  table: {
    fontFamily: "Nunito",
    width: "100%",
    borderCollapse: "collapse",
    border: "1px solid #43a047",
    boxShadow:
      "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(76, 175, 80,.4)",
    marginTop: "5px",
  },
  td: {
    border: "1px solid #43a047",
    textAlign: "center",
  },
  title: {
    border: "1px solid #43a047",
    width: "100%",
    textAlign: "center",
    fontWeight: "bold",
    backgroundColor: "#43a047",
    color: "white",
    marginTop: "20px",
    boxShadow:
      "0 4px 0px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(76, 175, 80,.4)",
  },
  tr: {
    "&:hover": {
      background: "#efefef",
    },
    "&:last-child": {
      borderRight: "solid 1px #cccccc",
    },
  },

  borderdark: { border: "1px solid #696969" },
  par: { backgroundcolor: "#cccccc" },
};

const stylesModal = {
  root: {
    display: "flex",
    justifyContent: "space-between",
    height: "250px",
  },
  list: {
    width: 350,
    height: 230,
    backgroundColor: "white",
    overflow: "auto",
  },
  button: {
    margin: "5px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    textAlign: "center",
  },
  cardtText: {
    minHeight: "auto",
    fontFamily: "sans-serif",
    fontSize: "16px",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
  },
};

export { styles, stylesModal };
