import React from "react";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Presence from "components/President/List/presence";
import { apiPresence } from "api/president.js";
import "../../assets/sass/components/president/tableft.scss";
import "../../assets/sass/styles.scss";

class TabLeft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      presentes: null,
      ausentes: null,
      count_presentes: null,
      count_ausentes: null,
    };
  }

  async componentDidMount() {
    let users = await apiPresence();
    this.setState({ presentes: _.get(users, "presentes", null) });
    this.setState({ ausentes: _.get(users, "ausentes", null) });
    this.setState({ count_presentes: _.get(users, "count_presentes", null) });
    this.setState({ count_ausentes: _.get(users, "count_ausentes", null) });

    this.props.echo.channel("session").listen("ParliamentaryOnline", (e) => {
      this.setState({ presentes: _.get(e, "presentes", null) });
      this.setState({ ausentes: _.get(e, "ausentes", null) });
      this.setState({ count_presentes: _.get(e, "count_presentes", null) });
      this.setState({ count_ausentes: _.get(e, "count_ausentes", null) });
    });
  }

  handleChange = (event) => {
    this.setState({ value: event });
  };

  render() {
    const {
      value,
      presentes,
      ausentes,
      count_presentes,
      count_ausentes,
    } = this.state;

    return (
      <div className="countUsers">
        <AppBar className="countUsers__tabs">
          <Tabs value={value} variant="scrollable">
            <Tab
              label={"Presentes ( " + count_presentes + " )"}
              onClick={() => this.handleChange(0)}
              value={0}
            />
            <Tab
              label={"Ausentes ( " + count_ausentes + " )"}
              onClick={() => this.handleChange(1)}
              value={1}
            />
          </Tabs>
        </AppBar>
        {value == 0 ? (
          <Presence users={presentes} classPresense="countUsers__present" />
        ) : (
          ""
        )}
        {value == 1 ? (
          <Presence users={ausentes} classPresense="countUsers__missing" />
        ) : (
          ""
        )}
      </div>
    );
  }
}

const styles = {
  root: {
    flexGrow: 1,
    width: "100%",
  },
};

TabLeft.propTypes = {
  echo: PropTypes.object.isRequired,
};

export default withStyles(styles)(TabLeft);
