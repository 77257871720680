import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
//import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TextField from "@material-ui/core/TextField";
import VideoVote from "components/Webcam/videoVote";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
//import ListSubheader from "@material-ui/core/ListSubheader";
import { apiVote } from "api/voting";
import { getSettingsByName } from "api/settings.js";
import _ from "lodash";
import PropTypes from "prop-types";

export default function ModalVoting(props) {
  const classes = useStyles();
  const { user_vote, openwebcam, descriptors, voting, id_session } = props;

  //const [nome_voting] = React.useState(_.get(voting, 'name', 'Projeto para Votação'))
  const [vote, setVote] = React.useState(-1);
  const [confimation] = React.useState(null);
  const [password, setPasssword] = React.useState(null);
  const [alert, setAlert] = React.useState(false);
  const docs = _.get(voting, "docs", null);
  const [alertSuccess, setAlertSuccess] = React.useState(false);
  const [settingsVotacao, setSettingsVotacao] = React.useState(null);

  const [can, setCan] = React.useState(openwebcam);
  //let configurationPlenary = JSON.parse(sessionStorage.getItem("CONFIGURATIONPLENARY"));
  //let habilitar_boton_abster = configurationPlenary ? configurationPlenary.find(config => config.alias =='habilitar_boton_abster') : {active : 0};

  const send = async () => {
    let user = JSON.parse(sessionStorage.getItem("USER"));

    let data = {
      vote: vote,
      id_session: id_session,
      id_user: _.get(user, "id", ""),
      password: password,
      public_name: _.get(user, "public_name", ""),
    };

    let voteUser = await apiVote(data, "/voting/bloc/vote");

    if (voteUser === 401) {
      setAlert(true);

      setTimeout(
        function() {
          setAlert(false);
        }.bind(this),
        2000
      );

      return;
    }

    if (user_vote == false || user_vote == null) {
      props.handleAlterarVoto();
    }

    setAlertSuccess(true);

    setTimeout(
      function() {
        setAlertSuccess(false);
        props.handleClose();
      }.bind(this),
      2000
    );
  };

  useEffect(() => {
    getSettingsByName('gerais').then((result) => {
      setSettingsVotacao(result.values.votacao);
    });
  }, []);

  const handleVote = (e) => {
    setVote(e);
  };

  const handleChangePassword = (e) => {
    setPasssword(e.target.value);
  };

  const setRef = (webcam) => {
    this.webcam = webcam;
  };

  const sendVerifique = async (file) => {
    let user = JSON.parse(sessionStorage.getItem("USER"));

    let data = {
      file: file,
      vote: vote,
      id_session: id_session,
      id_user: _.get(user, "id", ""),
    };
    let voteUser = await apiVote(data, "/voting/bloc/vote/face");

    if (voteUser === 401) {
      setAlert(true);

      setTimeout(
        function() {
          setAlert(false);
        }.bind(this),
        2000
      );

      return;
    }
    setAlertSuccess(true);

    setTimeout(
      function() {
        setAlertSuccess(false);
        props.handleClose();
      }.bind(this),
      2000
    );
  };

  return (
    <div>
      <div
        className="generalModal votingBloco-Styles"
      >
        <div className="modalContainer">
          <div>
            {confimation != null && openwebcam == 1 ? (
              ""
            ) : (
              <CardHeader className="modalContainer--header">
                <h2 className={classes.cardTitleWhite}>Votação em Bloco</h2>
              </CardHeader>
            )}
            {vote == -1 ? (
              <div className="modalContainer--body">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className="votingBloco--list">
                      <h3>Documentos a serem votados</h3>

                      <List className={classes.root} subheader={<li />}>
                        <li className={classes.listSection}>
                          <ul className={classes.ul}>
                            {docs.map((item) => (
                              <ListItem key={item.id} className="list-voting">
                                <ListItemText primary={` ${item.name}`} />
                                <hr></hr>
                              </ListItem>
                            ))}
                          </ul>
                        </li>
                      </List>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} className="m-1">
                    <Button
                      onClick={() => handleVote(true)}
                      className="componentButton componentButton--green-outline"
                    >
                      SIM
                    </Button>
                    <Button
                      onClick={() => handleVote(false)}
                      className="componentButton componentButton--red-outline"
                    >
                      NÃO
                    </Button>
                    {settingsVotacao && settingsVotacao.enableAbs && (
                      <Button
                          onClick={() => handleVote(null)}
                          className="componentButton componentButton--blue-outline"
                      >
                        ABSTER
                      </Button>
                    )}
                    {/*
                      habilitar_boton_abster.active===  1 ?
                          <Button
                              onClick={() => handleVote(null)}
                              className="componentButton componentButton--blue-outline"
                          >
                            ABSTER
                          </Button>
                          : ''
                    */}
                  </GridItem>
                </GridContainer>
              </div>
            ) : can == 0 ? (
              <>
                {" "}
                <CardBody className="modalContainer--body">
                <div className="boxPassword">
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Senha"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={handleChangePassword}
                  />
                  <Button
                    variant="contained"
                    className="componentButton componentButton--green"
                    onClick={() => send()}
                  >
                    Enviar Voto
                  </Button>
                </div>
                </CardBody>
                <div className="modalFooter__vote">
                  <div
                    className="modalFooter__vote--voltar"
                    variant="contained"
                    onClick={() => setVote(-1)}
                  >
                    <i className="fa-solid fa-arrow-rotate-left"></i>
                    Voltar
                  </div>
                  <div
                    className="modalFooter__vote--facial"
                    variant="contained"
                    onClick={() => setCan(1)}
                  >
                    Enviar por <span>Reconhecimento facial</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <VideoVote
                  sendVerifique={sendVerifique}
                  ref={setRef}
                  json_data={descriptors}
                />
                <div className="modalFooter__vote">
                  <div
                    className="modalFooter__vote--voltar"
                    variant="contained"
                    onClick={() => setVote(-1)}
                  >
                    <i className="fa-solid fa-arrow-rotate-left"></i>
                    Voltar
                  </div>
                  <div
                    className="modalFooter__vote--senha"
                    variant="contained"
                    onClick={() => setCan(0)}
                  >
                    Inserir <span>Senha</span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Snackbar
        place="tr"
        color="danger"
        icon={AddAlert}
        message="Credenciais Incorretas"
        open={alert}
        closeNotification={() => setAlert(false)}
        close
      />
      <Snackbar
        place="tr"
        color="success"
        icon={AddAlert}
        message="Seu voto foi computado com sucesso"
        open={alertSuccess}
        closeNotification={() => setAlertSuccess(false)}
        close
      />
    </div>
  );
}

const styles = {
  root: {
    width: "100%",
    //smaxWidth: 460,
    position: "relative",
    overflow: "auto",
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "24px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
  },
};

ModalVoting.propTypes = {
  classes: PropTypes.object.isRequired,
  user_vote: PropTypes.object,
  openwebcam: PropTypes.number,
  descriptors: PropTypes.object,
  voting: PropTypes.object,
  id_session: PropTypes.number,
  handleAlterarVoto: PropTypes.func,
  handleVote: PropTypes.func,
  handleChangePassword: PropTypes.func,
  send: PropTypes.func,
  sendVerifique: PropTypes.func,
  setRef: PropTypes.func,
  setVote: PropTypes.func,
  setCan: PropTypes.func,
  setAlert: PropTypes.func,
  setAlertSuccess: PropTypes.func,
  handleClose: PropTypes.func,
};

const useStyles = makeStyles(styles);
