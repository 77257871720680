import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { apiSalveUsers } from "api/user.js";
import { mask, unMask } from "remask";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import _ from "lodash";
import moment from "moment";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import ModalPhoto from "./ModalPhoto";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import NativeSelect from "@material-ui/core/NativeSelect";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import api from "api/api_v2";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CardActionArea from "@material-ui/core/CardActionArea";
import api_v1 from "api/typedocument";
import { generateHash } from "api/user.js";
import PropTypes from "prop-types";

export default function ModalUser(props) {
  const classes = useStyles();
  const { user } = props;
  let legislativo = JSON.parse(sessionStorage.getItem("LEGISLATIVO"));

  const [user_cargo, setCargo] = React.useState(
    _.get(user, "id_user_office", "")
  );
  const [user_acesso, setAcesso] = React.useState(
    _.get(user, "id_access_level", null)
  );
  const [user_partido, setPartido] = React.useState(
    _.get(user, "id_political_parties", null)
  );
  const [name, setName] = React.useState(_.get(user, "name", ""));
  const [email, setEmail] = React.useState(_.get(user, "email", ""));
  const [cpf, setCpf] = React.useState(_.get(user, "cpf", ""));
  const [publicname, setPublicName] = React.useState(
    _.get(user, "public_name", null)
  );
  const [cpassword, setCpassword] = React.useState(_.get(user, "password", ""));
  const [password, setPassword] = React.useState(_.get(user, "password", ""));
  const [telephone, setTelephone] = React.useState(
    _.get(user, "telephone", "")
  );
  const [cellphone, setCellphone] = React.useState(
    _.get(user, "cellphone", "")
  );
  const [id] = React.useState(_.get(user, "id", ""));
  const [photo, setPhoto] = React.useState("");
  const [descriptionFace, setDescriptionFace] = React.useState(null);
  const [alertError, setAlertError] = React.useState(false);
  const [messageError, setMessageErrot] = React.useState("");
  const [alertSuccess, setAlertSuccess] = React.useState(false);
  const [fileName, setfileName] = React.useState(null);
  const [fileFaceName, setFileFace] = React.useState(null);
  const [legislatura, setLegislatura] = React.useState(
    _.get(user, "legislaturas", [])
  );
  const [modal_photo, setModalPhoto] = React.useState(false);
  const [capture_photo, setCapturePhoto] = React.useState(null);
  const [validation, setValidation] = React.useState([]);
  const [enable, setEnable] = React.useState(
    _.get(user, "status", 1) === 1 ? true : false
  );
  const [edit_calendar, setEditCalendar] = React.useState(
    _.get(user, "calendar_edit", 0) === 1 ? true : false
  );
  const [pincode, setPincode] = React.useState(
    _.get(user, "profile.assing.pincode", null)
  );
  const [idfolder, setIdFolder] = React.useState(
    _.get(user, "folder_only_office_id", null)
  );
  const [certificado, setCertificado] = React.useState(null);
  const [photo_assing, setPhotoAssin] = React.useState(null);
  const [fileNameAss] = React.useState(null);
  const [departaments, setDepartaments] = React.useState([]);
  const [departament, setDepartament] = React.useState(
    _.get(user, "profile.id_departamental", null)
  );
  const cargos = JSON.parse(sessionStorage.getItem("CARGOS"));
  const partidos = JSON.parse(sessionStorage.getItem("PARTIDOS"));
  const acessos = JSON.parse(sessionStorage.getItem("NIVEIS"));
  const legislaturas = JSON.parse(sessionStorage.getItem("LEGISLATURAS"));
  const [comissao, setComissao] = React.useState(
    _.get(user, "profile.committee", [])
  );
  const [comissoes, setComissoes] = React.useState([]);
  const [ft, setFt] = React.useState(_.get(user, "profile.assign_file", []));
  const [ft2] = React.useState([]);
  const [user_assessor, setUserAssessor] = React.useState(
    _.get(user, "id_user_assessor", null)
  );
  const [vereadores, setVereadores] = React.useState([]);
  const [disabled_inputs, setDisableInputs] = React.useState(
    React.useState(
      _.get(user, "id_access_level", null) === 4 ||
        _.get(user, "id_access_level", null) === 3
        ? false
        : true
    )
  );
  const [disabled_inputs_cert, setDisableInputsCert] = React.useState(
    React.useState(_.get(user, "id_access_level", null) === 8 ? true : false)
  );
  const [fileCertificado, setFileCertificado] = React.useState(
    _.get(user, "profile.signatures[0].file", null) !== null
      ? "Certificado adcionado ✔"
      : null
  );

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const acss = (acss) => {
    if (parseInt(acss) === 3 || parseInt(acss) === 4) {
      setDisableInputs(false);
      setDisableInputsCert(false);
      return;
    }

    if (parseInt(acss) === 8) {
      setDisableInputs(true);
      setDisableInputsCert(true);
      return;
    }
    setDisableInputsCert(false);
    setDisableInputs(true);
  };

  const handleChange = (event) => {
    setLegislatura(event.target.value);
  };

  const handlePhotoCapture = (param) => {
    setCapturePhoto(param);
  };

  useEffect(() => {
    const fetchData = async () => {
      await api
        .get("/departament")
        .then((response) => setDepartaments(_.get(response, "data", [])));
      await api
        .get("/commitee")
        .then((response) => setComissoes(_.get(response, "data", [])));
      await api_v1
        .get("/users/parlamentaries")
        .then((response) => setVereadores(_.get(response, "data", [])));
    };
    fetchData();
  }, []);

  const sendUser = async () => {
    if (cpf) {
      var numberCPF = cpf.replace(/\./g, "").replace(/\-/g, ""); //eslint-disable-line
      if (TestaCPF(numberCPF) === false) {
        setMessageErrot("Verifique o CPF inserido");
        setAlertError(true);
        setTimeout(() => {
          setAlertError();
        }, 4000);
        return;
      }
    }
    setValidation([]);
    let route = id === "" ? "/users/register" : `/users/update/${id}`;
    if (
      disabled_inputs === false &&
      (user_partido === null || legislatura[0] === null || publicname === null)
    ) {
      data = {
        id_political_parties: user_partido === null ? "Campo requirido" : "",
        legislatura: legislatura[0] === null ? "Campo requirido" : "",
        public_name: publicname === null ? "Campo requirido" : "",
      };
      setValidation(data);
      return;
    }
    var formdata = new FormData();

    if (disabled_inputs === false) {
      formdata.append("id_political_parties", user_partido);
      formdata.append("public_name", publicname);
    }
    formdata.append("name", name);
    formdata.append("cpf", cpf);
    formdata.append("id_user_office", user_cargo);
    formdata.append("legislaturas", [legislatura]);
    formdata.append("telephone", telephone);
    formdata.append("cellphone", cellphone);
    formdata.append("id_access_level", user_acesso);
    formdata.append("email", email);
    formdata.append("status", enable ? 1 : 0);
    formdata.append("calendar_edit", edit_calendar ? 1 : 0);
    formdata.append("id_departamental", departament);
    formdata.append("folder_only_office_id", idfolder);
    if (user_assessor !== null) {
      formdata.append("id_user_assessor", user_assessor);
    }

    formdata.append("comissao", JSON.stringify(comissao));

    if (id === "" || (id !== "" && password !== "")) {
      formdata.append("c_password", generateHash(cpassword));
      formdata.append("password", generateHash(password));
    }

    var object = {};
    formdata.forEach((value, key) => (object[key] = value));
    var json = JSON.stringify(object);

    console.log("object", object)

    
    var formdata = new FormData();

    if (photo) formdata.append("photo", photo);
    if (capture_photo) formdata.append("capture_photo", capture_photo);
    if (descriptionFace) formdata.append("description", descriptionFace);
    console.log("descriptionFace", descriptionFace)

    // let resposta = await apiSalveUsers(formdata, route) //old
    let resposta = await apiSalveUsers(
      // [],
      formdata,
      route + "?data=" + generateHash(json)

      
      );
      console.log("resposta", resposta)
    if (!resposta.errors) {
      let err = 0;
      let param_id = id ? id : resposta.user.id;

      // if (photo) formdata.append("photo", photo);
      // if (capture_photo) formdata.append("capture_photo", capture_photo);
      // if (descriptionFace) formdata.append("description", descriptionFace);

      if (photo || capture_photo || descriptionFace) {
        await apiSalveUsers(formdata, "/users/update/" + param_id).catch(
          function() {
            err = 1;
            setMessageErrot("Houve algo de errado ao cadastrar photo");
            setAlertError(true);
            setTimeout(() => {
              setAlertError();
            }, 4000);
          }
        );
      }

      if (photo_assing) {
        var form = new FormData();
        form.append("id_user", param_id);
        form.append("file", photo_assing);
        await api.post("digital-signatures/assign-file", form);
      }
      if (legislativo === 1 && certificado !== null) {
        var data = new FormData();
        data.append("certPin", generateHash(pincode));
        data.append("id_user", param_id);
        data.append("identification", cpf);
        data.append("certFile", certificado);

        await api
          .post("digital-signatures/cert-add", data)
          .catch(function (error) {
            err = 1;
            if (error.response.data.erro) {
              setMessageErrot(error.response.data.erro);
            } else {
              setMessageErrot("Houve algo de errado ao cadastrar certificado, certifique que suas informações estão corretas");
            }
            
            setAlertError(true);
            setTimeout(() => {
              setAlertError();
            }, 4000);
          });
      }
      if (err == 1) {
        return;
      }
      setAlertSuccess(true);
      setTimeout(() => {
        setAlertSuccess();
        props.handleClick();
      }, 1000);

      return;
    }

    setValidation(
      _.get(resposta, "errors", "Algo de Errado no retorno da API")
    );
    setMessageErrot(
      "Houve algo de errado ao cadastrar usuario, certifique que suas informações estão corretas"
    );
    setAlertError(true);
    setTimeout(() => {
      setAlertError();
    }, 4000);
    return false;
  };

  const handleChangeEnable = (e) => {
    setEnable(e.target.checked);
  };

  const handleChangeCalendar = (e) => {
    setEditCalendar(e.target.checked);
  };

  const handleUploadFile = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      setPhoto(e.target.result);
      setfileName("foto adicionada");
    };
  };

  const handleUploadFileCertificado = (e) => {
    setCertificado(e.target.files[0]);
    setFileCertificado("Certificado Adicionado");
  };
  const handleUploadFileAssinatura = (e) => {
    setPhotoAssin(e.target.files[0]);
    // setFileNameAss('foto da assinatura adicionada')
    let fotoassing = URL.createObjectURL(e.target.files[0]);
    setFt(fotoassing);
  };

  const handleFileInput = (e) => {
    setDescriptionFace(e.target.files[0]);
    setFileFace("Reconhecimento Adicionado");
  };

  const hadleCargo = (event) => {
    setCargo(event.target.value);
  };
  const hadlePartido = (event) => {
    setPartido(event.target.value);
  };
  const hadleAcesso = (event) => {
    setAcesso(event.target.value);
    acss(event.target.value);
    if (event.target.value !== 8) {
      setUserAssessor(null);
    }
  };

  const handlePhoto = () => {
    setModalPhoto(true);
  };

  const handlePhotoClose = () => {
    setModalPhoto(false);
  };

  const hadleForm = (event) => {
    switch (event.target.name) {
      case "name":
        return setName(event.target.value);
      case "email":
        return setEmail(event.target.value);
      case "cpf":
        return setCpf(mask(unMask(event.target.value), ["999.999.999-99"]));
      case "telephone":
        return setTelephone(mask(unMask(event.target.value), ["99-9999-9999"]));
      case "cellphone":
        return setCellphone(
          mask(unMask(event.target.value), ["99-9999-99999"])
        );
      case "publicname":
        return setPublicName(event.target.value);
      case "password":
        return setPassword(event.target.value);
      case "cpassword":
        return setCpassword(event.target.value);
      default:
        return "";
    }
  };

  const flatProps = {
    options: comissoes,
    getOptionLabel: (option) => option.name,
  };

  const TestaCPF = (strCPF) => {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF.match(/(\d)\1{10}/)) return false;

    for (let i = 1; i <= 9; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
  };

  return (
    <div>
      <GridContainer style={{ display: "flex", justifyContent: "center" }}>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="success" className={classes.cardHeader}>
              <h4 className={classes.cardTitleWhite}>Gerenciar Usuário</h4>
              <span
                className="material-icons"
                onClick={() => props.handleClick()}
                style={{ cursor: "pointer" }}
              >
                close
              </span>
            </CardHeader>
            <CardBody style={{ overflow: "auto", height: "60vh" }}>
              <GridContainer>
                <GridItem xs={6} sm={6} md={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={enable}
                        onChange={handleChangeEnable}
                        color="primary"
                        value="dynamic-class-name"
                      />
                    }
                    label="Ativo"
                  />
                </GridItem>
                {/* Calendar edit */}
                <GridItem xs={6} sm={6} md={10}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={edit_calendar}
                        onChange={handleChangeCalendar}
                        color="primary"
                        value="dynamic-class-name"
                      />
                    }
                    label="Edição do Calendário"
                  />
                </GridItem>
              </GridContainer>
              <GridContainer style={{ padding: "10px" }}>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl className={classes.form}>
                    <InputLabel className={classes.label}>
                      {"Nome Completo"}
                    </InputLabel>
                    <Input name="name" value={name} onChange={hadleForm} />
                  </FormControl>
                  <p className={classes.validation}>
                    {_.get(validation, "name", "")}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl className={classes.form}>
                    <InputLabel type="email" className={classes.label}>
                      {"Email"}
                    </InputLabel>
                    <Input
                      autoComplete="off"
                      name="email"
                      value={email}
                      onChange={hadleForm}
                    />
                  </FormControl>
                  <p className={classes.validation}>
                    {_.get(validation, "email", "")}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl className={classes.form}>
                    <InputLabel className={classes.label}>{"CPF"}</InputLabel>
                    <Input
                      name="cpf"
                      value={cpf}
                      onChange={hadleForm}
                      autoComplete="off"
                    />
                  </FormControl>
                  <p className={classes.validation}>
                    {_.get(validation, "cpf", "")}
                  </p>
                </GridItem>
              </GridContainer>
              <GridContainer style={{ padding: "10px" }}>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl className={classes.form}>
                    <InputLabel className={classes.label}>
                      {"Telefone - (DDD)"}
                    </InputLabel>
                    <Input
                      name="telephone"
                      value={telephone}
                      onChange={hadleForm}
                    />
                  </FormControl>
                  <p className={classes.validation}>
                    {_.get(validation, "telephone", "")}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl className={classes.form}>
                    <InputLabel className={classes.label}>
                      {"Celular - (DDD)"}
                    </InputLabel>
                    <Input
                      name="cellphone"
                      value={cellphone}
                      onChange={hadleForm}
                    />
                  </FormControl>
                </GridItem>
                <p className={classes.validation}>
                  {_.get(validation, "cellphone", "")}
                </p>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl
                    className={classes.form}
                    style={{ minWidth: 120 }}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      className={classes.label}
                    >
                      Cargo
                    </InputLabel>
                    <NativeSelect
                      id="cargo"
                      value={user_cargo}
                      name="cargo"
                      onChange={hadleCargo}
                    >
                      <option value={""}></option>
                      {cargos.map((cargo, i) => (
                        <option key={i} value={cargo.id}>
                          {cargo.name}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                  <p className={classes.validation}>
                    {_.get(validation, "id_user_office", "")}
                  </p>
                </GridItem>
              </GridContainer>
              <GridContainer style={{ padding: "10px" }}>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl className={classes.form}>
                    <InputLabel className={classes.label}>
                      {"Apelido Eleitoral"}
                    </InputLabel>
                    <Input
                      disabled={disabled_inputs}
                      name="publicname"
                      value={publicname}
                      onChange={hadleForm}
                    />
                  </FormControl>
                  <p className={classes.validation}>
                    {_.get(validation, "public_name", "")}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl className={classes.form}>
                    <InputLabel className={classes.label}>{"Senha"}</InputLabel>
                    <Input
                      type="password"
                      name="password"
                      value={password}
                      onChange={hadleForm}
                    />
                  </FormControl>
                  <p className={classes.validation}>
                    {_.get(validation, "password", "")}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl className={classes.form}>
                    <InputLabel className={classes.label}>
                      {"Repita a Senha"}
                    </InputLabel>
                    <Input
                      type="password"
                      name="cpassword"
                      value={cpassword}
                      onChange={hadleForm}
                    />
                  </FormControl>
                  <p className={classes.validation}>
                    {_.get(validation, "c_password", "")}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl
                    className={classes.form}
                    style={{ minWidth: 120 }}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      className={classes.label}
                    >
                      Acesso
                    </InputLabel>
                    <NativeSelect
                      labelId="demo-simple-select-label"
                      id="acesso"
                      value={user_acesso}
                      name="acesso"
                      onChange={hadleAcesso}
                    >
                      <option value={""}></option>
                      {acessos.map((acesso, i) => (
                        <option key={i} value={acesso.id}>
                          {acesso.name}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                  <p className={classes.validation}>
                    {_.get(validation, "id_access_level", "")}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl
                    className={classes.form}
                    style={{ minWidth: 120 }}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      className={classes.label}
                    >
                      Vereador
                    </InputLabel>
                    <NativeSelect
                      labelId="demo-simple-select-label"
                      id="Assessor"
                      disabled={
                        user_acesso === "8" || user_acesso === 8 ? false : true
                      }
                      value={user_assessor}
                      name="Assessor"
                      onChange={(e) => setUserAssessor(e.target.value)}
                    >
                      <option value={""}></option>
                      {vereadores.map((vereador, i) => (
                        <option key={i} value={vereador.id}>
                          {vereador.name}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                  <p className={classes.validation}>
                    {_.get(validation, "id_access_level", "")}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl
                    className={classes.form}
                    style={{ minWidth: 120 }}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      className={classes.label}
                    >
                      Partidos
                    </InputLabel>
                    <NativeSelect
                      id="partido"
                      value={user_partido}
                      name="partido"
                      onChange={hadlePartido}
                      disabled={disabled_inputs}
                    >
                      <option value={""}></option>
                      {partidos.map((partido, i) => (
                        <option key={i} value={partido.id}>
                          {partido.name}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                  <p className={classes.validation}>
                    {_.get(validation, "id_political_parties", "")}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl
                    className={classes.form}
                    style={{ minWidth: 120 }}
                  >
                    <InputLabel id="demo-mutiple-checkbox-label">
                      Legislaturas
                    </InputLabel>
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      style={{ width: "150px" }}
                      value={legislatura}
                      onChange={handleChange}
                      input={<Input />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                      disabled={disabled_inputs}
                    >
                      {legislaturas.map((prop) => (
                        <MenuItem key={prop.inicio} value={prop.inicio}>
                          <Checkbox
                            checked={legislatura.indexOf(prop.inicio) > -1}
                          />
                          <ListItemText
                            primary={moment(prop.inicio).format("DD/MM/YYYY")}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <p className={classes.validation}>
                    {_.get(validation, "legislatura", "")}
                  </p>
                </GridItem>
                {legislativo === 1 ? (
                  <>
                    <GridItem xs={12} sm={12} md={4}>
                      <FormControl
                        className={classes.form}
                        style={{ minWidth: 120 }}
                      >
                        <InputLabel
                          id="demo-simple-select-label"
                          className={classes.label}
                        >
                          Departamentos
                        </InputLabel>
                        <NativeSelect
                          id="partido"
                          value={departament}
                          name="partido"
                          onChange={(e) => {
                            setDepartament(e.target.value);
                          }}
                        >
                          <option value={""}></option>

                          {departaments.map((partido, i) => (
                            <option key={i} value={partido.id}>
                              {partido.name}
                            </option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <FormControl className={classes.form}>
                        <InputLabel className={classes.label}>
                          {"pincode"}
                        </InputLabel>
                        <Input
                          type="password"
                          name="pincode"
                          value={pincode}
                          onChange={(e) => {
                            setPincode(e.target.value);
                          }}
                        />
                      </FormControl>
                      <p className={classes.validation}>
                        {_.get(validation, "certPin", "")}
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <FormControl className={classes.form}>
                        <InputLabel className={classes.label}>
                          {"Id Arquivo"}
                        </InputLabel>
                        <Input
                          type="text"
                          name="id_folder"
                          value={idfolder}
                          onChange={(e) => {
                            setIdFolder(e.target.value);
                          }}
                        />
                      </FormControl>
                      <p className={classes.validation}>
                       {_.get(validation, "id_folder", "")}
                      </p>
                    </GridItem>
                  </>
                ) : (
                  ""
                )}
              </GridContainer>

              {legislativo === 1 ? (
                <GridContainer style={{ padding: "10px" }}>
                  <GridItem style={{ display: "none" }} xs={12} sm={12} md={3}>
                    <FormControl
                      className={classes.form}
                      style={{ minWidth: 260 }}
                    >
                      <Autocomplete
                        multiple
                        {...flatProps}
                        value={comissao}
                        id="controlled-demo"
                        onChange={(event, newValue) => {
                          setComissao(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Comissões" />
                        )}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <FormControl className={classes.form}>
                      <Button
                        variant="contained"
                        component="label"
                        value={photo}
                        style={{ backgroundColor: "#4AA2A2", color: "white" }}
                        disabled={disabled_inputs_cert}
                      >
                        Certificado digital
                        <input
                          type="file"
                          style={{ display: "none" }}
                          onChange={handleUploadFileCertificado}
                        />
                      </Button>
                      <p style={{ fontSize: "12px", marginTop: "0px" }}>
                        {fileCertificado || ""}
                      </p>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <FormControl className={classes.form}>
                      <Button
                        variant="contained"
                        component="label"
                        value={photo_assing}
                        style={{ backgroundColor: "#4AA2A2", color: "white" }}
                      >
                        Foto Da Assinatura
                        <input
                          type="file"
                          accept="image/png"
                          style={{ display: "none" }}
                          onChange={handleUploadFileAssinatura}
                        />
                      </Button>
                    </FormControl>
                    <p style={{ fontSize: "12px", marginTop: "0px" }}>
                      {fileNameAss || ""}
                    </p>
                  </GridItem>
                  <GridItem>
                    <CardActionArea
                      style={{
                        width: "150px",
                        height: "150px",
                        padding: "15px",
                      }}
                    >
                      <img width="100%" src={ft} />
                    </CardActionArea>
                  </GridItem>
                </GridContainer>
              ) : (
                ""
              )}
              <GridContainer style={{ padding: "10px" }}>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl className={classes.form}>
                    <Button
                      variant="contained"
                      component="label"
                      value={photo}
                      style={{ backgroundColor: "#4AA2A2", color: "white" }}
                    >
                      Foto
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleUploadFile}
                      />
                    </Button>
                  </FormControl>
                  <p style={{ fontSize: "12px", marginTop: "0px" }}>
                    {fileName || ""}
                  </p>
                </GridItem>
                <GridItem>
                  <CardActionArea
                    style={{ width: "150px", height: "150px", padding: "15px" }}
                  >
                    <img width="100%" src={ft2} />
                  </CardActionArea>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl className={classes.form}>
                    <Button
                      variant="contained"
                      component="label"
                      value={descriptionFace}
                      style={{ backgroundColor: "#4AA2A2", color: "white" }}
                    >
                      Reconhecimento
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleFileInput}
                        accept="image/*"
                      />
                    </Button>
                  </FormControl>
                  <p style={{ fontSize: "12px", marginTop: "0px" }}>
                    {fileFaceName || ""}
                  </p>
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <FormControl className={classes.form}>
                    <Button
                      variant="contained"
                      component="label"
                      value={descriptionFace}
                      style={{ backgroundColor: "#4AA2A2", color: "white" }}
                      onClick={handlePhoto}
                    >
                      Tirar foto
                    </Button>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter style={{ padding: "10px" }}>
              <Button onClick={sendUser} color="success">
                Salvar
              </Button>
            </CardFooter>
          </Card>
          <Snackbar
            place="tr"
            color="danger"
            icon={AddAlert}
            message={messageError}
            open={alertError}
            closeNotification={() => setAlertError(false)}
            close
          />
          <Snackbar
            place="tr"
            color="success"
            icon={AddAlert}
            message="Cadastrado com sucesso"
            open={alertSuccess}
            closeNotification={() => setAlertSuccess(false)}
            close
          />
        </GridItem>
      </GridContainer>
      <Modal
        className={classes.modal}
        open={modal_photo}
        onClose={handlePhotoClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal_photo}>
          <ModalPhoto
            handlePhotoClose={handlePhotoClose}
            open={modal_photo}
            handlePhoto={handlePhotoCapture}
          />
        </Fade>
      </Modal>
    </div>
  );
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "16px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
  },
  validation: {
    color: "red",
    fontSize: "12px",
    marginTop: "0px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const useStyles = makeStyles(styles);

ModalUser.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  handleClick: PropTypes.func  
};