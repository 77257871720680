import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import FormControl from "@material-ui/core/FormControl";
import _ from "lodash";
import TableModalStatus from "../Table/TableModalStatus";
import TableModalStatusSelecionado from "../Table/TableModalStatusSelect";
import api from "api/api_v2";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.js";

class ModalIntegration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      array1: [],
      array2: [],
      title: null,
      text_alone: null,
      id: props.idSession,
      parts: [],
      partLeitura: null,
      partVotacao: null,
      user: JSON.parse(sessionStorage.getItem("USER")),
      propositions: [],
      id_type_proposition: null,
      urgent: false,
    };
  }

  async componentDidMount() {
    await api
      .get("/search/document/session")
      .then((response) =>
        this.setState({ array1: _.get(response, "data", []) })
      );
    await api.get("/session/time").then((response) => {
      this.setState({ parts: _.get(response, "data", []) });
      this.setState({ partLeitura: _.get(response, "data[0].id", null) });
      this.setState({ partVotacao: _.get(response, "data[0].id", null) });
    });
    await api
      .get("/type-proposition")
      .then((response) =>
        this.setState({ propositions: _.get(response, "data", []) })
      );
  }

  onChangeInputTitle = async (e) => {
    const { id_type_proposition } = this.state;
    this.setState({ title: e.target.value });
    await api
      .get("/search/document/session", {
        params: {
          title: e.target.value,
          type_proposition: id_type_proposition,
        },
      })
      .then((response) =>
        this.setState({ array1: _.get(response, "data", []) })
      );
  };

  onChangeInputText = async (e) => {
    const { id_type_proposition } = this.state;
    this.setState({ text_alone: e.target.value });
    await api
      .get("/search/document/session", {
        params: {
          text_alone: e.target.value,
          type_proposition: id_type_proposition,
        },
      })
      .then((response) =>
        this.setState({ array1: _.get(response, "data", []) })
      );
  };

  handleTypeProposition = async (e) => {
    const { title } = this.state;

    this.setState({ id_type_proposition: e.target.value });
    await api
      .get("/search/document/session", {
        params: { type_proposition: e.target.value, title: title },
      })
      .then((response) =>
        this.setState({ array1: _.get(response, "data", []) })
      );
  };

  handleIntegrar = async () => {
    const { array2, user, id, partLeitura, partVotacao } = this.state;

    await api
      .post("session/documents", {
        documents: array2,
        id_session: id,
        // id_part_leitura: partLeitura,
        // id_party: partVotacao,
        id_user: user.id,
      })
      .then(() => {
        //CALL component/Session/session.js
        this.props.handleClick();
      });
  };

  handlePartLeitura = (e, column) => {
    const { array1 } = this.state;
    array1.find(function (item) {
      if (item.id === column.id) {
          item.id_part_leitura = e;
      }
    });

    this.setState({ array1: array1 });
  };

  handlePartVotacao = (e, column) => {
    const { array1 } = this.state;
    array1.find(function (item) {
      if (item.id === column.id) {
        item.id_party = e;
      }
    });

    this.setState({ array1: array1 });
  };

  handleSelect = (param) => {
    const { array2 } = this.state;

    const itemIndex = array2.findIndex((element) => element.id === param.id);

    if (itemIndex !== -1) {
      const updatedArray = [...array2];
      updatedArray.splice(itemIndex, 1);
      this.setState({ array2: updatedArray });
    } else {
      this.setState((prevState) => ({
        array2: [...prevState.array2, param],
      }));
    }
  };

  handleRemoveLeitura = (column) => {
    const { array1 } = this.state;
    array1.find((item) => {
      if (item.id === column.id) {
        if (_.get(item, "add_leitura", null) === true) {
          item.add_leitura = false;
          
          if (_.get(item, "id_part_leitura", null)){
            delete item.id_part_leitura;
          }
        }
      }
    });

    this.setState({ array1: array1 });
  };

  handleSelectLeitura = (column) => {
    const { array1 } = this.state;
    array1.find(function(item) {
      if (item.id === column.id) {
        if (_.get(item, "add_leitura", null) === true) {
          item.add_leitura = false;
        } else {
          item.add_leitura = true;
        }
      }
    });

    this.setState({ array1: array1 });
  };

  handleRemoveSecret = (column) => {
    const { array1 } = this.state;
    array1.find((item) => {
      if (item.id === column.id) {
        if (_.get(item, "secret", null) === true) {
          item.secret = false;
        }
      }
    });
    this.setState({ array1: array1 });
  };

  handleSelectSecret = (column) => {
    const { array1 } = this.state;
    array1.find(function (item) {
      if (item.id === column.id) {
        if (_.get(item, "secret", null) === true) {
          item.secret = false;
        } else {
          item.secret = true;
        }
      }
    });

    this.setState({ array1: array1 });
  };

  handleSelectFirstVoting = (column) => {
    const { array1 } = this.state;
    array1.find(function(item) {
      if (item.id === column.id) {
        if (_.get(item, "add_first_voting", null) === true) {
          item.add_first_voting = false;
        } else {
          item.add_first_voting = true;
        }
      }
    });
    this.setState({ array1: array1 });
  };

  handleSelectSecundVoting = (column) => {
    const { array1 } = this.state;
    array1.find(function(item) {
      if (item.id === column.id) {
        if (_.get(item, "add_second_voting", null) === true) {
          item.add_second_voting = false;
        } else {
          item.add_second_voting = true;
        }
      }
    });
    this.setState({ array1: array1 });
  };

  handleSelectUrgent = (column) => {
    const { array1 } = this.state;
    array1.find(function(item) {
      if (item.id === column.id) {
        if (_.get(item, "urgent", null) === true) {
          item.urgent = false;
        } else {
          item.urgent = true;
        }
      }
    });
    this.setState({ array1: array1 });
  };

  handleSelectAll = () => {
    const { array1, array2 } = this.state;
    if (array1.length === array2.length) {
      const resetArray1 = array1.map((item) => ({
        ...item,
        add_leitura: false,
        add_first_voting: false,
        add_second_voting: false,
        secret: false,
        urgent: false,
      }));
      this.setState({ array2: [], array1: resetArray1, selectAll: false });
    } else {
      this.setState({ array2: array1, selectAll: true });
    }
  };

  render() {
    const { name } = this.props;
    const {
      id_type_proposition,
      propositions,
      parts,
      title,
      array1,
      array2,
      selectAll
    } = this.state;

    const isButtonDisabled =
      array2.length === 0

    return (
      <GridContainer
        style={{ display: "flex", justifyContent: "center", alignItems: "center", with: "100%", height: "100%" }}
      >
        <GridItem xs={12} sm={12} md={11}>
          <Card>
            {/* Cabeçalho Modal */}
            <CardHeader
              className="cardHeaderMIntegration"
              color="success"
              style={{
                background: "linear-gradient(60deg, #3F9241, #009F87)",
              }}
            >
              {name}
              <span
                className="material-icons"
                onClick={() => this.props.onClose()}
              >
                close
              </span>
            </CardHeader>

            <CardBody className="cardBody">
              <GridContainer xs={12} sm={12} md={12}>
                {/* Tabela Modal */}
                <Grid xs={12} sm={12} md={9} className="grid">
                  <TableModalStatus
                    tableHeaderColor="primary"
                    tableHead={["Nome", "Ordem"]}
                    tableData={array1}
                    selected={array2}
                    handleSelectUrgent={this.handleSelectUrgent}
                    handleSelectLeitura={this.handleSelectLeitura}
                    handleRemoveLeitura={this.handleRemoveLeitura}
                    handleSelectSecret={this.handleSelectSecret}
                    handleRemoveSecret={this.handleRemoveSecret}
                    handleSelectFirstVoting={this.handleSelectFirstVoting}
                    handleSelectSecundVoting={this.handleSelectSecundVoting}
                    handleSelect={this.handleSelect}
                    handleSelectAll={this.handleSelectAll}
                    parts={parts}
                    handlePartLeitura={this.handlePartLeitura}
                    handlePartVotacao={this.handlePartVotacao}
                    propositions={propositions}
                    handleTypeProposition={this.handleTypeProposition}
                    selectAll={selectAll}
                  />
                </Grid>

                {/* Projetos selecionados */}
                <Grid xs={12} sm={12} md={3}>
                  <div className="containerSearch">
                    {/** Pesquisa por titulo **/}
                    <div className="inputContaine">
                      <input
                        type="search"
                        name="title"
                        value={title}
                        onChange={this.onChangeInputTitle}
                        autoComplete="off"
                      />
                      <label htmlFor="searchForTitle">
                        Pesquise pelo titulo{" "}
                      </label>
                    </div>

                    {/** Pesquisa por documento **/}
                    <div className="inputContaine">
                      <select
                        id="demo-mutiple-checkbox"
                        value={id_type_proposition}
                        onChange={this.handleTypeProposition}
                      >
                        <option>---</option>
                        {propositions.map((prop) => (
                          <option key={prop.inicio} value={prop.id}>
                            {prop.name}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="searchForTitle">Tipo de documento </label>
                    </div>
                  </div>

                  {/* Projetos selecionados */}
                  <TableModalStatusSelecionado
                    tableHeaderColor="primary"
                    tableHead={["Nome", "Ordem"]}
                    tableData={array2}
                  />

                  {/** Adicionar a sessão **/}
                  <FormControl className="formControlMI">
                    <Button
                      onClick={this.handleIntegrar}
                      disabled={isButtonDisabled}
                      color="success"
                      className="buttonAddSession"
                    >
                      Adicionar a sessão
                    </Button>
                  </FormControl>

                  
                </Grid>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

ModalIntegration.propTypes = {
  idSession: PropTypes.number,
  handleClick: PropTypes.func,
  name: PropTypes.string,
  onClose: PropTypes.func,
};

export default ModalIntegration;
