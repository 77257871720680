import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import { apiLogout } from "api/api.js";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
  const classes = useStyles();
  const [openNotification] = React.useState(null);
  const [openProfile ] = React.useState(null);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let data = new Date();

  const [str_hora, setHora] = React.useState(data.toLocaleTimeString());

  const str_data = data.toLocaleDateString();

  const interval = setInterval(() => {

    const now = new Date();

    setHora(now.toLocaleTimeString())

  }, 1000);


  const handleExitProfile = () => {
    let user = JSON.parse(sessionStorage.getItem("USER"));
    apiLogout(user.id);
  };
  return (
    <div className={classes.divLogout}>
      <div className={classes.searchWrapper}>
        {"Hoje é " + str_data + " às " + str_hora}
      </div>
      <div className={classes.manager}>
        <Poppers
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openNotification }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                display: "none",
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            ></Grow>
          )}
        </Poppers>
      </div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleOpen}
          className={classes.buttonLink}
        >
          <ExitToAppIcon />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <h3 className={classes.modalTitle} id="transition-modal-title">
                Você quer sair do aplicativo?
              </h3>
              <div className={classes.modalButtons}>
                <Button
                  className={classes.buttomYes}
                  onClick={handleExitProfile}
                >
                  Sim
                </Button>
                <Button className={classes.buttomNo} onClick={handleClose}>
                  Não
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    </div>
  );
}
