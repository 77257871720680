import axios from "axios";
import _ from "lodash";

var tokenGet =
  sessionStorage.getItem("TOKEN_KEY") &&
  sessionStorage.getItem("TOKEN_KEY").charAt(0) == '"'
    ? sessionStorage.getItem("TOKEN_KEY")
    : JSON.stringify(sessionStorage.getItem("TOKEN_KEY"));
var token = JSON.parse(tokenGet);

async function apiGetConfiguration() {
  var data = null;
  let route = "/configuration";
  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + route,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      data = response.data.success;
    })
    .catch(function(error) {
      Promise.reject(error.response);
    });

  return data;
}

async function getSettings() {
  var data = null;
  let route = "/settings";
  await axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + route,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      data = response.data;
    })
    .catch(function(error) {
      Promise.reject(error.response);
    });

  return data;
}

async function getSettingByName(name) {
  var data = null;
  let route = "/settings/get";
  await axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + route + '/' + name,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      data = response.data;
    })
    .catch(function(error) {
      Promise.reject(error.response);
    });

  return data;
}

function apiDeleteMicro(id, route) {

  axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + route,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { id: id },
  })
    .then(() => {
      window.location.reload();
    })
    .catch(function(error) {
     Promise.reject(error.response);
    });

  return;
}

async function apiSalve(data, route) {
  let req = null;

  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + route,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: data,
  })
    .then((response) => {
      req = _.get(response, "data.success.resposta", "Salvo com Sucesso");
    })
    .catch(function(error) {
      if (error.response) {
        req = _.get(error, "response.data", "houve algo de errado");
      }
    });

  return req;
}

export { apiGetConfiguration, getSettings, getSettingByName, apiDeleteMicro, apiSalve };
