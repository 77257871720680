import React from "react";
import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import AttendanceTable from "components/Attendance/Table/Table.js";
import CardBody from "components/Card/CardBody.js";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "components/CustomButtons/Button.js";
import { apiUserOnline } from "api/user.js";
import { apiLogoutUsers } from "api/api.js";
import { apiLogoutRoot } from "api/api.js";
import echo from "api/channels";
import _ from "lodash";
import "../../assets/sass/components/president/president.scss";

class ListPresent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: "",
            open: false,
            userPresent: null,
            openFirst: false,
            msgFirst: null,
            msgSecond: null,
            openSecond: false,
        };
    }

    async componentDidMount() {
        this.setState({ users: await apiUserOnline() });
    }

    handleOpen = (param) => {
        this.setState({ userPresent: param})
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        this.setState({ openFirst: false});
        this.setState({ msgFirst: null });
        this.setState({ msgSecond: null });
        this.setState({ openSecond: false });
    };

    handleOpenFirst = () =>{
        this.setState({ msgFirst: 'Deseja deslogar todos os usuários?'})
        this.setState({ openFirst: true });
    };

    handleOpenSecond = () => {
        this.setState({ msgFirst: null })
        this.setState({ openSecond: true });
        this.setState({ msgSecond: "Ao deslogar todos os usuários a sessão será suspensa por falta de quórum."});
    };

    render() {
        const { users, userPresent, msgFirst, msgSecond } = this.state;
        const usr = JSON.parse(sessionStorage.getItem("USER"));
        echo.channel("presence").listen("UsersPushed", (e) => this.setState({ users: e })); //informar quando o usuario logar para add a lista
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>

                    {/* Botão de Deslogar todos */}
                    <div className="BtnLogout" style={{ padding: "1rem" }}>
                        <Button color="danger" onClick={
                            // () => apiLogoutUsers(usr.id)
                            () => this.handleOpenFirst()
                            }>
                            Deslogar todos
                        </Button>
                    </div>
                    
                    {/* table list de usuarios logados */}
                    <CardBody className="presencas-controlador">
                        {users != "" ? (
                            <AttendanceTable
                                tableHeaderColor="primary"
                                tableHead={["Nome", "Entrada", "Ultima Interação", "Logout"]}
                                tableData={users}
                                handleClickOpen={this.handleOpen.bind(this)}
                                id={usr.id}
                            />
                        ) : (
                            ""
                        )}
                    </CardBody>
                   
                </GridItem>
              
                <Modal /* modal de check Logout user */
                    className="generalModal"
                    open={this.state.open}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.open}>
                        <div className="modalContainer">
                            <div className="modalContainer--header">
                                <h4>Logout de usuário! </h4>
                            </div>

                                <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center", paddingBottom:"0" }}>
                                    <h4>{'Deseja deslogar o(a) '} {_.get(userPresent, 'name', null)}?</h4>
                                </div>

                            <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center", paddingTop: "0" }}>
                                <button 
                                    className="componentButton componentButton--red mx-0"
                                    onClick={() => { apiLogoutRoot(userPresent.id); this.handleClose(); }}>
                                        SIM
                                </button>

                                <button className="componentButton componentButton--gray ml-2" onClick={() => this.handleClose()}>
                                    Voltar
                                </button>
                            </div>
                        </div>
                    </Fade>
                </Modal>
{/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                <Modal /* modal de check Logout all-Users firt-check*/
                    className="generalModal"
                    open={this.state.openFirst}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.openFirst}>
                        <div className="modalContainer">
                            <div className="modalContainer--header">
                                <h4>Deslogar todos usuários!</h4>
                            </div>

                            <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center", paddingBottom: "0" }}>
                                <h4>{msgFirst != null ? msgFirst : msgSecond} <br />{msgFirst != null ? "" : "Deseja Continuar com o logout dos usuários?"}</h4>
                            </div>

                            <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center", paddingTop: "0" }}>

                                {this.state.openSecond == true ? // Validando se é o segundo check para habilitar o botão de deslogar todos
                                    (<button
                                        className="componentButton componentButton--red mx-0"
                                        onClick={() => apiLogoutUsers(usr.id) }
                                        >
                                        Deslogar todos
                                    </button>)
                                    :
                                    (<button
                                        className="componentButton componentButton--red mx-0"
                                        onClick={() => { this.handleOpenSecond(); }}>
                                        SIM
                                    </button>)
                                }

                                <button className="componentButton componentButton--gray ml-2" onClick={() => this.handleClose()}>
                                    Voltar
                                </button>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </GridContainer>
        );
    }
}

const styles = {
    BtnLogout:{
        width: "70%",
        display: "flex",
        justifyContent: "flex-end",
    },
};

ListPresent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListPresent);
