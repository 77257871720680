import React from 'react';
import _ from "lodash";
import "../../../assets/sass/components/president/voting.scss";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

function ModalChangePassword(props) {
    const { user} = props;
    return (
        <>
            <GridContainer className="generalModal">
                <GridItem xs={12}>
                    <div className="modalContainer">
                        <div className="modalContainer--header">
                            <h4>Senha alterada com sucesso.</h4>
                        </div>
                        <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center" }}>
                            <span><strong>{user.name}</strong>, A senha foi redefinida com sucesso.</span>
                        </div>
                        <div className="modalFooter__vote">
                            <div
                                className="modalFooter__vote--voltar"
                                variant="contained"
                                onClick={props.handleCloseRecovery}
                            >
                                <i className="fa-solid fa-arrow-rotate-left"></i>
                                Voltar
                            </div>
                            <div
                                className="modalFooter__vote--facial componentButton componentButton--green mx-0"
                                variant="contained"
                                onClick={props.handleCloseRecovery}
                            >
                               Ir pra o login
                            </div>
                        </div>
                    </div>
                </GridItem>
            </GridContainer>
        </>
    );
}

export default ModalChangePassword;