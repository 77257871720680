import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import TablePagination from "@material-ui/core/TablePagination";
import { ptBR } from "@material-ui/core/locale";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead
            className={classes[tableHeaderColor + "TableHeader"]}
            style={{ fontSize: "16px" }}
          >
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {(tableData || [])
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((prop, key) => {
              return (
                <TableRow key={key} className={classes.tableBodyRow}>
                  <TableCell
                    className={classes.tableCell}
                    style={{ fontSize: "16px" }}
                  >
                    {prop.name}
                  </TableCell>

                  <TableCell
                    className={classes.tableCell}
                    style={{ cursor: "pointer", textAlign: "end" }}
                  >
                    {/*
                    <span
                      className="material-icons"
                      style={{ color: "#43a047" }}
                      onClick={() => props.handleEdit(prop)}
                    >
                      create
                    </span>
                    <span
                      className="material-icons"
                      style={{ color: "red", marginLeft: "16px" }}
                      onClick={() => props.handleDelete(prop)}
                    >
                      delete_sweep
                    </span> */}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>

      <ThemeProvider theme={theme}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </ThemeProvider>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};
const theme = createMuiTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  ptBR
);
CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};
