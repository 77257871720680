import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import { apiSalve } from "api/configuration"
import { apiRefreshAtaSession } from "api/ataSession";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import _ from "lodash";
import NativeSelect from "@material-ui/core/NativeSelect";
import propType from "prop-types";

export default function ModalUser(props) {

  const classes = useStyles();
  const { session, types } = props;
  const [id, setId] = React.useState(_.get(session, 'id', ''))
  const [situation, setSituation] =  React.useState(_.get(session, 'situation', 'Criada'))
  const [name, setName] = React.useState(_.get(session, "name", ""));
  const [type_session, setTypeSession] = React.useState(_.get(session, "type.id", ""));
  const [alertSuccess, setAlertSuccess] = React.useState(false);
  const [validation, setValidation] = React.useState([]);

  const send = async () => {
    let data = {
      id: id,
      name: name,
      id_type: type_session,
      situation: situation,
    }
    setValidation([]);
    //apiSalve(data, '/session/save')

    let resposta = await apiSalve(data, "/session/save");
    
    // valida se existe sessão para atualizar
    if (id != "" && _.get(session, 'pauta.status', false) == true && (type_session == 3 || type_session == 4)) { 
      apiRefreshAtaSession(id);
    }

    if(typeof resposta.errors =='object'){
      setValidation(resposta.errors);
      return false;
    }
    setAlertSuccess(true);
    await props.apireload();

    setTimeout(() => {
      setAlertSuccess(false);
    }, 1000);
    props.handleClick();
  };

  const hadleType = (event) => {
    setTypeSession(event.target.value);
  };

  const hadleName = (event) => {
    setName(event.target.value);
  };

  return (
    <div>
      <GridContainer style={{ display: "flex", justifyContent: "center" }}>
        <GridItem xs={18} sm={18} md={18}>
          <Card>
            <CardHeader color="success" className={classes.cardHeader}>
              <h4 className={classes.cardTitleWhite}>Gerenciar Sessão</h4>
              <span
                className="material-icons"
                onClick={() => props.handleClick()}
              >
                close
              </span>
            </CardHeader>
            <CardBody>
              <GridContainer style={{ padding: "10px" }}>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl className={classes.form}>
                    <InputLabel className={classes.label}>{"Nome"}</InputLabel>
                    <Input name="name" value={name} onChange={hadleName} />
                  </FormControl>
                  <p className={classes.validation}>
                    {_.get(validation, "name", "")}
                  </p>
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <FormControl
                    className={classes.form}
                    style={{ minWidth: 200 }}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      className={classes.label}
                    >
                      Tipo
                    </InputLabel>
                    <NativeSelect
                      labelId="demo-simple-select-label"
                      id="cargo"
                      value={type_session}
                      name="cargo"
                      onChange={hadleType}
                    >
                      <option value={""}></option>
                      {types.map((type, i) => (
                        <option key={i} value={type.id}>
                          {type.name}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                  <p className={classes.validation}>
                    {_.get(validation, "id_type", "")}
                  </p>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter style={{ padding: "10px" }}>
              <Button onClick={send} color="success">
                Salvar
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbar
        place="tr"
        color="success"
        icon={AddAlert}
        message="Sessão Salva Com Sucesso"
        open={alertSuccess}
        closeNotification={() => setAlertSuccess(false)}
        close
      />
    </div>
  );
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "16px",
  },
  validation: {
    color: "red",
    fontSize: "12px",
    marginTop: "0px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
  },
};

ModalUser.propTypes = {
  session: propType.object,
  types: propType.array,
  apireload: propType.func,
  handleClick: propType.func,
};

const useStyles = makeStyles(styles);
