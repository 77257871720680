import React from "react";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import DashboardParliamentary from "components/Parliamentary/parliamentary";
import echo from "api/channels";
import {
  apiGetDocumentsParliamentary,
  apiGetDocumentsTribuna,
} from "api/documents.js";
import { apiSession } from "api/president.js";
//import { apiImplementPresident } from "api/tribuna.js";
import { RequestComment } from "api/_tribuna.js";
import { checkStartSession } from "api/_tribuna.js";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import ModalVoting from "components/Parliamentary/modal/voting";
import ModalVotingBloc from "components/Parliamentary/modal/votingBloco";
import ModalVotingCommittee from "components/Parliamentary/modal/votingCommittee";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Button from "components/CustomButtons/Button.js";
import _ from "lodash";
import "../../assets/sass/styles.scss";
import "../../assets/sass/components/vereador/index.scss";
import VotacaoComision from "components/President/Documents/votacaoComision";
import { getComisionesUser } from "api/comisiones";
import { getCurrentComision } from "api/comisiones";
import { DialogContent } from "@material-ui/core";

class ParliamentaryIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      sessions: null,
      session: null,
      session_name: null,
      session_time: null,
      roteiro: null,
      Documents: null,
      documents_votacao: null,
      user: JSON.parse(sessionStorage.USER),
      modal_votacao: false,
      modal_votacao_comision: false,
      votacao: null,
      id_session: null,
      openwebcam:
        localStorage.getItem("WEBCAM") === null
          ? 0
          : localStorage.getItem("WEBCAM"),
      descriptors: null,
      subscription: null,
      usersTribuna: null,
      alertSuccess: false,
      message: "",
      alertTribuna: false,
      text: "",
      name_order_session: "",
      voting_bloc: null,
      voting_committee: false,
      modal_votacao_bloc: false,
      user_vote: null,
      user_vote_committee: null,
      discution: null,
      concluido: null,
      inscrito: null,
      implement: null,
      message_tribuna: "",
      timeLeft: "",
      cancelado: null,
      cancelado_aparte: null,
      inscrition_finish: true,
      emFala: null,
      comisiones_user: [],
      option1: null,
      doc: {},
      comision: {},
      docsTribuna: [],
      configRead: false,
    };
    this.intervalId = null;
  }

  async componentDidMount() {
    const { user } = this.state;

    let session = await apiSession();
    const data = await checkStartSession();

    const habilitarDocsVereador = JSON.parse(
      sessionStorage.getItem("CONFIGURATIONPLENARY")
    );
    this.setState({
      configRead: habilitarDocsVereador[1]?.active ? true : false,
    });

    this.setState({ subscription: _.get(data, "tribuna_open", null) });
    this.setState({ discution: _.get(data, "discution", null) });

    this.setState({
      inscrition_finish: _.get(data, "tribuna_open.inscription", true),
    });

    this.start(
      _.get(session, "start", false) === true
        ? _.get(session, "success", [])
        : _.get(session, "success[0]", [])
    );

    // Documentos Tribuna
    let documentsTribuna = await apiGetDocumentsTribuna();
    this.setState({ docsTribuna: documentsTribuna });

    //verefica se a sessão é autorizada
    this.setState({
      session:
        _.get(session, "success[0].id", false) === false &&
        _.get(session, "start", false) === false
          ? false
          : true,
    });

    //Atribui usuários a tribuna caso estiver aberta
    this.assignUser(data);
    /*this.setState({
      emFala: _.get(data, ["emFala", 0, "id"], null) === user.id ? data.emFala : null,
      usersTribuna: _.get(data, "inscritos", null),
      inscrito: _.find(_.get(data, "inscritos", []), function (obj) {
        if (obj.id === user.id) {
          return true;
        }
      }),
      concluido: _.find(_.get(data, "concluidos", []), function (obj) {
        if (obj.id === user.id) {
          return true;
        }
      }),
      cancelado: _.find(_.get(data, "cancelados", []), function (obj) {
        if (obj.id === user.id) {
          return true;
        }
      }),
    });*/

    //canal que avisa inicio da sessão
    echo.channel("session").listen("StartSession", (e) => {
      this.setState({ session: true });
      this.start(e);
    });
    //canal que avisa quando a sessão foi autorizada
    echo.channel("session").listen("SessionAutorized", (e) => {
      this.setState({ session: true });
      this.start(e);
    });

    //Canal que avisa quando a sessão foi desautorizada
    echo.channel("session").listen("DisallowSession", () => {
      this.setState({ session: false });
    });

    //canal que avisa quando a sessão foi suspensa
    echo.channel("session").listen("Suspend", () => {
      this.setState({ session: false });
      this.setState({ documents: null });
      this.setState({ message: "Sessão Suspensa" });
      this.setState({ alertSuccess: true });
      setTimeout(() => {
        this.setState({ alertSuccess: false });
      }, 4000);
    });

    //canal que avisa fim da sessão
    echo.channel("session").listen("StopSession", () => {
      localStorage.removeItem("inscrito");
      localStorage.removeItem("STATUS");
      this.setState({ session: false });
    });

    //canal de alerta da tribuna
    echo.channel("tribuna").listen("MessageDenied", (e) => {
      if (_.get(e, "id", null) === this.state.user.id) {
        this.setState({ message: _.get(e, "message", "") });
        this.setState({ alertSuccess: true });
        setTimeout(() => {
          this.setState({ alertSuccess: false });
        }, 2000);

        if (e.message === "Tempo de Fala Cancelado") {
          this.setState({ inscrito: null });
        } else if (e.message === "Tempo de Fala Negado") {
          this.setState({ concluido: false });
        }
      }
    });

    //Canal quando interrompido tempo de fala
    echo.channel("tribuna").listen("TimeApp", (e) => {
      this.assignUser(e);
    });

    // canal que retorna usuários inscritos
    echo.channel("tribuna").listen("getUsersSubscription", (e) => {
      this.assignUser(e);
      //this.setState({ usersTribuna: e })
      /*const { user } = this.state;

      this.setState({
        emFala: _.get(e, "emFala.id", null) === user.id ? e.emFala : null,
        concluido: _.find(e.concluidos, function (obj) {
          if (obj.id === user.id) {
            return true;
          }
        }),
        inscrito: _.find(e.inscritos, function (obj) {
          if (obj.id == user.id) {
            return true;
          }
        }),
        cancelado: _.find(e.cancelados, function (obj) {
          if (obj.id === user.id) {
            return true;
          }
        }),
      });

      this.setState({ usersTribuna: _.get(e, "inscritos", null) });*/
    });

    //canal que retorna fim de inscrições
    echo.channel("tribuna").listen("closeSubscriptions", (e) => {
      this.setState({ inscrition_finish: false });
      this.setState({ alertTribuna: true });
      this.setState({ text: e });

      setTimeout(() => {
        this.setState({ alertTribuna: false });
      }, 5000);
      this.setState({ subscription: e });

      this.setState({ timeLeft: "" });
    });

    //canal que retorna documentos pelo tempo regimental
    echo.channel("session").listen("GetDocumentsParliamentary", (docs) => {
      this.setState({ documents: _.get(docs, "documents", null) });
      this.setState({
        name_order_session: _.get(docs, "order_session.name", "Documentos"),
      });
      this.setState({ roteiro: _.get(docs, "roteiro", null) });
    });

    //canal que avisa inicio de votação
    echo.channel("voting").listen("StartVoting", (e) => {
      this.setState({ votacao: e });
      this.setState({ modal_votacao: true });
    });

    //canal que avisa inicio de votação comisiones
    echo.channel("votingComision").listen("StartVotingComision", async (e) => {
      //GET COMISIONES DE USUARIO
      const { user } = this.state;
      const comisiones_user = await getComisionesUser();
      const current_comision = getCurrentComision(comisiones_user);
      this.setState({
        doc: e,
        comisiones_user: comisiones_user,
        comision: current_comision,
      });
      this.setState({ votacao: e });
      this.setState({ voting_committee: true });

      //Reiniciar status da votação
      this.setState({ user_vote_committee: null });
      this.setState({ user_vote: null });

      //Fecha todos os modais abertos para reaberturas dos atualizados
      this.setState({ modal_votacao_comision: false });

      //validar membros da comissão e apresenta modal apenas para os logados
      if (e.members_committee.includes(user.id)) {
        this.setState({ modal_votacao_comision: true });
      }
    });

    //canal que avisa fim da votação das comissões
    echo.channel("voting").listen("StopVoteCommittee", () => {
      this.setState({ votacao: null });
      // this.setState({ modal_votacao: false });
      this.setState({ modal_votacao_comision: false });
      // this.setState({ modal_votacao_bloc: false });
      this.setState({ user_vote: null });
      this.setState({ user_vote_committee: null });
      this.setState({ voting_committee: false });
    });

    //canal que avisa inicio de votação em bloco
    echo.channel("voting").listen("StartVotingBloc", (e) => {
      this.setState({ voting_bloc: e.documents });
      this.setState({ modal_votacao_bloc: true });
    });

    //canal que avisa fim da votação
    echo.channel("voting").listen("StopVoting", () => {
      this.setState({ votacao: null });
      this.setState({ modal_votacao: false });
      this.setState({ modal_votacao_bloc: false });
      this.setState({ user_vote: null });
    });

    //canal que busca usuarios inscritos
    echo.channel("getUsersTimeApp").listen("AddSubscription", (e) => {
      const { user } = this.state;
      this.setState({
        emFala: _.get(e, "emFala.id", null) === user.id ? e.emFala : null,
        concluido: _.find(e.concluidos, function(obj) {
          if (obj.id === user.id) {
            return true;
          }
        }),
        inscrito: _.find(e.inscritos, function(obj) {
          if (obj.id === user.id) {
            return true;
          }
        }),
        cancelado: _.find(e.cancelados, function(obj) {
          if (obj.id === user.id) {
            return true;
          }
        }),
      });

      this.setState({ usersTribuna: _.get(e, "inscritos", null) });
    });

    //canal que avisa fim de tempo de fala
    echo.channel("tribuna").listen("TalkTimeStop", (e) => {
      this.assignUser(e);

      const { user } = this.state;
      if (e.id === user.id) {
        this.setState({ inscrito: null });
        this.setState({ concluido: e });
      }
    });

    //canal que avisa o proximo tempo de fala
    echo.channel("tribuna").listen("TalkTime", (e) => {
      const { user } = this.state;
      if (e.id_user === user.id) {
        this.setState({ implement: null });
        return;
      }
      this.setState({ implement: false });
    });

    echo.channel("tribuna").listen("TalkTimeStop", () => {
      this.setState({ implement: null });
    });

    //canal que avisa que a tribuna abriu
    echo.channel("subscription").listen("OpenSubscription", (e) => {
      this.setState({ alertTribuna: true });
      this.setState({ text: "Tribuna" });
      this.setState({ emFala: null });
      this.setState({ concluido: null });
      this.setState({ inscrito: null });
      this.setState({ inscrition_finish: true });
      setTimeout(() => {
        this.setState({ alertTribuna: false });
      }, 5000);
      this.setState({ subscription: e });

      if (e.time_start_conceder == false) {
        if (e.time_inscription == null) {
          this.setState({ timeLeft: " " });
        } else {
          this.setState({ timeLeft: e.time_inscription * 60 });
          this.startTimer();
        }
      }
    });


    //Start Conceder fala
    echo.channel("timer-conceder").listen("TimerConceder", (e) => {
      
      if (e.time_start_conceder == true && this.state.timeLeft == "") { // vaidação conceder tempo
        if (e.time_inscription == null) {
          this.setState({ timeLeft: " " });
        } else {
          this.setState({ timeLeft: e.time_inscription * 60 });
        }
        this.startTimer();
      }

    });

    //canal que avisa que a discução está aberta
    echo.channel("subscription").listen("OpenDiscution", (e) => {
      let escreva = "Discusão " + _.get(e, "name", "");
      this.setState({ inscrition_finish: true });
      this.setState({ alertTribuna: true });
      this.setState({ text: escreva });
      this.setState({ emFala: null });
      this.setState({ concluido: null });
      this.setState({ inscrito: null });

      setTimeout(() => {
        this.setState({ alertTribuna: false });
      }, 5000);

      this.setState({ discution: e });
    });

    //canal que avisa fim de inscrições
    echo.channel("subscription").listen("StopSubscription", () => {
      this.setState({
        subscription: null,
        concluido: null,
        inscrito: null,
        implement: null,
        usersTribuna: [],
      });
    });

    //canal que avisa fim de discução de um projeto
    echo.channel("subscription").listen("CloseDiscution", () => {
      this.setState({
        discution: null,
        concluido: null,
        inscrito: null,
        implement: null,
        usersTribuna: [],
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.timeLeft !== this.state.timeLeft) {
      if (this.state.timeLeft <= 0) {
        this.handleTimerEnd();
        clearInterval(this.intervalId);
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  startTimer = () => {
    if (this.state.timeLeft > 0) {
      this.intervalId = setInterval(() => {
        this.setState(prevState => {
          if (prevState.timeLeft > 1) {
            return { timeLeft: prevState.timeLeft - 1 };
          } else {
            clearInterval(this.intervalId);
            return { timeLeft: 0 };
          }
        });
      }, 1270);
    }
  }

  handleTimerEnd = () => {
    console.log('Tempo esgotado!');
  }

  assignUser(data) {
    const { user } = this.state;

    this.setState({
      emFala:
        _.get(data, ["emFala", 0, "id"], null) === user.id ? data.emFala : null,
      usersTribuna: _.get(data, "inscritos", null),
      inscrito: _.find(_.get(data, "inscritos", []), function(obj) {
        if (obj.id === user.id) {
          return true;
        }
      }),
      concluido: _.find(_.get(data, "concluidos", []), function(obj) {
        if (obj.id === user.id) {
          return true;
        }
      }),
      cancelado: _.find(_.get(data, "cancelados", []), function(obj) {
        if (obj.id === user.id) {
          return true;
        }
      }),
    });
  }

  start = async (session) => {
    const { user } = this.state;
    let id = _.get(session, "id", null);
    this.setState({ session_name: _.get(session, "name", null) });
    this.setState({ start_session_time: _.get(session, "updated_at", null) });

    let docs = await apiGetDocumentsParliamentary(user.id);

    this.setState({
      session: _.get(docs, "documents", null) === null ? false : true,
    });
    this.setState({ documents: _.get(docs, "documents", null) });
    this.setState({
      name_order_session: _.get(docs, "order_session.name", "Documentos"),
    });
    this.setState({ roteiro: _.get(docs, "roteiro", null) });
    this.setState({ id_session: id });
    this.setState({ user_vote: _.get(docs, "start.vote_user", null) });
    this.setState({
      user_vote_committee: _.get(docs, "start_committee.vote_user", null),
    });

    if (
      _.get(docs, "start.document_session_register[0].voting_bloc", 0) === 1
    ) {
      this.setState({ voting_bloc: _.get(docs, "start", null) });
      this.setState({
        modal_votacao_bloc:
          _.get(docs, "start.vote_user", null) === null ||
          _.get(docs, "start.vote_user", null) === true
            ? false
            : true,
      });
      return;
    }

    //Validação se existe votação de comissão iniciada, para retoro de dados
    if (_.get(docs, "start_committee.id_committee", null) !== null) {
      this.setState({ votacao: _.get(docs, "start_committee", null) });
      this.setState({ voting_committee: true });

      if (docs.start_committee.membersCommittee.includes(user.id)) {
        this.setState({
          modal_votacao_comision:
            _.get(docs, "start_committee.vote_user", null) === null ||
            _.get(docs, "start_committee.vote_user", null) === true
              ? false
              : true,
        });
      }
      return;
    }

    this.setState({ votacao: _.get(docs, "start", null) });
    this.setState({
      modal_votacao:
        _.get(docs, "start.vote_user", null) === null ||
        _.get(docs, "start.vote_user", null) === true
          ? false
          : true,
    });
  };

  handleAlterarVoto = () => {
    const { votacao, voting_committee } = this.state;

    voting_committee == true
      ? this.setState({ modal_votacao_comision: true }) // se existe votação da comissão abre o modal de votação das comissões
      : votacao != null
      ? this.setState({ modal_votacao: true })
      : this.setState({ modal_votacao_bloc: true });
  };

  handleTrueVoteUser = () => {
    this.setState({ user_vote: true });
  };

  handleClose = () => {
    this.setState({ modal_votacao: false });
    this.setState({ modal_votacao_comision: false }); // fecha modal das comissões
  };

  handleCloseComision = () => {
    this.setState({ modal_votacao_comision: false });
  };

  handleCloseBloc = () => {
    this.setState({ modal_votacao_bloc: false });
  };

  implementDisable = (id) => {
    RequestComment(id, "aparte");
    this.setState({ implement: null });
  };

  ordemClick = (id) => {
    RequestComment(id, "ordem");
  };

  render() {
    const {
      emFala,
      timeLeft,
      inscrition_finish,
      cancelado,
      message_tribuna,
      implement,
      inscrito,
      concluido,
      discution,
      user_vote,
      user_vote_committee,
      voting_bloc,
      name_order_session,
      alertTribuna,
      text,
      alertSuccess,
      message,
      subscription,
      usersTribuna,
      openwebcam,
      descriptors,
      votacao,
      roteiro,
      documents,
      session,
      configRead,
      id_session,
      user,
      docsTribuna,
    } = this.state;
    return (
      <Grid container spacing={3} style={{ margin: "0px", width: "100%" }}>
        <Grid item xs={12} sm={3} className="userProfile parlamentaryStyles">
          <Paper className="userProfile__data vh-100">
            <div className="userProfile__data--circle">
              {user.photoUrl.includes(".") ? (
                <img src={user.photoUrl} />
              ) : (
                <i className="fa-solid fa-user"></i>
              )}
            </div>
            <div className="userProfile__data--text">
              <span className="userProfile__data--name">
                {user.public_name}
              </span>
            </div>
            <div className="userProfile__data--button">
              {user_vote === true || user_vote_committee === true ? (
                <Button
                  onClick={() => this.handleAlterarVoto()}
                  className="componentButton componentButton--green-outline my-5"
                >
                  Alterar Voto
                </Button>
              ) : (
                ""
              )}
              <Button
                onClick={() => this.ordemClick(user.id)}
                style={{ display: "none" }}
                className="componentButton componentButton--green-light my-5"
              >
                QUESTÃO DE ORDEM
              </Button>
            </div>
          </Paper>
        </Grid>
        {/* <div>
          Tempo restante: {this.state.timeLeft} segundos
        </div> */}
        {session === true || configRead === true ? (
          <Grid item xs={12} sm={9}>
            <Paper className="dashboardTable parlamentaryHeader h-100">
              <DashboardParliamentary
                style={{ width: "100%", padding: "0px" }}
                emFala={emFala}
                timeLeft={timeLeft}
                inscrition_finish={inscrition_finish}
                cancelado={cancelado}
                ordemClick={this.ordemClick.bind(this)}
                implementDisable={this.implementDisable.bind(this)}
                message={message_tribuna}
                implement={implement}
                concluido={concluido}
                inscrito={inscrito}
                user={user}
                usersTribuna={usersTribuna}
                discution={discution}
                subscription={subscription}
                roteiro={roteiro}
                name_order_session={name_order_session}
                documents={documents}
                docsTribuna={docsTribuna}
                configRead={configRead}
              />
            </Paper>
          </Grid>
        ) : (
          ""
        )}
        {session === false ? (
          <Grid item xs={12} sm={9}>
            <Paper className="h-100 box-session__parlamentary">
              Não há sessão aberta
            </Paper>
          </Grid>
        ) : (
          ""
        )}
        <Snackbar
          place="tr"
          color="success"
          icon={AddAlert}
          message={message}
          open={alertSuccess}
          closeNotification={() => this.setState({ alertSuccess: false })}
          close
        />

        <Modal
          className="modalParlamentary generalModal"
          open={this.state.modal_votacao}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <div className="mw-35">
            <div>
              <ModalVoting
                handleAlterarVoto={this.handleTrueVoteUser.bind()}
                user_vote={user_vote}
                openwebcam={openwebcam}
                descriptors={descriptors}
                voting={votacao}
                id_session={id_session}
                handleClose={this.handleClose.bind()}
              />
            </div>
          </div>
        </Modal>
        <Modal
          className="modalParlamentary generalModal"
          open={this.state.modal_votacao_bloc}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <div className="mw-35">
            <div>
              <ModalVotingBloc
                handleAlterarVoto={this.handleTrueVoteUser.bind()}
                user_vote={user_vote}
                openwebcam={openwebcam}
                descriptors={descriptors}
                voting={voting_bloc}
                id_session={id_session}
                handleClose={this.handleCloseBloc.bind()}
              />
            </div>
          </div>
        </Modal>

        <Modal
          className="generalModal"
          open={this.state.modal_votacao_comision}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <div className="mw-35">
            <div>
              <ModalVotingCommittee
                handleAlterarVoto={this.handleTrueVoteUser.bind()}
                user_vote={user_vote}
                openwebcam={openwebcam}
                descriptors={descriptors}
                voting={votacao}
                id_session={id_session}
                handleClose={this.handleCloseComision.bind()}
              />
            </div>
          </div>
          {/* <DialogContent>
            <VotacaoComision
              comisionesUser={this.state.comisiones_user}
              comision={this.state.comision}
              document={this.state.doc}
              onClose={this.handleCloseComision.bind()}
            />
          </DialogContent> */}
        </Modal>

        <Snackbar
          place="tr"
          color="success"
          icon={AddAlert}
          message={text}
          open={alertTribuna}
          closeNotification={() => this.setState({ alertTribuna: false })}
          close
        />
      </Grid>
    );
  }
}

const styles = {
  root: {
    flexGrow: 1,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "700px",
  },
  paper: {
    textAlign: "center",
    fontSize: "1rem",
    fontWeight: "bold",
    borderRadius: "10px",
  },
};

ParliamentaryIndex.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ParliamentaryIndex);
