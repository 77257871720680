import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import avatar from "assets/img/faces/marc.jpg";
import { apiOpenTime, apiCloseTime } from "api/timeapp.js";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "70%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
    fontSize: "32px",
  },
  margin: {
    margin: theme.spacing(2),
  },
}));

export default function AlignItemsList(props) {
  const classes = useStyles();

  const { name, id, cont } = props;

  const openTime = () => {
    apiOpenTime(id);
  };

  const closeTime = () => {
    apiCloseTime(id);
  };

  return (
    <List className={classes.root}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="tribuna" src={avatar} />
        </ListItemAvatar>
        <ListItemText
          primary="Parlamentar"
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                {name}
              </Typography>
            </React.Fragment>
          }
        />
        {cont == 0 ? (
          <Button
            variant="contained"
            onClick={openTime}
            className="componentButton componentButton--green"
          >
            Conceder
          </Button>
        ) : (
          ""
        )}

        {cont == 0 ? (
          <Button
            variant="contained"
            onClick={closeTime}
            className="componentButton componentButton--red"
          >
            Rejeitar
          </Button>
        ) : (
          ""
        )}
      </ListItem>

      <Divider variant="inset" component="li" />
    </List>
  );
}

AlignItemsList.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  cont: PropTypes.number.isRequired,
};
