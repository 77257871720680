import React from "react";
import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Session/Table/DocumentsTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import PropTypes from "prop-types";
import ArchiveRoundedIcon from "@material-ui/icons/ArchiveRounded";
import { apiUpdate } from "api/documents.js";
import { apiGetDocsProjects } from "api/sessions.js";
import { apiGetDocuments } from "api/documents";
import { apiRefreshAtaSession } from "api/ataSession";
import Modal from "@material-ui/core/Modal";
import ModalDocument from "components/Session/Form/ModalDocument";
import ModalDeleteDoc from "components/Session/Form/ModalDeleteDoc";
import ModalSignUsers from "components/Session/Form/ModalSignUsers";
import ModalIntegration from "components/Session/Form/ModalIntegration";
import ModalListDocuments from "components/Session/Form/ModalListDocuments";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import api from "api/api_v2";
import LinearProgress from "@material-ui/core/LinearProgress";

class SessionView extends React.Component {
  constructor(props) {
    super(props);
    const { session } = this.props;
    this.state = {
      documents: _.get(session, "documents", null),
      name: _.get(session, "name", ""),
      types: _.get(session, "typeDocument", []),
      parts: _.get(session, "partSession", []),
      sessionId: _.get(session, "id", ""),
      situation: _.get(session, "situation", ""),
      types_voting: [
        { name: "Maioria Absoluta" },
        { name: "Maioria Qualificada (2/3)" },
        { name: "Maioria Qualificada (3/5)" },
        { name: "Maioria Simples" },
      ],
      first_page_url: "",
      from: "",
      last_page: 1,
      last_page_url: "",
      next_page_url: null,
      path: "",
      per_page: "",
      prev_page_url: null,
      to: 1,
      total: 1,
      open: false,
      openDelete: false,
      setOpen: false,
      edit: 0,
      sessao: null,
      data_session: null,
      sign: false,
      modal_documents: false,
      documents_select: null,
      deleteid: null,
      modal_integration: false,
    };
  }

  onIntegration = () => {
    const { sessionId } = this.state;
    const { session } = this.props;

    this.setState({ modal_integration: false });
    this.setState({ documents: null });
    setTimeout(() => {
      this.openDocs(sessionId);

      // valida se existe sessão autorizada para atualizar
      if (_.get(session, 'pauta.status', false) == true && (session.id_type == 3 || session.id_type == 4)) { 
          apiRefreshAtaSession(sessionId);
        }
    }, 1000);
  };

  async handleOrder(row, order) {
    row.type_order = order;
    let session = await apiGetDocsProjects(row);
    this.setState({ documents: session.documents || [] });
  }

  handleOpen = (param) => {
    if (param.document_photo && param.document_photo.length > 0) {
      param.document_photo[0].url = param.document_photo[0].url
        .split("/")
        .pop();
    }
    if (typeof param.pdf == "string") {
      param.pdf = param.pdf.split("/").pop();
    }
    this.setState({ edit: param.id ? param : 0 });
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleClickDelete = async (param) => {
    this.setState({ deleteid: param.id });
    this.setState({ openDelete: true });
  };

  handleClickCloseDoc = async () => {
    this.setState({ openDelete: false });
  };

  handleSign = () => {
    this.setState({ sign: true });
  };

  handleCloseSing = () => {
    this.setState({ sign: false });
  };

  async handleDocs(sessionId) {
    this.setState({ documents_select: await apiGetDocuments(sessionId) });
    this.setState({ modal_documents: true });
  }

  handleCloseDocs = () => {
    this.setState({ modal_documents: false });
  };

  async addDocument(id, name, idSession) {
    const data = {
      id_session: idSession,
      id_document: id,
      name: name,
    };

    this.setState({
      documents: await apiUpdate(data, "/documents/update/session"),
    });
    this.setState({ documents_select: await apiGetDocuments(idSession) });
  }

  handleAtualize(documents) {
    const {session} = this.props;
    this.setState({ documents: documents });
    this.setState({ open: false });
    
    // valida se existe sessão autorizada para atualizar
    if (_.get(session, 'pauta.status', false) == true && (session.id_type == 3 || session.id_type == 4)) { 
      apiRefreshAtaSession(this.state.sessionId);
    }
  }

  async onSession(id) {
    api.post("session/" + id);
  }

  async openDocs(id) {
    let session;
    session = await apiGetDocsProjects(id);

    this.setState({ sessao: id });
    this.setState({ data_session: session || [] });
    this.setState({ documents: session.documents || [] });
  }

  render() {
    const {
      modal_integration,
      deleteid,
      documents,
      types,
      name,
      parts,
      types_voting,
      sessionId,
      situation,
      documents_select,
    } = this.state;
    return (
      <GridItem xs={12} sm={12} md={12} >
        <Card >
          <CardHeader color="success"
            className="cardHeaderSession"
            style={{ background: "linear-gradient(60deg, #3F9241, #009F87)", }}>
            <LightTooltip title="VOLTAR">
              <span
                className="material-icons"
                onClick={() => this.props.handleReload()}
              >
                reply_all
              </span>
            </LightTooltip>
            <h4 className="cardTitleWhite">{name}</h4>
            <div
              style={{
                width: "10%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <LightTooltip title="Integrar Documentos do Legislativo">
                <ArchiveRoundedIcon
                  onClick={() => this.setState({ modal_integration: true })}
                />
              </LightTooltip>

              <LightTooltip title="Adicionar Documentos/Projetos a Sessão Referente">
                <span className="material-icons" onClick={this.handleOpen}>
                  library_add
                </span>
              </LightTooltip>
            </div>
          </CardHeader>
          <CardBody>
            {!documents ? (
              <div >
                <LinearProgress />{" "}
              </div>
            ) : (
              <Table
                tableHeaderColor="primary"
                tableHead={[
                  "Nome",
                  "Tipo",
                  "Visualizar em",
                  "Ordem",
                  "Ações",
                  "",
                ]}
                tableData={documents}
                situation={situation}
                idSession={sessionId}
                handleClickOpen={this.handleOpen.bind(this)}
                handleClickDelete={this.handleClickDelete.bind(this)}
                handleSign={this.handleSign.bind(this)}
                openDocs={this.openDocs.bind(this)}
                handleOrder={this.handleOrder.bind(this)}
              />
            )}
          </CardBody>
        </Card>

        <Modal
          className="modalSession"
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <ModalDocument
              handleAtualize={this.handleAtualize.bind(this)}
              id={this.state.edit}
              open={this.state.open}
              typesVoting={types_voting}
              document={this.state.edit}
              documents={this.state.documents}
              types={types}
              parts={parts}
              idSession={sessionId}
              handleClick={this.handleClose.bind(this)}
            />
          </Fade>
        </Modal>

        <Modal
          className="modalSession"
          open={this.state.openDelete}
          onClose={this.handleClickCloseDoc}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openDelete}>
            <ModalDeleteDoc
              idSession={sessionId}
              id={deleteid}
              handleClick={this.handleClickCloseDoc.bind(this)}
              handleReloadDocument={this.handleAtualize.bind(this)}
            />
          </Fade>
        </Modal>

        <Modal
          open={modal_integration}
          onClose={() => this.setState({ modal_integration: false })}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={modal_integration}>
            <ModalIntegration
              idSession={sessionId}
              name={name}
              handleClick={this.onIntegration.bind(this)}
              onClose={() => this.setState({ modal_integration: false })}
            />
          </Fade>
        </Modal>

        <Modal
          className="modalSession"
          open={this.state.sign}
          onClose={this.handleCloseSing}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <ModalSignUsers handleClick={this.handleCloseSing.bind(this)} />
          </Fade>
        </Modal>

        <Modal
          className="modalSession"
          open={this.state.modal_documents}
          onClose={this.handleCloseDocs}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <ModalListDocuments
              close={this.handleCloseDocs.bind(this)}
              id_session={sessionId}
              handleClick={this.handleCloseSing.bind(this)}
              documentos={documents_select}
              handleAdd={this.addDocument.bind(this)}
            />
          </Fade>
        </Modal>
      </GridItem>
    );
  }
}

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

SessionView.propTypes = {
  session: PropTypes.object.isRequired,
  handleReload: PropTypes.func.isRequired,
};

export default SessionView;
