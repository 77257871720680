import axios from "axios";
import _ from "lodash";

var tokenGet =
  sessionStorage.getItem("TOKEN_KEY") && sessionStorage.getItem("TOKEN_KEY").charAt(0) == '"'
    ? sessionStorage.getItem("TOKEN_KEY")
    : JSON.stringify(sessionStorage.getItem("TOKEN_KEY"));
var token = JSON.parse(tokenGet);

function apiSendTribuna(id) {
  axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/tribuna/send/timetalk",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      id: id,
    },
  });
}

function apiStopTribuna() {
  axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + "/tribuna/stop/timetalk",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

async function checkStartSession() {
  let data = null;

  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/tribuna/check/session",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      data = _.get(response, "data.success", null);
    })
    .catch(function (error) {
      Promise.reject(error.response);
    });

  return data;
}

async function apiAddSubscription(id_user, id_tribuna) {
  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/tribuna/add/subscription",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { id_user: id_user, id_tribuna: id_tribuna },
  });
}

function apiAlertStopTribuna(id) {
  axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/StopTribuna",
    headers: {
      Accept: "application/json",
    },
    data: {
      id: id,
    },
  });
}

function apiStopTribunaImplement() {
  axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/StopTribunaImplement",
    headers: {
      Accept: "application/json",
    },
  });
}

async function apiImplementPresident(id, type) {
  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/tribuna/add/talk/time/implement",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { id: id, type: type },
  });
}

async function apiImplement(time, id, type, route) {
  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + route,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: { time: time, id: id, type: type },
  });
}

function apiSendFinishTribuna() {
  axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + "/timeapp/tribuna/finish/message",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

function apiFinishInscription(id_tribuna) {
  axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + "/tribuna/finish/inscription?id=" + id_tribuna,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export {
  apiStopTribunaImplement,
  apiFinishInscription,
  apiSendFinishTribuna,
  apiSendTribuna,
  checkStartSession,
  apiAddSubscription,
  apiStopTribuna,
  apiAlertStopTribuna,
  apiImplement,
  apiImplementPresident,
};
