import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
//import { apiOpenTime, apiRejectTime } from "api/timeapp.js";
import { GrantSpeech, RejectSpeech } from "api/_tribuna";
import PropTypes from "prop-types";

export default function AlignItemsList(props) {
  const classes = useStyles();

  const { name, id, cont, disabled } = props;

  const grantSpeech = () => {
    GrantSpeech(id);
    props.hadleDesabled();
  };

  const rejectSpeech = () => {
    RejectSpeech(id);
  };

  /*const closeTime = () => {
    apiRejectTime(id);
  };*/

  return (
    <List className={classes.root}>
      <ListItem alignItems="flex-start">
        <ListItemText
          primary="Parlamentar"
          secondary={
            <React.Fragment>
              <Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                {name}
              </Typography>
            </React.Fragment>
          }
        />

        {cont == 0 && disabled == false ? (
          <Button variant="contained" onClick={grantSpeech} className="componentButton componentButton--green">
            Conceder
          </Button>
        ) : (
          ""
        )}
        <Button variant="contained" onClick={rejectSpeech} className="componentButton componentButton--red">
          Rejeitar
        </Button>
        {/*{cont == 0 ? (
          <Button variant="contained" onClick={rejectSpeech} className="componentButton componentButton--red">
            Rejeitar
          </Button>
        ) : (
          ""
        )}*/}
      </ListItem>

      <Divider variant="inset" component="li" />
    </List>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "70%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
    fontSize: "32px",
  },
  margin: {
    margin: theme.spacing(2),
  },
}));

AlignItemsList.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  cont: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  hadleDesabled: PropTypes.func.isRequired,
};
