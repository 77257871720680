import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import api from "../../api/api_v2";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import TableTipoProtocol from "./Tables/TableTipoProtocol";
import { Error } from "../Parties/styles";
import _ from "lodash";
import Modal from "@material-ui/core/Modal";
import ModalStatus from "./Modal/ModalStatus";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";


export default function TypeDocument() {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [setPrazo] = useState(0);
  const [qtdade, setQtdade] = useState();
  const [tiposProposituras, setTiposProposituras] = useState([]);
  const [alert, setAlert] = useState(false);
  const [validation, setValidation] = useState([]);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState([]);
  const [statusSelect, setStatusSelect] = useState([]);
  const [setDiasUteis] = useState();
  const [multiple, setMultiple] = useState(0);
  const [ type,setType] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await api
      .get("/type-protocol")
      .then((response) => setTiposProposituras(_.get(response, "data", [])));
  };
  const handleForm = (e) => {
    switch (e.target.name) {
      case "name":
        return setName(e.target.value);
      case "qtdassinaturas":
        return setQtdade(e.target.value);
      case "prazo":
        return setPrazo(e.target.value);
      case "diasuteis":
        return setDiasUteis(e.target.value);
      case "multiple":
        return setMultiple(e.target.value);
      case "type":
        return setType(e.target.value);
    }
  };

  const handleAddTypeDocument = async () => {
    if (statusSelect.length === 0) {
      setValidation({
        status: "Status do tipo de Protocolo devem ser adicionados.",
      });

      return;
    }

    if (edit == true) {
      await api
        .put("/type-protocol/update/" + id, {
          name: name,
          statuses: statusSelect,
        })
        .then(function() {
          setAlertMessage("Tipo de Propositura criada com sucesso");

          setAlert(true);
          fetchData();
          setTimeout(() => {
            setAlert(false);
          }, 3000);
        })
        .catch(function(error) {
          if (error.response) {
            setValidation(_.get(error, "response.data.errors", []));
          }
        });
      setName("");
      setQtdade(0);
      setMultiple("");
      setType("");
      return;
    }

    await api
      .post("/type-protocol/store", {
        name: name,
        qntdade_assign: qtdade,
        num_row_proposition: 0,
        statuses: statusSelect,
        oficio_multiple: multiple,
      })
      .then(function() {
        setAlert(true);
        setAlertMessage("Tipo de Propositura criada com sucesso");
        fetchData();

        setTimeout(() => {
          setAlert(false);
        }, 3000);
      })
      .catch(function(error) {
        if (error.response) {
          setValidation(_.get(error, "response.data.errors", []));
        }
      });

    setName(0);
    setQtdade(0);
    setMultiple(0);
  };

  const handleDelete = async (e) => {
    setValidation("");
    await api.delete(`/type-protocol/delete/${e.id}`,{
      data:{
        id: e.id,
        name: e.name,
      }
    });

    await fetchData();
  };

  const handleEdit = async (e) => {
    setId(e.id);
    setType(_.get(e, "type_voting", null));
    setName(_.get(e, "name", ""));
    setQtdade(_.get(e, "qntdade_assign", 0));
    setMultiple(_.get(e, "oficio_multiple", 0));
    const array = _.get(e, "status", []);
    setEdit(true);

    array.forEach((value) => {
      value.prazo = _.get(value, "pivot.prazo", null);
      value.diasuteis = _.get(value, "pivot.diasuteis", null);
    });

    setStatusSelect(array);
  };

  const handleOpen = async () => {
    setValidation([]);
    await api.get("/status-protocol").then(function(response) {
      const retorno = _.get(response, "data", []);
      retorno.forEach((value) => {
        value.prazo = 0;
        value.diasuteis = 0;
      });

      setStatus(retorno);
    });

    if (edit == true) {
      statusSelect.forEach((value) => {
        status.find(function(item) {
          if (item.id === value.id) {
            item.index = true;
          }
        });
      });

      setStatusSelect(statusSelect);
    }

    setOpen(true);
  };

  const add = (select) => {
    setStatusSelect(select);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card
            style={{
              boxShadow:
                "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
            }}
          >
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Adicionar tipo de Protocolo</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <InputLabel className={classes.label}>{"Nome"}</InputLabel>
                  <FormControl className={classes.form}>
                    <Input name="name" value={name} onChange={handleForm} />
                    <Error>{_.get(validation, "name", "")}</Error>
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <Button onClick={handleOpen} color="default">
                    STATUS
                  </Button>

                  <FormControl className={classes.form}>
                    <Error>{_.get(validation, "status", "")}</Error>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button onClick={handleAddTypeDocument} color="success">
                Salvar
              </Button>
            </CardFooter>
            <Snackbar
              place="tr"
              color="success"
              icon={AddAlert}
              message={alertMessage}
              open={alert}
              closeNotification={() => setAlert(false)}
              close
            />
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {tiposProposituras != "" ? (
            <TableTipoProtocol
              tableHeaderColor="primary"
              tableHead={["Nome", ""]}
              tableData={tiposProposituras}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          ) : (
            ""
          )}
        </GridItem>
      </GridContainer>
      <Modal
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <ModalStatus
            status={status}
            status_select={statusSelect}
            handleClick={() => setOpen(false)}
            add={add}
          />
        </Fade>
      </Modal>
    </>
  );
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
  },
};

const useStyles = makeStyles(styles);
