import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { ptBR } from "@material-ui/core/locale";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import _, { isSet } from "lodash";
import { getComision } from "api/voting.js";
import echo from "api/channels";
import { Modal } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import "../../../assets/sass/components/president/votacao.scss";
import "../../../assets/sass/styles.scss";
import { apiStartVotacaoComision, apiFinishVotingComision } from "api/comisiones";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles({
    root: {
        width: "100%",
    },
    container: {
        maxHeight: 740,
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        textAlign: "center",
        fontSize: "160%",
        fontWeight: "bold",
        borderRadius: "10px",
        width: "800px",
        height: "300px",
    },
    btnVotar: {
        fontSize: "30px",
    },
});

export default function VotacaoComission(props) {
    const { enable_btn } = props;
    const classes = useStyles();

    const [modal_justice, setOpenModalDiscusion] = useState(false);
    const [modal_finish, setOpenModalFinish] = useState(false);
    const [modal_start, setOpenModalStart] = useState(false);
    const [rowById, setRowById] = useState(false);
    const [votingComision, setVotingComision] = useState([]);
    const [nameComision, setnameComision] = useState(null);

    useEffect(() => {
        echo.channel("voting").listen("UserVoteCommitte", async (e) => {
            const dataComision = await getComision();//pega dados da votação da vomissão ativa
            setVotingComision(dataComision); // atribuia ao stado 'votingComision' dados da comissão

            //validação referente a comissão de justiça para habilitar modal
            dataComision.map((comit) => {
                if (_.get(comit, "poder_veto", null) == 1 && _.get(comit, "register.approved", null) == 0) {
                    setOpenModalDiscusion(true);
                    setnameComision(comit.name.toLowerCase());
                }
            })
        });

        echo.channel("votingComision").listen("StartVotingComision", async (e) => {
            setVotingComision(await getComision()); // atribuia ao stado 'votingComision' dados da comissão
        });
    }, []);

    useEffect(() => {
        //retorna dados da votação ativa
        const fetchData = async () => {
        const dataComision = await getComision();//pega dados da votação da vomissão ativa
        // setVoting(data);
        setVotingComision(dataComision); // atribuia ao stado 'votingComision' dados da comissão

        //validação referente a comissão de justiça para habilitar modal
            dataComision.map((comit) =>{
                if (_.get(comit, "poder_veto", null) == 1 && _.get(comit, "register.approved", null) == 0){
                    setOpenModalDiscusion(true);
                    setnameComision(comit.name.toLowerCase());
                }
            })
        };
        fetchData()
    }, []);

    const handleVotacionComisiones = (doc) => {
        apiStartVotacaoComision(doc, doc.title);
        setOpenModalStart(false);
    };

    const handleFinish = () => {
        apiFinishVotingComision();
    };

    const openModalFinsih = (valueId, valueName) => {
        setOpenModalFinish(true);
    };

    const openModalStart = (valueId) => {
        setOpenModalStart(true);
        setRowById(valueId);
    };
    
    return (
        <Paper className="voteTable">
             
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead /* Cabeçalho title do card */>
                        <TableRow>
                            {
                                <TableCell className="rowTable">
                                    EM VOTAÇÂO : {_.get(votingComision[0], 'id_document.name', null)}
                                </TableCell>
                            }
                        </TableRow>
                    </TableHead>
                            
                    <TableBody className="voteTable__body"/* tabela de lista de votação comissões */>
                        {votingComision.map((row, indice) => {

                            var register = _.get(row, "register", null); //get register
                            var approved = _.get(row, "register.approved", null); // get approved
                            var start_voting = row.ordem = indice - 1;
                            var id_document = _.get(row, "id_document.id", null);

                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                    <TableCell  className="rowTable">
                                        <div>
                                            COMISSÃO DE {row['name'] + " "} {" "}
                                        </div>
                                        <div className="voteTable__body--row">
                                            <>  
                                                {register != null && approved == null ?  
                                                    <Button disabled={true}  className="componentButton componentButton--green-light">Em Votação</Button>
                                                    : register != null && approved == 0 ?
                                                    <Button disabled={true} className="componentButton componentButton--red-light">REPROVADO</Button>
                                                    : register != null && approved == 1 ?
                                                    <Button disabled={true} className="componentButton componentButton--green-light">APROVADO</Button>
                                                    : votingComision[start_voting].register != null && votingComision[start_voting].register.approved != null ?
                                                    <Button variant="outlined" className="componentButton componentButton--green" onClick={() => openModalStart(id_document)}>
                                                        Iniciar Votação
                                                    </Button>
                                                    : <Button disabled={true} className="componentButton componentButton--green-light">Iniciar Votação</Button>
                                                }
                                            </>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal /* modal de check Iniciar votaço */
                className="generalModal"
                open={modal_start}
                onClose={() => setOpenModalStart(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={modal_start}>
                    <div className="modalContainer">
                        <div className="modalContainer--header">
                            <h4>Deseja Iniciar Votação? </h4>
                        </div>
                        <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center" }}>
                            <button
                                className="componentButton componentButton--green mx-0"
                                onClick={() => {
                                    // onStarVotacion(rowById, rowByValue);
                                    handleVotacionComisiones(rowById);
                                }}
                            >
                                SIM
                            </button>
                            <button className="componentButton componentButton--gray ml-2" onClick={() => setOpenModalStart(false)}>
                                Voltar
                            </button>
                        </div>
                    </div>
                </Fade>
            </Modal>
            <Modal /* modal de check Finalizar votação */
                className="generalModal"
                open={modal_finish}
                onClose={() => setOpenModalFinish(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={modal_finish}>
                    <div className="modalContainer">
                        <div className="modalContainer--header">
                            <h4>Deseja Finalizar Votação? </h4>
                        </div>
                        <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center" }}>
                            <button
                                className="componentButton componentButton--green mx-0"
                                onClick={() => {
                                    handleFinish();
                                    setOpenModalFinish(false);
                                }}
                            >
                                SIM
                            </button>
                            <button className="componentButton componentButton--gray ml-2" onClick={() => setOpenModalFinish(false)}>
                                Voltar
                            </button>
                        </div>
                    </div>
                </Fade>
            </Modal>
            <Modal /* modal de validação de reprovação do juridico */
                className="generalModal"
                open={modal_justice}
                // onClose={() => setOpenModalDiscusion(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={modal_justice}>
                    <div className="modalContainer">
                        <div className="modalContainer--header">
                            <h4>Reprovado pela comissão de {nameComision}</h4>
                        </div>
                        
                        <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center", paddingBottom: "0" }}>
                            <h4> 
                                O {_.get(votingComision[0], 'id_document.name', null)} foi <b>REPROVADO</b> pela comissão de {nameComision} <br/>
                                Invalidando a continuação da votação do mesmo. <br/><br/>

                                Deseja seguir com a votação ?
                            </h4> 
                        </div>
                        
                        <div className="modalContainer--body" style={{ display: "flex", justifyContent: "center", paddingTop: "0" }}>
                            <button
                                className="componentButton componentButton--green mx-0"
                                onClick={() => {
                                    // props.handletribuna(rowById, rowByValue);
                                    setOpenModalDiscusion(false);
                                }}
                            >
                                SIM
                            </button>
                            <button className="componentButton componentButton--yellow ml-2" 
                                    onClick={() =>{ 
                                        setOpenModalDiscusion(false);
                                        openModalFinsih();
                                        }}>
                                Finalizar votação
                            </button>
                        </div>
                    </div>
                </Fade>
            </Modal>
            
            <ThemeProvider theme={theme}>
                <div
                    style={{
                        display: "flex",
                        widht: "100%",
                        alignItems: "flex-start",
                        justifyContent: "flex-end",
                        marginLeft: "5px",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                    }}
                >
                    {   
                    // _.get(votingComision[0], "finish", null) != true ?
                        <>
                            <Button variant="contained" onClick={() => openModalFinsih()} className="componentButton componentButton--yellow">
                                FINALIZAR VOTAÇÃO DAS COMISSÕES
                            </Button>

                            { enable_btn == true ? 
                                <Button variant="contained" onClick={() => props.handleOpen()} className="componentButton componentButton--green">
                                    Votar
                                </Button>
                                : "" 
                            }
                        </>
                    // : ""
                    }
                </div>
              
            </ThemeProvider>
        </Paper>
    );
}
const theme = createMuiTheme(
    {
        palette: {
            primary: { main: "#1976d2" },
        },
    },
    ptBR
);
