import axios from "axios";
import _ from "lodash";

var tokenGet =
  sessionStorage.getItem("TOKEN_KEY") &&
  sessionStorage.getItem("TOKEN_KEY").charAt(0) == '"'
    ? sessionStorage.getItem("TOKEN_KEY")
    : JSON.stringify(sessionStorage.getItem("TOKEN_KEY"));
var token = JSON.parse(tokenGet);

async function apiPresence() {
  let data;
  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/attendance",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      data = _.get(response, "data");
    })
    .catch(function(error) {
      Promise.reject(error.response);
    });

  return data;
}

async function apiSession() { 
  let data;
  await axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + "/session/president",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      data = _.get(response, "data", null);
    })
    .catch(function(error) {
      Promise.reject(error.response);
    });

  return data;
}

async function apiGetMicros() {
  let data;
  await axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + "/microphone",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      data = _.get(response, "data.success", null);
    })
    .catch(function(error) {
      Promise.reject(error.response);
    });

  return data;
}

async function apiStartVotacao(id,name, manual = false) {
  let req = null;

  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/voting/start",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      id: id,
      nome:name,
      vote_manual: manual
    },
  })
    .then((response) => {
      req = response;
    })
    .catch(function(error) {
      Promise.reject(error.response);
    });

  return req;
}

function apiDiscussion(id,name) {
  axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/tribuna/discussion/start",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      id: id,
      nome:name
    },
  }).catch(function(error) {
    Promise.reject(error.response);
  });
}

function apiReadDocument(id) {
  axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/documents/read",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      id: id,
    },
  }).catch(function(error) {
    Promise.reject(error.response);
  });
}

function apiSuspendSession() {
  axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/session/suspend",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  }).catch(function(error) {
    Promise.reject(error.response);
  });
}

async function apiStartVotingBlock(ids, type_voting) {
  let req = null;

  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/voting/bloc/start",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      ids: ids,
      type: type_voting,
    },
  })
    .then((response) => {
      req = response;
    })
    .catch(function(error) {
      Promise.reject(error.response);
    });

  return req;
}
async function apiOpenPdf(url) {
  let data;

  await axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + '/documents/pdflog/'+url,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
      .then((response) => {
        data = _.get(response, "data.success", null);
      })
      .catch(function(error) {
        Promise.reject(error.response);
      });
  return data;
}
async function apiCloseDiscution(id,name) {
  let response = null;
  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/tribuna/discussion/close",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      id: id,
      nome:name
    },
  }).catch(function(error) {
    response = _.get(error, "response.data.message", null);
  });

  return response;
}

async function apigetOrder() {
  let data;

  await axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + "/session/get/order",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      data = _.get(response, "data.success", null);
    })
    .catch(function(error) {
      Promise.reject(error.response);
    });

  return data;
}

export {
  apiOpenPdf,
  apigetOrder,
  apiCloseDiscution,
  apiStartVotingBlock,
  apiPresence,
  apiSession,
  apiGetMicros,
  apiStartVotacao,
  apiDiscussion,
  apiReadDocument,
  apiSuspendSession,
};
