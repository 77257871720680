import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import api from "../../api/api_v2";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import TableComite from "./Tables/TableComite";
import { Error } from "../Parties/styles";
import _ from "lodash";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

class Comissoes extends React.Component {
  editor = null;

  constructor(props) {
    super(props);

    this.state = {
      name: "",
      comite: [],
      alert: false,
      validation: [],
      edit: false,
      id: null,
      alertMessage: null,
      members: [{ id: null, user: null }],
      users: [],
      presidente: null,
      secretario: null,
      enable: 1,
    };
  }

  async componentDidMount() {
    await this.all();
    await api
      .get("/users/parlamentares")
      .then((response) =>
        this.setState({ users: _.get(response, "data", []) })
      );
  }

  addmemners = () => {
    const { members } = this.state;
    members.push([{ id: null, user: null }]);
    this.setState({ members: members });
  };

  editMember = (i, e) => {
    const { members } = this.state;

    let data = { id: null, user: e };
    members[i] = data;

    this.setState({ members: members });
  };

  clear = () => {
    this.setState({
      name: "",
      validation: [],
      edit: false,
      id: null,
      members: [{ id: null, user: null }],
      presidente: null,
      secretario: null,
      enable: 0,
    });
  };

  handleAddTypeDocument = async () => {
    const { edit } = this.state;

    const response = edit === true ? await this.update() : await this.create();

    if (response !== undefined) {
      this.setState({ alertMessage: "Comite Salvo com Sucesso" });

      this.setState({ alert: true });
      this.clear();

      setTimeout(() => {
        this.setState({ alert: false });
      }, 3000);

      await this.all();

      return;
    }
  };

  update = async () => {
    const { id, name, enable, presidente, secretario, members } = this.state;

    return await api
      .put(`/commitee/update/${id}`, {
        id: id,
        name: name,
        enable: enable,
        presidente: presidente,
        secretario: secretario,
        members: members,
      })
      .catch((error) => {
        this.setState({ validation: _.get(error, "response.data.errors", []) });
      });
  };

  create = async () => {
    const { name, enable, presidente, secretario, members } = this.state;

    return await api
      .post(`/commitee/store`, {
        name: name,
        enable: enable,
        presidente: presidente,
        secretario: secretario,
        members: members,
      })
      .catch((error) => {
        this.setState({ validation: _.get(error, "response.data.errors", []) });
      });
  };

  all = async () => {
    await api
      .get("/commitee")
      .then((response) =>
        this.setState({ comite: _.get(response, "data", []) })
      );
  };

  handleDelete = async (e) => {
    this.setState({ validation: [] });

    const response = await api.get(`/commitee/delete/${e.id}`);

    await this.all();

    this.setState({
      alertMessage: _.get(
        response,
        "data.success",
        "Não foi possivel excluir comite"
      ),
    });

    this.setState({ alert: true });

    setTimeout(() => {
      this.setState({ alert: false });
    }, 3000);
  };

  handleEdit = async (e) => {
    const { members } = this.state;
    this.setState({ id: e.id });
    this.setState({ name: e.name });
    this.setState({ enable: e.enable });

    _.get(e, "users", []).forEach((element) => {
      if (_.get(element, "pivot.presidente", 0) === 1) {
        this.setState({ presidente: element });
      } else if (_.get(element, "pivot.secretario", 0) === 1) {
        this.setState({ secretario: element });
      } else {
        let data = { id: null, user: element };

        if (members[0].user === null) {
          members[0] = data;
        } else {
          members.push(data);
        }

        this.setState({ members: members });
      }
    });
    this.setState({ edit: true });
  };

  render() {
    const { classes } = this.props;
    const {
      enable,
      users,
      name,
      members,
      validation,
      alert,
      comite,
      presidente,
      secretario,
      alertMessage,
    } = this.state;

    const flatProps = {
      options: users,
      getOptionLabel: (option) => option.user.name,
    };

    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card
              style={{
                overflow: "auto",
                height: "50vh",
                boxShadow:
                  "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
              }}
            >
              <CardBody style={{}}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4>Editar Registro</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={10}>
                    <FormControl className={classes.form}>
                      <InputLabel className={classes.label}>
                        {"Nome"}
                      </InputLabel>
                      <Input
                        autoComplete="off"
                        name="name"
                        value={name}
                        onChange={(e) =>
                          this.setState({ name: e.target.value })
                        }
                      />
                      <Error>{_.get(validation, "name", "")}</Error>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={10}>
                    <FormControl className={classes.form}>
                      <Autocomplete
                        {...flatProps}
                        value={presidente}
                        id="controlled-demo"
                        onChange={(event, newValue) => {
                          this.setState({ presidente: newValue });
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label={"Presidente"} />
                        )}
                      />
                      <Error>{_.get(validation, "presidente", "")}</Error>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={10}>
                    <FormControl className={classes.form}>
                      <Autocomplete
                        {...flatProps}
                        value={secretario}
                        id="controlled-demo"
                        onChange={(event, newValue) => {
                          this.setState({ secretario: newValue });
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label={"Secretário"} />
                        )}
                      />
                      <Error>{_.get(validation, "secretario", "")}</Error>
                    </FormControl>
                  </GridItem>

                  {members.map((prop, i) => (
                    <GridItem key={i} xs={12} sm={12} md={10}>
                      <FormControl key={i} className={classes.form}>
                        <Autocomplete
                          {...flatProps}
                          value={prop.user}
                          id="controlled-demo"
                          onChange={(event, newValue) => {
                            this.editMember(i, newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={"Membro " + (i + 1)}
                            />
                          )}
                        />
                      </FormControl>
                    </GridItem>
                  ))}
                  <Error>{_.get(validation, "members", "")}</Error>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={10}
                    style={{ textAlign: "right" }}
                  >
                    <a onClick={this.addmemners} style={{ width: "100%", fontSize: "10px" }}>
                      {" "}
                      Adicionar mais membros{" "}
                      <LibraryAddIcon style={{ fontSize: "12px" }} />{" "}
                    </a>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={10}>
                    <div>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={enable}
                            onChange={(e) =>
                              this.setState({ enable: e.target.checked })
                            }
                            color="primary"
                            value="dynamic-class-name"
                          />
                        }
                        label="Ativo"
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button onClick={this.handleAddTypeDocument} color="success">
                  Salvar
                </Button>
              </CardFooter>
              <Snackbar
                place="tr"
                color="success"
                icon={AddAlert}
                message={alertMessage}
                open={alert}
                closeNotification={() => this.setState({ alert: false })}
                close
              />
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            {comite != "" ? (
              <TableComite
                tableHeaderColor="primary"
                tableHead={["Nome", "", ""]}
                tableData={comite}
                handleEdit={this.handleEdit}
                handleDelete={this.handleDelete}
              />
            ) : (
              ""
            )}
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "16px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
    width: "100%",
  },
};

Comissoes.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Comissoes);
