import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import Webcam from "react-webcam";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import LoopIcon from "@material-ui/icons/Loop";
import "../../../assets/sass/components/president/video-vote.scss";
import PropTypes from "prop-types";

const INIT_STATE = {
  fullDesc: null,
  match: null,
};

const WIDTH = 400;
const HEIGHT = 260;

class ImageInput extends Component {
  constructor(props) {
    super(props);
    this.webcam = React.createRef();

    this.state = {
      ...INIT_STATE,
      faceMatcher: null,
      btn: false,
      cam: "user",
    };
  }

  handleImage = () => {
    this.setState({ btn: true });
    this.props.handlePhoto(this.webcam.current.getScreenshot());
    this.setState({ btn: false });
    this.props.handlePhotoClose();
  };

  handleCam = () => {
    const { cam } = this.state;
    if (cam === "user") {
      this.setState({ cam: { exact: "environment" } });
    } else {
      this.setState({ cam: "user" });
    }
  };

  resetState = () => {
    this.setState({ ...INIT_STATE });
  };

  capture = async () => {
    this.resetState();
    await this.setState({
      imageURL: URL.createObjectURL(this.webcam.target.files[0]),
      loading: true,
    });
    this.handleImage();
  };

  render() {
    const { btn, cam } = this.state;
    const videoConstraints = {
      width: 1280,
      height: 720,
      facingMode: cam,
    };
    return (
      <GridContainer style={{ display: "flex", justifyContent: "center" }}>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader
              color="success"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <span
                className="material-icons"
                onClick={() => this.props.handlePhotoClose()}
              >
                close
              </span>
              <LoopIcon
                style={{ color: "white", fontSize: "24px" }}
                onClick={this.handleCam}
              />
            </CardHeader>
            <CardBody>
              <div
                className="Camera"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "25px",
                }}
              >
                <div
                  style={{
                    width: WIDTH,
                    height: HEIGHT,
                  }}
                >
                  <div style={{ position: "relative", width: WIDTH }}>
                    <div style={{ position: "absolute" }}>
                      <Webcam
                        audio={false}
                        width={WIDTH}
                        height={HEIGHT}
                        ref={this.webcam}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                      />
                    </div>
                  </div>
                </div>

                <Button
                  disabled={btn}
                  onClick={this.handleImage}
                  variant="outlined"
                  className="videoVote__button componentButton componentButton--green-outline"
                >
                  <i className="fa-solid fa-camera"></i>
                  Enviar foto
                </Button>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

ImageInput.propTypes = {
  handlePhoto: PropTypes.func.isRequired,
  handlePhotoClose: PropTypes.func.isRequired,
};

export default withRouter(ImageInput);
