import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert"; // core components
import { apiDeleteDocument } from "api/documents";
import PropTypes from "prop-types";

import _ from "lodash";

export default function ModalDelete(props) {
  const classes = useStyles();
  const { id, idSession } = props;
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [color, setColor] = useState("success");

  const deleteDocument = async () => {
    await apiDeleteDocument(id, idSession)
      .catch(function(error) {
        console.log(error);

        if (_.get(error, "response.data", null) !== null) {
          setColor("danger");
          setMessage(_.get(error, "response.data", ""));
          setAlert(true);

          setTimeout(
            function() {
              setAlert(false);

              props.handleClick();
            }.bind(this),
            3000
          );
          return;
        }
      })
      .then((response) => {
        if (_.get(response, "data", null) !== null) {
          setColor("success");

          setMessage("Deletado com sucesso");
          setAlert(true);
          setTimeout(
            function() {
              setAlert(false);
              props.handleClick();
              props.handleReloadDocument(_.get(response, "data.documents", []));
            }.bind(this),
            3000
          );
        }
      });
  };

  return (
    <>
      <GridContainer style={{ display: "flex", justifyContent: "center" }}>
        <GridItem xs={18} sm={18} md={18}>
          <Card>
            <CardHeader color="danger" className={classes.cardHeader}>
              <h4 className={classes.cardTitleWhite}>Excluir Documento</h4>
              <span
                className="material-icons"
                onClick={() => props.handleClick()}
                style={{ cursor: "pointer" }}
              >
                close
              </span>
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardtText}>
                Tem certeza que deseja excluir o documento?
              </h4>
            </CardBody>
            <CardFooter>
              <Button onClick={deleteDocument} color="danger">
                Excluir
              </Button>
            </CardFooter>
            <Snackbar
              place="tr"
              color={color}
              icon={AddAlert}
              message={message}
              open={alert}
              closeNotification={() => setAlert(false)}
              close
            />
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardtText: {
    minHeight: "auto",
    fontFamily: "sans-serif",
    fontSize: "16px",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
  },
};

ModalDelete.propTypes = {
  id: PropTypes.string.isRequired,
  idSession: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleReloadDocument: PropTypes.func.isRequired,
};

const useStyles = makeStyles(styles);
