import axios from "axios";
import Echo from "laravel-echo";
import socketio from "socket.io-client";

const port1 = process.env.REACT_APP_STAGE_PORT ? process.env.REACT_APP_STAGE_PORT : '6001'

const TimeappEcho = new Echo({
  broadcaster: "socket.io",
  namespace: "App.Events.TimeApp",
  host: process.env.REACT_APP_URL_SERVER ? process.env.REACT_APP_URL_SERVER + ':'+port1 : window.location.hostname + ':'+port1,
  client: socketio,
  auth: {
    headers: {
      Accept: "application/json",
    },
  },
});

async function GetInfos() {
  let data;

  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/timeapp/getInfos",
    headers: {
      Accept: "application/json",
    },
  })
    .then(function (response) {
      data = response.data;
    })
    .catch(function (error) {
      Promise.reject(error.response);
    });

  return data;
}

async function TalkStop() {
  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/timeapp/talkTimeStop",
    headers: {
      Accept: "application/json",
    },
  }).catch(function (error) {
    Promise.reject(error.response);
  });
}

async function TalkStopComment() {
  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/timeapp/talkTimeStopComment",
    headers: {
      Accept: "application/json",
    },
  }).catch(function (error) {
    Promise.reject(error.response);
  });
}

export { TimeappEcho, GetInfos, TalkStop, TalkStopComment };
