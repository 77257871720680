import React from "react";
import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Parties/Table";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import PartidosCreate from "components/Parties/ModalParties";
import EditPartidos from "components/Parties/ModalEditParties";
import DeletePartidos from "components/Parties/ModalDeleteParties";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import apiPartidos from "../../api/partidos";
import _ from "lodash";
import echo from "api/channels";
import { Tooltip } from "@material-ui/core";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: "",
      types: [],
      first_page_url: "",
      from: "",
      last_page: 1,
      last_page_url: "",
      next_page_url: null,
      path: "",
      per_page: "",
      prev_page_url: null,
      to: 1,
      total: 1,
      open: false,
      openEdit: false,
      openDelete: false,
      setOpen: false,
      edit: 0,
      name: "",
      filter: "",
      filter2: "",
      partidos: "",
    };
  }

  async reload() {
    this.setState({ types: _.get("typeSessions", []) });
  }

  handleOpen = (param) => {
    this.setState({ edit: param.id ? param : 0 });
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpenEdit = (param) => {
    this.setState({ edit: param.id ? param : 0 });
    this.setState({ openEdit: true });
  };

  handleCloseEdit = () => {
    this.setState({ openEdit: false });
  };

  handleOpenDelete = (param) => {
    this.setState({ name: param.name });
    this.setState({ edit: param.id });
    this.setState({ openDelete: true });
  };

  handleCloseDelete = () => {
    this.setState({ openDelete: false });
  };

  showParties(partidos) {
    this.setState({ partidos: partidos });
    this.setState({ open: false });
  }

  async componentDidMount() {
    await this.reloadPartitie();

    echo.channel("admin").listen("GetPoliticalParties", (e) => {
      this.setState({ partidos: e });
    });
  }

  async reloadPartitie() {
    const partidos = await apiPartidos.get("partidos");

    this.setState({ partidos: partidos.data });
    sessionStorage.setItem("PARTIDOS", JSON.stringify(partidos.data));
  }

  handleName = async (e) => {

    const partidos = await apiPartidos.get("partidos/name", {
      params: {
        name: e.target.value,
      },
    });
    if (_.get(partidos, "data.partidos[0].id", null) == null) {
      this.setState({ partidos: _.get(partidos, "data.partidos") });
      return;
    }
    this.setState({ partidos: _.get(partidos, "data.partidos") });
  };

  render() {
    const { classes } = this.props;
    const { types, partidos, setPartidos } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success" className={classes.cardHeader}>
              <h4 className={classes.cardTitleWhite}>Partidos Politicos </h4>
              <LightTooltip title="Criar partido">
                <span
                  className="material-icons"
                  onClick={this.handleOpen}
                  style={{ cursor: "pointer" }}
                >
                  library_add
                </span>
              </LightTooltip>
            </CardHeader>
            <CardBody>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <div>
                  <CustomInput
                    formControlProps={{
                      className: classes.margin + " " + classes.search,
                    }}
                    inputProps={{
                      onChange: this.handleName,
                      placeholder: "Filtro pela sigla",
                      inputProps: {
                        "aria-label": "Search",
                      },
                    }}
                  />
                </div>
              </div>

              {partidos && setPartidos != "" ? (
                <Table
                  tableHeaderColor="primary"
                  tableHead={["Sigla", "Partido", ""]}
                  tableData={partidos}
                  handleClickOpenEdit={this.handleOpenEdit.bind(this)}
                  handleClickOpenDelete={this.handleOpenDelete.bind(this)}
                />
              ) : (
                ""
              )}
            </CardBody>
          </Card>
        </GridItem>

        <Modal
          handleShow={this.showParties.bind(this)}
          className={classes.modal}
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <PartidosCreate
              id={this.state.edit}
              open={this.state.open}
              session={this.state.edit}
              types={types}
              handleClick={this.handleClose.bind(this)}
            />
          </Fade>
        </Modal>

        <Modal
          className={classes.modal}
          open={this.state.openEdit}
          onClose={this.handleCloseEdit}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openEdit}>
            <EditPartidos
              id={this.state.edit}
              open={this.state.openEdit}
              session={this.state.edit}
              types={types}
              handleClick={this.handleCloseEdit.bind(this)}
            />
          </Fade>
        </Modal>

        <Modal
          handleShow={this.showParties.bind(this)}
          className={classes.modal}
          open={this.state.openDelete}
          onClose={this.handleCloseDelete}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openDelete}>
            <DeletePartidos
              handleReloadPartitie={this.reloadPartitie.bind(this)}
              id={this.state.edit}
              name={this.state.name}
              open={this.state.openDelete}
              session={this.state.edit}
              types={types}
              handleClick={this.handleCloseDelete.bind(this)}
            />
          </Fade>
        </Modal>
      </GridContainer>
    );
  }
}

const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: "0px 0px 25px 5px rgba(0,0,0,0.3)",
    padding: "20px 20px 50px 20px",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dashboard);
