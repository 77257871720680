import React from "react"
import { withStyles } from "@material-ui/core/styles"
import GridItem from "components/Grid/GridItem.js"
import GridContainer from "components/Grid/GridContainer.js"
import Table from "components/Logs/Table/Table.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardBody from "components/Card/CardBody.js"
import CustomInput from "components/CustomInput/CustomInput.js"
import PropTypes from 'prop-types'
import { apiGetLogs } from "api/logs.js"
import Modal from '@material-ui/core/Modal'
import LogsView from "components/Logs/Form/ModalLogs"
import ReportLogs from "components/Logs/Form/ModalReport"
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import FormControl from "@material-ui/core/FormControl"
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import InputLabel from "@material-ui/core/InputLabel"
import Tooltip from '@material-ui/core/Tooltip'
import echo from 'api/channels'

class Logs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Logs: "",
            first_page_url: "",
            from: "",
            last_page: 1,
            last_page_url: "",
            next_page_url: null,
            path: "",
            per_page: "",
            prev_page_url: null,
            to: 1,
            total: 1,
            open: false,
            setOpen: false,
            openReport: false,
            searchFilter: [],
            logInfo: [],
            filterPerfil: 0,
            filterModule: 0,
            filterUsername: 0,
            selectedFilterValue: '',
            openDelete: false,
            validation_name:'',
            page:0,
            rowsPerPage : 25,
            showModule:0
        }

        this.hadleFilter = this.hadleFilter.bind(this);
        this.handleName = this.handleName.bind(this);
    }

    componentDidMount() {
        this.reloadLogs()

        echo.channel('admin').listen('GetLogs', (e) => {
            this.setState({ logs: e })
        })
    }
    async handleName(e){
        let inputName            = 'filterUsername';
        let inputValue           = e.target.value;
        let stateSearchFilter = Object.assign({}, this.state);
        stateSearchFilter.searchFilter[inputName] = inputValue;
        this.setState(stateSearchFilter);


        let Logs = await apiGetLogs(stateSearchFilter.searchFilter,this.state.rowsPerPage,this.state.page+1)

        if (Logs != 0) {
            this.setState({validation_name:''})
            this.setState({ Logs: Logs })
            return
        }else{
            this.setState({ Logs: '' })
            this.setState({validation_name:'O usuário não tem logs registrados'})
        }
    }
    async handleChangePage(rowsPerPage,newPage){
      //  setPage(newPage);
        let Logs = await apiGetLogs(this.state.searchFilter,rowsPerPage,newPage)
        if (Logs != 0) {
            this.setState({ Logs: Logs })
            this.setState({ rowsPerPage: rowsPerPage })
            this.setState({ page: newPage })
            return
        }else{
            this.setState({ Logs: '' })
            this.setState({ rowsPerPage: 25 })
            this.setState({ page: 1 })
        }
    };
    async hadleFilter(e) {
        let inputName         = e.target.name;
        let inputValue        = e.target.value;
        let stateSearchFilter = Object.assign({}, this.state);
        stateSearchFilter.searchFilter[inputName] = inputValue;
        this.setState(stateSearchFilter);

        if(inputValue === ''){
            this.setState({ filterPerfil: 0 })
            this.setState({ filterModule: 0 })
            this.setState({ showModule: 0 })
            this.reloadLogs()
            return
        }
        if (inputName === 'filterPerfil') {
            this.setState({ filterPerfil: inputValue })
            this.setState({ showModule: 1})
        }


        if (inputName === 'filterModule'){
            this.setState({ filterModule: inputValue })
        }

        let Logs = await apiGetLogs(stateSearchFilter.searchFilter,this.state.rowsPerPage,this.state.page);

        if (Logs != 0) {
            this.setState({ Logs: Logs })
            return
        }else{
            this.setState({ Logs: '' })
        }
    }

    async reloadLogs() {
        let Logs = await apiGetLogs('',this.state.rowsPerPage,this.state.page);
        if (Logs != 0) {
            this.setState({ Logs: Logs })
        }
    }

    handleOpen = (param) => {
        this.setState({ logInfo: param.id ? param : 0 })
        this.setState({ open: true })
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    handleOpenReport = (param) => {
        this.setState({ openReport: true })
    }

    handleCloseReport = () => {
        this.setState({ openReport: false });
    }

    render() {
        const { classes } = this.props
        const { validation_name, Logs,showModule } = this.state

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="success" className={classes.cardHeader}>
                            <h4 className={classes.cardTitleWhite}>Logs </h4>
                            <LightTooltip title="Exportar Logs">
                                <span className="material-icons" onClick={this.handleOpenReport}>
                                  get_app
                                </span>
                            </LightTooltip>
                        </CardHeader>
                        <CardBody>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                <div >
                                    <CustomInput

                                        formControlProps={{
                                            className: classes.margin + " " + classes.search
                                        }}
                                        inputProps={{
                                            onChange: this.handleName,
                                            placeholder: "Filtro por usuarios",
                                            inputProps: {
                                                "aria-label": "Search"
                                            }
                                        }}
                                    />
                                    <p style={{color:'red'}}>{validation_name}</p>
                                </div>
                              <div style={{ width: '70%', display: 'flex', justifyContent: 'flex-end' }} >
                                    <p className={classes.validation}>  </p>
                                  <FormControl style={{ margin: '1%', minWidth: '25%' }} >
                                        <InputLabel id="demo-simple-select-label" className={classes.label} > Filtro por Perfil</InputLabel>
                                        <Select
                                            id="perfil"
                                            value={this.state.filterPerfil}
                                            name='filterPerfil'
                                            onChange={this.hadleFilter}
                                        >
                                            <MenuItem value={''}>Todos</MenuItem>
                                            {
                                                JSON.parse(sessionStorage.getItem('NIVEIS')).map((perfil, i) =>
                                                    <MenuItem key={i} value={perfil.name}>{perfil.name}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                  { showModule ?  <FormControl style={{ margin: '1%', minWidth: '25%' }} >
                                        <InputLabel
                                            id="demo-simple-select-label"
                                            className={classes.label}
                                        >
                                            Filtro por Módulo
                                        </InputLabel>
                                        <Select
                                            id="module"
                                            value={this.state.filterModule}
                                            name='filterModule'
                                            onChange={this.hadleFilter}
                                        >
                                            <MenuItem value={''}>Todos</MenuItem>
                                            { sessionStorage.getItem('MODULE') ?
                                                JSON.parse(sessionStorage.getItem('MODULE')).map((module, i) =>
                                                    <MenuItem key={i} value={module.module}>{module.module}</MenuItem>
                                                )
                                                : []
                                            }
                                        </Select>
                                    </FormControl>  : ''}
                                </div>

                            </div>
                            {Logs  && <Table
                                tableHeaderColor="primary"
                                tableHead={["ID","USUARIO", "PERFIL", "MÓDULO", "AÇÃO", "IP", "APP","DATA DE CRIAÇÃO","AÇÃO/DETALHAR"]}
                                Logs={Logs}
                                handleClickOpen={this.handleOpen.bind(this)}
                                handleChangePage={this.handleChangePage.bind(this)}
                            />

                            }
                        </CardBody>
                    </Card>
                </GridItem>
                <Modal
                    className={classes.modal}
                    open={this.state.open}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.open}>
                        <LogsView
                            logInfo ={this.state.logInfo}
                            open={this.state.open}
                            onClose={this.handleClose}
                            handleClick={this.handleClose.bind(this)}
                        />
                    </Fade>
                </Modal>
                <Modal
                    className={classes.modal}
                    open={this.state.openReport}
                    onClose={this.handleCloseReport}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.openReport}>
                        <ReportLogs
                            open={this.state.openReport}
                            onClose={this.handleCloseReport}
                            handleClick={this.handleCloseReport.bind(this)}
                        />
                    </Fade>
                </Modal>
            </GridContainer>

        )
    }
}


const styles = {
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: 'white',
        border: '2px solid #000',
        boxShadow: '0px 0px 25px 5px rgba(0,0,0,0.3)',
        padding: '20px 20px 50px 20px',
    },
    cardHeader: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    validation:{
        color:'red',
        fontSize: '14px',
        //marginTop: '0px'
    }
}

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);



export default withStyles(styles)(Logs)