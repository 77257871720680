import axios from "axios";
import _ from "lodash";

var tokenGet =
  sessionStorage.getItem("TOKEN_KEY") &&
  sessionStorage.getItem("TOKEN_KEY").charAt(0) == '"'
    ? sessionStorage.getItem("TOKEN_KEY")
    : JSON.stringify(sessionStorage.getItem("TOKEN_KEY"));
var token = JSON.parse(tokenGet);

async function apiGetUsers(id, filter, name) {
  var data = null;
  let route = "/users";

  if (id !== "" && filter !== "") route = "/users/filter";

  if (name != "") route = "/users/filter/name";

  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + route,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      filter: filter,
      id: id,
      name: name,
    },
  })
    .then((response) => {
      data = _.get(response, "data.success", 0);
    })
    .catch(function(error) {
      Promise.reject(error.response);
    });

  return data;
}

async function apiSalveUsers(data, route) {
  let req = null;
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    body: data,
  };
  await fetch(process.env.REACT_APP_STAGE + route, requestOptions)
    .then((response) => (req = response.json()))
    .then((data) => console.log(data))
    .catch((error) => (req.errors = error.message));

  return req;
}

async function apiUserOnline() {
  var data = null;
  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/online",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      data = _.get(response, "data.success", []);
    })
    .catch(function(error) {
      Promise.reject(error.response);
    });

  return data;
}

const apiDisableUser = axios.create({
  baseURL: process.env.REACT_APP_STAGE,
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + token,
  },
});

const generateHash = (data) => {
  const utf8EncodedData = encodeURIComponent(data);
  const utf8EncodedBuffer = Buffer.from(utf8EncodedData, 'utf-8');
  const base64EncodedData = utf8EncodedBuffer.toString('base64');
  return base64EncodedData; 
};
export {
  apiGetUsers,
  apiSalveUsers,
  apiUserOnline,
  apiDisableUser,
  generateHash,
};
