import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import { api } from "../../api/api";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import TableTipoComision from "./TableTipoComision";
import { Error } from "../Parties/styles";
import _ from "lodash";
import TipoComisionConfig from "./TipoComisionConfig";
import TipoComisionCargos from "./TipoComisionCargos";

export default function TipoComision() {
  const classes = useStyles();

  const [name, setName] = useState();
  const [status, setStatus] = useState([]);
  const [alert, setAlert] = useState(false);
  const [color, setColor] = useState("success");
  const [validation, setValidation] = useState([]);
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [id, setId] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      await all();
    };
    fetchData();
  }, []);

  const handleForm = (e) => {
    switch (e.target.name) {
      case "name":
        return setName(e.target.value);
    }
  };

  const handleAdd = async () => {
    if (add === true) {
      const response = await create();
      if (_.get(response, "status", null) == 200) {
        setAlertMessage("Criado com sucesso");
        setColor("success");
        setAlert(true);

        setTimeout(() => {
          setAlert();
        }, 1000);

        setEdit(false);
        setAdd(false);
        setName("");
        await all();

        return;
      }
    }
  };

  const handleEditar = async () => {
    const response = await update();

    if (_.get(response, "status", null) == 200) {
      setAlertMessage("Editado com sucesso");
      setColor("success");
      setAlert(true);

      setTimeout(() => {
        setAlert();
      }, 1000);

      setEdit(false);
      setAdd(false);
      setName("");
      await all();

      return;
    }
  };

  const update = async () => {
    return await api
      .put(`/tipoComision/${id}`, {
        id: id,
        name: name,
      })
      .catch(function(error) {
        if (error.response) {
          setValidation(_.get(error, "response.data.erro", []));
        }
      });
  };

  const create = async () => {
    return await api
      .post(`/tipoComision`, {
        name: name,
      })
      .catch(function(error) {
        if (error.response) {
          setValidation(_.get(error, "response.data.erro", []));
        }
      });
  };

  const all = async () => {
    await api.get("/tipoComision").then((response) => {
      setStatus(_.get(response, "data", []));
      setAdd(true);
    });
  };

  const handleEdit = async (e) => {
    setId(e.id);
    setName(e.name);
    setEdit(true);
    setAdd(false);
  };

  const handleDelete = async (e) => {
    setValidation("");
    await api
      .delete(`/tipoComision/${e.id}`,{
        data:{
          id: e.id,
          name: e.name,
        }
      })
      .catch((err) => {
        const msgError = _.get(
          err.response,
          "data.erro",
          "não foi possível excluir o registro"
        );
        setColor("danger");
        setAlertMessage(msgError);
      })
      .then((response) => {
        if (response) {
          let message = _.get(response, "data.success", "Excluído com sucesso");
          setColor("success");
          setAlertMessage(message);
        }
      });
    await all();
    setAlert(true);
    setTimeout(() => {
      setAlert();
    }, 1000);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <TipoComisionConfig />
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card
            style={{
              boxShadow:
                "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
            }}
          >
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Adicionar Tipo de Comissão</h4>
                  <br></br>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Nome do Tipo de Comissão
                  </InputLabel>
                  <FormControl className={classes.form}>
                    <Input
                      name="name"
                      type="name"
                      value={name}
                      onChange={handleForm}
                    />
                    <Error>
                      {_.get(validation, "name", "")}
                      {validation}
                    </Error>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <Button disabled={!add} onClick={handleAdd} color="success">
                    Salvar
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Button
                    disabled={!edit}
                    onClick={handleEditar}
                    color="success"
                  >
                    Atualizar
                  </Button>
                </GridItem>
              </GridContainer>
            </CardFooter>
            <Snackbar
              place="tr"
              color={color}
              icon={AddAlert}
              message={alertMessage}
              open={alert}
              closeNotification={() => setAlert(false)}
              close
            />
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {status != "" ? (
            <TableTipoComision
              tableHeaderColor="primary"
              tableHead={["Id", "Tipo de Comissâo", "", ""]}
              tableData={status}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          ) : (
            <></>
          )}
        </GridItem>
      </GridContainer>

      <TipoComisionCargos />
    </>
  );
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
  },
};

const useStyles = makeStyles(styles);
