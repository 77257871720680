import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';

export default function TableModalConfirmation({ onConfirm, onClose, onOpen }) {
  return (
    // <div className="modal">
    //   <div className="overlay"></div>
    //   <div className="modal-content-confirm">
    //     <div className="modal-body">
    //       <div className="confirmation">
    //         <h1>Tem certeza que deseja selecionar todos os documentos?</h1>
    //         <p>
    //           As configurações feitas individualmente serão
    //           perdidas!
    //         </p>
    //         <div>
    //           <button className="componentButton componentButton--green" onClick={onConfirm}>Sim</button>
    //           <button className="componentButton componentButton--red ml-2" onClick={onClose}>Cancelar</button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <Modal
                        className="generalModal"
                        open={onOpen}
                        onClose={() => onClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                        >
                            <Fade in={onOpen}>
                                <div className="generalModal__row">
                                    <div className="generalModal__container">
                                        <div className="generalModal__header">
                                            <h4>Tem certeza que deseja selecionar todos os documentos?</h4>
                                        </div>
                                        <div className="generalModal__body d-flex justify-content-center">
                                              <div>
                                                  As configurações feitas individualmente serão perdidas!
                                              </div>
                                        </div>
                                              <div className="d-flex justify-content-center my-3">
                                                  <button className="componentButton componentButton--green mx-0" onClick={onConfirm}>SIM</button>
                                                  <button className="componentButton componentButton--red ml-2" onClick={onClose}>Não</button>
                                              <div/>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                    </Modal>
  );
}
