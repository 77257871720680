import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import List from "./List.js";
import Button from "@material-ui/core/Button";
import { Subscribe, Unsubscribe } from "api/_tribuna.js";
import GridListTile from "@material-ui/core/GridListTile";
import _ from "lodash";
import UpdateIcon from "@material-ui/icons/Update";
import SettingsVoiceIcon from "@material-ui/icons/SettingsVoice";
import echo from "api/channels";
import "../../../assets/sass/components/vereador/subscription.scss";
import "../../../assets/sass/styles.scss";

class Subscription extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    this.state = {
      displaynone: "block",
      displaynone2: "block",
      user: user,
      inscription: null,
      message_talk_time: "",
      is_talking: false,
    };

    this.subscribe = this.subscribe.bind(this);
  }

  componentDidMount() {
    this.checkIsTalking(this.props.emFala);

    echo.channel("tribuna").listen("getUsersSubscription", (e) => {
      this.checkIsTalking(e);
    });
  }

  checkIsTalking(data) {
    if (data) {
      const { user } = this.props;

      if (data.emFala && data.emFala.length) {
        this.setState({
          is_talking: _.find(_.get(data, "emFala", []), function (obj) {
            if (obj.id === user.id) {
              return true;
            }
          }),
        });
      }
    }
  }

  subscribe = (id) => {
    const { subscription } = this.props;
    Subscribe(id, _.get(subscription, "id", null));
  };

  unsubscribe = (id) => {
    Unsubscribe(id);
  };

  render() {
    const { emFala, inscrition_finish, timeLeft, cancelado, implement, classes, concluido, inscrito, usersTribuna, discution, subscription, user } = this.props;
    const { is_talking } = this.state;

    return (
      <div className={classes.root}>
        <Grid container spacing={5} style={{ width: "100%", margin: "0px" }}>
          <Grid item xs={12} sm={12}>
            {_.get(emFala, "id", null) != null && concluido == null ? (
              <Paper className="subscription__discution">
                <p className="titleTime">
                  {" "}
                  <SettingsVoiceIcon /> Seu Tempo de Fala {_.get(emFala, "public_name", "")}{" "}
                </p>
              </Paper>
            ) : (
              <Paper className="subscription__discution">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {inscrition_finish == true ?
                      <span><b> Tempo restante de inscrição: {Math.floor(timeLeft / 60)}:{String(timeLeft % 60).padStart(2, '0')} </b></span>
                      : ""
                    }
                  </div>
                <div className="subscription__discution--box">
                  {discution === null ? "" : <h3> Discussão Referente: a {_.get(discution, "name", "")} </h3>}
                  {subscription === null ? "" : <h3> Inscrição {_.get(subscription, "subscription", "")} </h3>}
                </div>
                {inscrito !== null && inscrito !== undefined}
                {inscrito !== null && inscrito !== undefined ? (
                  <p className="titleWait">
                    {" "}
                    <UpdateIcon /> Em espera para Tribuna{" "}
                  </p>
                ) : (
                  ""
                )}
                {concluido !== null && concluido !== undefined ? (
                  <p className="titleFinish">
                    {" "}
                    <UpdateIcon />
                    Tempo de Fala Concluído
                  </p>
                ) : (
                  ""
                )}

                {(concluido === null || cancelado === undefined) && (concluido === null || concluido === undefined) ? (
                  <>
                    <FormControl className="vereadorButton">
                      {!is_talking &&
                        (inscrito !== null && inscrito !== undefined ? (
                          <Button onClick={() => this.unsubscribe(user.id)} variant="contained" className="componentButton componentButton--gray">
                            CANCELAR
                          </Button>
                        ) : inscrition_finish == true ? (
                          <Button onClick={() => this.subscribe(user.id)} variant="contained" className="componentButton componentButton--green">
                            INSCREVA-SE
                          </Button>
                        ) : (
                          ""
                        ))}
                    </FormControl>
                  </>
                ) : (
                  ""
                )}
                {implement == null ? (
                  ""
                ) : implement == false ? (
                  <>
                    <FormControl className="vereadorButton__aparte">
                      <Button onClick={() => this.props.implementDisable(user.id)} variant="contained" className="componentButton componentButton--orange">
                        Aparte
                      </Button>
                    </FormControl>
                  </>
                ) : (
                  ""
                )}
              </Paper>
            )}
          </Grid>

          {usersTribuna == null || usersTribuna.length == 0 ? (
            ""
          ) : (
            <Grid item xs={12} sm={12}>
              <div className="vereadorList">
                <h4>Vereadores Inscritos:</h4>
                <div className="vereadorList__users">
                  {usersTribuna.map((prop, key) => (
                    <GridListTile style={{ height: "auto" }} key={key} cols={2 || 1}>
                      <List name={prop.public_name} photo={prop.photoUrl} />
                    </GridListTile>
                  ))}
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

const styles = {
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: "left",
    color: "black",
    display: "flex",
    fontSize: "80%",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "left",
    fontWeight: "bold",
    padding: "16px",
    boxShadow: "none",
  },

  gridList: {
    width: 500,
    height: 350,
  },
  paperSub: {
    textAlign: "center",
    color: "black",
    display: "flex",
    fontSize: "24px",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    fontWeight: "bold",
    padding: "16px",
    boxShadow: "none",
    borderStyle: "inset",
  },
  formControl: {
    //margin: '4%',
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: "3%",
  },
  margin: {
    margin: "1%",
  },
  div_button: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "10px",
  },
};

Subscription.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  discution: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired,
  usersTribuna: PropTypes.array.isRequired,
  inscrition_finish: PropTypes.bool.isRequired,
  implementDisable: PropTypes.func.isRequired,
  implement: PropTypes.bool.isRequired,
  concluido: PropTypes.bool.isRequired,
  cancelado: PropTypes.bool.isRequired,
  emFala: PropTypes.object.isRequired,
  inscrito: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Subscription);
