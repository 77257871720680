import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    display: "flex",
    position: "fixed",
    top: "0px;",
    left: "0px",
    zIndex: "99999",
    backgroundColor: "rgba(0,0,0,0.5)",
    "& .boxLoading": {
      padding: "20px 25px",
      backgroundColor: "#ffffff",
    },
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default function Loading(props) {
  const { label, active } = props;
  const classes = useStyles();

  return (
    <div>
      {active ? (
        <Box
          className={classes.root}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            className="boxLoading"
            boxShadow={3}
            borderRadius={10}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {label || "Carreogando..."}&nbsp;&nbsp;
            <CircularProgress color="primary" />
          </Box>
        </Box>
      ) : (
        ""
      )}
    </div>
  );
}

Loading.propTypes = {
  label: PropTypes.string,
  active: PropTypes.bool,
};
