import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CardHeader from "components/Card/CardHeader.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import _ from "lodash";
import { Error } from "../../Parties/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import api from "../../../api/api_v2";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AppEditor from "../../Editor/Editor";
import TextField from "@material-ui/core/TextField";

class ModalEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: _.get(props.edit, "id", null),
      name: _.get(props.edit, "name", ""),
      fileId: _.get(props.edit, "fileId", ""),
      validation: [],
      ids_type_document: _.get(props.edit, "types_model_document", []),
      parecer: _.get(props.edit, "parecer", 0),
      text: _.get(props.edit, "text", ""),
      alert: false,
      alertMessage: "",
      texto: _.get(props.edit, "text", ""),
    };
  }

  handleForm = (e) => {
    this.setState({ name: e.target.value });
  };

  handleFileId = (e) => {
    this.setState({ fileId: e.target.value });
  };

  handleChange = (e) => {
    this.setState({ ids_type_document: e.target.value });
  };

  handleChangeCheck = () => {
    const { parecer } = this.state;
    this.setState({ parecer: !parecer });
  };

  handleAddTypeDocument = async () => {
    const { id, texto, name, parecer, ids_type_document,fileId } = this.state;

    const response =
      id !== null
        ? await this.update(id, name, parecer, texto, ids_type_document,fileId)
        : await this.create(name, parecer, texto, ids_type_document,fileId);

    if (response != undefined) {
      this.setState({ alertMessage: "Modelo Salvo com Sucesso" });

      this.setState({ alert: true });

      setTimeout(() => {
        this.setState({ alert: false });
      }, 1000);
      this.props.handleClick();
      return;
    }
  };

  update = async (id, name, parecer, text, ids_type_document,fileId) => {
    let valida;

    const req = await api
      .put(`/model-documents/update/${id}`, {
        name: name,
        parecer: parecer,
        text: text,
        typesDocumentsIds: ids_type_document,
        fileId:fileId
      })
      .catch(function(error) {
        valida = _.get(error, "response.data.errors", []);
      });

    this.setState({ validation: valida });

    return req;
  };

  create = async (name, parecer, text, ids_type_document,fileId) => {
    let valida;
    const req = await api
      .post("/model-documents/store", {
        name: name,
        parecer: parecer,
        text: text,
        typesDocumentsIds: ids_type_document,
        fileId:fileId
      })
      .catch(function(error) {
        valida = _.get(error, "response.data.errors", []);
      });

    this.setState({ validation: valida });

    return req;
  };

  onWriting = (text) => {
    this.setState({ texto: text });
  };

  render() {
    const { classes, types_document } = this.props;
    const {
      alert,
      alertMessage,
      text,
      parecer,
      ids_type_document,
      validation,
      name,
      fileId
    } = this.state;

    const flatProps = {
      options: types_document,
      getOptionLabel: (option) => option.name,
    };

    return (
      <GridContainer
        style={{ display: "flex", justifyContent: "center", height: "700px" }}
        tabindex="none"
      >
        <GridItem
          style={{ display: "flex", justifyContent: "center", height: "700px" }}
          xs={12}
          sm={12}
          md={10}
        >
          <Card>
            <CardHeader color="success" className={classes.cardHeader}>
              <h4 className={classes.cardTitleWhite}>Status</h4>
              <span
                className="material-icons"
                onClick={() => this.props.handleClick()}
                style={{ cursor: "pointer" }}
              >
                close
              </span>
            </CardHeader>
            <CardBody>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <FormControl className={classes.form}>
                    <InputLabel className={classes.label}>
                      {"Nome do Modelo"}
                    </InputLabel>
                    <Input
                      name="name"
                      value={name}
                      onChange={this.handleForm}
                    />
                    <Error>{_.get(validation, "name", "")}</Error>
                  </FormControl>
                  <FormControl
                    className={classes.form}
                    style={{ minWidth: 240 }}
                  >
                    <Autocomplete
                      multiple
                      {...flatProps}
                      value={ids_type_document}
                      id="controlled-demo"
                      onChange={(event, newValue) => {
                        this.setState({ ids_type_document: newValue });
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Tipos de Documentos" />
                      )}
                    />
                  </FormControl>
                  <FormControl className={classes.form}>
                      <InputLabel className={classes.label}>
                        {"Template Id"}
                      </InputLabel>
                      <Input
                        name="fileId"
                        value={fileId}
                        onChange={this.handleFileId}
                      />
                      <Error>{_.get(validation, "text", "")}</Error>
                  </FormControl>
                </div>

                <FormControl className={classes.form} style={{ minWidth: 120 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={parecer}
                        onChange={this.handleChangeCheck}
                        value="Habilitar"
                      />
                    }
                    label="Modelo Parecer"
                  />
                </FormControl>
              </div>

              <GridItem style={{ marginTop: "10px" }} xs={12} sm={12} md={12}>
                <AppEditor
                  dc_ass={false}
                  onWriting={this.onWriting.bind()}
                  text={text}
                />
              </GridItem>
            </CardBody>

            <CardFooter style={{ marginLeft: "80px", display: "flex" }}>
              <Button onClick={this.handleAddTypeDocument} color="success">
                ✔
              </Button>
            </CardFooter>
            <Snackbar
              place="tr"
              color="success"
              icon={AddAlert}
              message={alertMessage}
              open={alert}
              closeNotification={() => this.setState({ alert: false })}
              close
            />
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px",
    padding: "10px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
    padding: "10px",
  },
};

ModalEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  edit: PropTypes.bool,
  handleClick: PropTypes.func,
  types_document: PropTypes.array
};

export default withStyles(styles)(ModalEditor);
