import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import styles from "assets/jss/material-dashboard-react/layouts/app.js";
import Button from "@material-ui/core/Button";
import logo from "assets/img/logo-cliente.png";

import { apiLogout } from "api/api.js";

const useStyles = makeStyles(styles);

export default function NavBarTimer() {
  const classes = useStyles();

  const handleExitProfile = () => {
    let user = JSON.parse(sessionStorage.getItem("USER"));

    apiLogout(user.id);
  };

  let data = new Date();

  const [str_hora, setHora] = React.useState(data.toLocaleTimeString());

  const str_data = data.toLocaleDateString();

  const interval = setInterval(() => {

    const now = new Date();

    setHora(now.toLocaleTimeString())

}, 1000);


  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar variant="dense" className={classes.sidebar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <img src={logo} alt="logo" className={classes.img} />
          </IconButton>
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <Typography color="inherit" style={{ marginRight: "15px" }}>
              {"Hoje é " + str_data + " às " + str_hora}
            </Typography>
            <Button
              variant="contained"
              onClick={handleExitProfile}
              style={{ backgroundColor: "#43BD18", color: "white" }}
              size="medio"
              className={classes.margin}
            >
              SAIR
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
