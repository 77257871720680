import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { apiUpdate } from "api/documents";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { Error } from "../../Parties/styles";
import _ from "lodash";
import NativeSelect from "@material-ui/core/NativeSelect";
import PropTypes from "prop-types";

export default function ModalUser(props) {
  const classes = useStyles();
  const { document, documents, types, typesVoting, parts, idSession } = props;
  const [id] = React.useState(_.get(document, "id", ""));
  const [name, setName] = React.useState(_.get(document, "name", ""));
  const [ementa, setEmenta] = React.useState(
    _.get(document, "description", "")
  );
  const [type, setType] = React.useState(_.get(document, "type.id", ""));
  const [urgent, setUrgent] = React.useState(_.get(document, "urgent", "0"));
  const [secret, setSecret] = React.useState(_.get(document, "secret", "0"));
  const [part, setPart] = React.useState(_.get(document, "part.id", ""));
  const [typeVoting, setTypeVoting] = React.useState(
    _.get(document, "type_voting", "")
  );
  const [pdf, setPdf] = React.useState("");
  const [order, setOrder] = React.useState(_.get(document, "order", ""));
  const [img, setImg] = React.useState("");
  const [vote, setVote] = React.useState(_.get(document, "vote", "0"));
  const [alertSuccess, setAlertSuccess] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState("");
  const [alertError, setAlertError] = React.useState(false);
  const [sendDisabled, setDisabledSend] = React.useState(false);
  const [isDocumentV2, setisDocumentV2] = React.useState(_.get(document, "is_document_v2", false));
  const [messagePdf, setMessagePdf] = React.useState(
    _.get(document, "pdf", "")
  );
  const [messageIMG, setMessageIMG] = React.useState(
    _.get(document, "document_photo[0].url", "")
  );
  const [validation, setValidation] = useState([]);

  const send = async () => {
    if (typeVoting == "" && vote == 1) {
      setAlertError(true);
      setTimeout(() => {
        setAlertError();
      }, 2000);
      return;
    }
    setDisabledSend(true);
    let data = {
      id: id,
      name: name,
      description: ementa,
      type_voting: typeVoting,
      pdf: pdf,
      order: order,
      urgent: parseInt(urgent),
      secret: parseInt(secret),
      id_part: part,
      id_type: type,
      id_session: idSession,
      img: img,
      vote: parseInt(vote),
    };

    let docs = await apiUpdate(data, "/documents/save").catch(function(error) {
      if (error.response) {
        setValidation(_.get(error, "response.data.errors", []));
        setDisabledSend(false);
      }
    });

    if (docs == undefined || docs == null) {
      return;
    }

    setAlertMsg("Cadastrado com sucesso");
    setAlertSuccess(true);
    setTimeout(() => {
      setDisabledSend(false);
    }, 2000);
    setTimeout(() => {
      props.handleAtualize(docs);
    }, 1000);
  };

  const clickRadio = (event) => {
    urgent == "0" ? setUrgent(event.target.value) : setUrgent("0");
  };

  const clickRadioSecret = (event) => {
    secret == "0" ? setSecret(event.target.value) : setSecret("0");
  };

  const clickRadioVt = (event) => {
    vote == "0" ? setVote(event.target.value) : setVote("0");
  };
  //CHANGE EXIBICAO, GET LAST ORDER BY PART AND SESSION
  const getOrderPart = (part) => {
    let d = documents
      ? documents.filter(
          (prop) => prop.id_part == part && prop.id_session == idSession
        )
      : 0;
    let nextOrden = d.length + 1;
    setOrder(nextOrden);
    setAlertMsg("Ordem " + nextOrden + " automaticamente com sucesso");
    setAlertSuccess(true);
    setTimeout(() => {
      setAlertSuccess(false);
    }, 2000);
  };

  const hadle = (event) => {
    switch (event.target.name) {
      case "name":
        return setName(event.target.value);
      case "ementa":
        return setEmenta(event.target.value);
      case "part":
        getOrderPart(event.target.value);
        return setPart(event.target.value);
      case "typedoc":
        return setType(event.target.value);
      case "typevoting":
        return setTypeVoting(event.target.value);
      case "order":
        return setOrder(event.target.value);
      default:
        return "";
    }
  };

  const handleUploadFile = (e) => {

    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;

    let reader = new FileReader();

    reader.readAsDataURL(files[0]);

    if (e.target.name == "pdf") {
      reader.onload = (e) => {
        setPdf(e.target.result);
      };

      setMessagePdf(files[0].name);
    }
    if (e.target.name == "img") {
      reader.onload = (e) => {
        setImg(e.target.result);
      };
      setMessageIMG(files[0].name);
    }
  };

  return (
    <div>
      <GridContainer style={{ display: "flex", justifyContent: "center" }}>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="success" className={classes.cardHeader}>
              <h4 className={classes.cardTitleWhite}>
                Gerenciar Documentos / Projetos
              </h4>
              <span
                className="material-icons"
                onClick={() => props.handleClick()}
              >
                close
              </span>
            </CardHeader>
            <CardBody>
              <GridContainer style={{ padding: "10px" }}>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl className={classes.form}>
                    <InputLabel className={classes.label}>
                      {"Titulo"}
                    </InputLabel>
                    <Input
                      autoComplete="off"
                      name="name"
                      value={name}
                      disabled={isDocumentV2}
                      onChange={hadle}
                      inputProps={{ maxLength: "53" }}
                    />
                    <Error>{_.get(validation, "name", "")}</Error>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                    className={classes.form}
                    style={{ minWidth: 200 }}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      className={classes.label}
                    >
                      Exibição{" "}
                    </InputLabel>
                    <NativeSelect
                      labelId="demo-simple-select-label"
                      id="part"
                      value={part}
                      name="part"
                      onChange={hadle}
                    >
                      <option value={""}></option>
                      {(parts || []).map((type, i) => (
                        <option key={i} value={type.id}>
                          {type.name}
                        </option>
                      ))}
                    </NativeSelect>
                    <Error>{_.get(validation, "id_part", "")}</Error>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                    className={classes.form}
                    style={{ minWidth: 200 }}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      className={classes.label}
                    >
                      Tipo de documento
                    </InputLabel>
                    <NativeSelect
                      labelId="demo-simple-select-label"
                      id="typedoc"
                      value={type}
                      name="typedoc"
                      disabled={isDocumentV2}
                      onChange={hadle}
                    >
                      <option value={""}></option>
                      {(types || []).map((type, i) => (
                        <option key={i} value={type.id}>
                          {type.name}
                        </option>
                      ))}
                    </NativeSelect>
                    <Error>{_.get(validation, "id_type", "")}</Error>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl className={classes.form}>
                    <InputLabel className={classes.label}>{"Ordem"}</InputLabel>
                    <Input
                      autoComplete="off"
                      name="order"
                      type="number"
                      inputProps={{ min: "0" }}
                      value={order}
                      onChange={hadle}
                      readOnly="true"
                    />
                    <Error>{_.get(validation, "order", "")}</Error>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <FormControl className={classes.form}>
                    <RadioGroup
                      aria-label="Votacao"
                      value={Number.isInteger(vote) ? vote.toString() : vote}
                      name="votacao"
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <FormControlLabel
                        onClick={clickRadioVt}
                        value={"1"}
                        control={<Radio color="primary" />}
                        label="Votação"
                      />
                    </RadioGroup>
                  </FormControl>
                </GridItem>
                <GridItem
                  style={vote != "1" ? { display: "none" } : {}}
                  xs={12}
                  sm={12}
                  md={6}
                >
                  <FormControl
                    className={classes.form}
                    style={{ minWidth: 200 }}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      className={classes.label}
                    >
                      Tipo de votação
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="typevoting"
                      value={typeVoting}
                      name="typevoting"
                      onChange={hadle}
                    >
                      {(typesVoting || []).map((type, i) => (
                        <MenuItem key={i} value={type.name}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem
                  style={vote != "1" ? { display: "none" } : {}}
                  xs={12}
                  sm={12}
                  md={6}
                >
                  <InputLabel className={classes.label}>{"Ementa"}</InputLabel>
                  <FormControl className={classes.form}>
                    <textarea
                      style={{ resize: "none" }}
                      rows="5"
                      cols="30"
                      aria-label="minimum height"
                      rowsMin={4}
                      name="ementa"
                      value={ementa}
                      onChange={hadle}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <FormControl className={classes.form}>
                  <RadioGroup
                    aria-label="tribuna_finish"
                    value={
                      Number.isInteger(urgent) ? urgent.toString() : urgent
                    }
                    name="urgent"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <FormControlLabel
                      onClick={clickRadio}
                      value={"1"}
                      control={<Radio color="primary" />}
                      label="Requerimento de urgencia"
                    />
                  </RadioGroup>
                </FormControl>
              </GridItem>
              <GridItem style={vote != "1" ? { display: "none" } : {}} xs={12} sm={12} md={6}>
                <FormControl className={classes.form}>
                  <RadioGroup
                    aria-label="tribuna_finish"
                    value={
                      Number.isInteger(secret) ? secret.toString() : secret
                    }
                    name="secret"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <FormControlLabel
                      onClick={clickRadioSecret}
                      value={"1"}
                      control={<Radio color="primary" />}
                      label="Votação secreta"
                    />
                  </RadioGroup>
                </FormControl>
              </GridItem>
              <GridContainer style={{ padding: "10px" }}>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl className={classes.form}>
                    <Button
                      variant="contained"
                      component="label"
                      style={{ backgroundColor: "#4AA2A2", color: "white" }}
                    >
                      pdf
                      <input
                        name="pdf"
                        type="file"
                        disabled={isDocumentV2}
                        onChange={handleUploadFile}
                        style={{ display: "none" }}
                      />
                    </Button>
                    {isDocumentV2  ? <Error>Você não pode modificar o documento.</Error>  : ''}
                    {messagePdf}
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl className={classes.form}>
                    <Button
                      variant="contained"
                      component="label"
                      style={{ backgroundColor: "#4AA2A2", color: "white" }}
                    >
                      imagens
                      <input
                        name="img"
                        type="file"
                        onChange={handleUploadFile}
                        style={{ display: "none" }}
                      />
                    </Button>
                    {messageIMG}
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter style={{ padding: "10px" }}>
              <Button onClick={send} color="success" disabled={sendDisabled}>
                Salvar
              </Button>
            </CardFooter>
          </Card>
          <Snackbar
            place="tr"
            color="success"
            icon={AddAlert}
            message={alertMsg}
            open={alertSuccess}
            closeNotification={() => setAlertSuccess(false)}
            close
          />

          <Snackbar
            place="tr"
            color="danger"
            icon={AddAlert}
            message="Selecione um tipo de votação"
            open={alertError}
            closeNotification={() => setAlertError(false)}
            close
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "16px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
  },
};

ModalUser.propTypes = {
  classes: PropTypes.object.isRequired,
  document: PropTypes.object,
  documents: PropTypes.array,
  getDocuments: PropTypes.func,
  getDocument: PropTypes.func,
  updateDocument: PropTypes.func,
  createDocument: PropTypes.func,
  deleteDocument: PropTypes.func,
  getTypesVoting: PropTypes.func,
  typesVoting: PropTypes.array,
  getUsers: PropTypes.func,
  users: PropTypes.array,
  getGroups: PropTypes.func,
  groups: PropTypes.array,
  getGroupsUsers: PropTypes.func,
  groupsUsers: PropTypes.array,
  getGroupsDocuments: PropTypes.func,
  groupsDocuments: PropTypes.array,
  types: PropTypes.array,
  parts: PropTypes.array,
  idSession: PropTypes.number,
  setId: PropTypes.func,
  handleAtualize: PropTypes.func,
  handleClick: PropTypes.func,
};

const useStyles = makeStyles(styles);
