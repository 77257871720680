import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert"; // core components
import { apiDisableUser } from "api/user";
import PropTypes from "prop-types";
/**
 * Buscar o ID
 */

export default function ModalDelete(props) {
  const classes = useStyles();
  const { user } = props;
  const [alert, setAlert] = useState(false);

  const handleDisableUser = () => {
    const docs = apiDisableUser.post(`users/delete/${user.id}`, {
      id: user.id,
      nome:user.name
    });

    setAlert(true);

    setTimeout(
      function() {
        setAlert();
        props.handleClick();
        props.handleReloadUser(docs);
      }.bind(this),
      1300
    );
  };

  return (
    <>
      <GridContainer style={{ display: "flex", justifyContent: "center" }}>
        <GridItem xs={18} sm={18} md={18}>
          <Card>
            <CardHeader color="danger" className={classes.cardHeader}>
              <h4 className={classes.cardTitleWhite}>Excluir Usuário</h4>
              <span
                className="material-icons"
                onClick={() => props.handleClick()}
                style={{ cursor: "pointer" }}
              >
                close
              </span>
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardtText}>
                Tem certeza que deseja excluir o usuário?
              </h4>
            </CardBody>
            <CardFooter>
              <Button onClick={handleDisableUser} color="danger">
                Excluir
              </Button>
            </CardFooter>
            <Snackbar
              place="tr"
              color="danger"
              icon={AddAlert}
              message="Deletado com sucesso"
              open={alert}
              closeNotification={() => setAlert(false)}
              close
            />
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardtText: {
    minHeight: "auto",
    fontFamily: "sans-serif",
    fontSize: "16px",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
  },
};

ModalDelete.propTypes = {
  user: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleReloadUser: PropTypes.func.isRequired
};

const useStyles = makeStyles(styles);
