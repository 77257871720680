import Image from "assets/img/background.jpg";

const loginStyle = {
  filogo: {
    height: "6vh",
    margin: "50px auto 25px auto",
    display: "flex",
  },

  root: {
    height: "100vh",
    minHeight: "100%",
    backgroundImage: "url(" + Image + ")",
    //backgroundColor: 'f1f1f1',
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  paper: {
    margin: "40px",
    paddingTop: "50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  fisubmit: {
    height: "45px",
    display: "flex",
    backgroundColor: "#4caf50",
    color: "#ffffff",
    margin: "20px auto",
    padding: "0px 40px",
    fontSize: "16px",
    fontWeight: "500",
    "&:hover": {
      backgroundColor: "#76c479",
    },
  },
  filink: {
    cursor: "pointer",
    display: "flex",
    fontSize: "16px",
    color: "#333333 ",
    "&:hover": {
      color: "#666666",
      textDecoration: "none",
    },
  },
  image: {
    backgroundImage: "url(" + Image + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  form: {
    margin: "25px 50px 25px 50px",
  },
  items: {
    margin: "10px auto",
  },
  iconLock: {
    margin: "0px 10px 0px 0px",
  },
  footerSpan: {
    float: "left",
    margin: "0 0 -10px -35px",
    padding: "30px 0 10px 0",
  },
  footerCam: {
    display: "flex",
    height: "30px",
    margin: "0 10px",
    padding: "5px 0",
  },
  divFooter: {
    position: "absolute",
    bottom: "0",
    right: "0",
    background: "white",
    borderRadius: "15px 0 0 0",
    boxShadow:
      "0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(76 175 80 / 40%)",
    padding: "0 0 0 20px",
  },

  /**
 * 
    card:{
        width:'calc(100% - 400px)',
        maxWidth:'600px',
        height:'250px',
        backgroundColor:'white',
        borderRadius: '0px 30px 0px 30px',
        boxShadow:'0px 0px 25px 5px rgba(0,0,0,0.3)',
        marginLeft:'20%'
    },
    
    container:{
        display:'flex',
        width:'100%',
        height:'100%',
        justifyContent: 'space-around',
        alignItems: 'center',
        
    },

    item1:{
        display:'flex',
       // padding:'50px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },

    item2:{
        display:'flex',
        flexDirection: 'column',
        marginTop: '14px'
    },

    button: {
        width: "86px",
        height: "86px"
    },

    submit: {
        backgroundColor:'#adc533',
        borderColor:'#adc533',
        color:'white',
        width: '100px',
        
        '&:hover': {
            backgroundColor:'white',
            color:'#adc533;'
        },
    },
 */
};

export default loginStyle;
