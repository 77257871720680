import styled from "styled-components";

export const Snack = styled.div`
  display: flex;
  background: #fff;
  color: white;
  width: 90%;
  cursor: pointer;
  padding: 22px;
  box-shadow: 0 12px 20px -10px rgba(255, 255, 255, 0.28),
    0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255, 0.2);
  margin-top: 12px;
  transition: 0.3s;
  border-radius: 6px;
  :hover {
    background: #4caf50;

    span {
      color: #fff;
    }

    svg {
      color: #fff;
    }
  }

  svg {
    color: #555555;
    font-size: 24px;
  }

  span {
    color: #555555;
    margin-left: 18px;
    font-size: 18px;
    font-weight: bold;
  }
`;
