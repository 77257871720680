import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Logo from "assets/img/logo-login.png";
import { apiLogin } from "api/api.js";
import PropTypes from "prop-types";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-react/views/loginStyle.js";
import { mask } from "remask";
import ImageImput from "components/Webcam/ImageInput";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { generateHash } from "api/user.js";
import Reaptcha from "reaptcha";
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      cpf: "",
      openwebcam:
        localStorage.getItem("WEBCAM") == null
          ? 0
          : localStorage.getItem("WEBCAM"),
      image_name: "",
      imageData: null,
      saveImage: false,
      setAlert: false,
      setCPF: "",
      verified: false,
      showCaptcha: false,
      loginFaceDisable: process.env.REACT_APP_FACIAL_DISABLE
    };
    this.handleChangeUsername = this.handleChangeUsername.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleClickSubmit = this.handleClickSubmit.bind(this);
    this.handleOpenWebCam = this.handleOpenWebCam.bind(this);
    this.handleClickCapturar = this.handleClickCapturar.bind(this);
    this.handleCloseWebCam = this.handleCloseWebCam.bind(this);
    this.BaseUrl = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : '';
  }

  onVerify = () => {
    this.setState({ verified: true });
  };

  setRef = (webcam) => {
    this.webcam = webcam;
  };

  handleChangeUsername(event) {
    let masked = mask(event.target.value, ["999.999.999-99"]);
    this.setState({ cpf: masked });
  }

  handleChangePassword(event) {
    this.setState({ password: event.target.value });
  }

  async handleClickSubmit() {
    let userLogin = await apiLogin(
      this.state.cpf,
      generateHash(this.state.password)
    );
    if (userLogin === 401) {
      this.setState({ setAlert: true, showCaptcha: false });

      setTimeout(
        function() {
          this.setState({
            setAlert: false,
            showCaptcha: true,
            verified: false,
          });
        }.bind(this),
        2000
      );
    }
  }

  handleOpenWebCam() {
    this.setState({ openwebcam: 1 });
    localStorage.setItem("WEBCAM", 1);
  }

  handleCloseWebCam() {
    this.setState({ openwebcam: 0 });
    localStorage.setItem("WEBCAM", 0);
  }

  handleClickCapturar() {
    const imageSrc = this.webcam.getScreenshot();
    this.setState({ imageData: imageSrc });
    // await apiLoginFace(imageSrc)
  }
 handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.handleClickSubmit();
    }
  };

  render() {
    const { classes } = this.props;
    const { openwebcam } = this.state;
    const { setAlert } = this.state;
    const { cpf } = this.state;

    return (
      <Grid container component="main" className={classes.root}>
        <Grid
          item
          xs={7}
          sm={8}
          md={5}
          component={Paper}
          square
          style={{
            borderRadius: "10px 20px 80px",
            maxWidth: "530px",
            zoom: "90%",
          }}
        >
          <div className={classes.paper}>
            <img className={classes.filogo} src={Logo} alt="logo envsense" />

            {openwebcam == 0 ? (
              <form className={classes.form}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="CPF"
                  name="cpf"
                  autoComplete="off"
                  autoFocus
                  value={cpf}
                  onChange={this.handleChangeUsername}
                  onKeyDown={this.handleKeyDown}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Senha"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={this.handleChangePassword}
                  onKeyDown={this.handleKeyDown}
                />
                {this.state.showCaptcha ? (
                  <Reaptcha
                    sitekey="6LcDE6geAAAAAOJHAghRHZB-7uLP-lgx3e4jvLRG"
                    onVerify={this.onVerify}
                  />
                ) : (
                  ""
                )}
                {
                  <Grid container>
                    <Grid item className={classes.items}>
                      <Link
                        className={classes.filink}
                        href={`${this.BaseUrl}/recovery`}
                        color="danger"
                        >
                        {" Esqueceu sua senha ?"}
                      </Link>
                    </Grid>
                  </Grid>
                }    
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.fisubmit}
                  onClick={this.handleClickSubmit}
                  onKeyDown={this.handleKeyDown}
                  disabled={!this.state.verified && this.state.showCaptcha}
                >
                  Entrar
                </Button>    
                {this.state.loginFaceDisable === "false" ? <Grid container>
                  <Grid item className={classes.items}>
                    <Link
                      className={classes.filink}
                      variant="body2"
                      onClick={this.handleOpenWebCam}
                    >
                      <LockOpenIcon className={classes.iconLock} />{" "}
                      {"Logar com reconhecimento facial"}
                    </Link>
                  </Grid>
                </Grid> : ''}
                <div className={classes.footerSpan}></div>
              </form>
            ) : (
              <>
                <Grid container>
                  <Grid item className={classes.items}>
                    <ImageImput />
                  </Grid>
                  <Grid item className={classes.items}>
                    <Link
                      className={classes.filink}
                      onClick={this.handleCloseWebCam}
                    >
                      <LockOpenIcon className={classes.iconLock} />{" "}
                      {"Login com senha"}
                    </Link>
                  </Grid>
                </Grid>
                <div className={classes.footerCam}></div>
              </>
            )}
            <Snackbar
              place="tr"
              color="danger"
              icon={AddAlert}
              message="Credenciais incorretas"
              open={setAlert}
              closeNotification={() => ({ setAlert: false })}
              close
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);
