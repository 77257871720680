
import axios from "axios";
import _ from "lodash";
import { api } from "api/api";

let user = JSON.parse(sessionStorage.getItem("USER"));
let user_id = _.get(user, "id", null);

var tokenGet =
  sessionStorage.getItem("TOKEN_KEY") &&
  sessionStorage.getItem("TOKEN_KEY").charAt(0) == '"'
    ? sessionStorage.getItem("TOKEN_KEY")
    : JSON.stringify(sessionStorage.getItem("TOKEN_KEY"));
var token = JSON.parse(tokenGet);

export async function apiStartVotacaoComision(id, name) {
    let req = null;

    await axios({
        method: "post",
        url: process.env.REACT_APP_STAGE + "/votingComision/start",
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            id: id,
            nome: name
        },
    })
        .then((response) => {
            req = response;
        })
        .catch(function (error) {
            Promise.reject(error.response);
        });

    return req;
}

// finalizar votação das comissões
export async function apiFinishVotingComision(id, name) {
    axios({
        method: "post",
        url: process.env.REACT_APP_STAGE + "/votingComision/finish",
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        data: {
            id: id,
            nome: name
        }
    });
}

export async function getStatusConfig(name) {
    return await api.get("/tipoComisionConfig").then((response) => {
        const result = _.get(response, "data", []).filter(data => data.name === name);
        return result ? result[0].status : false;
    });
}

export async function getComisionesUser() {
    return await api.get("/comisionactive").then((response) => {
        let comis_user = [];
        let comis_rel = [];
        let comisiones = _.get(response, "data", []);
        comisiones.map((comision) => {
            if (comision.status) {
                comis_rel = comision.users.filter(
                    (user) => user.user_id === user_id
                );
                if (comis_rel.length > 0) {
                    comis_rel.map((item) => {
                        item.comision_name = comision.name;
                        item.status_voto = false;
                    });
                    comis_user.push(comis_rel[0]);
                }
            }
        });
        return comis_user;
    });
}

export function getCurrentComision(comisionesUser) {
    if (comisionesUser.length === 0) {
        return false;
    }
    
    let comisiones_pendientes = comisionesUser.filter(comi => comi.status_voto === false);
    if (comisiones_pendientes.length > 0) {
        return comisiones_pendientes[0];
    }

    return false;
}