import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import apiCargo from "../../api/cargo";

import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import TableCargo from "./TableCargo";
import { Error } from "../Parties/styles";
import _ from "lodash";

export default function TypeDocument() {
  const classes = useStyles();

  const [name, setName] = useState();
  const [order, setOrder] = useState('');
  const [alert, setAlert] = useState(false);
  const [validation, setValidation] = useState([]);
  const [cargo, setCargo] = useState([]);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null);

  const handleForm = (e) => {
    switch (e.target.name) {
      case "name":
        return setName(e.target.value);
      case "order":
        return setOrder(e.target.value);  
    }
  };

  const handleAddCargo = async (e) => {
    e.preventDefault();

    if (edit == true) {
      await apiCargo.put(`cargo/${id}`, {
        name: name,
        order_list: order,
      });

      setName("");
      setOrder("");

      setAlert(true);

      setTimeout(() => {
        setAlert();
      }, 1000);

      setEdit(false);

      apiCargo.get("cargo").then((response) => {
        setCargo(response.data);
        sessionStorage.setItem("CARGOS", JSON.stringify(response.data));
      });

      return;
    }

    let data = await apiCargo
      .post("cargo", {
        name: name,
        order_list: order,
      })
      .catch(function(error) {
        if (error.response) {
          setValidation(_.get(error, "response.data.errors", []));
        }
      });

    if (data == undefined || data == null) {
      return;
    }

    setName("");
    setOrder("");
    setValidation("");

    setAlert(true);

    setTimeout(
      function() {
        setAlert();
      }.bind(this),
      1000
    );

    apiCargo.get("cargo").then((response) => {
      setCargo(response.data);
      sessionStorage.setItem("CARGOS", JSON.stringify(response.data));
    });
  };

  const handleDelete = async (e) => {
    await apiCargo.delete(`cargo/${e.id}`, {
      id: e.id,
    });
    setAlert(true);

    setTimeout(() => {
      setAlert();
    }, 1000);

    await apiCargo.get("cargo").then((response) => {
      setCargo(response.data);
      sessionStorage.setItem("CARGOS", JSON.stringify(response.data));
    });
  };

  useEffect(() => {
    apiCargo.get("cargo").then((response) => {
      setCargo(response.data);
    });
  }, []);

  const handleEdit = async (e) => {
    setId(e.id);
    setName(e.name);
    setOrder(e.order_list ? e.order_list : '');
    setEdit(true);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card
            style={{
              boxShadow:
                "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
            }}
          >
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Adicionar cargo</h4>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <InputLabel className={classes.label}>
                    {"Nome do cargo"}
                  </InputLabel>
                  <FormControl className={classes.form}>
                    <Input name="name" value={name} onChange={handleForm} />
                    <Error>{_.get(validation, "name", "")}</Error>
                  </FormControl>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <InputLabel className={classes.label}>
                    {"Order do cargo"}
                  </InputLabel>
                  <FormControl className={classes.form}>
                    <Input name="order" type="number" value={order} onChange={handleForm} />
                    <Error>{_.get(validation, "name", "")}</Error>
                  </FormControl>
                </GridItem>
                <CardFooter>
                  <Button onClick={handleAddCargo} color="success">
                    Salvar
                  </Button>
                </CardFooter>
              </GridContainer>
            </CardBody>
            <Snackbar
              place="tr"
              color="success"
              icon={AddAlert}
              message="Sucesso"
              open={alert}
              closeNotification={() => setAlert(false)}
              close
            />
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {cargo && setCargo != "" ? (
            <TableCargo
              tableHeaderColor="primary"
              tableHead={["Cargos", "", ""]}
              tableData={cargo}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          ) : (
            ""
          )}
        </GridItem>
      </GridContainer>
    </>
  );
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
  },
};

const useStyles = makeStyles(styles);
