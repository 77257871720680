import React from "react";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import Tooltip from "@material-ui/core/Tooltip";
import ImageIcon from "@material-ui/icons/Image";
import _ from "lodash";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import Pdf from "components/Util/pdf";

const useStyles = makeStyles(styles);

var tokenGet =
  sessionStorage.getItem("TOKEN_KEY") &&
  sessionStorage.getItem("TOKEN_KEY").charAt(0) == '"'
    ? sessionStorage.getItem("TOKEN_KEY")
    : JSON.stringify(sessionStorage.getItem("TOKEN_KEY"));
var TOKEN_KEY = JSON.parse(tokenGet);

export default function DocumentTable(props) {
  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor,situation} = props;
  const [modal, setModal] = React.useState(false);
  const [pdf, setPdf] = React.useState(null);
  const [pdfExecutivo, setPdfExecutivo] = React.useState(null);
  const [img, setImg] = React.useState(false);

  const handleOpenExecutivo = (pdfExecutivo) => {
    if (pdfExecutivo.indexOf("localhost") >= 0) {
      pdfExecutivo = pdfExecutivo.replace("/api/", "");
    } else {
      pdfExecutivo = pdfExecutivo.replace("/api2/api/", "/api2/");
    }
    pdfExecutivo = pdfExecutivo.replace("public", "") + "#toolbar=0&view=FitH";
    setPdfExecutivo(pdfExecutivo);
    setModal(true);
  };

  const handleOpen = (pdf, img_click) => {
    //SI TRAE LA EXTENSION PDF DEL CAMPO pdfExecutivo ASIGNADO EN V2 SessionController -> integration
    if (pdf.indexOf(".pdf") >= 0) {
      handleOpenExecutivo(pdf);
    } else {
      setPdfExecutivo(null);
      if (img_click) {
        //setPdf(pdf + "?api_token=" + TOKEN_KEY);
        setPdf(pdf);
        setImg(true);
        setModal(true);
      } else {
        //setPdf(pdf + "?api_token=" + TOKEN_KEY);
        setPdf(pdf);
        setImg(false);
        setModal(true);
      }
    }
  };

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {(tableData || []).map((prop, key) => {
            return (
              <TableRow
                key={key}
                className={classes.tableBodyRow}
                name={prop.id}
              >
                <TableCell
                  className={classes.tableCell}
                  style={{ fontSize: "16px" }}
                >
                  {_.get(prop, "name", "")}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  style={{ fontSize: "16px" }}
                >
                  {_.get(prop, "type.name", "")}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  style={{ fontSize: "16px" }}
                >
                  {_.get(prop, "part.name", "")}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  style={{ fontSize: "16px" }}
                >
                  {/*_.get(prop, 'order', '')*/}
                  &nbsp;
                  {key === 0 ? (
                    <span
                      className="material-icons"
                      style={{ cursor: "pointer", color: "gray" }}
                    >
                      arrow_upward
                    </span>
                  ) : (
                    <span
                      className="material-icons"
                      style={{ cursor: "pointer" }}
                      onClick={() => props.handleOrder(prop, "up")}
                    >
                      arrow_upward
                    </span>
                  )}
                  &nbsp;
                  {key === tableData.length - 1 ? (
                    <span
                      className="material-icons"
                      style={{ cursor: "pointer", color: "gray" }}
                    >
                      arrow_downward
                    </span>
                  ) : (
                    <span
                      className="material-icons"
                      style={{ cursor: "pointer" }}
                      onClick={() => props.handleOrder(prop, "down")}
                    >
                      arrow_downward
                    </span>
                  )}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <LightTooltip title="Adicinar Usuários que Deveram Assinar o Documento/Projeto Referente">
                    <BorderColorIcon
                      style={{ display: "none" }}
                      onClick={() => props.handleSign(prop)}
                    />
                  </LightTooltip>

                  {(situation!='Finalizado' && situation!='Excluida') &&  _.get(prop, "register.id", null) === null ? (
                    <LightTooltip title="Editar Dados Referente Ao Documento">
                      <span
                        className="material-icons"
                        style={{ color: "#43a047", marginLeft: "20px" }}
                        onClick={() => props.handleClickOpen(prop)}
                      >
                        create
                      </span>
                    </LightTooltip>
                  ) : (
                    <span></span>
                  )}

                  { (situation!='Finalizado' && situation!='Excluida') && _.get(prop, "register.id", null) === null ? (
                    <LightTooltip title="Excluir documento">
                      <span
                        className="material-icons"
                        style={{ color: "red", marginLeft: "12px" }}
                        onClick={() => props.handleClickDelete(prop)}
                      >
                        delete_sweep
                      </span>
                    </LightTooltip>
                  ) : (
                    <span></span>
                  )}

                  {_.get(prop, "document_photo[0]", null) ? (
                    <LightTooltip title="Contém imagem">
                      <ImageIcon
                        onClick={() =>
                          handleOpen(prop.document_photo[0].url, true)
                        }
                        className="componentButton componentButton--gray-light"
                      />
                    </LightTooltip>
                  ) : (
                    ""
                  )}
                </TableCell>
                {_.get(prop, "pdf", null) ? (
                  <TableCell>
                    <LightTooltip title="Visualizar PDF">
                      <VisibilityIcon
                        onClick={() => handleOpen(prop.pdfUrl, false)}
                        style={{ color: "#43a047", marginRight: "3px" }}
                      />
                    </LightTooltip>
                  </TableCell>
                ) : (
                  <TableCell></TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Modal
        className={classes.modal}
        open={modal}
        onClose={() => setModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <Card className="makeStylesView">
            <CardHeader color="success" className={classes.cardHeader}>
              <span className="material-icons" onClick={() => setModal(false)}>
                close
              </span>
            </CardHeader>

            <CardBody>
              {pdfExecutivo ? (
                <object
                  data={pdfExecutivo}
                  type="application/pdf"
                  width="100%"
                  height="900"
                />
              ) : img ? (
                <img src={pdf}></img>
              ) : (
                <Pdf height={800} file={pdf} />
              )}
            </CardBody>
          </Card>
        </Fade>
      </Modal>
    </div>
  );
}

DocumentTable.defaultProps = {
  tableHeaderColor: "gray",
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

DocumentTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  handleOrder: PropTypes.func,
  handleSign: PropTypes.func,
  handleClickOpen: PropTypes.func,
  handleClickDelete: PropTypes.func,
};
