import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import { apiLoginFace } from "api/api.js";
import Webcam from "react-webcam";
import { withStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/loginStyle.js";
import PropTypes from "prop-types";

const INIT_STATE = {
  fullDesc: null,
  match: null,
};

const WIDTH = 400;
const HEIGHT = 260;

class ImageInput extends Component {
  constructor(props) {
    super(props);
    this.webcam = React.createRef();

    this.state = { ...INIT_STATE, faceMatcher: null, btn: false };
  }

  handleImage = async () => {
    this.setState({ btn: true });
    await apiLoginFace(this.webcam.current.getScreenshot());
    this.setState({ btn: false });
  };

  resetState = () => {
    this.setState({ ...INIT_STATE });
  };

  capture = async () => {
    this.resetState();
    await this.setState({
      imageURL: URL.createObjectURL(this.webcam.target.files[0]),
      loading: true,
    });
    this.handleImage();
  };

  render() {
    const { classes } = this.props;
    const { btn } = this.state;
    const videoConstraints = {
      width: 1280,
      height: 720,
      facingMode: "user",
    };
    return (
      <div
        className="Camera"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: WIDTH,
            height: HEIGHT,
          }}
        >
          <div style={{ position: "relative", width: WIDTH }}>
            <div style={{ position: "absolute" }}>
              <Webcam
                audio={false}
                width={WIDTH}
                height={HEIGHT}
                ref={this.webcam}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
            </div>
          </div>
        </div>

        <Button
          disabled={btn}
          onClick={this.handleImage}
          variant="contained"
          color="primary"
          className={classes.fisubmit}
        >
          Capturar
        </Button>
      </div>
    );
  }
}

ImageInput.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(ImageInput));
