import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "assets/jss/material-dashboard-react/components/tableSelect.js";
import Checkbox from "@material-ui/core/Checkbox";
import _ from "lodash";

const useStyles = makeStyles(styles);
export default function TableModalStatus(props) {
  const classes = useStyles();
  const { tableData, selected } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    tableData.map((element) => element.index = null)
    tableData.map((element) => {
      selected.find(function(item) {
        if (item.id === element.id) {
          element.index = 1;
        }
      });
    });
    setData(tableData)
  },[tableData]);

  const deleteIndex = (column) => {
    data.find(function(item) {
      if (item.id === column.id) {
        item.index = null;
      }
    });

    props.handleSelect(column);
  };

  return (
    <>
      <div className={classes.title}>Todos os status</div>
      <div
        style={{
          overflow: "auto",
          height: "200px",
        }}
      >
        <table className={classes.table}>
          <tr>
            <td className={classes.td}>Nome</td>

            <td className={classes.td}>Selecionar</td>
          </tr>

          {data.map(function(column, i) {
            return (
              <tr className={classes.tr} key={i}>
                <td className={classes.td}>{column.name}</td>

                <td className={classes.td}>
                  {_.get(column, "index", null) === null ? (
                    <Checkbox
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                      onClick={() => props.handleSelect(column)}
                    />
                  ) : (
                    <Checkbox
                      checked
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                      onClick={() => deleteIndex(column)}
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </table>
      </div>
    </>
  );
}

TableModalStatus.defaultProps = {
  tableHeaderColor: "gray",
};

TableModalStatus.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  selected: PropTypes.arrayOf(PropTypes.string),
  handleSelect: PropTypes.func,
};
