import React, {useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import _ from "lodash";
//import CardFooter from "components/Card/CardFooter.js";
//import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { apiSalve } from "api/configuration.js";
import { Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
//import api from "../../api/api_v2";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
//import Snackbar from "../Snackbar/Snackbar";
//import AddAlert from "@material-ui/icons/AddAlert";

export default function Gerais(props) {
  const classes = useStyles();
  const { configPlenary, settingsValues } = props;
  //const [id] = React.useState(_.get(automation, "id", ""));
  //const [ip, setIp] = React.useState(_.get(automation, "ip", ""));
  //const [token, setToken] = React.useState(_.get(automation, "token", ""));
  //const [configurations, setConfiguration] = useState([]);
  //const [alert, setAlert] = useState(false);
  const [btnDiscurso, setDiscurso] = React.useState(false)

  const [gerais, setGeraisValues] = React.useState({
    integracao: { empresa: 'Sino' },
    votacao: { enableAbs: 0 },
  });

  const [logoCliente, setLogo] = React.useState({
    file: null,
    dataUrl: props.logoCliente,
    error: null,
  });

  useEffect(() => {
    let discurso = configPlenary.filter((value) => value.alias == "habilitar_documentos_vereador")
    setDiscurso(_.get(discurso[0], 'active', false))

    let _gerais = { ...gerais };

    for (const [key, value] of Object.entries(settingsValues.gerais)) {
      _gerais[key] = value;
    }
    setGeraisValues(_gerais);

    /*const fetchData = async () => {
      await api
          .get("/configurationplenary")
          .then((response) => setConfiguration(_.get(response, "data", [])));
    };
    fetchData();*/
  }, []);

  /*
  const update = async (event, id) => {
    return await api
        .post("/configurationplenary/update", {
          id: id,
          active: event.target.checked,
        })
        .then((response) => {
          setConfiguration(_.get(response, "data", []));
          setAlert(true);
          setTimeout(() => {
            setAlert(false);
          }, 2000);
        });
  };
  */
  
  /*const sendAutomation = () => {
    let data = {
      id: id,
      token: token,
      ip: ip,
    };

    apiSalve(data, "/configuration/automation/save");
  };*/

  /*const hadleForm = (event) => {
    switch (event.target.name) {
      case "ip":
        return setIp(event.target.value);
      case "token":
        return setToken(event.target.value);
      default:
        return "";
    }
  };*/

  const setLogImage = (e) => {
    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) {
      return;
    }

    if (files[0].type == "image/png") {
      let reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => {
        setLogo({ file: files[0], dataUrl: e.target.result });
      };
    } else {
      setLogo({
        dataUrl: logoCliente.dataUrl,
        error: "Somente aceito arquivo do tipo PNG!",
      });
    }
  };

  const handleLogo = async () => {
    if (logoCliente.file) {
      var formData = new FormData();
      formData.append("files", logoCliente.file);

      let resp = await apiSalve(formData, "/configuration/logo");

      if (typeof resp == "object" && !resp.success) {
        setLogo({
          dataUrl: logoCliente.dataUrl,
          error: "Erro ao salvar o arquivo!",
        });
      } else {
        props.sendMessage(resp);
      }
    }
  };

  const handleSettings = async (e) => {
    let key = e.target.dataset.key;
    let _gerais = { ...gerais };

    if (key) {
      _gerais[key][e.target.name] = e.target.value;

      setGeraisValues(_gerais);
      props.handleSettings('gerais', gerais);
    }
  }

  const handleEnable = async (e) => {
    let key = e.target.dataset.key;
    let _gerais = { ...gerais };
    
    if (key) {
      _gerais[key][e.target.name] = !_gerais[key][e.target.name];
      setGeraisValues(_gerais);
      props.handleSettings('gerais', gerais);
    }
  }

  const handleEnableDiscursos = async (e) => {
    let key = e.target.checked
    let data = {
      alias: "habilitar_documentos_vereador",
      active: key
    };

    await apiSalve(data, "/config/read-docs/enable");

    setDiscurso(key)
  }

  /*const updateSettings = async () => {
    //console.log(gerais.enableAbs);
    //props.handleSettings('gerais', gerais);
  }*/

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card
            style={{
              boxShadow:
                "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
            }}
          >
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Integração</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  {/*<InputLabel className={classes.label}>Empresa</InputLabel>*/}
                  <FormControl className={classes.form}>
                    <Select
                      name="empresa"
                      onChange={handleSettings}
                      value={gerais.integracao.empresa}
                      native={true}
                      inputProps={{
                        'data-key': 'integracao'
                      }}
                    >    
                      <option value={"Sino"}>Sino</option>
                      <option value={"QuatroR"}>QuatroR</option>
                    </Select>
                  </FormControl>
                </GridItem>
                {
                /*<GridItem sm={12}>
                  <GridItem xs={12} sm={12} md={12}>
                    <Button
                      style={{
                        backgroundColor: "#4caf50",
                        color: "white",
                        marginTop: "12px",
                      }}
                      color="default"
                      onClick={updateSettings}
                    >
                      Salvar
                    </Button>
                  </GridItem>
                </GridItem>
                */}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card
            style={{
              boxShadow:
                "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
            }}
          >
            <CardBody>
              <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                  <h4>Votação</h4>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <h4>Discursos do Vereador</h4>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  {/*<InputLabel className={classes.label}>Botão abster</InputLabel>*/}
                  <FormControlLabel
                    label="Habilitar Abstenção"
                    control={
                      <Switch
                          checked={gerais.votacao.enableAbs === true}
                          name="enableAbs"
                          onChange={handleEnable}
                          color="primary"
                          value={gerais.votacao.enableAbs} 
                          inputProps={{
                            'data-key': 'votacao'
                          }}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <FormControlLabel
                    label="Habilitar aba de discursos"
                    control={
                      <Switch
                        checked={btnDiscurso == 1 ? true : false}
                        name="enableDiscurso"
                        onChange={handleEnableDiscursos}
                        color="primary"
                        value={btnDiscurso == 1 ? true : false}
                      // inputProps={{
                      //   'data-key': 'votacao'
                      // }}
                      />
                    }
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem sm={12}>
          <Card
            style={{
              boxShadow:
                "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
            }}
          >
            <CardBody>
              <GridContainer>
                <GridItem sm={12}>
                  <h4>Logo do Cliente</h4>
                </GridItem>
                <GridItem sm={12}>
                  <Button
                    variant="contained"
                    component="label"
                    value={logoCliente.dataUrl}
                    style={{ backgroundColor: "#4AA2A2", color: "white" }}
                  >
                    Clique para selecionar
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={setLogImage}
                      accept="image/png"
                    />
                  </Button>
                </GridItem>
                <GridItem sm={12}>
                  <FormControl
                    className={classes.form}
                    style={{ padding: "5px 5px", backgroundColor: "#f3f3f3" }}
                  >
                    <img
                      src={logoCliente.dataUrl}
                      style={{ maxWidth: "100%", maxHeight: "65px" }}
                    />
                  </FormControl>
                  {logoCliente.error ? (
                    <Alert severity="error">{logoCliente.error}</Alert>
                  ) : (
                    ""
                  )}
                </GridItem>
                <GridItem sm={12}>
                  <Button
                    style={{
                      backgroundColor: "#4caf50",
                      color: "white",
                      marginTop: "12px",
                    }}
                    color="default"
                    onClick={handleLogo}
                  >
                    Salvar
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        {/*
        <GridItem xs={12} sm={12} md={12}>
          <Card
            style={{
              boxShadow:
                "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
            }}
          >
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Configurações de IP</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl className={classes.form}>
                    <InputLabel className={classes.label}>
                      {"AUTOMAÇÃO"}
                    </InputLabel>
                    <Input name="ip" value={ip} onChange={hadleForm} />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl className={classes.form}>
                    <InputLabel className={classes.label}>{"Token"}</InputLabel>
                    <Input name="token" value={token} onChange={hadleForm} />
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <span
                className={"material-icons"}
                style={{ color: "#4caf50" }}
                onClick={sendAutomation}
              >
                check_circle
              </span>
            </CardFooter>
          </Card>
        </GridItem>
        */}
        {/*<GridItem xs={12} sm={12} md={12}>
          <Card
            style={{
              boxShadow:
                "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
            }}
          >
            <CardBody>
              {
              configurations.filter(item =>item.parent_id==null).map((itemParent, i) => (
                      <CardBody key={i}>
                        <GridContainer>
                          <GridItem xs={6}>
                            <FormControlLabel
                                label={itemParent.name}
                                control={
                                  <Switch
                                      checked={itemParent.active}
                                      onChange={(e) => update(e, itemParent.id)}
                                      color="primary"
                                      value="dynamic-class-name"
                                  />
                                }
                            />
                          </GridItem>
                          {
                            configurations.filter(filter => filter.parent_id==itemParent.id).map((item, i) => (
                                    <GridItem xs={6} key={i}>
                                      <ul className="list">
                                        <li>
                                          <FormControlLabel
                                              label={item.name}
                                              control={
                                                <Switch
                                                    checked={item.active}
                                                    onChange={(e) => update(e, item.id)}
                                                    color="primary"
                                                    value="dynamic-class-name"
                                                    disabled={itemParent.active==1 ? false : true}
                                                />
                                              }
                                          />
                                        </li>
                                      </ul>
                                    </GridItem>
                                )
                            )
                          }
                        </GridContainer>
                      </CardBody>
                  )
              )
            }
            </CardBody>
            
            <Snackbar
              place="tr"
              color="success"
              icon={AddAlert}
              message={"Configuração atualizada com sucesso"}
              open={alert}
              closeNotification={() => setAlert(false)}
              close
            />
          </Card>
        </GridItem>*/
        }
      </GridContainer>
    </div>
  );
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
  },
};

Gerais.propTypes = {
  classes: PropTypes.object,
  automation: PropTypes.object.isRequired,
  sendMessage: PropTypes.func.isRequired,
  logoCliente: PropTypes.string.isRequired,
  settingsValues: PropTypes.object.isRequired,
  handleSettings: PropTypes.func.isRequired,
};

const useStyles = makeStyles(styles);
