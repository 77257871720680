import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Admin from "layouts/Admin.js";
import ControllerTime from "layouts/ControllerTime.js";
import LoginPage from "layouts/Login.js";
import RecoveryPage from "layouts/Recovery.js";
import ChangePasswordPage from "layouts/ChangePassword.js";
import TimeAppPage from "layouts/TimeApp.js";
import Parliamentary from "layouts/Parliamentary.js";
import President from "layouts/President.js";
import VideowallPage from "layouts/VideoWall";
import "assets/css/material-dashboard-react.css?v=1.8.0";
import echo from "api/channels";
import api from "api/api_v2";
import _ from "lodash";


const BaseUrl = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : ''
const hist = createBrowserHistory({'basename': BaseUrl});

let user = JSON.parse(sessionStorage.getItem("USER"));
let user_id = _.get(user, "id", null);
let usc = _.get(user, "id_access_level", 0);
let legislativo = JSON.parse(sessionStorage.getItem("LEGISLATIVO"));



if (window.location.pathname === `${BaseUrl}/logout`) {
  sessionStorage.clear();
  localStorage.clear();

  ReactDOM.render(
    <Router history={hist}>
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Redirect from="/" to="/login" />
      </Switch>
    </Router>,
    document.getElementById("root")
  );
} else if (window.location.pathname === `${BaseUrl}/legislativo/consultaPublica`) {
  window.location.href = process.env.REACT_APP_STAGE_2 + "/consultaPublica";
} else if (
  window.location.pathname === `${BaseUrl}/sessions` &&
  (usc === 2 || usc === 10)
) {
  ReactDOM.render(
    <Router history={hist}>
      <Switch>
        <Route path="/admin" component={Admin} />
        <Redirect from="/" to="/admin/session" />
      </Switch>
    </Router>,
    document.getElementById("root")
  );
} else if (
  legislativo === 1 &&
  (usc === 6 || usc === 7) &&
  sessionStorage.getItem("TOKEN_KEY") != null
) {
  window.location.href =
    process.env.REACT_APP_STAGE_2 +
    "/?id=" +
    user_id +
    "&token=" +
    sessionStorage.getItem("TOKEN_KEY");
} else if (
  legislativo === 1 &&
  usc === 2 &&
  sessionStorage.getItem("USER") !== null &&
  sessionStorage.getItem("TOKEN_KEY") != null
) {
  api.get("plenario/user/" + user.id).then(function(response) {
    if (_.get(response, "data.status", 0) === 1) {
      ReactDOM.render(
        <Router history={hist}>
          <Switch>
            <Route path="/admin" component={Admin} />
            <Redirect from="/" to="/admin/dashboard" />
          </Switch>
        </Router>,
        document.getElementById("root")
      );
    } else {
      window.location.href =
        process.env.REACT_APP_STAGE_2 +
        "/?id=" +
        user_id +
        "&token=" +
        sessionStorage.getItem("TOKEN_KEY");
    }
  });
} else if (legislativo === 1 && usc === 3) {
  api.get("plenario/user/" + user.id).then(function(response) {
    if (_.get(response, "data.session.id", null) !== null) {
      ReactDOM.render(
        <Router history={hist}>
          <Switch>
            <Route path="/President" component={President} />
            <Redirect from="/" to="/President" />
          </Switch>
        </Router>,
        document.getElementById("root")
      );
    } else {
      window.location.href =
        process.env.REACT_APP_STAGE_2 +
        "/?id=" +
        user_id +
        "&token=" +
        sessionStorage.getItem("TOKEN_KEY");
    }
  });
} else if (legislativo === 1 && usc === 4) {
  api.get("plenario/user/" + user.id).then(function(response) {
    if (_.get(response, "data.session.id", null) !== null) {
      ReactDOM.render(
        <Router history={hist}>
          <Switch>
            <Route path="/Parliamentary" component={Parliamentary} />
            <Redirect from="/" to="/Parliamentary" />
          </Switch>
        </Router>,
        document.getElementById("root")
      );
    } else {
      window.location.href =
        process.env.REACT_APP_STAGE_2 +
        "/?id=" +
        user_id +
        "&token=" +
        sessionStorage.getItem("TOKEN_KEY");
    }
  });
} else if (window.location.pathname===`${BaseUrl}/timeapp`) {
  ReactDOM.render(
    <Router history={hist}>
      <Switch>
        <Route path="/timeapp" component={TimeAppPage} />
        <Redirect from="/" to="/timeapp" />
      </Switch>
    </Router>,
    document.getElementById("root")
  );
} else if (window.location.pathname === `${BaseUrl}/videowall`) {
  ReactDOM.render(
    <Router history={hist}>
      <Switch>
        <Route path="/videowall" component={VideowallPage} />
        <Redirect from="/" to="/videowall" />
      </Switch>
    </Router>,
    document.getElementById("root")
  );
} else if (
  usc === 3 &&
  sessionStorage.getItem("USER") !== null &&
  sessionStorage.getItem("TOKEN_KEY") != null
) {
  ReactDOM.render(
    <Router history={hist}>
      <Switch>
        <Route path="/President" component={President} />
        <Redirect from="/" to="/President" />
      </Switch>
    </Router>,
    document.getElementById("root")
  );
} else if (
  usc == 4 &&
  sessionStorage.getItem("USER") !== null &&
  sessionStorage.getItem("TOKEN_KEY") != null
) {
  ReactDOM.render(
    <Router history={hist}>
      <Switch>
        <Route path="/Parliamentary" component={Parliamentary} />
        <Redirect from="/" to="/Parliamentary" />
      </Switch>
    </Router>,
    document.getElementById("root")
  );
} else if (
  (window.location.pathname === `${BaseUrl}/controlador` || usc == 5) &&
  sessionStorage.getItem("USER") !== null &&
  sessionStorage.getItem("TOKEN_KEY") != null
) {
  ReactDOM.render(
    <Router history={hist}>
      <Switch>
        {/* Usando o component do presidente para o acesso do controlador */}
        <Route path="/ControllerTime" component={President}  />
        <Redirect from="/" to="/ControllerTime" />
      </Switch>
    </Router>,
    document.getElementById("root")
  );
} else if (
  (usc == 1 || usc == 2) &&
  sessionStorage.getItem("USER") !== null &&
  sessionStorage.getItem("TOKEN_KEY") != null
) {
  ReactDOM.render(
    <Router history={hist}>
      <Switch>
        <Route path="/admin" component={Admin} />
        <Redirect from="/" to="/admin/dashboard" />
      </Switch>
    </Router>,
    document.getElementById("root")
  );
} else if (usc === 8 || usc === 9 || usc === 10 || usc === 11 || usc === 12) {
  window.location.href =
    process.env.REACT_APP_STAGE_2 +
    "/?id=" +
    user_id +
    "&token=" +
    sessionStorage.getItem("TOKEN_KEY");
}else if (window.location.pathname === `${BaseUrl}/recovery`) {
  ReactDOM.render(
    <Router history={hist}>
      <Switch>
        <Route path="/recovery" component={RecoveryPage} />
        <Redirect from="/" to="/recovery" />
      </Switch>
    </Router>,
    document.getElementById("root")
  );
}else if(window.location.pathname.search("/changepassword/")===0){
  ReactDOM.render(
    <Router history={hist}>
      <Switch>
        <Route exact path="/changepassword/:token" component={ChangePasswordPage} />
        <Redirect from="/" to="/changepassword" />
      </Switch>
    </Router>,
    document.getElementById("root")
  );
}else
  ReactDOM.render(
    <Router history={hist}>
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Redirect from="/" to="/login" />
      </Switch>
    </Router>,
    document.getElementById("root")
  );

echo.channel("presence").listen("Logout", event => {
  let id = parseInt(event);
  if (id == user_id) {
    sessionStorage.clear();
    window.location.reload();
  }
});
