import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import List from "components/ControllerTime/List.js";
import Button from "@material-ui/core/Button";
import { apiSendTribuna } from "api/tribuna.js";
import { checkStartSession } from "api/_tribuna.js";
import { apiAddTime } from "api/timeapp.js";
import echo from "api/channels";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { apiImplement } from "api/tribuna";
import _ from "lodash";

const styles = {
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: "center",
    color: "black",
    display: "flex",
    fontSize: "200%",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    fontWeight: "bold",
    padding: "66px",
  },
  formControl: {
    margin: "4%",
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: "3%",
  },
  margin: {
    margin: "1%",
  },
  div_button: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

class ControllerTimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      select_inscricao: null,
      selected_tribuna: "",
      usersTribuna: null,
      pause: false,
      modal: false,
      aparte: null,
    };
    this.hadleTribuna = this.hadleTribuna.bind(this);
    this.sendTribuna = this.sendTribuna.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalConceder = this.handleModalConceder.bind(this);
    this.handleModalNaoConceder = this.handleModalNaoConceder.bind(this);
  }

  handleModal = () => {
    this.setState({ modal: true });
  };

  handleModalClose = () => {
    this.setState({ modal: false });
  };

  handleModalNaoConceder = (id_user, message) => {
    apiImplement(id_user, null, message, "/tribuna/talk/time/TalkDenied");
    this.setState({ modal: false });
  };
  handleModalConceder = (id_user, id_tribuna, type) => {
    apiImplement(id_user, id_tribuna, type, "/tribuna/talk/time/approved");
    this.setState({ modal: false });
  };

  async componentDidMount() {
    const data = await checkStartSession();
    this.setState({ select_inscricao: _.get(data, "tribunas", null) });
    this.setState({ usersTribuna: _.get(data, "users", null) });

    echo.channel("tribuna").listen("TalkTimeImplement", () => {
      this.setState({ modal: false });
      this.setState({ aparte: null });
    });

    echo.channel("tribuna").listen("GoOnTalk", (e) => {
      this.setState({ aparte: e });
      this.handleModal();
    });

    echo.channel("session").listen("StartSessionTribuna", (e) => {
      if (e.id == null) {
        this.setState({ select_inscricao: null });
      }
      this.setState({ select_inscricao: e });
    });

    echo.channel("getUsersTimeApp").listen("AddSubscription", (e) => {
      this.setState({ usersTribuna: _.get(e, "inscritos", null) });
    });
  }
  hadleTribuna(event) {
    this.setState({ selected_tribuna: event.target.value });
  }

  sendTribuna() {
    apiSendTribuna(this.state.selected_tribuna);
  }

  addTime(operation, time) {
    if (operation === "pause") {
      this.setState({ pause: true });
    }
    if (operation === "retomar" || operation === "finish" || operation === "zerar") {
      this.setState({ pause: false });
    }
    apiAddTime(operation, time);
  }

  render() {
    const { classes } = this.props;
    const { aparte, select_inscricao, selected_tribuna, usersTribuna } = this.state;

    return (
      <div className={classes.root}>
        <Grid container spacing={5} style={{ width: "100%", margin: "0px" }}>
          <Grid item xs={12} lg={6}>
            <Paper className={classes.paper}>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "space-around",
                  width: "60%",
                }}
              >
                Inscrição de tribuna
              </div>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple">Tribuna</InputLabel>
                <Select
                  native
                  value={selected_tribuna}
                  onChange={this.hadleTribuna}
                  label="Age"
                  inputProps={{
                    name: "age",
                    id: "outlined-age-native-simple",
                  }}
                >
                  <option value=""> </option>
                  {(select_inscricao || []).map((prop) => {
                    return (
                      <option key={prop.id} value={prop.id}>
                        {prop.subscription}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>

              {selected_tribuna === "" ? (
                ""
              ) : (
                <div className={classes.div_button}>
                  <Button onClick={this.sendTribuna} variant="contained" className="componentButton componentButton--green">
                    Enviar
                  </Button>
                </div>
              )}
            </Paper>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Paper className={classes.paper}>
              Controle de Tempo
              <div style={{ marginTop: "5%", width: "100%" }}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => this.addTime("add", "00:01:00")}
                  style={{ backgroundColor: "#4AA2A2", color: "white" }}
                  className={classes.margin}
                >
                  + 1 minuto
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => this.addTime("add", "00:05:00")}
                  style={{ backgroundColor: "#4AA2A2", color: "white" }}
                  color="primary"
                  className={classes.margin}
                >
                  + 5 minutos{" "}
                </Button>
              </div>
              <div style={{ width: "100%" }}>
                <Button
                  variant="contained"
                  onClick={() => this.addTime("finish", "00:00:00")}
                  style={{ backgroundColor: "#43BD18", color: "white" }}
                  size="large"
                  className={classes.margin}
                >
                  Concluir
                </Button>
                {this.state.pause == false ? (
                  <Button
                    variant="contained"
                    onClick={() => this.addTime("pause", "00:00:00")}
                    style={{ backgroundColor: "#C0941D", color: "white" }}
                    size="large"
                    className={classes.margin}
                  >
                    Pausar
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => this.addTime("retomar", "00:00:00")}
                    style={{ backgroundColor: "#C0941D", color: "white" }}
                    size="large"
                    className={classes.margin}
                  >
                    Retomar
                  </Button>
                )}
                <Button
                  variant="contained"
                  onClick={() => this.addTime("zerar", "00:00:00")}
                  style={{ backgroundColor: "#A41D33", color: "white" }}
                  size="large"
                  className={classes.margin}
                >
                  zerar
                </Button>
              </div>
            </Paper>
          </Grid>
          {select_inscricao == null ? (
            ""
          ) : (
            <Grid item xs={12} sm={12}>
              <Paper className={classes.paper} style={{ marginBottom: "10px" }}>
                Vereadores Inscritos:
                {usersTribuna == null ? "" : usersTribuna.map((prop, key) => <List key={key} cont={key} name={prop.name} id={prop.id} />)}
              </Paper>
            </Grid>
          )}
        </Grid>
        <Modal
          className={classes.modal}
          open={this.state.modal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Paper className={classes.paper}>
            <h4>
              Parlamentar {_.get(aparte, "user.name", "")} pediu {_.get(aparte, "type", "")}
            </h4>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
              }}
            >
              <Button
                onClick={() => this.handleModalConceder(_.get(aparte, "user.id", null), _.get(aparte, "tribuna.id", null), _.get(aparte, "type", null))}
                variant="contained"
                className="componentButton componentButton--green"
              >
                Conceder
              </Button>
              <Button
                onClick={() => this.handleModalNaoConceder(_.get(aparte, "user.id", null), "Questão de Ordem Negada")}
                variant="contained"
                className="componentButton componentButton--red"
              >
                Rejeitar
              </Button>
            </div>
          </Paper>
        </Modal>
      </div>
    );
  }
}

ControllerTimer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ControllerTimer);
