import React, {useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
//import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
//import CardBody from "components/Card/CardBody.js";
import TextField from "@material-ui/core/TextField";
import VideoVote from "components/Webcam/videoVote";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { apiVote } from "api/voting";
import _ from "lodash";
import "../../../assets/sass/components/president/voting.scss";
import PropTypes from "prop-types";

import { getSettingsByName } from "api/settings.js";

export default function ModalVoting(props) {
  const classes = useStyles();
  let user = JSON.parse(sessionStorage.getItem("USER"));
  //let configurationPlenary = JSON.parse(sessionStorage.getItem("CONFIGURATIONPLENARY"));
  //let habilitar_boton_abster = configurationPlenary ? configurationPlenary.find(config => config.alias =='habilitar_boton_abster') : {active : 0};

  const { user_vote, openwebcam, descriptors, voting, id_session } = props;
  const [nome_voting] = React.useState(
    _.get(voting, "name", "Projeto para Votação")
  );
  const [ementa_voting] = React.useState(
    _.get(voting, "description", " Não contém Ementa")
  );
  const [id_document] = React.useState(_.get(voting, "id", null));
  const [vote, setVote] = React.useState(-1);
  const [confimation] = React.useState(null);
  const [password, setPasssword] = React.useState(null);
  const [alert, setAlert] = React.useState(false);
  const [alertSuccess, setAlertSuccess] = React.useState(false);
  const [settingsVotacao, setSettingsVotacao] = React.useState(null);

  const [can, setCan] = React.useState(openwebcam);

  const send = async () => {
    let data = {
      vote: vote,
      id_document: id_document,
      id_session: id_session,
      id_user: _.get(user, "id", ""),
      password: password,
      public_name: _.get(user, "public_name", ""),
    };

    let voteUser = await apiVote(data, "/voting/vote");

    if (voteUser === 401) {
      setAlert(true);

      setTimeout(
        function() {
          setAlert(false);
        }.bind(this),
        2000
      );

      return;
    }

    if (user_vote == false || user_vote == null) {
      props.handleAlterarVoto();
    }

    setAlertSuccess(true);

    setTimeout(
      function() {
        setAlertSuccess(false);
        props.handleClose();
      }.bind(this),
      1500
    );
  };

  useEffect(() => {
    getSettingsByName('gerais').then((result) => {
      setSettingsVotacao(result.values.votacao);
    });
  }, []);

  const handleVote = (e) => {
    setVote(e);
  };

  const handleChangePassword = (e) => {
    setPasssword(e.target.value);
  };

  const setRef = (webcam) => {
    this.webcam = webcam;
  };

  const sendVerifique = async (img) => {
    let data = {
      file: img,
      vote: vote,
      id_document: id_document,
      id_session: id_session,
      id_user: _.get(user, "id", ""),
    };
    let voteUser = await apiVote(data, "/voting/vote/face");

    if (voteUser === 401) {
      setAlert(true);

      setTimeout(
        function() {
          setAlert(false);
        }.bind(this),
        2000
      );

      return;
    }

    setAlertSuccess(true);

    setTimeout(
      function() {
        setAlertSuccess(false);
        props.handleClose();
      }.bind(this),
      1500
    );

    if (user_vote == false || user_vote == null) {
      props.handleAlterarVoto();
    }
  };

  return (
    <div>
      <GridContainer
        className="generalModal"
      >
        <GridItem xs={12} sm={12} md={12}>
          <div className="modalContainer">
            {confimation != null && openwebcam == 1 ? (
              ""
            ) : (
              <CardHeader className="modalContainer--header">
                <h2 className={classes.cardTitleWhite}>{nome_voting}</h2>
              </CardHeader>
            )}
            {vote == -1 ? (
              <div className="modalContainer--body">
                <GridContainer className="p-0">
                  <GridItem xs={12} sm={12} md={12}>
                    <h3>EMENTA:</h3>
                    <p>{ementa_voting}</p>
                  </GridItem>
                </GridContainer>
                <GridContainer className="p-0">
                  {voting && voting.id_type === 5 ? (
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      className="modalVotacao__buttons"
                    >
                      <Button
                        onClick={() => handleVote(true)}
                        className="componentButton componentButton--green-outline"
                      >
                        Acatar Veto
                      </Button>
                      <Button
                        onClick={() => handleVote(false)}
                        className="componentButton componentButton--red-outline"
                      >
                        Rejeitar Veto
                      </Button>
                      {settingsVotacao && settingsVotacao.enableAbs && (
                        <Button
                          onClick={() => handleVote(null)}
                          className="componentButton componentButton--blue-outline"
                        >
                          Abster
                        </Button>
                      )}
                    </GridItem>
                  ) : (
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      className="modalVotacao__buttons"
                    >
                      <Button
                        onClick={() => handleVote(true)}
                        className="componentButton componentButton--green-outline"
                      >
                        SIM
                      </Button>
                      <Button
                        onClick={() => handleVote(false)}
                        className="componentButton componentButton--red-outline"
                      >
                        NÃO
                        </Button>
                        {settingsVotacao && settingsVotacao.enableAbs && (
                          <Button
                                onClick={() => handleVote(null)}
                                className="componentButton componentButton--blue-outline"
                            >
                              ABSTER
                            </Button>
                        )}
                      {/*
                        habilitar_boton_abster.active===  1 ?
                            <Button
                                onClick={() => handleVote(null)}
                                className="componentButton componentButton--blue-outline"
                            >
                              ABSTER
                            </Button>
                            : ''
                  */}

                    </GridItem>
                  )}
                </GridContainer>
              </div>
            ) : can == 0 ? (
              <>
                {" "}
                <div className="modalContainer--body">
                  <div className="boxPassword">
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Senha"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      onChange={handleChangePassword}
                    />
                    <Button
                      variant="contained"
                      className="componentButton componentButton--green"
                      onClick={() => send()}
                    >
                      Enviar Voto
                    </Button>
                  </div>
                </div>
                <div className="modalFooter__vote">
                  <div
                    className="modalFooter__vote--voltar"
                    variant="contained"
                    onClick={() => setVote(-1)}
                  >
                    <i className="fa-solid fa-arrow-rotate-left"></i>
                    Voltar
                  </div>
                  <div
                    className="modalFooter__vote--facial"
                    variant="contained"
                    onClick={() => setCan(1)}
                  >
                    Enviar por <span>Reconhecimento facial</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <VideoVote
                  sendVerifique={sendVerifique}
                  ref={setRef}
                  json_data={descriptors}
                />
                <div className="modalFooter__vote">
                  <div
                    className="modalFooter__vote--voltar"
                    variant="contained"
                    onClick={() => setVote(-1)}
                  >
                    <i className="fa-solid fa-arrow-rotate-left"></i>
                    Voltar
                  </div>
                  <div
                    className="modalFooter__vote--senha"
                    variant="contained"
                    onClick={() => setCan(0)}
                  >
                    Inserir <span>Senha</span>
                  </div>
                </div>
              </>
            )}
          </div>
        </GridItem>
      </GridContainer>
      <Snackbar
        place="tr"
        color="danger"
        icon={AddAlert}
        message="Credenciais Incorretas"
        open={alert}
        closeNotification={() => setAlert(false)}
        close
      />
      <Snackbar
        place="tr"
        color="success"
        icon={AddAlert}
        message="Seu voto foi computado com sucesso"
        open={alertSuccess}
        closeNotification={() => setAlertSuccess(false)}
        close
      />
    </div>
  );
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
  },
};

ModalVoting.propTypes = {
  classes: PropTypes.object.isRequired,
  user_vote: PropTypes.object,
  voting: PropTypes.object,
  handleVote: PropTypes.func,
  send: PropTypes.func,
  sendVerifique: PropTypes.func,
  descriptors: PropTypes.object,
  setRef: PropTypes.func,
  setCan: PropTypes.func,
  setVote: PropTypes.func,
  setAlert: PropTypes.func,
  setAlertSuccess: PropTypes.func,
  alert: PropTypes.bool,
  alertSuccess: PropTypes.bool,
  handleChangePassword: PropTypes.func,
  password: PropTypes.string,
  openwebcam: PropTypes.bool,
  id_session: PropTypes.number,
  setOpenwebcam: PropTypes.func,
  handleAlterarVoto: PropTypes.func,
  handleClose: PropTypes.func,
};

const useStyles = makeStyles(styles);
