import api_v2 from "../../api/api_v2";

export default async function ModuloLegislativo() {
  const user = JSON.parse(sessionStorage.getItem("USER"));

  await api_v2
    .get("plenario/active/" + user.id + "/"+0+'?&status=0')
    .then(function(response) {
      console.log(response);
      window.location.reload();
    });
}
