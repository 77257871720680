import React from "react";
import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Session/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import PropTypes from "prop-types";
import {
  apiIntegration,
  exportSession,
  apiDisallow,
  apiGet,
  apiGetDocsProjects,
  apiFilter,
} from "api/sessions.js";
import { apiSuspendSession } from "api/president";
import { apiCreateAtaSession, apiDisableAta } from "api/ataSession";
import Modal from "@material-ui/core/Modal";
import UserCreate from "components/Session/Form/ModalUser";
import SessionView from "components/Session/session.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Backdrop from "@material-ui/core/Backdrop";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import echo from "api/channels";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "components/CustomButtons/Button.js";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Input from "@material-ui/core/Input";
import CardFooter from "components/Card/CardFooter.js";
import _ from "lodash";
import api from "../../api/typedocument";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import fileDownload from "js-file-download";
import Loading from "components/Util/Loading";

class Session extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sessions: [],
      types: [],
      first_page_url: "",
      from: "",
      last_page: 1,
      last_page_url: "",
      next_page_url: null,
      path: "",
      per_page: "",
      prev_page_url: null,
      to: 1,
      total: 1,
      open: false,
      setOpen: false,
      edit: 0,
      sessao: null,
      data_session: null,
      start_session_name: null,
      start_session_time: null,
      desable: false,
      status_sessions: "",
      type_sessions: "",
      message: "",
      param: "success",
      alertSuccess: false,
      open_integracao: false,
      url_xml: "",
      file_xml: "",
      docReplace: false,
      loading_active: false,
      loading_label: "",
    };
  }

  componentDidMount() {
    this.reload();
    echo.channel("session").listen("StartSession", (e) => {
      this.setState({ start_session_name: _.get(e, "name", null) });
      this.setState({ start_session_time: _.get(e, "updated_at", null) });
      this.reload();
    });

    echo.channel("session").listen("SessionAutorized", () => {
      this.reload();
    });

    echo.channel("session").listen("DisallowSession", () => {
      this.reload();
    });

    echo.channel("session").listen("Suspend", () => {
      this.reload();
    });

    echo.channel("session").listen("StopSession", () => {
      this.reload();
    });
  }

  async reload() {
    let sessions = await apiGet();
    this.setState({ sessions: _.get(sessions, "sessions", []) });
    this.setState({ types: _.get(sessions, "typeSessions", []) });
    this.setState({
      start_session_name: _.get(sessions, "session_start.name", null),
    });
    this.setState({
      start_session_time: _.get(sessions, "session_start.updated_at", null),
    });
  }

  handleOpen = (param) => {
    this.setState({ edit: param.id ? param : 0 });
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleActivePauta = async (id, status) => {
    
    if (status == 0){
      apiCreateAtaSession(id);// criar ata da sessão 
    }

    if (status == 1){
      apiDisableAta(id); // disable ata_session
    }

    this.reload();
  }

  handleClickDelete = async (id,name) => {
    const { sessions } = this.state

    await api.post("session/disable", { id: id,'Nome da Sessão  ':name }).catch(function(error) {
      if (_.get(error, "response.data", null) !== null) {
        alert("Não é possivel excluir sessão, pois está autorizada");
        return;
      }
    });
    this.handleReload();

    // Validação se sessão é ordinaria ou extraordinaria
    sessions.map((session) => {
      if (session.id == id && (session.id_type == 4 || session.id_type == 3)) {
        apiDisableAta(id); // disable ata_session
      }  
    }) 
    //apiDeleteMicro(id, '/session/disable')
  };

  handleFormFile = (e) => {
    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;

    let reader = new FileReader();

    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      this.setState({ file_xml: e.target.result });
    };
  };

  handleForm = (e) => {
    this.setState({ url_xml: e.target.value });
  };

  async openDocs(id) {
    let session = await apiGetDocsProjects(id);
    this.setState({ sessao: id });
    this.setState({ data_session: session || [] });
  }

  startSession = async (id) => {
    let user = JSON.parse(sessionStorage.getItem("USER"));
    await api
      .post("session/autorized", { id: id, user: user.id })
      .catch(function(error) {
        if (_.get(error, "response.data", null) !== null) {
          alert(
            "Não é possivel autorizar está sessão, pois há outra sessão autorizada"
          );
          return;
        }
      });

  };

  stopSession = () => {
    apiSuspendSession();
    this.setState({ start_session_name: null });
    this.setState({ start_session_time: null });
  };

  dasautorizar = (id) => {

    apiDisallow(id);

 
  };

  handleFilterStatus = async (event) => {
    this.setState({ status_sessions: event.target.value });
    let sessions = await apiFilter(
      event.target.value,
      this.state.type_sessions
    );
    this.setState({ sessions: sessions || [] });
  };

  handleFilterType = async (event) => {
    this.setState({ type_sessions: event.target.value });
    let sessions = await apiFilter(
      this.state.status_sessions,
      event.target.value
    );
    this.setState({ sessions: sessions || [] });
  };

  handleReload = async () => {
    let sessions = await apiGet();
    this.setState({ sessions: _.get(sessions, "sessions", []) });
    this.setState({ type_sessions: "" });
    this.setState({ status_sessions: "" });
    this.setState({ data_session: null });
  };

  handleIntegration = async () => {
    this.setState({ loading_label: "Importando arquivo..." });
    this.setState({ loading_active: true });

    const { file_xml, url_xml, docReplace } = this.state;
    const response = await apiIntegration(file_xml, url_xml, docReplace);

    this.setState({ loading_active: false });

    if (_.get(response, "sucesso", null) != null) {
      this.setState({
        message: _.get(response, "sucesso", ""),
        param: "success",
        alertSuccess: true,
      });

      setTimeout(() => {
        this.setState({ alertSuccess: false });
        this.handleReload();
      }, 3000);
    } else {
      this.setState({
        message: _.get(
          response,
          "error",
          "Ocorreu algum erro no processo de integração"
        ),
        param: "danger",
        alertSuccess: true,
      });

      setTimeout(() => {
        this.setState({ alertSuccess: false });
        this.handleReload();
      }, 3000);

      return;
    }
  };

  setDocReplace = () => {
    this.setState({ docReplace: this.state.docReplace ? false : true });
  };

  handleExport = async (id) => {
    this.setState({ loading_label: "Exportando arquivo..." });
    this.setState({ loading_active: true });

    const response = await exportSession(id);

    this.setState({ loading_active: false });

    if (_.get(response, "sucesso", null) != null) {
      if (response.sucesso.xml) {
        fileDownload(response.sucesso.xml, response.sucesso.fileName);
      } else {
        this.setState({
          message: "Arquivo exportado com sucesso!",
          param: "success",
          alertSuccess: true,
        });
      }
    } else {
      this.setState({
        message: _.get(
          response,
          "error",
          "Ocorreu algum erro no processo de integração"
        ),
        param: "danger",
        alertSuccess: true,
      });
    }

    setTimeout(() => {
      this.setState({ alertSuccess: false });
    }, 3000);

    return;
  };

  render() {
    const { classes } = this.props;
    const {
      url_xml,
      file_xml,
      alertSuccess,
      message,
      param,
      status_sessions,
      type_sessions,
      sessions,
      types,
      data_session,
      start_session_name,
      start_session_time,
      docReplace,
      loading_active,
      loading_label,
    } = this.state;
    return (
      <GridContainer>
        <Loading label={loading_label} active={loading_active} />
        {data_session == null ? (
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success" className={classes.cardHeader}>
                <h4 className={classes.cardTitleWhite}>Sessões </h4>
                <div
                  style={{
                    width: "5%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <LightTooltip title="Criar Sessão">
                    <span className="material-icons" onClick={this.handleOpen}>
                      library_add
                    </span>
                  </LightTooltip>
                  <LightTooltip title="Integração">
                    <InsertLinkIcon
                      onClick={() => this.setState({ open_integracao: true })}
                    />
                  </LightTooltip>
                </div>
              </CardHeader>
              <CardBody>
                {start_session_name == null ? (
                  ""
                ) : (
                  <div>
                    {start_session_name} Iniciada ás{" "}
                    {moment(start_session_time).format("YYYY-MM-DD hh:mm ")}
                  </div>
                )}

                <div>
                  <FormControl style={{ margin: "1%", minWidth: "25%" }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      className={classes.label}
                    >
                      Tipo de Sessão
                    </InputLabel>
                    <Select
                      id="type_session"
                      value={type_sessions}
                      name="PoliticalParty"
                      onChange={this.handleFilterType}
                    >
                      {types.map((type, i) => (
                        <MenuItem key={i} value={type.id}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl style={{ margin: "1%", minWidth: "25%" }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      className={classes.label}
                    >
                      Status
                    </InputLabel>
                    <Select
                      id="status_session"
                      value={status_sessions}
                      name="PoliticalParty"
                      onChange={this.handleFilterStatus}
                    >
                      <MenuItem value="Aberta">Aberta</MenuItem>
                      <MenuItem value="Criada">Criada</MenuItem>
                      <MenuItem value="Suspenso">Suspenso</MenuItem>
                      <MenuItem value="Finalizado">Finalizado</MenuItem>
                      <MenuItem value="Excluida">Excluida</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl style={{ margin: "1%" }}>
                    <Button onClick={this.handleReload} color="success">
                      Limpar Filtro
                    </Button>
                  </FormControl>
                </div>

                {sessions !== [] ? (
                  <Table
                    tableHeaderColor="primary"
                    tableHead={[
                      "Operação",
                      "Nome",
                      "Tipo",
                      "Última Atualização",
                      "Ativar Pauta",
                      "Ações",
                      "Status",
                    ]}
                    tableData={sessions}
                    handleExport={this.handleExport.bind(this)}
                    handleClickOpen={this.handleOpen.bind(this)}
                    handleClickDelete={this.handleClickDelete.bind(this)}
                    dasautorizar={this.dasautorizar.bind(this)}
                    openDocs={this.openDocs.bind(this)}
                    startSession={this.startSession.bind(this)}
                    stopSession={this.stopSession.bind(this)}
                    desabilitar={start_session_name}
                    activePauta={this.handleActivePauta.bind(this)}
                  />
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          </GridItem>
        ) : (
          <SessionView
            session={data_session}
            handleReload={this.handleReload.bind(this)}
          />
        )}
        <Modal
          className={classes.modal}
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <UserCreate
              apireload={this.reload.bind(this)}
              id={this.state.edit}
              open={this.state.open}
              session={this.state.edit}
              types={types}
              handleClick={this.handleClose.bind(this)}
            />
          </Fade>
        </Modal>
        <Snackbar
          place="tr"
          color={param}
          icon={AddAlert}
          message={message}
          open={alertSuccess}
          closeNotification={() => this.setState({ alertSuccess: false })}
          close
        />
        <Modal
          className={classes.modal}
          open={this.state.open_integracao}
          onClose={() => this.setState({ open_integracao: false })}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open_integracao}>
            <GridContainer
              style={{ display: "flex", justifyContent: "center" }}
            >
              <GridItem sm={6}>
                <Card>
                  <CardHeader color="success" className={classes.cardHeader}>
                    <h4 className={classes.cardTitleWhite}>Integração</h4>
                    <span
                      className="material-icons"
                      onClick={() => this.setState({ open_integracao: false })}
                    >
                      close
                    </span>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem sm={12}>
                        <p>
                          Insira a URL que se encontra o aquivo XML para
                          integrar a sessão{" "}
                        </p>
                        <FormControl
                          fullWidth
                          className={classes.form}
                          width={1}
                        >
                          <InputLabel className={classes.label}>
                            {"EX:https://192.168.12.10/caminho/arquivo.xml"}
                          </InputLabel>
                          <Input
                            name="url_xml"
                            value={url_xml}
                            onChange={this.handleForm}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem sm={12}>
                        <p>OU</p>
                        <p>Importe o XML</p>
                      </GridItem>
                      <GridItem sm={12}>
                        <FormControl className={classes.form}>
                          <Button
                            variant="contained"
                            component="label"
                            value={file_xml}
                          >
                            arquivo.xml
                            <input
                              type="file"
                              style={{ display: "none" }}
                              name="file_xml"
                              onChange={this.handleFormFile}
                            />
                          </Button>
                        </FormControl>
                      </GridItem>
                      <GridItem sm={12}>
                        <FormControl className={classes.form}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={docReplace ? true : false}
                                name="replace"
                                color="primary"
                                onClick={this.setDocReplace}
                              />
                            }
                            label="Substituir existente"
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter style={{ padding: "10px" }}>
                    <Button
                      onClick={() => this.handleIntegration()}
                      color="success"
                    >
                      Enviar
                    </Button>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
          </Fade>
        </Modal>
      </GridContainer>
    );
  }
}

const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: "0px 0px 25px 5px rgba(0,0,0,0.3)",
    padding: "20px 20px 50px 20px",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

Session.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Session);
