import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { apiSession } from "api/president.js";
import { apiStartSession } from "api/sessions.js";
import _ from "lodash";
import Tooltip from "@material-ui/core/Tooltip";
import "../../assets/sass/components/president/session.scss";
import "../../assets/sass/styles.scss";

class SessionPresident extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      sessions: null,
      session: null,
      session_name: null,
      session_time: null,
      quorum: false,
    };
  }

  async componentDidMount() {
    let session = await apiSession();
    let start = _.get(session, "start", null);
    this.setState({ quorum: _.get(session, "quorum", false) });

    if (start == true) {
      let id = (session = _.get(session, "success.id", null));
      this.setState({ session: true });
      this.setState({
        start_session_name: _.get(session, "success.name", null),
      });
      this.setState({
        start_session_time: _.get(session, "success.updated_at", null),
      });
      this.props.start(id);
    }

    this.setState({ sessions: _.get(session, "success", null) });

    this.props.echo.channel("session").listen("StartSession", (e) => {
      let id = (session = _.get(e, "id", null));
      this.setState({ start_session_name: _.get(e, "name", null) });
      this.setState({ start_session_time: _.get(e, "updated_at", null) });
      this.setState({ quorum: _.get(e, "quorum", false) });

      this.props.start(id);
    });

    this.props.echo.channel("session").listen("Quorum", (e) => {
      let quorum = _.get(e, "quorum", false);
      this.setState({ quorum: quorum });
    });

    this.props.echo.channel("session").listen("SessionAutorized", (e) => {
      this.setState({ sessions: e });
    });

    this.props.echo.channel("session").listen("DisallowSession", () => {
      this.setState({ sessions: null });
    });
  }

  startSession = (id) => {
    apiStartSession(id);
  };

  render() {
    const {
      sessions,
      session,
      start_session_name,
      quorum,
    } = this.state;
    return (
      <div className="homeSession">
        {quorum == false ? (
          <p className="homeSession__textSession">Não há Quórum</p>
        ) : (
          <p className="homeSession__textSession">Há Quórum</p>
        )}
        {session != true ? (
          <p className="homeSession__textSession--text">Não há sessão aberta</p>
        ) : (
          <div> {start_session_name} foi iniciada </div>
        )}
        {sessions == null || session == true
          ? ""
          : sessions.map((prop, key) => (
              <ListItem key={key} className="homeSession__boxStartSession">
                <ListItemText
                  primary={_.get(prop, "type.name", "")}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className="homesesion__boxStartSession--text"
                      >
                        {prop.name}
                        {quorum == false ? (
                          ""
                        ) : (
                          <LightTooltip title="Iniciar a sessão">
                            <button
                              className="componentButton componentButton--green animate__animated animate__bounce animate__delay-2s"
                              variant="outlined"
                              onClick={() => this.startSession(prop.id)}
                            >
                              Iniciar
                            </button>
                          </LightTooltip>
                        )}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            ))}
        {session == true ? "" : ""}
      </div>
    );
  }
}

const styles = {
  root: {
    width: "100%",
    // maxWidth: '70%',
  },
  inline: {
    display: "inline",
    fontSize: "20px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    textAlign: "center",
    fontSize: "160%",
    fontWeight: "bold",
    borderRadius: "10px",
    width: "800px",
    height: "300px",
  },
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

SessionPresident.propTypes = {
  classes: PropTypes.object.isRequired,
  echo: PropTypes.object.isRequired,
  start: PropTypes.func.isRequired,
};

export default withStyles(styles)(SessionPresident);
