import React from "react";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import _ from "lodash";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import { Wrapper } from "./styles";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor, desabilitar } = props;

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {(tableData || []).map((prop, key) => {
            return (
              <TableRow
                key={key}
                className={classes.tableBodyRow}
                name={prop.id}
              >
                {prop.situation === "Finalizado" ? (
                  <TableCell></TableCell>
                ) : (
                  <TableCell
                    className={classes.tableCell}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {prop.status == false ? (
                      <div style={{ display: "flex" }}>
                        <LightTooltip title="Adicionar documentos/projetos referente a sessão">
                          <Button
                            disabled={!prop.enable}
                            style={
                              prop.enable
                                ? { backgroundColor: "#43a047", color: "white" }
                                : { backgroundColor: "#d3d3d3" }
                            }
                            onClick={() => props.openDocs(prop.id)}
                          >
                            Incluir documentos
                          </Button>
                        </LightTooltip>

                        {desabilitar == null && prop.authorized == false ? (
                          <LightTooltip title="Habilitar Sessão a Tela do Presidente">
                            <Button
                              disabled={!prop.enable}
                              variant="outlined"
                              onClick={() => props.startSession(prop.id)}
                              style={
                                prop.enable
                                  ? {
                                      backgroundColor: "#43a047",
                                      color: "white",
                                      marginLeft: "1em",
                                    }
                                  : {
                                      backgroundColor: "#d3d3d3",
                                      marginLeft: "1em",
                                    }
                              }
                            >
                              Autorizar
                            </Button>
                          </LightTooltip>
                        ) : (
                          ""
                        )}

                        {prop.authorized == true ? (
                          <LightTooltip title="Habilitar Sessão a Tela do Presidente">
                            <Button
                              variant="outlined"
                              onClick={() => props.dasautorizar(prop.id)}
                              style={{
                                backgroundColor: "#43a047",
                                color: "white",
                                marginLeft: "1em",
                              }}
                            >
                              Desautorizar
                            </Button>
                          </LightTooltip>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <div style={{ display: "flex" }}>
                        <Button
                          variant="outlined"
                          onClick={() => props.stopSession(prop.id, "pause")}
                          style={{ color: "#C0941D" }}
                        >
                          SUSPENDER
                        </Button>
                      </div>
                    )}
                  </TableCell>
                )}
                <TableCell className={classes.tableCell}>{prop.name}</TableCell>
                <TableCell className={classes.tableCell}>
                  {prop.type.name}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {moment(prop.updated_at).format("DD/MM/YYYY - HH:mm")} 
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={_.get(prop, 'pauta.status', 0) == 1 ? true : false}
                        onChange={() => props.activePauta(prop.id, _.get(prop, 'pauta.status', 0))}
                        color="primary"
                        value="dynamic-class-name"
                      />
                    }
                  />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {prop.external_infos != null ? (
                    <div style={{ float: "left" }}>
                      <LightTooltip title="Exportar XML">
                        <span
                          className="material-icons"
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => props.handleExport(prop.id)}
                        >
                          publish
                        </span>
                      </LightTooltip>
                    </div>
                  ) : (
                    ""
                  )}
                  {prop.status == false && prop.situation === "Criada" ? (
                    <div style={{ float: "left" }}>
                      <LightTooltip title="Editar Sessão">
                        <span
                          className="material-icons"
                          style={{ color: "#43a047", cursor: "pointer" }}
                          onClick={() => props.handleClickOpen(prop)}
                        >
                          create
                        </span>
                      </LightTooltip>
                      <LightTooltip title="Desativar Sessão">
                        <span
                          className="material-icons"
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => props.handleClickDelete(prop.id,prop.name)}
                        >
                          delete_sweep
                        </span>
                      </LightTooltip>
                    </div>
                  ) : (
                    <Button
                      variant="outlined"
                      onClick={() => props.openDocs(prop.id)}
                      style={{ color: "#43BD18", float: "left" }}
                    >
                      Gerenciar
                    </Button>
                  )}
                </TableCell>
                <TableCell>
                  <Wrapper>
                    {prop.situation ? prop.situation : "Aguardando"}
                  </Wrapper>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  desabilitar: PropTypes.func,
  openDocs: PropTypes.func,
  startSession: PropTypes.func,
  dasautorizar: PropTypes.func,
  stopSession: PropTypes.func,
  updated_at: PropTypes.func,
  handleExport: PropTypes.func,
  handleClickOpen: PropTypes.func,
  handleClickDelete: PropTypes.func,
};
