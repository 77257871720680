import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import api from "../../api/api_v2";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import TableFeriados from "./Tables/TableFeriados";
import { Error } from "../Parties/styles";
import _ from "lodash";

export default function Feriados() {
  const classes = useStyles();

  const [date, setDate] = useState();
  const [status, setStatus] = useState([]);
  const [alert, setAlert] = useState(false);
  const [validation, setValidation] = useState([]);
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [id, setId] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      await all();
    };
    fetchData();
  }, []);

  const handleForm = (e) => {
    switch (e.target.name) {
      case "date":
        setAdd(true);
        return setDate(e.target.value);
    }
  };

  const handleAdd = async () => {
    if (add === true) {
      const response = await create();
      if (_.get(response, "status", null) == 200) {
        setAlertMessage("Criado com sucesso");

        setAlert(true);

        setTimeout(() => {
          setAlert();
        }, 1000);

        setEdit(false);
        setAdd(false);
        setDate("");
        await all();

        return;
      }
    }
  };

  const handleEditar = async () => {
    const response = await update();

    if (_.get(response, "status", null) == 200) {
      setAlertMessage("Editado com sucesso");

      setAlert(true);

      setTimeout(() => {
        setAlert();
      }, 1000);

      setEdit(false);
      setAdd(false);
      setDate("");
      await all();

      return;
    }
  };

  const update = async () => {
    return await api
      .put(`/feriado/update/${id}`, {
        id: id,
        date: date,
      })
      .catch(function(error) {
        if (error.response) {
          setValidation(_.get(error, "response.data.errors", []));
        }
      });
  };

  const create = async () => {
    return await api
      .post(`/feriado/add`, {
        date: date,
      })
      .catch(function(error) {
        if (error.response) {
          setValidation(_.get(error, "response.data.errors", []));
        }
      });
  };

  const all = async () => {
    await api
      .get("/feriado")
      .then((response) => setStatus(_.get(response, "data", [])));
  };

  const handleEdit = async (e) => {
    setId(e.id);
    setDate(e.date);
    setEdit(true);
  };

  const handleDelete = async (e) => {
    setValidation("");

    const response = await api.get(`/feriado/delete/${e.id}`);

    await all();

    let message = _.get(response, "data.success", "Excluído com sucesso");

    setAlertMessage(message);

    setAlert(true);

    setTimeout(() => {
      setAlert();
    }, 1000);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card
            style={{
              boxShadow:
                "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
            }}
          >
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Adicionar Feriado</h4>
                  <br></br>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>{"Data"}</InputLabel>
                  <FormControl className={classes.form}>
                    <Input
                      name="date"
                      type="date"
                      value={date}
                      onChange={handleForm}
                    />
                    <Error>{_.get(validation, "date", "")}</Error>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <Button disabled={!add} onClick={handleAdd} color="success">
                    Add
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Button
                    disabled={!edit}
                    onClick={handleEditar}
                    color="success"
                  >
                    Edit
                  </Button>
                </GridItem>
              </GridContainer>
            </CardFooter>
            <Snackbar
              place="tr"
              color="success"
              icon={AddAlert}
              message={alertMessage}
              open={alert}
              closeNotification={() => setAlert(false)}
              close
            />
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {status != "" ? (
            <TableFeriados
              tableHeaderColor="primary"
              tableHead={["Id", "Data", "", ""]}
              tableData={status}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          ) : (
            ""
          )}
        </GridItem>
      </GridContainer>
    </>
  );
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
  },
};

const useStyles = makeStyles(styles);
