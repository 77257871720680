import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ImageUploading from "react-images-uploading";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Button, IconButton } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import _ from "lodash";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  button: {
    margin: "10px 5px 10px 0px",
  },
  buttonGreen: {
    backgroundColor: "green",
    color: "#ffffff",
  },
  imageItem: {
    width: "120px",
    float: "left",
    margin: "0px 10px 20px 10px",
    position: "relative",
    "& figure": {
      width: "100%",
      height: "80px",
      position: "relative",
      overflow: "hidden",
    },
    "& figure img": {
      width: "auto",
      height: "110%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    "& .image-item__btn-wrapper": {
      margin: "0px 0px",
      backgroundColor: "#f1f1f1",
      textAlign: "right",
    },
  },
}));

export default function UploadImagePreview(props) {
  const [images, setImages] = React.useState(_.get(props, "value", ""));
  const [
    multiple,
    maxNumber,
    acceptType,
    maxFileSize,
    resolutionWidth,
    resolutionHeight,
  ] = [
    props.multiple || true,
    props.maxNumber || 20,
    props.acceptType,
    props.maxFileSize || 5000000,
    props.resolutionWidth,
    props.resolutionHeight,
  ];

  const onChange = (imageList) => {
    props.callback(imageList);
    setImages(imageList);
  };

  const onError = () => {
    //
  };

  const classes = useStyles();

  return (
    <ImageUploading
      multiple={multiple}
      value={props.value || images}
      onChange={props.onChange || onChange}
      onError={props.onError || onError}
      maxNumber={maxNumber}
      acceptType={acceptType}
      maxFileSize={maxFileSize}
      resolutionType={props.resolutionType}
      resolutionWidth={resolutionWidth}
      resolutionHeight={resolutionHeight}
      dataURLKey="data_url"
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
        errors,
      }) => (
        // write your building UI
        <div className="upload__image-wrapper">
          <GridContainer>
            <GridItem sm={12}>
              <Button
                variant="contained"
                size="small"
                className={classes.button + " " + classes.buttonGreen}
                startIcon={<CloudUploadIcon />}
                style={isDragging ? { color: "red" } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              >
                Clique para adicionar
              </Button>
              {imageList.length > 1 ? (
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  className={classes.button}
                  startIcon={<DeleteIcon />}
                  onClick={onImageRemoveAll}
                >
                  Remover todas
                </Button>
              ) : (
                ""
              )}
            </GridItem>
            <GridItem>
              {errors && (
                <Alert severity="error">
                  {errors.maxNumber && (
                    <span>Selecione até {props.maxNumber} arquivos!</span>
                  )}
                  {errors.acceptType && (
                    <span>Seu tipo de arquivo selecionado não é permitido</span>
                  )}
                  {errors.maxFileSize && (
                    <span>
                      Tamanho de arquivo excedido ({maxFileSize / 1000000} MB)
                    </span>
                  )}
                  {errors.resolution && (
                    <span>
                      A resolução deve ser maior ou igual a{" "}
                      {props.resolutionWidth}x{props.resolutionHeight}
                    </span>
                  )}
                </Alert>
              )}
              {imageList.map((image, index) => (
                <div key={index} className={classes.imageItem}>
                  <figure>
                    <img src={image["data_url"]} alt="" />
                  </figure>
                  <div className="image-item__btn-wrapper">
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => onImageUpdate(index)}
                    >
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      size="small"
                      onClick={() => onImageRemove(index)}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </div>
                </div>
              ))}
            </GridItem>
          </GridContainer>
        </div>
      )}
    </ImageUploading>
  );
}

UploadImagePreview.propTypes = {
  callback: PropTypes.func,
  value: PropTypes.array,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  multiple: PropTypes.bool,
  maxNumber: PropTypes.number,
  acceptType: PropTypes.string,
  maxFileSize: PropTypes.number,
  resolutionWidth: PropTypes.number,
  resolutionHeight: PropTypes.number,
  resolutionType: PropTypes.string,
};
