import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import { apiSalve, apiDeleteMicro } from "api/configuration.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import _ from "lodash";
import propType from "prop-types";

export default function Tribuna(props) {
  const classes = useStyles();
  const { tribuna, part } = props;
  const [id] = React.useState(_.get(tribuna, "id", ""));
  const [subscription, setSubscription] = React.useState(
    _.get(tribuna, "subscription", "")
  );
  const [time, setTime] = React.useState(_.get(tribuna, "time", ""));
  const [timeInscription, setTimeInscription] = React.useState(_.get(tribuna, "time_inscription", null));
  const [timeStartConceder, setTimeStartConceder] = React.useState(_.get(tribuna, "time_start_conceder", null));
  const [setAparte] = React.useState(_.get(tribuna, "aparte", ""));
  const [setOrdem] = React.useState(_.get(tribuna, "ordem", ""));

  const send = async () => {
    if (subscription == "" || time == "") {
      return;
    }

    let data = {
      id: id,
      subscription: subscription,
      time: time,
      time_inscription: timeInscription,
      time_start_conceder: timeStartConceder,
      id_part: part.id,
    };
    let retorno = await apiSalve(data, "/configuration/tribuna/save");
    props.sendMessage(retorno);
  };

  const hadleForm = (event) => {
    switch (event.target.name) {
      case "inscricao":
        return setSubscription(event.target.value);
      case "time":
        return setTime(event.target.value);
      case "time_inscription":
        return setTimeInscription(event.target.value);
      case "time_start_conceder":
        return setTimeStartConceder(event.target.value === "true" ? true : false);
      case "aparte":
        return setAparte(event.target.value);
      case "ordem":
        return setOrdem(event.target.value);
      default:
        return "";
    }
  };

  const deleteTribuna = () => {
    apiDeleteMicro(id, "/configuration/tribuna/delete");
  };

  return (
    <Card
      style={{
        boxShadow:
          "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
      }}
    >
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <InputLabel className={classes.label} onChange={hadleForm}>
              {"Inscrição"}
            </InputLabel>
            <FormControl className={classes.form}>
              <Input
                name="inscricao"
                value={subscription}
                onChange={hadleForm}
              />
            </FormControl>
          </GridItem>

          <GridItem xs={12} sm={12} md={3}>
            <InputLabel className={classes.label} onChange={hadleForm}>
              {"Tempo"}
            </InputLabel>
            <FormControl className={classes.form}>
              <Input
                name="time"
                type="time"
                value={time}
                onChange={hadleForm}
              />
            </FormControl>
          </GridItem>

          <GridItem xs={12} sm={12} md={3}>
            <InputLabel className={classes.label} onChange={hadleForm}>
              {"Iniciar tempo ao conceder"}
            </InputLabel>

            <FormControlLabel
              control={
                <Switch
                  checked={timeStartConceder}
                  name="time_start_conceder"
                  onChange={hadleForm}
                  color="primary"
                  value={timeStartConceder == 1 ? false : true}
                  inputProps={{
                    'data-key': 'votacao'
                  }}
                />
              }
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={3}>
            <InputLabel className={classes.label} onChange={hadleForm}>
              {"Tempo de Inscrição em min"}
            </InputLabel>
            <FormControl className={classes.form}>
              <Input
                name="time_inscription"
                type="number"
                value={timeInscription}
                onChange={hadleForm}
              />
            </FormControl>
          </GridItem>

        </GridContainer>
      </CardBody>
      <CardFooter>
        <span
          className="material-icons"
          style={{ color: "#4caf50" }}
          onClick={send}
        >
          check_circle
        </span>
        <span
          className="material-icons"
          style={{ color: "red" }}
          onClick={deleteTribuna}
        >
          delete_sweep
        </span>
      </CardFooter>
    </Card>
  );
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
  },
};

Tribuna.propTypes = {
  part: propType.object,
  tribuna: propType.object,
  sendMessage: propType.func,
};

const useStyles = makeStyles(styles);
