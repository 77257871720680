import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Snack } from "./styles";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import TipoPropositura from "components/Legislativo/TipoPropositura.js";
import Status from "components/Legislativo/Status.js";
import Comissoes from "components/Legislativo/Comissoes.js";
import ConfAmbiente from "components/Legislativo/ConfAmbiente";
import ModeloDocumento from "components/Legislativo/ModeloDocumento.js";
import MesaDiretora from "components/Legislativo/Mesadiretora.js";
import DescriptionIcon from "@material-ui/icons/Description";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import FormatIndentIncreaseIcon from "@material-ui/icons/FormatIndentIncrease";
import GroupAddIcon from "@material-ui/icons/Group";
import NearMeIcon from "@material-ui/icons/NearMe";
import { FaCog } from "react-icons/fa";
import StatusProtocol from "components/Legislativo/StatusProtocol";
import TipoProtocol from "components/Legislativo/TipoProtocol";
import Departamets from "components/Legislativo/Departaments.js";
import BookmarksRoundedIcon from "@material-ui/icons/BookmarksRounded";
import Feriados from "components/Legislativo/Feriados";

class Configuration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tipo_propositura: 1,
      modelo_tipo_documento: 0,
      status: 0,
      comissoes: 0,
      mesa_diretora: 0,
      open_message: 0,
      ambiente: 0,
      message: "",
      type_protocolo: 0,
      status_protocolo: 0,
      departamento: 0,
      feriados: 0,
    };

    this.clickDepartamento = this.clickDepartamento.bind(this);
    this.clickTypePropositura = this.clickTypePropositura.bind(this);
    this.clickModelo = this.clickModelo.bind(this);
    this.clickStatus = this.clickStatus.bind(this);
    this.clickComissoes = this.clickComissoes.bind(this);
    this.clickMesaDiretora = this.clickMesaDiretora.bind(this);
    this.clickAmbiente = this.clickAmbiente.bind(this);
    this.clickTypeProtocol = this.clickTypeProtocol.bind(this);
    this.clickStatusProtocol = this.clickStatusProtocol.bind(this);
    this.clickFeriados = this.clickFeriados.bind(this);
  }

  clickDepartamento() {
    this.setState({
      departamento: 1,
      tipo_propositura: 0,
      modelo_tipo_documento: 0,
      status: 0,
      comissoes: 0,
      mesa_diretora: 0,
      ambiente: 0,
      type_protocolo: 0,
      status_protocolo: 0,
      feriados: 0,
    });
  }

  clickTypePropositura() {
    this.setState({
      departamento: 0,
      tipo_propositura: 1,
      modelo_tipo_documento: 0,
      status: 0,
      comissoes: 0,
      mesa_diretora: 0,
      ambiente: 0,
      type_protocolo: 0,
      status_protocolo: 0,
      feriados: 0,
    });
  }

  clickModelo() {
    this.setState({
      departamento: 0,
      tipo_propositura: 0,
      modelo_tipo_documento: 1,
      status: 0,
      comissoes: 0,
      mesa_diretora: 0,
      ambiente: 0,
      type_protocolo: 0,
      status_protocolo: 0,
      feriados: 0,
    });
  }
  clickStatus() {
    this.setState({
      departamento: 0,
      tipo_propositura: 0,
      modelo_tipo_documento: 0,
      status: 1,
      comissoes: 0,
      mesa_diretora: 0,
      ambiente: 0,
      type_protocolo: 0,
      status_protocolo: 0,
      feriados: 0,
    });
  }
  clickComissoes() {
    this.setState({
      departamento: 0,
      tipo_propositura: 0,
      modelo_tipo_documento: 0,
      status: 0,
      comissoes: 1,
      mesa_diretora: 0,
      ambiente: 0,
      type_protocolo: 0,
      status_protocolo: 0,
      feriados: 0,
    });
  }
  clickMesaDiretora() {
    this.setState({
      departamento: 0,
      tipo_propositura: 0,
      modelo_tipo_documento: 0,
      status: 0,
      comissoes: 0,
      mesa_diretora: 1,
      ambiente: 0,
      type_protocolo: 0,
      status_protocolo: 0,
      feriados: 0,
    });
  }

  clickAmbiente() {
    this.setState({
      departamento: 0,
      tipo_propositura: 0,
      modelo_tipo_documento: 0,
      status: 0,
      comissoes: 0,
      mesa_diretora: 0,
      ambiente: 1,
      type_protocolo: 0,
      status_protocolo: 0,
      feriados: 0,
    });
  }

  clickStatusProtocol() {
    this.setState({
      departamento: 0,
      tipo_propositura: 0,
      modelo_tipo_documento: 0,
      status: 0,
      comissoes: 0,
      mesa_diretora: 0,
      ambiente: 0,
      type_protocolo: 0,
      status_protocolo: 1,
      feriados: 0,
    });
  }

  clickTypeProtocol() {
    this.setState({
      departamento: 0,
      tipo_propositura: 0,
      modelo_tipo_documento: 0,
      status: 0,
      comissoes: 0,
      mesa_diretora: 0,
      ambiente: 0,
      type_protocolo: 1,
      status_protocolo: 0,
      feriados: 0,
    });
  }

  clickFeriados() {
    this.setState({
      departamento: 0,
      tipo_propositura: 0,
      modelo_tipo_documento: 0,
      status: 0,
      comissoes: 0,
      mesa_diretora: 0,
      ambiente: 0,
      type_protocolo: 0,
      status_protocolo: 0,
      feriados: 1,
    });
  }

  render() {
    const { classes } = this.props;
    const {
      departamento,
      type_protocolo,
      status_protocolo,
      ambiente,
      open_message,
      message,
      tipo_propositura,
      modelo_tipo_documento,
      status,
      comissoes,
      mesa_diretora,
      feriados,
    } = this.state;

    return (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Configurações do sistema </h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Snack onClick={this.clickDepartamento}>
                <BookmarksRoundedIcon />
                <span>Departamentos</span>
              </Snack>
              <Snack onClick={this.clickTypePropositura}>
                <DescriptionIcon />
                <span>Tipos de Proposituras</span>
              </Snack>
              <Snack onClick={this.clickStatus}>
                <NearMeIcon />
                <span>Status</span>
              </Snack>
              <Snack onClick={this.clickModelo}>
                <FormatIndentIncreaseIcon />
                <span>Modelos de Documentos</span>
              </Snack>
              {/* <Snack onClick={this.clickComissoes}>
                <GroupIcon />
                <span>Gestão de Comissões</span>
              </Snack> */}
              <Snack onClick={this.clickMesaDiretora}>
                <GroupAddIcon />
                <span>Mesa Diretora</span>
              </Snack>
              <Snack onClick={this.clickAmbiente}>
                <FaCog />
                <span>Ajustes de ambiente</span>
              </Snack>
              <Snack onClick={this.clickStatusProtocol}>
                <FaCog />
                <span>Status de Protocolo</span>
              </Snack>
              <Snack onClick={this.clickTypeProtocol}>
                <FaCog />
                <span>Tipos de Protocolo</span>
              </Snack>
              <Snack onClick={this.clickFeriados}>
                <FaCog />
                <span>Feriados</span>
              </Snack>
            </GridItem>

            {departamento === 1 ? (
              <GridItem xs={12} sm={12} md={6}>
                <SnackbarContent
                  style={{ marginBottom: "8px", color: "green" }}
                  message={"Departamentos"}
                />
                <Departamets />
              </GridItem>
            ) : (
              ""
            )}
            {tipo_propositura === 1 ? (
              <GridItem xs={12} sm={12} md={6}>
                <SnackbarContent
                  style={{ marginBottom: "8px", color: "green" }}
                  message={"Configuração Tipo de Propositura"}
                />
                <TipoPropositura />
              </GridItem>
            ) : (
              ""
            )}

            {status === 1 ? (
              <GridItem xs={12} sm={12} md={6}>
                <SnackbarContent
                  style={{ marginBottom: "8px", color: "green" }}
                  message={"Configuração Status do Fluxo de Documentos"}
                />
                <Status />
              </GridItem>
            ) : (
              ""
            )}

            {comissoes === 1 ? (
              <GridItem xs={12} sm={12} md={6}>
                <SnackbarContent
                  style={{ marginBottom: "8px", color: "green" }}
                  message={"Gerenciar Comissoẽs"}
                />
                <Comissoes />
              </GridItem>
            ) : (
              ""
            )}

            {mesa_diretora === 1 ? (
              <GridItem xs={12} sm={12} md={6}>
                <SnackbarContent
                  style={{ marginBottom: "8px", color: "green" }}
                  message={"Mesa Diretora"}
                />
                <MesaDiretora />
              </GridItem>
            ) : (
              ""
            )}

            {modelo_tipo_documento === 1 ? (
              <GridItem xs={12} sm={12} md={6}>
                <SnackbarContent
                  style={{ marginBottom: "8px", color: "green" }}
                  message={"Modelo de tipo de Documentos"}
                />
                <ModeloDocumento />
              </GridItem>
            ) : (
              ""
            )}

            {ambiente === 1 ? (
              <GridItem xs={12} sm={12} md={6}>
                <SnackbarContent
                  style={{ marginBottom: "8px", color: "green" }}
                  message={"Variaveis de ambiente"}
                />
                <ConfAmbiente />
              </GridItem>
            ) : (
              ""
            )}
            {status_protocolo === 1 ? (
              <GridItem xs={12} sm={12} md={6}>
                <SnackbarContent
                  style={{ marginBottom: "8px", color: "green" }}
                  message={"Status de Protocolo"}
                />
                <StatusProtocol />
              </GridItem>
            ) : (
              ""
            )}
            {type_protocolo === 1 ? (
              <GridItem xs={12} sm={12} md={6}>
                <SnackbarContent
                  style={{ marginBottom: "8px", color: "green" }}
                  message={"Tipos de Protocolo"}
                />
                <TipoProtocol />
              </GridItem>
            ) : (
              ""
            )}
            {feriados === 1 ? (
              <GridItem xs={12} sm={12} md={6}>
                <SnackbarContent
                  style={{ marginBottom: "8px", color: "green" }}
                  message={"Feriados"}
                />
                <Feriados />
              </GridItem>
            ) : (
              ""
            )}
          </GridContainer>
        </CardBody>

        <Snackbar
          place="tr"
          color="success"
          icon={AddAlert}
          message={message}
          open={open_message}
          closeNotification={() => this.setState({ open_message: false })}
          close
        />
      </Card>
    );
  }
}

const styles = {
  root: {
    background: "red",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

Configuration.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Configuration);
