import React, {useEffect} from "react"
import { makeStyles } from "@material-ui/core/styles"
import InputLabel from "@material-ui/core/InputLabel"
import GridItem from "components/Grid/GridItem.js"
import GridContainer from "components/Grid/GridContainer.js"
import Button from "components/CustomButtons/Button.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardBody from "components/Card/CardBody.js"
import CardFooter from "components/Card/CardFooter.js"
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl"
import { apiShowReport } from "api/logs.js"
import _ from 'lodash'
import { generateHash } from "api/logs.js"
import { apiGenerateReports } from "api/logs.js"

export default function ModalReport(props) {
    const classes = useStyles()
    const [validation, setValidation] = React.useState([])
    const [stardate, setStartDate] = React.useState(0)
    const [enddate, setEndDate] = React.useState(0)

    const  sendReport = async (e) => {
        e.preventDefault();
        let route = '/logs/exportExcel';
        let object = {
            date_begin: stardate,
            date_end: enddate
        };

        if ((typeof stardate==="number" || stardate=="") || (typeof enddate==="number" || enddate=="")) {
            let data = {
                date_begin: typeof stardate==="number" || stardate=="" ? 'Campo requirido' : '',
                date_end: typeof enddate==="number" || enddate=="" ? 'Campo requirido' : ''
            }
            setValidation(data)
            return
        }
        setValidation([])
        let resposta = await apiGenerateReports(object, route);

        if (!resposta) {
            let err = 0;
        }
    }
    return (
        <div>
            <GridContainer style={{ display: 'flex', justifyContent: 'center' }}>
                <GridItem xs={12} sm={12} md={8}>
                    <Card>
                        <form
                            className={classes.container}
                            onSubmit={sendReport}
                            autoComplete="off"
                        >
                            <CardHeader color="success" className={classes.cardHeader}>
                                <h4 className={classes.cardTitleWhite}>Exportar Logs</h4>
                                <span
                                    className="material-icons"
                                    onClick={() => props.handleClick()}
                                    style={{ cursor: 'pointer' }}
                                >
                                close
                              </span>
                            </CardHeader>
                            <CardBody  style={{ overflow: 'auto', height: '30vh'    }}>
                                <GridContainer style={{ padding: '10px' }}>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <FormControl  className={classes.form}>
                                            <TextField
                                                id="fecha_inicio"
                                                name="fecha_inicio"
                                                type="date"
                                                label="Fecha  Inicio"
                                                required
                                                className={classes.textField}
                                                value={stardate}
                                                onChange={(e) => { setStartDate(e.target.value)}}
                                                margin="normal"
                                            />
                                            <p className={classes.validation}>{_.get(validation, 'date_begin', '')}</p>
                                        </FormControl>

                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <FormControl className={classes.form}>
                                            <TextField
                                                id="fecha_final"
                                                name="fecha_final"
                                                type="date"
                                                label="Fecha  Final"
                                                required
                                                className={classes.textField}
                                                value={enddate}
                                                onChange={(e) => { setEndDate(e.target.value)}}
                                                margin="normal"
                                            />
                                        </FormControl>
                                        <p className={classes.validation}>{_.get(validation, 'date_end', '')}</p>
                                     </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardFooter style={{ padding: '10px' }}>
                                <Button onClick={sendReport} color="success">Exportar</Button>
                            </CardFooter>
                        </form>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}


const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    cardHeader: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    label: {
        fontSize: '16px',
    },
    form: {
        marginBottom: '10px',
        marginTop: '10px'
    },
    validation: {
        color: 'red',
        fontSize: '12px',
        marginTop: '0px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

}

const useStyles = makeStyles(styles)
