import axios from "axios";
import _ from "lodash";

var tokenGet =
  sessionStorage.getItem("TOKEN_KEY") &&
  sessionStorage.getItem("TOKEN_KEY").charAt(0) == '"'
    ? sessionStorage.getItem("TOKEN_KEY")
    : JSON.stringify(sessionStorage.getItem("TOKEN_KEY"));
var token = JSON.parse(tokenGet);

async function getSettingsByName(name) {
    let data;
    await axios({
        method: "get",
        url: process.env.REACT_APP_STAGE + "/settings/get/" + name,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
    .then((response) => {
        data = _.get(response, "data");
    })
    .catch(function(error) {
        Promise.reject(error.response);
    });

    return data;
}

async function getAutomationSettings() {
    let data;
    await axios({
        method: "get",
        url: process.env.REACT_APP_STAGE + "/automation/get/settings",
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    })
    .then((response) => {
        data = _.get(response, "data");
    })
    .catch(function(error) {
        Promise.reject(error.response);
    });

    return data;
}

export {
    getSettingsByName,
    getAutomationSettings
};