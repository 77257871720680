import React from "react";
import { withStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/User/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import PropTypes from "prop-types";
import { apiGetUsers } from "api/user.js";
import Modal from "@material-ui/core/Modal";
import UserCreate from "components/User/Form/ModalUser";
import ModalDelete from "components/User/Form/ModalDeleteUser";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "@material-ui/core/Tooltip";
import echo from "api/channels";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: "",
      first_page_url: "",
      from: "",
      last_page: 1,
      last_page_url: "",
      next_page_url: null,
      path: "",
      per_page: "",
      prev_page_url: null,
      to: 1,
      total: 1,
      open: false,
      setOpen: false,
      edit: 0,
      filter: "",
      filter2: "",
      found_find: null,
      openDelete: false,
      validation_name: "",
    };

    this.hadleFilter = this.hadleFilter.bind(this);
    this.handleName = this.handleName.bind(this);
  }

  componentDidMount() {
    this.reloadUsers();

    echo.channel("admin").listen("GetUsers", (e) => {
      this.setState({ users: e });
    });
  }

  async hadleFilter(event) {
    if (event.target.value == "") {
      this.setState({ filter2: "" });
      this.setState({ filter: "" });
      this.reloadUsers();
      return;
    }

    if (event.target.name == "UserOffice") {
      this.setState({ filter: event.target.value });
      this.setState({ filter2: "" });
    }

    if (event.target.name == "PoliticalParty") {
      this.setState({ filter2: event.target.value });
      this.setState({ filter: "" });
    }

    let users = await apiGetUsers(event.target.value, event.target.name, "");

    if (users != 0) {
      this.setState({ users: users });
      return;
    }

    this.setState({ found_find: "Não existe usuário cadastrado" });

    setTimeout(() => {
      this.setState({ found_find: null });
    }, 3000);
  }

  async handleName(event) {
    let users = await apiGetUsers("", "", event.target.value);

    this.setState({ filter: "" });
    this.setState({ filter2: "" });

    if (users != 0) {
      this.setState({ validation_name: "" });
      this.setState({ users: users });
      return;
    }
    this.setState({ validation_name: "usuário não existe" });
  }

  async reloadUsers() {
    let data = await apiGetUsers("", "", "");
    if (data != 0) {
      this.setState({ users: data });
    }
  }

  handleOpen = (param) => {
    this.setState({ edit: param.id ? param : 0 });
    this.setState({ open: true });
  };

  handleOpenDelete = (param) => {
    this.setState({ edit: param });
    this.setState({ openDelete: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleCloseDelete = () => {
    this.setState({ openDelete: false });
  };

  render() {
    const { classes } = this.props;
    const { validation_name, users, found_find } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success" className={classes.cardHeader}>
              <h4 className={classes.cardTitleWhite}>Usuários </h4>
              <LightTooltip title="Criar novo usuário">
                <span className="material-icons" onClick={this.handleOpen}>
                  library_add
                </span>
              </LightTooltip>
            </CardHeader>
            <CardBody>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <div>
                  <CustomInput
                    formControlProps={{
                      className: classes.margin + " " + classes.search,
                    }}
                    inputProps={{
                      onChange: this.handleName,
                      placeholder: "Filtro por nome",
                      inputProps: {
                        "aria-label": "Search",
                      },
                    }}
                  />

                  <p style={{ color: "red" }}>{validation_name}</p>
                </div>
                <div
                  style={{
                    width: "70%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <p className={classes.validation}> {found_find || ""} </p>
                  <FormControl style={{ margin: "1%", minWidth: "25%" }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      className={classes.label}
                    >
                      {" "}
                      Filtro por Partido
                    </InputLabel>
                    <Select
                      id="cargo"
                      value={this.state.filter2}
                      name="PoliticalParty"
                      onChange={this.hadleFilter}
                    >
                      <MenuItem value={""}>Todos</MenuItem>
                      {JSON.parse(sessionStorage.getItem("PARTIDOS")).map(
                        (cargo, i) => (
                          <MenuItem key={i} value={cargo.id}>
                            {cargo.name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                  <FormControl style={{ margin: "1%", minWidth: "25%" }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      className={classes.label}
                    >
                      Filtro por Cargo
                    </InputLabel>
                    <Select
                      id="cargo"
                      value={this.state.filter}
                      name="UserOffice"
                      onChange={this.hadleFilter}
                    >
                      <MenuItem value={""}>Todos</MenuItem>
                      {JSON.parse(sessionStorage.getItem("CARGOS")).map(
                        (cargo, i) => (
                          <MenuItem key={i} value={cargo.id}>
                            {cargo.name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </div>
              </div>
              {users != "" ? (
                <Table
                  tableHeaderColor="primary"
                  tableHead={[
                    "Foto",
                    "Cpf",
                    "Nome",
                    "Cargo",
                    "Acesso",
                    "Ações",
                    "Status",
                    "Excluir",
                  ]}
                  tableData={users}
                  handleClickOpen={this.handleOpen.bind(this)}
                  handleClickOpenDelete={this.handleOpenDelete.bind(this)}
                />
              ) : (
                ""
              )}
            </CardBody>
          </Card>
        </GridItem>
        <Modal
          className={classes.modal}
          open={this.state.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <UserCreate
              id={this.state.edit}
              open={this.state.open}
              user={this.state.edit}
              handleClick={this.handleClose.bind(this)}
              reloadUsers={this.reloadUsers.bind(this)}
            />
          </Fade>
        </Modal>

        <Modal
          className={classes.modal}
          open={this.state.openDelete}
          onClose={this.handleCloseDelete}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openDelete}>
            <ModalDelete
              handleReloadUser={this.reloadUsers.bind(this)}
              user={this.state.edit}
              open={this.state.open}
              handleClick={this.handleCloseDelete.bind(this)}
            />
          </Fade>
        </Modal>
      </GridContainer>
    );
  }
}

const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: "0px 0px 25px 5px rgba(0,0,0,0.3)",
    padding: "20px 20px 50px 20px",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  validation: {
    color: "red",
    fontSize: "14px",
    //marginTop: '0px'
  },
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dashboard);
