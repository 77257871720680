import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import { apiSalve } from "../../api/configuration";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import TableLegislatura from "./TableLegislatura";
import { Error } from "../Parties/styles";
import _ from "lodash";
import PropTypes from "prop-types";

export default function TypeDocument(props) {
  const classes = useStyles();
  const [legislatura, setLegislatura] = useState(null);
  const [inicio, setInicio] = useState();
  const [fim, setFim] = useState();
  const [legislaturas, setLegislaturas] = useState(props.legislaturas);
  const [alert, setAlert] = useState(false);
  const [validation, setValidation] = useState([]);
  const [setEdit] = useState(false);
  const [id, setId] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);

  const handleForm = (e) => {
    switch (e.target.name) {
      case "legislatura":
        return setLegislatura(e.target.value);
      case "inicio":
        return setInicio(e.target.value);
      case "fim":
        return setFim(e.target.value);
    }
  };

  const handleAddTypeDocument = async (e) => {
    e.preventDefault();

    if (fim < inicio) {
      setValidation({
        fim:
          "Data de inicio não pode ser maior que a data de fim da legislatura.",
      });
      return;
    }

    const data = await apiSalve(
      { inicio: inicio, fim: fim, id: id, legislatura: legislatura },
      "/configuration/legislatura/save"
    );

    if (_.get(data[0], "id", null) === null) {
      setValidation(_.get(data, "errors", null));
      return;
    }

    setAlertMessage("Legislatura salva com sucesso");
    setInicio("");
    setFim("");
    setLegislatura("");
    setId(null);

    setValidation("");

    setAlert(true);

    setLegislaturas(data);
    sessionStorage.setItem("LEGISLATURAS", JSON.stringify(data));

    setTimeout(
      function() {
        setAlert();
      }.bind(this),
      1000
    );
  };

  const handleDelete = async (e) => {
    setValidation("");

    const response = await apiSalve(
      { id: e.id },
      "/configuration/legislatura/delete"
    );

    if (_.get(response[0], "id", null) === null) {
      setValidation({ cad: "Há usuários cadastrados com essa legislatura" });
      return;
    }

    setAlertMessage("Legislatura deletada com sucesso");

    setAlert(true);

    setTimeout(() => {
      setAlert();
    }, 1000);

    setLegislaturas(response);

    sessionStorage.setItem("LEGISLATURAS", JSON.stringify(response));
  };

  const handleEdit = async (e) => {
    setId(e.id);
    setInicio(e.inicio);
    setFim(e.fim);
    setLegislatura(_.get(e, "legislatura", ""));
    setEdit(true);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card
            style={{
              boxShadow:
                "0 12px 20px -10px rgba(255, 255, 255, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 255, 255)",
            }}
          >
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Legislatura</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <InputLabel className={classes.label}>
                    {"Legislatura"}
                  </InputLabel>
                  <FormControl className={classes.form}>
                    <Input
                      name="legislatura"
                      value={legislatura}
                      onChange={handleForm}
                    />
                    <Error>{_.get(validation, "legislatura", "")}</Error>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <InputLabel className={classes.label}>{"Inicio"}</InputLabel>
                  <FormControl className={classes.form}>
                    <Input
                      type="date"
                      name="inicio"
                      value={inicio}
                      onChange={handleForm}
                    />
                    <Error>{_.get(validation, "inicio", "")}</Error>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <InputLabel className={classes.label}>{"Fim"}</InputLabel>
                  <FormControl className={classes.form}>
                    <Input
                      type="date"
                      name="fim"
                      value={fim}
                      onChange={handleForm}
                    />
                    <Error>{_.get(validation, "fim", "")}</Error>
                  </FormControl>
                </GridItem>
                <FormControl>
                  <Error>{_.get(validation, "cad", "")}</Error>
                </FormControl>
                <CardFooter>
                  <Button
                    onClick={handleAddTypeDocument}
                    style={{ backgroundColor: "#C0941D", color: "white" }}
                  >
                    Salvar
                  </Button>
                </CardFooter>
              </GridContainer>
            </CardBody>
            <Snackbar
              place="tr"
              color="success"
              icon={AddAlert}
              message={alertMessage}
              open={alert}
              closeNotification={() => setAlert(false)}
              close
            />
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {legislaturas != "" ? (
            <TableLegislatura
              tableHeaderColor="primary"
              tableHead={["Legislatura", "Período", "", ""]}
              tableData={legislaturas}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          ) : (
            ""
          )}
        </GridItem>
      </GridContainer>
    </>
  );
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: "12px",
  },
  form: {
    marginBottom: "10px",
    marginTop: "10px",
  },
};

TypeDocument.propTypes = {
  classes: PropTypes.object.isRequired,
  legislaturas: PropTypes.array,
};


const useStyles = makeStyles(styles);
