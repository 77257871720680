import axios from "axios";
import _ from "lodash";
import { generateHash } from "api/user.js";

  var tokenGet =
    sessionStorage.getItem("TOKEN_KEY") &&
    sessionStorage.getItem("TOKEN_KEY").charAt(0) == '"'
      ? sessionStorage.getItem("TOKEN_KEY")
    : JSON.stringify(sessionStorage.getItem("TOKEN_KEY"));
var token = JSON.parse(tokenGet);

const api = axios.create({
  baseURL: process.env.REACT_APP_STAGE,
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + token,
  },
});

async function apiLogin(username, password) {
  let error;
  let formdata = {
    email: username,
    password: password,
  };

  var json = generateHash(JSON.stringify(formdata));

  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/login" + "?data=" + json,
    headers: {
      Accept: "application/json",
    },
    data: [],
  })
    .then((response) => {
      error = _.get(response, "data.error", null);
      
      let token = JSON.stringify(response.data.success.token);
      let user = JSON.stringify(response.data.success.user);
      let cargos = JSON.stringify(response.data.success.cargos);
      let niveis = JSON.stringify(response.data.success.niveis);
      let partidos = JSON.stringify(response.data.success.partidos);
      let legislaturas = JSON.stringify(response.data.success.legislaturas);
      let configurationPlenary = JSON.stringify(response.data.success.configurationPlenary);
      let configuration = JSON.stringify(response.data.success.configuration);
      let module = JSON.stringify(response.data.success.module);
      let legislativo = JSON.stringify(
        _.get(response, "data.success.legislativo.status", false)
      );

      sessionStorage.setItem("TOKEN_KEY", token);
      sessionStorage.setItem("USER", user);
      sessionStorage.setItem("CARGOS", cargos);
      sessionStorage.setItem("NIVEIS", niveis);
      sessionStorage.setItem("PARTIDOS", partidos);
      sessionStorage.setItem("LEGISLATURAS", legislaturas);
      sessionStorage.setItem("LEGISLATIVO", legislativo);
      sessionStorage.setItem("MODULE", module);
      sessionStorage.setItem("CONFIGURATIONPLENARY", configurationPlenary);
      sessionStorage.setItem("MANUALVOTING", configuration);

      window.location.reload();
    })
    .catch(function() {});

  return error;
}

async function apiLoginFace(username) {
  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/login/face",
    headers: {
      Accept: "application/json",
    },
    data: {
      file: username,
    },
  })
    .then((response) => {
      let token = JSON.stringify(response.data.success.token);
      let user = JSON.stringify(response.data.success.user);
      let cargos = JSON.stringify(response.data.success.cargos);
      let niveis = JSON.stringify(response.data.success.niveis);
      let partidos = JSON.stringify(response.data.success.partidos);
      let legislaturas = JSON.stringify(response.data.success.legislaturas);
      let module = JSON.stringify(response.data.success.module);
      let configurationPlenary = JSON.stringify(response.data.success.configurationPlenary);
      let configuration = JSON.stringify(response.data.success.configuration);
      let legislativo = JSON.stringify(
        _.get(response, "data.success.legislativo.status", false)
      );

      sessionStorage.setItem("TOKEN_KEY", token);
      sessionStorage.setItem("USER", user);
      sessionStorage.setItem("CARGOS", cargos);
      sessionStorage.setItem("NIVEIS", niveis);
      sessionStorage.setItem("PARTIDOS", partidos);
      sessionStorage.setItem("LEGISLATURAS", legislaturas);
      sessionStorage.setItem("LEGISLATIVO", legislativo);
      sessionStorage.setItem("MODULE", module);
      sessionStorage.setItem("CONFIGURATIONPLENARY", configurationPlenary);
      sessionStorage.setItem("MANUALVOTING", configuration);
      window.location.reload();
    })
    .catch(function(error) {
      if (error.response.status === 401) {
        alert("Reconhecimento não cadastrado");
        return;
      }

      console.log(Promise.reject(error.response));
    });
}

function apiLogoutRoot(id) {
    var token = JSON.parse(sessionStorage.getItem("TOKEN_KEY"));
    return axios({
        method: "get",
        url: process.env.REACT_APP_STAGE + "/logout",
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
        params: { id: id },
    })
    .catch(function(error) {
        return Promise.reject(error.response);
    });
}
function apiLogout(id) {
  return axios({
    method: "get",
    url: process.env.REACT_APP_STAGE + "/logout",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    params: { id: id },
  })
    .then(() => {
      sessionStorage.clear();
      window.location.reload();
    })
    .catch(function(error) {
      return Promise.reject(error.response);
    });
}

function apiLogoutUsers(id) {
  var token = JSON.parse(sessionStorage.getItem("TOKEN_KEY"));
  return axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/users/logout/all",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    params: { id: id },
  });
}

async function apiLoginRecovery(cpf) {
  let resp;
  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/forgot-password",
    headers: {
      Accept: "application/json",
    },
    data: { cpf: cpf },
  })
  .then((response) => {
    resp = _.get(response, "data", []);
  })
  .catch(function (resq) {
    if (resq.response) {
      resp = _.get(resq, "response.data.errors",[]);
    }
  });
  return resp;
}

async function apiLoginResetPassword(data) {
  let resp;
  await axios({
    method: "post",
    url: process.env.REACT_APP_STAGE + "/reset-password",
    headers: {
      Accept: "application/json",
    },
    data,
  })
  .then((response) => {
    resp = _.get(response, "data", []);
  })
  .catch(function (resq) {
    if (resq.response) {
      resp = _.get(resq, "response.data.errors",[]);
    }
  });
  return resp;
}

export { api, apiLogin, apiLoginFace, apiLogout, apiLogoutUsers,apiLogoutRoot,apiLoginRecovery,apiLoginResetPassword};
