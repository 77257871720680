import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import logo from "assets/img/logo-login.png";
import echo from "api/channels";
import { apiGetCanalName } from "api/sessions";
import _ from "lodash";
import "../../assets/sass/styles.scss";
import "../../assets/sass/components/vereador/index.scss";

import { apiLogout } from "api/api.js";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100px",
  },
  img: {
    width: "120px",
  },

  sidebar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // backgroundColor: 'whitesmoke',
    //height: '70px',
    width: "100%",
  },
  appBar: {
    backgroundColor: "whitesmoke",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function Index() {
  const classes = useStyles();
  const [user] = React.useState(JSON.parse(sessionStorage.USER));
  const [title, setTitle] = React.useState(
    "Bem-vindo " + _.get(user, "name", "")
  );

  const handleExitProfile = () => {
    let user = JSON.parse(sessionStorage.getItem("USER"));

    apiLogout(user.id);
  };

  React.useEffect(() => {
    apiGetCanalName();

    echo.channel("tribuna").listen("MessageFinishTribuna", (e) => {
      setTitle(e);
    });

    echo.channel("session").listen("StartSession", (e) => {
      setTitle(_.get(e, "name", ""));
    });

    echo.channel("session").listen("GetSessionName", (e) => {
      setTitle(_.get(e, "name", title));
    });
  }, []);

  let data = new Date();

  const [str_hora, setHora] = React.useState(data.toLocaleTimeString());

  const str_data = data.toLocaleDateString();

  const interval = setInterval(() => {

    const now = new Date()

    setHora(now.toLocaleTimeString())

}, 1000)


  return (
    <div className="componentNavbar">
      <AppBar position="static">
        <Toolbar variant="dense" className={classes.sidebar}>
          <div className="componentNavbar__logo">
            <IconButton edge="start" aria-label="menu">
              <img className="componentNavbar__imgLogo" src={logo} alt="logo" />
            </IconButton>
          </div>
          <div className="boxButtons__session">
            <div className="boxButtons__session--text">
              <p className="boxButtons__session--name"> {title} </p>
            </div>
          </div>
          <div className="componentNavbar__date">
            <p>{"Hoje é " + str_data + " às " + str_hora}</p>
            <button
              className="componentButton componentButton--orange"
              variant="contained"
              onClick={handleExitProfile}
            >
              SAIR
            </button>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
