import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

export default function TableModal({
  closedModal,
  data,
  props,
  selectAllModals
}) {
  const [inputStateModal, setInputStateModal] = useState(valueInputs);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [btnPrevSlide, setBtnPrevSlide] = useState(false);
  const [previewChoices, setPreviewChoices] = useState(null);

  //Objeto que atribui o useState InputStateModal
  const valueInputs = {
    leitura: "",
    momentoLeitura: null,
    votacao: null,
    secret: null,
    momentoVotacao: null,
    tipoDeVotacao: null,
  };

  //Funções que atribui os valores a cada posição do objeto que pertence
  //ao useState inputStateModal
  const handelInputLeitura = (e) => {
    const leituraSimOuNao = e.target.value;
    setInputStateModal({
      ...inputStateModal,
      leitura: leituraSimOuNao,
    });
  };

  const handleInputMomentoLeitura = (e) => {
    const momentoLeitura = e.target.value;
    setInputStateModal({
      ...inputStateModal,
      momentoLeitura: momentoLeitura,
    });
  };

  const handelInputVotacao = (e) => {
    const votacao = e.target.value;
    setInputStateModal({
      ...inputStateModal,
      votacao: votacao,
    });
  };

  const handelInputSecret = (e) => {
    const secret = e.target.value;
    setInputStateModal({
      ...inputStateModal,
      secret: secret,
    });
  };

  const handelInputMomentoVotacao = (e) => {
    const momentoVotacao = e.target.value;
    setInputStateModal({
      ...inputStateModal,
      momentoVotacao: momentoVotacao,
    });
  };

  const handleinputTipoDeVotacao = (e) => {
    const tipoDeVotacao = e.target.value;
    setInputStateModal({
      ...inputStateModal,
      tipoDeVotacao: tipoDeVotacao,
    });
  };

  const handleSaveChoices = () => {
    const allChoicesMade =
      (inputStateModal?.leitura &&
        inputStateModal?.momentoLeitura &&
        inputStateModal?.votacao &&
        inputStateModal?.momentoVotacao &&
        inputStateModal?.tipoDeVotacao &&
        inputStateModal?.secret) ||
      inputStateModal?.votacao == "nao" ||
      inputStateModal?.momentoLeitura == null;

    setIsSaveButtonEnabled(allChoicesMade);

    if (allChoicesMade) {
      setPreviewChoices(inputStateModal);
    }
    closedModal();
  };

  const PreviewChoices = () => {
    const leitura = inputStateModal.leitura;
    const momentoLeitura = inputStateModal.momentoLeitura;
    const momentoVotacao = inputStateModal.momentoVotacao;
    const votacao = inputStateModal.votacao;
    const tipoDeVotacao = inputStateModal.tipoDeVotacao;
    const secret = inputStateModal.secret;

    let leituraString = "";
    if (leitura === "sim") {
      leituraString = "Sim";
    } else if (leitura === "nao") {
      leituraString = "Não";
    }

    let votacaoString = "";
    if (votacao === "sim") {
      votacaoString = "Sim";
    } else if (votacao === "nao") {
      votacaoString = "Não";
    }

    let secretString = "";
    if (secret === "sim") {
      secretString = "Sim";
    } else if (secret === "nao") {
      secretString = "Não";
    }

    let momentoLeituraString = "";
    if (momentoLeitura === "1") {
      momentoLeituraString = "Peq Expediente";
    } else if (momentoLeitura === "2") {
      momentoLeituraString = "Expediente";
    } else if (momentoLeitura === "3") {
      momentoLeituraString = "Ordem do Dia";
    }

    let tipoDeVotacaoString = "";
    if (tipoDeVotacao === "primeiraVotacao") {
      tipoDeVotacaoString = "1º Votação";
    } else if (tipoDeVotacao === "segundaVotacao") {
      tipoDeVotacaoString = "2º Votação";
    } else if (tipoDeVotacao === "votacaoUnica") {
      tipoDeVotacaoString = "Votação Unica";
    }

    let momentoVotacaoString = "";
    if (momentoVotacao === "1") {
      momentoVotacaoString = "peq Expediente";
    } else if (momentoVotacao === "2") {
      momentoVotacaoString = "Expediente";
    } else if (momentoVotacao === "3") {
      momentoVotacaoString = "Ordem do Dia";
    }

    return (
      <>
        <div className="preview">
          <li>
            <span>Leitura</span>: {leituraString}
          </li>
          <li>
            <span>Momento</span>: {momentoLeituraString}
          </li>
          <hr />
          <li>
            <span>Votação</span>: {votacaoString}
          </li>
          <li>
            <span>Momento</span>: {momentoVotacaoString}
          </li>
          <li>
            <span>Secret</span>: {secretString}
          </li>
          <li>
            <span>Tipo de Votação</span>: {tipoDeVotacaoString}
          </li>
        </div>
      </>
    );
  };

  const btnVoltarRef = useRef(null);

  //Cuida do SLide
  useEffect(() => {
    const slides = document.querySelectorAll(".formGroup");
    const inputsSlides = document.querySelectorAll(
      ".formGroup .formInput input"
    );

    let currentSlideIndex = 0;

    btnVoltarRef.current.addEventListener("click", () => {
      currentSlideIndex = currentSlideIndex === 0 ? 0 : currentSlideIndex - 1;

      slides.forEach((slide) => {
        slide.classList.remove("formGroup--visible");
      });

      slides[currentSlideIndex].classList.add("formGroup--visible");
    });

    //Verificar se o primerio input está checked
    //E verificar se o valor é true ou false
    //Se houver leitura aparece o campo momento da leitura
    if (document.getElementById("leituraSim")?.checked) {
      setBtnPrevSlide(true);
      slides[0].classList.remove("formGroup--visible");
      slides[1].classList.add("formGroup--visible");
      currentSlideIndex++;
    }

    if (
      document.getElementById("Peq Expediente")?.checked ||
      document.getElementById("Expediente")?.checked ||
      document.getElementById("Ordem do Dia")?.checked ||
      document.getElementById("votacaoSim")?.checked
    ) {
      slides[1].classList.remove("formGroup--visible");
      slides[2].classList.add("formGroup--visible");
      currentSlideIndex++;
    }

    //Se não houver leitura, aparece o campo votacao
    if (document.getElementById("leituraNao")?.checked) {
      setBtnPrevSlide(true);
      slides[0].classList.remove("formGroup--visible");
      slides[2].classList.add("formGroup--visible");
      currentSlideIndex++;
    }

    //Se houver votação aparece o campo do momento da votação
    if (document.getElementById("votacaoSim")?.checked) {
      setBtnPrevSlide(true);
      slides[2].classList.remove("formGroup--visible");
      slides[3].classList.add("formGroup--visible");
      currentSlideIndex++;
    }

    if (document.getElementById("votacaoNao")?.checked) {
      setBtnPrevSlide(true);
      closedModal();
    }

    //Se votacao for 1votacao || Se votacao for 2votacao || votacao for votacao unica...
    if (
      document.getElementById("primeiraVotacao")?.checked ||
      document.getElementById("segundaVotacao")?.checked ||
      document.getElementById("votacaoUnica")?.checked
    ) {
      setBtnPrevSlide(true);
      slides[4].classList.add("formGroup--visible");
      slides[3].classList.remove("formGroup--visible");
      currentSlideIndex++;
    }

    if (document.getElementById("Peq Expediente")) {
      if (
        inputsSlides[10]?.checked ||
        inputsSlides[11]?.checked ||
        inputsSlides[12]?.checked
      ) {
        setBtnPrevSlide(true);
        slides[5].classList.add("formGroup--visible");
        slides[4].classList.remove("formGroup--visible");
        currentSlideIndex++;
      }
    } else {
      if (
        inputsSlides[9]?.checked ||
        inputsSlides[10]?.checked ||
        inputsSlides[11]?.checked ||
        inputsSlides[12]?.checked
      ) {
        setBtnPrevSlide(true);
        slides[5].classList.add("formGroup--visible");
        slides[4].classList.remove("formGroup--visible");
        currentSlideIndex++;
      }
    }

    //se for sim ou não votaçao secreta abrir tela de validar esclolhas
    if (document.getElementById("secretSim")?.checked ||
        document.getElementById("secretNao")?.checked ) {
      setBtnPrevSlide(true);
      slides[6].classList.add("formGroup--visible");
      slides[5].classList.remove("formGroup--visible");
      currentSlideIndex++;
    }

    // Verifica se todas as escolhas foram feitas
    const allChoicesMade =
      (inputStateModal?.leitura &&
        inputStateModal?.momentoLeitura &&
        inputStateModal?.votacao &&
        inputStateModal?.momentoVotacao &&
        inputStateModal?.tipoDeVotacao &&
        inputStateModal?.secret) ||
      inputStateModal?.votacao == "nao" ||
      inputStateModal?.momentoLeitura == null;

    setIsSaveButtonEnabled(allChoicesMade);

    if (allChoicesMade) {
      setPreviewChoices(inputStateModal);
    }

    if (selectAllModals) {
      for (let i = 0; i < selectAllModals.length; i++) {
        switch (inputStateModal?.leitura) {
          case "sim":
            selectAllModals[i].add_leitura = true;
            break;
        }
        switch (inputStateModal?.tipoDeVotacao) {
          case "primeiraVotacao":
            selectAllModals[i].add_first_voting = true;
            break;
          case "segundaVotacao":
            selectAllModals[i].add_second_voting = true;
            break;
          case "votacaoUnica":
            selectAllModals[i].urgent = true;
            break;
        }
        switch (inputStateModal?.secret) {
          case "sim":
            selectAllModals[i].secret = true;
            break;
        }

        if (inputStateModal?.momentoLeitura) {
          selectAllModals[i].id_part_leitura = inputStateModal?.momentoLeitura;
        }

        if (inputStateModal?.momentoVotacao) {
          selectAllModals[i].id_party = inputStateModal?.momentoVotacao;
        }
      }
    }
  }, [inputStateModal]);

  //Add Icon
  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  return (
    <div className="modal">
      <div className="overlay"></div>
      <div className="modal-content">
        <div className="modal-body">
          <div className="close-modal">
            <button
              className="btnVoltar"
              disabled={!btnPrevSlide}
              ref={btnVoltarRef}
            >
              <LightTooltip title="VOLTAR">
                <span className="material-icons" disabled={!btnPrevSlide}>
                  reply_all
                </span>
              </LightTooltip>
            </button>
            {inputStateModal?.leitura == null ||
            inputStateModal?.leitura == "nao" ? (
              <span
                title="FECHAR"
                className="material-icons"
                onClick={closedModal}
              >
                close
              </span>
            ) : (
              ""
            )}
          </div>

          <div className="formGroup formGroup--visible leitura">
            <h3>Leitura</h3>
            <div className="formInput">
              <input
                type="radio"
                id="leituraSim"
                value="sim"
                name="inputLeitura"
                onClick={() => props.handleSelectLeitura(data)}
                onChange={handelInputLeitura}
              />
              <label htmlFor="leituraSim"> Sim</label>

              <input
                type="radio"
                value="nao"
                id="leituraNao"
                name="inputLeitura"
                onChange={handelInputLeitura}
                onClick={() => props.handleRemoveLeitura(data)}
              />
              <label htmlFor="leituraNao"> Não</label>
            </div>
          </div>

          <div className="formGroup momentoLeitura">
            <h3>Momento</h3>
            <div className="formInput">
              {(props.parts || []).map((type, i) => (
                <div key={i}>
                  <input
                    value={type.id}
                    type="radio"
                    name="inputMomentoLeitura"
                    id={type.name}
                    onChange={handleInputMomentoLeitura}
                    onClick={() => props.handlePartLeitura(type.id, data)}
                  />
                  <label htmlFor={type.name}> {type.name}</label>
                </div>
              ))}
            </div>
          </div>

          <div className="formGroup votacao">
            <h3>Votação</h3>
            <div className="formInput">
              <input
                type="radio"
                value="sim"
                id="votacaoSim"
                name="inputVotacaoSimOrNao"
                onChange={handelInputVotacao}
              />
              <label htmlFor="votacaoSim"> Sim</label>

              <input
                type="radio"
                value="nao"
                id="votacaoNao"
                name="inputVotacaoSimOrNao"
                onChange={handelInputVotacao}
              />
              <label htmlFor="votacaoNao"> Não</label>
            </div>
          </div>

          <div className="formGroup tipoDeVotacao">
            <h3>Tipo de Votação</h3>
            <div className="formInput">
              <input
                type="radio"
                value="primeiraVotacao"
                name="inputMomentoVotacao"
                id="primeiraVotacao"
                onChange={handleinputTipoDeVotacao}
                onClick={() => props.handleSelectFirstVoting(data)}
              />
              <label htmlFor="primeiraVotacao"> 1º votação</label>

              <input
                type="radio"
                value="segundaVotacao"
                name="inputMomentoVotacao"
                id="segundaVotacao"
                onChange={handleinputTipoDeVotacao}
                onClick={() => props.handleSelectSecundVoting(data)}
              />
              <label htmlFor="segundaVotacao"> 2º votação</label>

              <input
                type="radio"
                value="votacaoUnica"
                name="inputMomentoVotacao"
                id="votacaoUnica"
                onChange={handleinputTipoDeVotacao}
                onClick={() => props.handleSelectUrgent(data)}
              />
              <label htmlFor="votacaoUnica"> Votacao Única</label>
            </div>
          </div>

          <div className="formGroup momentoDeVotacao">
            <h3>Momento</h3>
            <div className="formInput">
              {(props.parts || []).map((type, i) => (
                <div key={i}>
                  <input
                    value={type.id}
                    type="radio"
                    name="inputMomentVotacao"
                    id={type.name + " "}
                    onChange={handelInputMomentoVotacao}
                    onClick={() => props.handlePartVotacao(type.id, data)}
                  />
                  <label htmlFor={type.name + " "}> {type.name + " "}</label>
                </div>
              ))}
            </div>
          </div>

          <div className="formGroup secret">
            <h3>Votação Secreta</h3>
            <div className="formInput">
              <input
                type="radio"
                value="sim"
                id="secretSim"
                name="inputSecretSimOrNao"
                onClick={() => props.handleSelectSecret(data)}
                onChange={handelInputSecret}
              />
              <label htmlFor="secretSim"> Sim</label>

              <input
                type="radio"
                value="nao"
                id="secretNao"
                name="inputSecretSimOrNao"
                onClick={() => props.handleRemoveSecret(data)}
                onChange={handelInputSecret}
              />
              <label htmlFor="secretNao"> Não</label>
            </div>
          </div>

          <div style={{margin:0}} className="formGroup activo preview">
            <h3>Validar Escolhas:</h3>
            <div>
              {previewChoices && (
                <PreviewChoices
                  className="preview"
                  previewChoices={previewChoices}
                />
              )}
            </div>
          </div>
          <div className="save">
            <button onClick={handleSaveChoices} disabled={!isSaveButtonEnabled}>
              SALVAR
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
