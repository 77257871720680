import React from "react";
import PropTypes from "prop-types";
import { Grid, Box } from "@material-ui/core";
import Timer from "../Util/timer";
import default_face from "assets/img/faces/marc.jpg";

class Talk extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }

  render() {
    const {
      id,
      className,
      photo,
      time,
      paused,
      timerChangeColorStage1Time,
      timerChangeColorStage2Time,
      timerStartCallback,
      timerStopCallback,
      timerPauseCallback,
      timerResumeCallback,
      timerOnChangeTime,
    } = this.props;

    return (
      <Box className={className} style={{ width: "100%" }}>
        <Grid container spacing={0}>
          {photo && (
            <Grid item xs={4} className={"boxAvatar transition " + (time ? "left" : "")}>
              <figure>
                <img src={photo || default_face} />
              </figure>
            </Grid>
          )}
          <Grid item xs className={"time boxTimer transition " + (time && photo ? "right" : "center")}>
            {time ? (
              <Timer
                id={id}
                ref={this.child}
                format="mm:ss"
                paused={paused}
                timerStart={time}
                timerType="regressive"
                changeColor={true}
                changeColorStage1Time={timerChangeColorStage1Time}
                changeColorStage2Time={timerChangeColorStage2Time}
                chageColorEfect={true}
                changeColorEfect={true}
                startCallback={timerStartCallback}
                stopCallback={timerStopCallback}
                pauseCallback={timerPauseCallback}
                resumeCallback={timerResumeCallback}
                onChangeTime={timerOnChangeTime}
              />
            ) : (
              <div>00:00</div>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  }
}

Talk.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  photo: PropTypes.string,
  time: PropTypes.string,
  paused: PropTypes.bool,
  timerChangeColorStage1Time: PropTypes.string,
  timerChangeColorStage2Time: PropTypes.string,
  timerStartCallback: PropTypes.func,
  timerStopCallback: PropTypes.func,
  timerPauseCallback: PropTypes.func,
  timerResumeCallback: PropTypes.func,
  timerOnChangeTime: PropTypes.func,
};

export default Talk;
